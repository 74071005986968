// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
//import Reports from '../components/ReportsnoDate'
import Reports from '../components/Reports'
import { Redirect } from "react-router-dom";

let users = storeGlobal.getusers();

class saleReport extends Component {
  constructor() {
    super()
    this.state = {
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      value:{
        //urls:"http://localhost:3001/api/v1/report/stock",
        urls: window.url + "api/v1/report/product-refill",
        title:"Product refill report ",
        //header:["Item","Date", "Vending ID", "User Name", "Channal", "Product","Before refilling","Add","Latest number"],
        header:["ITEM","DATE", "VENDING ID", "USER NAME", "CHANNAL", "PRODUCT","BEFORE REFILLING","ADD PRODUCT","CURRENT QUANTITY","FLAG"],
        defaultValue:[['-','-','-','-','-']],
        defaultSum:['','','','',''],
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:20,
        startDate: new Date(),
        endtDate: new Date(),
        brabchidSelect:[],
        typeSelectFilter:"useDate"
      },
      redirect: false,
      location: {}
    }
  }

  componentWillMount() {
    let stateSave = storeGlobal.getStateSave();
    //+-console.log("componentWillMount =>",stateSave)
    if(stateSave !== undefined && stateSave.goBackSaleEn === true){
      let value = this.state.value
      value.startDate = stateSave.startDate
      value.endDate = stateSave.endDate
      value.brabchidSelect = stateSave.brabchidSelect
      stateSave.goBackSaleEn = false
      stateSave.gotoBack = "/salesreport"
      storeGlobal.setStateSave(stateSave)
      this.setState({
        value: value
      })
    }
  }

  changFormatDate = (dateIn) => {  //chang date format invalid frome 'DD/MM/YYYY HH:SS' to 'YYYY-MM-DD HH:MM'
    let tempArr = dateIn.split("/");
    return (tempArr[2] + '-' + tempArr[1] + '-' + tempArr[0])
  }

  showDetail = (dataCallback) => {
    //+-console.log("dataCalback =>",dataCallback)
    //+-console.log(dataCallback.item[0])
    let dateTime =  this.changFormatDate(dataCallback.item[0])
    //+-console.log(dateTime)
    let startDate = new Date(dateTime)
    startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
    let endDate = new Date(dateTime)
    endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59);
    let branchIDs = []
    for (let x in dataCallback.branchID) {
      branchIDs.push({branchID: dataCallback.branchID[x]})
    }

    let stateSave = storeGlobal.getStateSave()
    if(stateSave !== undefined){
      stateSave['startDate'] = dataCallback.startDate
      stateSave['endDate'] = dataCallback.endDate
      stateSave['brabchidSelect'] = dataCallback.branchID
      stateSave['goBacTopUpkEn'] = false
      stateSave['goBackSaleEn'] = true
      stateSave['gotoBack'] = "/salesreport"
      storeGlobal.setStateSave(stateSave)
    }
    //+-console.log("stateSave =>",stateSave)

    let values = {}
    let value = {}
    value.urls = window.url + "api/v1/report/row-data"
    value.title = "Detail Sales Report "
    //value.headerShow = ["date","branch","name","type","payType","payValue","payChange","cardNoRef"] 
    value.headerShow = ["date","vending name","product","Report type","Payment Type","Value","Change","Card no/Ref no"] 
    values.projection = {date:1,branch:1,name:1,type:1,payType:1,payValue:1,payChange:1,cardNoRef:1}
    values.collections=dataCallback.organization+"_transaction"
    values.countLimit = 0
    values.countSkip = 0
    values.branchID = dataCallback.branchID
    values.organization = dataCallback.organization
    values.header = ["date","branch","name","type","payType","payValue","payChange","cardNoRef"] 
    values.query = { 
      $and:[
        {date: {$gte: startDate, $lt: endDate }},
        {$or:branchIDs},
        {$or:[{type:'Sale'}]}
      ]
    }
    value.values = values

    //+-console.log("value =>",value)

    let location = {
      pathname: "/detailreports",
      state: {
        value:value
      }
    };

    this.setState({
      location: location,
      redirect: true
    });

  }

//dfasdgfghjjk42
  render() {
    users = storeGlobal.getusers();
    //+-console.log("Products user -->",users)
    if (this.state.redirect) return <Redirect to={this.state.location} />;
    return (
      <Reports 
        user = {users} 
        props = {this.props} 
        value = {this.state.value}
        //showDetail = {this.showDetail}
      >
      </Reports>
    );
  }
}

export default saleReport

 