import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  // Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalUpload from "../components/ModalUpload";
import ModalFormSmall from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import Moment from 'react-moment';

class UpdateSoftware extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      users: [],
      usersPage: [],
      userEdit: null,

      modalAddUserShow: false,
      modalEditUserShow: false,
      modalUploadShow: false,
      modalWorning:'',
      modalForm: {
        name: '',
        fileName: '',
        deployDate: '',
        status: '',
        // role: props.roleAll[0] || '',
        branchID: [],
        // organization: props.organizationAll[0] || '',
      },
      modalFormDisabled: {
        userID: false,
        username: false,
        password: false,
        // fullname: false,
        // role: false,
        // branchID: false,
        // organization: false,
      },
      modalFormSelected: {
        role: '',
        // branchID: [],
        organization: '',
      },

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      usersPageLimit: 7,
      
      btDeployDisnabled: true,
      fileUpload: [{fileName:'a.zip', path:'/upload/a.zip'}, {fileName:'b.zip', path:'/upload/b.zip'}],

      deployText: 'Synchronize',
      syncText: 'Success',

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization",

      alertShow: false,
      alertText: ''
    };

    // this.handleClick = this.handleClick.bind(this);

  }

  // componentDidMount() {
  //   this.setState({title: 'company'});
  //   //+-console.log('this.state 1 -->', this.state)
  //   this.getbranchs({OrganizationSelect: this.state.user.organization[0]})
  //   this.getFiles({OrganizationSelect: this.state.user.organization[0]})
  // }

  componentDidMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){

      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: this.state.user.OrganizationSelect,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }

      this.setState({
        OrganizationSelect: users.OrganizationSelect,
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },() =>{
        this.getbranchs(dataCallback)  
        this.getFiles(dataCallback)
      })
    }
  }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteUser(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.fullname}"
            </SweetAlert>
          )
        }));
        break
        case 'deploy':
          this.setState( state => ({
            alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, deploy it!"
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=> {this.deploy(e)} } //{this.deleteFile}
                onCancel={ this.hideAlert.bind(this) }
                >
                Deploy "{e.fullname}"
              </SweetAlert>
            )
          }));
          break
          default:
          break
    }
		
  } 

  deleteUser(user) {
    //+-console.log('deleteUser =>', user)
    let THIS = this
    // axios({
    //   method: 'delete',
    //   //url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization + '/' + user.id,
    //   url: window.url + 'api/v1/users/' + this.state.user.organization + '/' + user.id,
    //   data: 'values',
    //   headers:{
    //     "Authorization": this.state.user.token
    //   }
    // })
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/'+ this.state.OrganizationSelect +'/update/' + user.id,
      data: {flag: 'delete', updateBy: this.state.user.username, status: 'delete'},
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // console.log('deleteUser response.data.value =>', response.data.value);  
      // let users = [...THIS.state.users]
      // users.splice(user.i, 1)
      let users = THIS.state.users 
      users = users.filter(person => person._id !== user.id)
      THIS.setState({ 
        users: users
      },() => {        
        THIS.paginationItemCal()
        // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
        if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
      })
      THIS.hideAlert()

      let usersPage = THIS.state.usersPage 
      usersPage = usersPage.filter(person => person._id !== user.id)
      // console.log('usersPage =>', usersPage); 
      THIS.state.usersPage = usersPage
      THIS.setState({usersPage: usersPage})
      if(!usersPage.length) THIS.paginationActive(1)
    
      
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
    
  }
  
  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.usersPage(page)
  }

  usersPage(page) {
		let usersPage = []
    let start = (page-1) * this.state.usersPageLimit
    let end = start + this.state.usersPageLimit
    if(end >= this.state.users.length ) {
      end = this.state.users.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      usersPage.push(this.state.users[i])
    }
    this.setState({usersPage: usersPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.users.length; i+=this.state.usersPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }
    //this.state.paginationItem = paginationItem
    this.setState({paginationItem: paginationItem})
  }
  
  getbranchs = (dataCallback) => {
    //+-console.log("getbranchs ->",dataCallback);
    this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    let THIS = this
    axios({
      method: 'get',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization[0],
      //url: 'http://localhost:3001/api/v1/users/' + dataCallback.OrganizationSelect,
      url: window.url + 'api/v1/collection/'+dataCallback.OrganizationSelect+'/update', //?' + 'organization=' + dataCallback.OrganizationSelect+ '&type=software',
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log('response.data.value =>', response.data.value);  
      let users = response.data.value
      // // console.log('=====>', dataCallback.BranchidSelect); 
      // filter
      users = users.filter(person => {
        // console.log('=====> 1', person.branchID); 
        // return (dataCallback.BranchidSelect.indexOf(person.branchID) >= 0)
        if(person.flag !== 'delete' ){
          return true
        }          
      });

      // // check status for enable deploy button
      // for(let i=0; i<users.length; i++) {
      //   if(users[i].status != 'deploy' && users[i].status != 'sync') {
      //     THIS.setState({
      //       btDeployDisnabled: false
      //     })
      //     break
      //   }
      // }

      // sort by value
      // items.sort(function (a, b) {
      //   return a.value - b.value;
      // });
      // sort by name
      users.sort(function(a, b) {
        var nameA = (new Date(b.updateDate).getTime())
        var nameB = (new Date(a.updateDate).getTime())
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      // THIS.setState({users: response.data.value})
      // console.log('THIS.state 2 -->', THIS.state)
      THIS.setState({
        users: users
      },() => {
        THIS.paginationItemCal()
        THIS.usersPage(1)        

      });
    }) 
    .catch(function (error) {
      console.log(error);
      // alert("error: " + JSON.stringify(error) );
    });
  }
  
  getFiles = (dataCallback) => {
    //+-console.log("getFiles ->",dataCallback);
    let THIS = this
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/'+dataCallback.OrganizationSelect+'/upload', 
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log('getFiles response.data.value =>', response.data.value);  
      THIS.state.fileUpload = response.data.value
      THIS.setState({
        fileUpload: THIS.state.fileUpload
      },() => {
        //+-console.log('getFiles fileUpload =>', THIS.state.fileUpload);  
      });
    }) 
    .catch(function (error) {
      console.log('getFiles error =>', error);
      // alert("error: " + JSON.stringify(error) );
    });
  }

  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.getFiles({OrganizationSelect: this.state.OrganizationSelect})
    this.setState({ 
      modalWorning: "", 
      modalAddUserShow: false, 
      modalEditUserShow: false, 
      modalUploadShow: false })
    //+-console.log('modalClose this.state.modalForm =>', this.state.modalForm)
  };

  getForm = (form) => {
    console.log('getForm =>', form, this.state.modalForm);
    this.setState({modalWorning: ""}) 
    let dataObj = {}   
    let worningFlag = false
    this.state.modalForm.forEach((d, i) => {
      // console.log("data.forEach d, i =>", d, i ); 
      if(d.value === '') {
        // console.log("return this.setState({modalWorning" ); 
        worningFlag = true
        return this.setState({modalWorning: "Input data is blank!"})
      }
      if(d.key === 'branchID') {
        if(!d.value.length) {
          worningFlag = true
          return this.setState({modalWorning: "Vending ID is blank!"})
        }
      }
      dataObj[d.key] = d.value
    })
    if(worningFlag) return 
    
    let file = this.state.fileUpload.find((f) => f.fileName === dataObj.fileName)
    const projectSelect = this.state.user.project.find( ({ projectName }) => projectName === dataObj.project );
    // console.log("dataObj, projectSelect =>", dataObj, projectSelect );
    let data = {  
      "branchID" : dataObj.branchID,
      "name" : dataObj.name,
      "createBy" : this.state.user.username,
      "updateBy" : this.state.user.username,
      "status" : "New",
      "path" : file.path,
      "createDate" : new Date(),
      "deployDate" : new Date(),
      "updateDate" : new Date(),
      "fileName" : dataObj.fileName,
      "flag" : "publish",
      projectType: projectSelect.projectType
    }    
    //+-console.log("data =>", data)
    // return
    let THIS = this
    axios({
      method: 'post',
      //url: 'http://localhost:3001/api/v1/users/' + this.state.user.organization,
      url: window.url + 'api/v1/collection/' + THIS.state.OrganizationSelect + '/update',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      let userAdd = response.data.value[0]
      //+-console.log("saveAddUser response =>", response.data.value[0]);  
      THIS.state.usersPage.unshift(userAdd)
      THIS.state.users.unshift(userAdd)
      THIS.setState({usersPage: THIS.state.usersPage, users:THIS.state.users})
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Add user error!"})
    });

  }

  editUser = (item) => {    
    //+-console.log('editUser item =>', item);
    let modalAddUserTitle = 'Edit Update'
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }
    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: true, type: 'input' }, 
      { key: "fileName", name: "File Name", placeholder:"", value: item.fileName, disabled: false, type: 'select', items: this.state.fileNames }, 
      // { key: "deployDate", name: "Deploy Date", placeholder:"", value: item.deployDate, disabled: false, type: 'input' }, 
      // { key: "status", name: "Status", placeholder:"", value: item.status, disabled: true, type: 'input' }, 
      // { key: "description", name: "Description", placeholder:"", value: item.description, disabled: false, type: 'textarea', rows: 3 },
      { key: "branchID", name: "Vending ID", placeholder:"", value: item.branchID, disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({ 
      modalForm: modalForm, 
      modalAddUserTitle: modalAddUserTitle,
      modalEditUserShow: true,
      userEdit: item, 
    })
  }

  addUser = () => {
    //+-console.log('addUser item =>');
    // this.state.modalAddUserTitle = 'Edit Update'
    let fileNames = []
    this.state.fileUpload.forEach((f, i) => {
      if(f.type==='software') fileNames.push(f.fileName)
    })
    // if(fileNames.length) fileNames.unshift('All')

    let optionProjects = []
    this.state.optionProjects.forEach((f, i) => {
      optionProjects.push(f.value)
    })
    if(optionProjects.length) optionProjects.shift() 

    let optionBranchs = []
    this.state.user.branch.forEach((f, i) => {
      if(f.projectName === optionProjects[0]) optionBranchs.push(f.branchID)
    })
    // console.log('----- this.state.user.branch =>', this.state.user.branch);
    let modalForm = [ 
      { key: "name", name: "Name", placeholder:"", value: '', disabled: false, type: 'input' }, 
      { key: "fileName", name: "File Name", placeholder:"", value: fileNames[0], disabled: false, type: 'select', items: fileNames }, 
      { key: "deployDate", name: "Deploy Date", placeholder:"", value: new Date(), disabled: false, type: 'date-picker' }, 
      // { key: "status", name: "Status", placeholder:"", value: item.status, disabled: true, type: 'input' }, 
      // { key: "description", name: "Description", placeholder:"", value: item.description, disabled: false, type: 'textarea', rows: 3 },
      { key: "project", name: "Project", placeholder:"", value: optionProjects[0], disabled: false, type: 'select', items: optionProjects },
      { key: "branchID", name: "Vending ID", placeholder:"", value: [], disabled: false, type: 'select-multi', items: optionBranchs }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({ 
      modalForm: modalForm, 
      modalAddUserShow: true,
    })
  }

  deploy = (e) => {      
    // console.log('deploy data =>', data); return
    let dataResponse = this.state.dataResponse
    let deployDatas = e.data
    let paginationActive = this.state.paginationActive

    this.paginationItemCal(dataResponse, paginationActive)

    let branchs = []
    for(let j=0; j<deployDatas.branchID.length; j++) {
      branchs.push({
        name: deployDatas.name,
        type: 'software',
        status: this.state.deployText,
        branchID: deployDatas.branchID[j],
        projectType: deployDatas.projectType,
        deployID: deployDatas._id,
        updateBy: this.state.user.username,
        deployTime: (new Date(deployDatas.deployDate)).getTime(),

      })
    }

    //+-console.log('branchs =>', branchs);
    // Get all branchID
    let THIS = this
    // Insert to deploy collections
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + THIS.state.OrganizationSelect + '/deploy/many', 
      data: branchs,
      headers:{
        "Authorization": THIS.state.user.token
      }
    })
    .then(function (response) {
      // console.log("Insert deploy response =>", response); 
      // delete deployDatas.branchID
      let obj = {
        status: THIS.state.deployText,
        updateBy: THIS.state.user.username,
      }
      axios({
        method: 'put',
        url: window.url + 'api/v1/collection/' + THIS.state.OrganizationSelect + '/update/' + deployDatas._id, 
        data: obj,
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("Insert deploy response =>", response); 
        let users = THIS.state.users 
        users.forEach((element, index) => {
          if(element._id === deployDatas._id) {
            users[index].status = THIS.state.deployText;
          }
        });
        THIS.setState({ 
          users: users
        },() => {        
          THIS.paginationItemCal()
          // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
          if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
        })
        THIS.hideAlert()

        let usersPage = THIS.state.usersPage 
        usersPage.forEach((element, index) => {
          if(element._id === deployDatas._id) {
            usersPage[index].status = THIS.state.deployText;
          }
        });
        THIS.state.usersPage = usersPage
        THIS.setState({usersPage: usersPage})
        if(!usersPage.length) THIS.paginationActive(1)
      })
      .catch(function (error) {
        console.log('error ->', error);
        THIS.setState({modalWorning: "Insert deploy error!"})

      });
    })
    .catch(function (error) {
      console.log('error ->', error);
      THIS.setState({modalWorning: "Insert deploy error!"})

    });

  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("----- handleChange target.name =>", target.name);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    let modalForm = this.state.modalForm
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value        
        break
      }
    }
    if(target.name === 'Project') {
      const result = modalForm.find( ({ key }) => key === 'branchID' );
      let optionBranchs = []
      this.state.user.branch.forEach((f, i) => {
        if(f.projectName === target.value) optionBranchs.push(f.branchID)
      })
      result.value = []
      result.items = optionBranchs
    }

    this.setState({modalForm: modalForm})
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddUserShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };

    return (   
      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content title="Update software">
         { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
          <Card>
            <Card.Header>
              <Card.Title>Update</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button.List>
                  <Button 
                    color="blue"                  
                    onClick={ () => this.setState({modalUploadShow:true}) }
                  > Upload file </Button>  
                  <Button 
                    color="green"                  
                    onClick={ () => this.addUser() }
                  > New Update </Button>  
                </Button.List>              
              </Card.Options>
              }
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Name</Table.ColHeader>    
                  <Table.ColHeader>file name</Table.ColHeader>
                  <Table.ColHeader>update by</Table.ColHeader>
                  <Table.ColHeader>deploy date</Table.ColHeader>
                  <Table.ColHeader>update date</Table.ColHeader>
                  <Table.ColHeader>status</Table.ColHeader>
                  { this.state.user.level==='write' && <Table.ColHeader className="text-center">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
              { this.state.usersPage.length ? this.state.usersPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    <Table.Col>{ item.name }</Table.Col>
                    <Table.Col>{ item.fileName }</Table.Col> 
                    <Table.Col>{ item.updateBy }</Table.Col> 
                    <Table.Col><Moment format="DD/MM/YYYY HH:mm">
                        { item.deployDate }
                      </Moment></Table.Col> 
                      <Table.Col><Moment format="DD/MM/YYYY HH:mm">
                        { item.updateDate }
                      </Moment></Table.Col>
                    <Table.Col>{ item.status }</Table.Col> 
                    { this.state.user.level==='write' && <Table.Col className="text-center">
                      <Button.List>
                        {/* <Button size="sm" color="info"
                          onClick={ () => this.editUser(item) }
                          >
                          Edit
                        </Button>  */}

                        { (()=> {
                          if(item.status === this.state.deployText) {
                            return <Button size="sm" color="default"> Deploy </Button>
                          } else {
                            return <Button size="sm" color="warning"
                                    onClick={ ()=> { this.alert({action:'deploy', id: item._id, fullname: item.name, i: i, data: item }) } } 
                                    > Deploy </Button>
                          }
                        })() }
                        
                        <Button size="sm" color="danger" 
                          onClick={ ()=> { this.alert({action:'delete', id: item._id, fullname: item.name, i: i }) } } >
                          Delete
                        </Button>                        
                      </Button.List>
                    </Table.Col> 
                    }          
                  </Table.Row>
                ): <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>

        <ModalFormSmall
          show={this.state.modalAddUserShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={'New Update'}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

        <ModalUpload
          show={this.state.modalUploadShow}
          onHide={this.modalClose}
          title={'Upload File'}    
          worning={this.state.modalWorning}        
          organization={this.state.OrganizationSelect}
          uri={window.url + 'upload'}
          type={'software'}
          filetype={['application/x-zip-compressed', 'application/octet-stream']}
        />

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default UpdateSoftware;