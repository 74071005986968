// import React,{Component} from 'react'
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "react-google-maps";

// class Map extends Component{
//   constructor(props) {
//     super(props);
//     this.state = {
//       openInfoWindowMarkerId: '',
//     }
//   }

//   componentDidMount() {
//     console.log(this.props)
//   }

//   handleClickMarker = (place) => {
//     console.log('handleClickMarker =>', place)
//     const isOpen = !this.state.isOpen
//     this.setState({isOpen})
//     this.props.onClickMarker(place)
//   }

//   handleToggleOpen = (markerId) => {
//     console.log('handleToggleOpen =>', markerId)
//     this.setState({
//       openWindowMarkerId: markerId
//     });
//     this.props.onClickMarker(markerId)
//   }

//   render() {
//     const MapWithAMarker = withScriptjs(withGoogleMap(props =>
//       <GoogleMap
//         defaultZoom={props.props.zoom}
//         defaultCenter={props.props.center}
//       >
//         {props.props.places.map( (place) => {
//           return (
//             <Marker
//               key={place.id}
//               position={{ lat: place.lat, lng: place.lng }}
//               draggable={true}
//               label={''}
//               onDragstart={() => {
//                 // return handleDragStart();
//               }}
//               onDragend={() => {
//                 // return handleDragEnd();
//               }}
//               // onClick={this.handleClickMarker.bind(this, place)}
//               onClick={() => this.handleToggleOpen(place.id)}
//               options={ ( (place.status=='online') && {icon:`http://maps.google.com/mapfiles/marker_green.png`}) }
//             >
//               {(this.state.openWindowMarkerId == place.id ) &&
//                 <InfoWindow
//                     // onCloseClick={this.handleToggle}
//                     >
//                   <div>
//                     <h4>{place.name}</h4>
//                     <p>{place.title}</p>
//                   </div>
//                 </InfoWindow>
//               }
//             </Marker>
//           );
//         })}
//       </GoogleMap>

//     ));

//     return (
//       <MapWithAMarker
//         googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyClkZIciNzGwR7HLpNgyROEd1T1IwLsdCg"
//         loadingElement={<div style={{ height: `100%` }} />}
//         containerElement={<div style={{ height: `400px` }} />}
//         mapElement={<div style={{ height: `100%` }} />}
//         props={this.props}
//       />
//     )
//   }
// }

// export default Map;

import React,{Component} from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Button } from "tabler-react";
import ReactDOM from "react-dom";

export class InfoWindowEx extends Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement(`div`);   

  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {
      place: {}
    },
    bounds: null,

    latInitialCenter: 13.808051,
    lngInitialCenter: 100.5588903,

  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    },() => {
      //+-console.log('props, marker, e =>', props )
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  makeBounds = () => {
    //+-console.log('makeBounds() this.state.selectedPlace.place =>', this.state.selectedPlace.place)
    var points = [
      // { lat: 42.02, lng: -77.01 },
      // { lat: 42.03, lng: -77.02 },
    ]
    for (let i = 0; i < this.props.places.length; i++) {
      points.push( { lat: parseFloat(this.props.places[i].lat), lng: parseFloat(this.props.places[i].lng) });
    }
    // console.log('points =>', points)
    var bounds = new this.props.google.maps.LatLngBounds();
    for (let i = 0; i < points.length; i++) {
      // console.log('points[i] =>', i, points[i])
      bounds.extend(points[i]);
    }

    if(this.props.places.length < 2) bounds=null

    this.setState({bounds})
  }

  onReady = () => {
    //+-console.log('onReady() this.props =>', this.props)
    this.makeBounds()
    
  }

  render() {
    return (
      <Map google={this.props.google}
        onClick={this.onMapClicked}
        style={{width: '100%', height: '100%', position: 'relative'}}
        initialCenter={{
          lat: this.state.latInitialCenter, //13.8059513, 
          lng: this.state.lngInitialCenter //100.8384445
        }}
        onReady={this.onReady}
        bounds={this.state.bounds}
        zoom={15} 
        >
        {this.props.places.map( (place) =>
          <Marker
            key={place._id}
            place={place}
            // label ={{text: 'sdfsdfsdfsdfsdf', color: 'red'}}
            onClick={this.onMarkerClick}
            name={place.name}
            position={{ lat: place.lat, lng: place.lng }}
            icon={ (place.status==='online' || place.status==='Online') ? {
              url: "/map/go.png",
              anchor: new this.props.google.maps.Point(32,32),
              scaledSize: new this.props.google.maps.Size(64,64)
              } :
              {
                url: "/map/red-square.png",
                anchor: new this.props.google.maps.Point(32,32),
                scaledSize: new this.props.google.maps.Size(64,64)
              }
            }
          />

        )}
        <InfoWindowEx
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          >
            <div>
              <h5>{this.state.selectedPlace.place.name || ''} </h5>
              <p>{this.state.selectedPlace.place.title || ''} </p>
              <p>Status: {this.state.selectedPlace.place.status || ''}</p>
              <div className="text-center">
                  <Button size="sm" color="info" className="center-block"
                    onClick={ this.props.onClickDetail.bind(this, this.state.selectedPlace.place)}
                  >
                    Detail
                  </Button>
              </div>
            </div>
        </InfoWindowEx>
      </Map>
    )
  }
}

export default GoogleApiWrapper( (props) => ({
  apiKey: 'AIzaSyClkZIciNzGwR7HLpNgyROEd1T1IwLsdCg',
  places: props.places,
  onClickDetail: props.onClickDetail,
}))(MapContainer)




// import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// class SimpleMap extends Component {
//   static defaultProps = {
//     center: {
//       lat: 59.95,
//       lng: 30.33
//     },
//     zoom: 11
//   };

//   render() {
//     return (
//       // Important! Always set the container height explicitly
//       <div style={{ height: '100vh', width: '100%' }}>
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: 'AIzaSyClkZIciNzGwR7HLpNgyROEd1T1IwLsdCg' }}
//           defaultCenter={this.props.center}
//           defaultZoom={this.props.zoom}
//         >
//           <AnyReactComponent
//             lat={59.955413}
//             lng={30.337844}
//             text="My Marker"
//           />
//         </GoogleMapReact>
//       </div>
//     );
//   }
// }

// export default SimpleMap;