import { store } from 'react-easy-state'

// a complex global store with a lot of derived data (getters and setters)
// use 'todos' instead of 'this' in the store methods to make them passable as callbacks
const storeGlobal = store({
  user: {},
  props:{},
  filterBranchs:{},
  stateSave:{},
  getusers () {
    //console.log("storeGlobal.user =>>",storeGlobal.user);
    return storeGlobal.user
  },
  setusers (dataSet) {
    //console.log("setusers =>>",dataSet);
    storeGlobal.user = dataSet
    //console.log("storeGlobal.user =>>",storeGlobal.user);
  },getprops () {
    //console.log("storeGlobal.user =>>",storeGlobal.user);
    return storeGlobal.props
  },
  setprops (dataSet) {
    //console.log("setprops =>>",dataSet);
    storeGlobal.props = dataSet
    //console.log("storeGlobal.user =>>",storeGlobal.user);
  },
  getFilterBranchs () {
    //console.log("getFilterBranchs =>>",storeGlobal.filterBranchs);
    return storeGlobal.filterBranchs
  },
  setFilterBranchs (dataSet) {
    console.log("setFilterBranchs =>>",dataSet);
    storeGlobal.filterBranchs = dataSet
    //console.log("storeGlobal.user =>>",storeGlobal.user);
  },
  getStateSave () {
    //console.log("getFilterBranchs =>>",storeGlobal.filterBranchs);
    return storeGlobal.stateSave
  },
  setStateSave (dataSet) {
    console.log("stateSave =>>",dataSet);
    storeGlobal.stateSave = dataSet
    //console.log("storeGlobal.user =>>",storeGlobal.user);
  }
})

export default storeGlobal
