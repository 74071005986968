// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
//import Reports from '../components/ReportsnoDate'
import Reports from '../components/Reports'

//let users = storeGlobal.getusers();

class stockReport extends Component {
  constructor() {
    super()
    this.state = {
      users: storeGlobal.getusers(),
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      value:{
        //urls:"http://localhost:3001/api/v1/report/stock",
        urls: window.url + "api/v1/report/stock_ch",
        urlExport: window.url + "api/v1/report/stock_export_ch",
        title:"Stock ",
        firstBranchID: storeGlobal.getusers().branchID[0],
        // header:["Product","remine","MustAdd","Total"],
        //header:["Item", "sku", "Product", "Available amount", "Sold out", "Total"],
        header:["Vending ID", "date", "", "", "","", "ACTION"],
        headerExport: ["Vending ID", "date", "", "", "","", ""],
        // header:[
        //   {"name":"Vending ID", "class":"text-left"},
        //   {"name":"date", "class":"text-left"},
        //   {"name":"", "class":"text-center"},
        //   {"name":"", "class":"text-center"},
        //   {"name":"", "class":"text-center"},
        //   {"name":"ACTION", "class":"text-center"}
        // ],
        defaultValue:[['-','-','-','-','-','-']],
        defaultSum:['','','','','',''],
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:48,
        typeSelectFilter:"nodate",
      }
    }
  }

  showDetail = (itemIn,index) =>{
    console.log("index =>",index,"itemIn =>",itemIn)
    for(let i = 0; i < itemIn.length; i++){
      let item = itemIn[i] +''
      //console.log(item)
      let words = item.split(' ');
      //console.log(words[0])
      if((words[0] === '<<<') && (words[2] === '>>>')){
        console.log(words[1])
        console.log(words[3])
      }
    }
  }
//dfasdgfghjjk42
  render() {
    //users = storeGlobal.getusers();
    //+-console.log("Products user -->",this.state.users)
    return (
      <Reports 
        user={this.state.users} 
        props = {this.props} 
        value = {this.state.value}
        //showDetail = {this.showDetail}
        >
      </Reports>
    );
  }
}

export default stockReport
