import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text, Icon, } from "tabler-react";

class ModalResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback1: "",
      invalid1: false,
      value1: null,
      feedback2: "",
      invalid2: false, 
      value2: null,
    };
    // this.handleClick = this.handleClick.bind(this);

  }

  // componentDidMount() {
  //   console.log('componentDidMount ...')    
    
  // }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate prevProps =>', prevProps)
  // }

  // componentWillReceiveProps(prevProps) { 
  // }

  hasWhiteSpace = (s) => {
    return /\s/g.test(s);
  }

  handleSubmit = () => {
    console.log('======', this.state.value1, this.state.value2, this.props.resetId)
    if(!this.state.value1) {
      return this.setState({ 
        feedback1: "Required",
        invalid1: true,
      })
    }

    if(this.hasWhiteSpace(this.state.value1)) {
      return this.setState({ 
        feedback1: "Password must not contain spaces!",
        invalid1: true,
      })
    }

    if((this.state.value1.length < window.configUser.passwordMinLengh) || (this.state.value1.length > window.configUser.passwordMaxLengh)) {
      return this.setState({ 
        feedback1: "Invalid 'Password' length!",
        invalid1: true,
      })
    }

    if(!this.state.value2) {
      return this.setState({ 
        feedback2: "Required",
        invalid2: true,
      })
    }
    if(this.hasWhiteSpace(this.state.value2)) {
      return this.setState({ 
        feedback2: "Password must not contain spaces!",
        invalid2: true,
      })
    }

    if((this.state.value2.length < window.configUser.passwordMinLengh) || (this.state.value2.length > window.configUser.passwordMaxLengh)) {
      return this.setState({ 
        feedback2: "Invalid 'Password' length!",
        invalid2: true,
      })
    }

    if(this.state.value1 != this.state.value2) {
      return this.setState({ 
        feedback2: "Password is not the same",
        invalid2: true,
      })
    }
    
    this.props.onSave({ id: this.props.resetId, value: this.state.value2})
  }

  render() {
    // console.log('this.props.form =>', this.props.form)
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={this.handleSubmit}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Grid.Row>
            <Grid.Col>   
              <Form.Group label="Enter new password">
                <Form.Input
                  feedback={this.state.feedback1}
                  invalid={this.state.invalid1}
                  placeholder="length 4-16 must not contain spaces!"
                  required
                  type="password"
                  onChange={event => this.setState({value1: event.target.value, invalid1: false,})}
                />
              </Form.Group>
              <Form.Group label="Enter password again">
                <Form.Input
                  feedback={this.state.feedback2}
                  invalid={this.state.invalid2}
                  placeholder="length 4-16 must not contain spaces!"
                  required
                  type="password"
                  onChange={event => this.setState({value2: event.target.value, invalid2: false,})}
                />
              </Form.Group>
            </Grid.Col> 
          </Grid.Row>
          
        </Modal.Body>
        <Modal.Footer>
          {/* <div class="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>           */}
          <Button type="button" onClick={ this.props.onHide } >
            <Icon prefix="fe" name="arrow-left" className="mr-2" />
            BACK</Button>
          <Button color="danger" type="button" onClick={ this.handleSubmit} >Save</Button>
          {/* <Button color="danger" type="submit" >Save</Button> */}
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

ModalResetPassword.defaultProps = {
  title: 'Reset Password',
  // worning: "",
  resetId: null,
}

export default ModalResetPassword