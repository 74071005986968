// @flow
  import React,{Component} from 'react'
  import {  Page,  Icon,  Grid,  Card,  Table,  Button,  Form, Text } from "tabler-react";
  import SiteWrapper from "../components/SiteWrapper.react";
  import axios from 'axios';
  import ModalFormLarge from "../components/ModalFormImage";
  import SweetAlert from 'react-bootstrap-sweetalert'
  import Pagination from 'react-bootstrap/Pagination'
  import storeGlobal from "../components/globalStore";
  import PulseLoader from 'react-spinners/PulseLoader'
  import { Redirect } from "react-router-dom";
  import Select from 'react-select';
import { concatSeries } from 'async';
  //import Moment from 'react-moment';
  
  class branchSetting extends Component{
    constructor(props) {
      super(props);
      this.state = {
        user: storeGlobal.getusers(),

        modalWorning:"",
        modalFormTitle:"",
        // header:[
        //   {"name":"IMAGE", "class":"text-center"},
        //   {"name":"NAME", "class":"text-center"},
        //   {"name":"PRICE", "class":"text-center"},
        //   {"name":"GROUP", "class":"text-center"},
        //   {"name":"CHANNEL", "class":"text-center"},
        //   {"name":"CURRENT VALUE", "class":"text-center"},
        //   {"name":"MAX VALUE", "class":"text-center"},
        //   {"name":"EXP DATE", "class":"text-center"},
        //   {"name":"STATUS", "class":"text-center"},
        //   {"name":"ACTION", "class":"text-center"}
        // ],
        tempModalForm:{},
        modalForm: [
          { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'image', col: '2' }, 
          { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
          { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
          { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
          { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select',col: '2', items: [
            'p1', 'p2', 'p3'
          ] }, 
        ],

        alert: null,
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:6,
        organizItems:[],
        projectItems:[],
        dataResponse:{products:[]},
        dataShow:[],

        redirect: false,
        location: '/',
        btnSaveClick: false,
        btnSaveEnable: false,
        defaultFileUpload:{
          organization:'',
          uri:window.url + 'upload',
          type:'logo',
          filetype:['image/png', 'image/jpeg']
        },
        alertShow: false,
        alertText: '',
        isButtonLoading: true,
        isButtonSelectFileEnable: true,
        isFilePondEnable: true,

        Branchs: ['All'],
        deployText: 'Synchronize',
      }
    }

    refresh = () => {
      //+-console.log('props -->', this.props)
      let users = this.state.user
      if(!Object.entries(users).length) return this.goto401()
      if(this.props.location.state === undefined) return this.goto401()

      if(!(Object.entries(users).length === 0)){
        if(!(Object.entries(this.props.location.state).length === 0)){
          let THIS = this
          let organization = this.props.location.state.organization
          let id = this.props.location.state.id
          let defaultFileUpload = this.state.defaultFileUpload 
          defaultFileUpload['organization'] = organization 
          this.setState({defaultFileUpload:defaultFileUpload})
          //console.log('defaultFileUpload =>',defaultFileUpload)
          axios({
            method: 'get',
            url: window.url + 'api/v1/collection/' + organization + '/project/' + id,
            data: 'values',
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            //console.log("response -->",response.data)
            //console.log("THIS.state.branchInOrg -->",THIS.state.branchInOrg)
            if(response.data.value){
              let nowProject = JSON.parse(JSON.stringify(response.data.value))
              let BranchidSelect = []
              axios({
                method: 'get',
                url: window.url + 'api/v1/collection/' + organization + '/branch/',
                data: 'values',
                headers:{
                  "Authorization": users.token
                }
              })
              .then(function (response2) {
                //console.log('response2 =>',response2)
                let branchID = []
                let branchInOrg = []
                if(response2.data.value){
                  branchInOrg = response2.data.value
                    for(let j = 0; j < branchInOrg.length; j++){
                      if(nowProject.projectID == branchInOrg[j].projectID){
                        let k = 0;
                        for(;k < branchID.length; k++){
                          if(branchInOrg[j].branchID == branchID[k].label) break
                        }
                        if(k >=  branchID.length){
                          branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID}); 
                          BranchidSelect.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});   
                        }
                      }else if((branchInOrg[j].project == undefined) || (branchInOrg[j].project == null)){
                        let l = 0;
                        for(; l < branchID.length; l++){
                          if(branchInOrg[j].branchID == branchID[l].label) break  
                        }
                        if(l >=  branchID.length){
                          if(nowProject.vendingType !== undefined){
                            for(let o = 0; o < nowProject.vendingType.length; o++){
                              if(nowProject.vendingType[o] === branchInOrg[j].type){
                                branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});
                                break
                              }
                            }  
                          }else{
                            branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});  
                          }   
                        }
                      }else{
                        for(let m = 0; m < users.branch.length; m++){
                          if(users.branch[m].branchID === branchInOrg[j].branchID){
                            let n = 0;
                            for(; n < branchID.length; n++){
                              if(branchInOrg[j].branchID === branchID[n].label) break  
                            }
                            if(n >= branchID.length){
                              if(nowProject.vendingType !== undefined){
                                for(let o = 0; o < nowProject.vendingType.length; o++){
                                  if(nowProject.vendingType[o] === branchInOrg[j].type){
                                    branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});
                                    break
                                  }
                                }  
                              }else{
                                branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});  
                              }  
                            }
                          }
                        }  
                      }
                    }
                  THIS.setState({
                    dataResponse:response.data.value,
                    BranchidSelect: BranchidSelect,
                    optionBranchs:branchID,
                    branchInOrg: branchInOrg,
                    nowProject: nowProject,
                    oldBranchidSelect: BranchidSelect,
                    isButtonLoading: true
                  })
                }
              })
              .catch(function (error) {
                THIS.setState({
                  alertShow: true,
                  alertText: error.message
                })
                console.log('axios error =>',error.message);
              })
            }
          })
          .catch(function (error) {
            THIS.setState({
              alertShow: true,
              alertText: error.message
            })
            console.log('axios error =>',error.message);
          });
        }
      }
    }

    // componentDidMount() {
    //   let user = storeGlobal.getusers()
    //   if(user && user.username){
    //     let organization = user.OrganizationSelect
    //     let THIS = this
    //     axios({
    //       method: 'get',
    //       url: window.url + 'api/v1/collection/' + organization + '/branch/',
    //       data: 'values',
    //       headers:{
    //         "Authorization": user.token
    //       }
    //     })
    //     .then(function (response) {
    //       console.log('response =>',response)
    //       let branchID = []
    //       let users = THIS.state.user
    //       let branchInOrg = []
    //       if(response.data.value){
    //         branchInOrg = response.data.value
    //         for(let i = 0; i < users.branch.length; i++){
    //           for(let j = 0; j < branchInOrg.length; j++){
    //             if(users.branch[i].branchID == branchInOrg[j].branchID){
    //               let k = 0;
    //               for(;k < branchID.length; k++){
    //                 if(users.branch[i].branchID == branchID[k].label) break
    //               }
    //               if(k >=  branchID.length){
    //                 branchID.push({label:users.branch[i].branchID,value:users.branch[i].branchID});  
    //               }
    //             }else if(branchInOrg[j].project == undefined){
    //               let l = 0;
    //               for(; l < branchID.length; l++){
    //                 if(branchInOrg[j].branchID == branchID[l].label) break  
    //               }
    //               if(l >=  branchID.length){
    //                 branchID.push({label:branchInOrg[j].branchID,value:branchInOrg[j].branchID});  
    //               }
    //             }
    //           }
    //         }
    //       }
    //       THIS.setState({
    //         optionBranchs:branchID,
    //         branchInOrg: branchInOrg
    //       },THIS.refresh());
    //     })
    //     .catch(function (error) {
    //       THIS.setState({
    //         alertShow: true,
    //         alertText: error.message
    //       })
    //       console.log('axios error =>',error.message);
    //     })
    //   }
    // }

    componentDidMount() {
      let user = storeGlobal.getusers()
      // console.log('user now =>',user)
      if(user && user.username){
        let optionProjectType = []
        for(let i = 0; i < window.configProject.typeItems.length; i++){
          optionProjectType.push({label:window.configProject.typeItems[i],value:window.configProject.typeItems[i]})
        }
        this.setState({
          optionProjectType: optionProjectType
        })
        this.refresh()  
      }
    }
  
    handleClick(e) {
      console.log('this ->', e)
    }
  
    hideAlert() {
      this.setState(state => ({
        alert: null
      }));
    }
    
    alert(e) {
      //console.log(e)
      switch(e.action) {
        case 'delete':
          this.setState( state => ({
            alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=> {this.delete(e)} } //{this.deleteFile}
                onCancel={ this.hideAlert.bind(this) }
                >
                Delete "{e.name}"
              </SweetAlert>
            )
          }));
          break
        case 'deploy':
          this.setState( state => ({
            alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, Save it!"
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={()=> {this.saveConfig()} } //{this.deleteFile}
                onCancel={ this.hideAlert.bind(this) }
                >
                Save Project "{e.name}"
              </SweetAlert>
            )
          }));
        break
        default:
        break
      }
      
    } 
    
    delete(dataIn) {
      //+-console.log('delete =>', dataIn)
      let dataSave = this.state.dataResponse.products
      for(var i = 0; i < dataSave.length; i++){
        if(dataSave[i]._id === dataIn.id){
          dataSave.splice(i,1)
          i--
        }
      }
      let THIS = this
      let id = this.state.dataResponse._id
      let values = {
        products:dataSave,
        status:'deleteProduct',
        createBy:this.state.user.username,
        updateBy:this.state.user.username
      }
      axios({
        method: 'put',
        url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
        // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
        data: values,
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
        //THIS.setState({dataResponse:response.data.value})
        // let branchAdd = response.data.value[0]
        // THIS.state.branchsPage.unshift(branchAdd)
        // THIS.setState({usersPage: THIS.state.branchsPage})
        let products = THIS.state.dataResponse.products
        let paginationActive = THIS.state.paginationActive
        THIS.paginationItemCal(products,paginationActive)
        THIS.hideAlert()
      })
      .catch(function (error) {
        console.log(error);
        THIS.hideAlert()
      });    
    }
    
    // paginationActive(page) {
    //   this.setState(state => ({
    //     paginationActive: page
    //   }));
  
    //   this.usersPage(page)
    // }


    paginationActive(e) {
      let dataShow = this.state.dataResponse.products
      this.paginationItemCal(dataShow,e)
    }
  
    paginationItemCal(dataIn,paginationActive) {
      //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
      let page = this.state.countPerPage
      let pageCount = Math.ceil(dataIn.length / page)
      let tmppaginationActive = this.state.paginationActive 
      let paginationItem = []

      switch (paginationActive){
        case '>':
            tmppaginationActive++;
        break;
        case '<':
            tmppaginationActive--;
        break;
        case '>|':
            tmppaginationActive = pageCount;
        break;
        case '|<':
            tmppaginationActive  = 1;
        break;
        default:
            tmppaginationActive = paginationActive*1
        break;
      }

      if(tmppaginationActive > pageCount){
        tmppaginationActive = pageCount;
      }

      if(pageCount < 6){
        for(let i = 1; i <= pageCount; i++){
            paginationItem.push(i)
        }
      }else{
        if(tmppaginationActive < 4){
          for(let i = 1; i <= pageCount; i++){
            if(i < 6){
              paginationItem.push(i)
            }else if(i === 6){
              paginationItem.push(">")
            }else if(i === 7){
              paginationItem.push(">|")
            }else{
              break;
            }  
          }
        }else if(tmppaginationActive > (pageCount-3)){
          for(let i = 1; i < 8; i++){
            if(i > 2){
              paginationItem.push((pageCount - (7-i)))
            }else if(i === 2){
              paginationItem.push("<")
            }else if(i === 1){
              paginationItem.push("|<")
            }else{
              break;
            }  
          }  
        }else{
          for(let i = 1; i < 10; i++){
            switch(i){
              case 1:
                paginationItem.push("|<")
              break;
              case 2:
                paginationItem.push("<")
              break;
              case 3:
                  paginationItem.push(tmppaginationActive-2)
              break;
              case 4:
                  paginationItem.push(tmppaginationActive-1)
              break;
              case 5:
                paginationItem.push(tmppaginationActive)
              break;
              case 6:
                  paginationItem.push(tmppaginationActive+1)
              break;
              case 7:
                paginationItem.push(tmppaginationActive+2)
              break;
              case 8:
                  paginationItem.push(">")
              break;
              case 9:
                  paginationItem.push(">|")
              break;
              default:
                paginationItem.push(tmppaginationActive)  
              break
            }
          }  
        }
      }

      let dataShow = []
      if(dataIn.length){
        for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
          dataShow.push(dataIn[i])
        }
      }

      this.setState({
        dataShow: dataShow,
        paginationItem: paginationItem,
        paginationActive: tmppaginationActive
      })
    }
    
    modalClose = () => {
      // this.setState({modalWorning: ""})
      this.setState({ modalWorning: "", modalAddbranchShow: false })
      // console.log('this.state.modalForm =>', this.state.modalForm)
    };
  
    getForm = () => {
      const uuidv1 = require('uuid/v1');
      //console.log('uuidv1 ->',uuidv1());
      let modalForm = this.state.modalForm 
      //+-console.log('getForm modalForm =>', modalForm);  
      //+-console.log('getForm tempModalForm =>', this.state.tempModalForm);     
      let dataEdit = this.state.tempModalForm
      for(let k=0; k<modalForm.length; k++) {
        if(modalForm[k].value === '') {
          return this.setState({modalWorning: "Input is blank!"})
        }
        dataEdit[modalForm[k].key] = modalForm[k].value
        if(modalForm[k].key === 'image'){
          dataEdit['path']= modalForm[k].path 
        }
      }
      let THIS = this
      let id = this.state.dataResponse._id
      let values = dataEdit
      // let values = {
      //   status:dataEdit.status,
      //   createBy:dataEdit.createBy,
      //   updateBy:dataEdit.updateBy
      // }
      //+-console.log('values =>',values)
      axios({
        method: 'put',
        // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/'+ id,
        url: window.url + 'api/v1/collection/' + this.props.location.state.organization + '/project/'+ id,
        // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
        data: values,
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
        // let products = []
        // if(THIS.state.dataResponse.products !== undefined ){
        //   products = THIS.state.dataResponse.products
        // }else{
        //   products.push(dataSave) 
        // }
        // let paginationActive = THIS.state.paginationActive
        // THIS.paginationItemCal(products,paginationActive)
        THIS.setState({
          dataResponse: dataEdit,  
          btnSaveEnable: true
        })
        THIS.modalClose()
      })
      .catch(function (error) {
        console.log(error);
        THIS.setState({modalWorning: "Add product error"})
      });
  
    }

    saveConfig = () => {
      //let dataSave = this.state.dataResponse
      let THIS = this
      let id = this.state.dataResponse._id
      let values = JSON.parse(JSON.stringify(this.state.dataResponse));
      let user = storeGlobal.getusers()
      //console.log('user now =>',user)
      if(user && user.username){
        let organization = user.OrganizationSelect
        THIS.setState({btnSaveClick: true})

        values['status'] = 'saveProject'
        values['createBy'] = user.username
        values['updateBy'] = user.username
        delete values['_id']
        //+-console.log('saveProject =>',dataSave);  
        axios({
          method: 'put',
          //url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/'+ id,
          url: window.url + 'api/v1/collection/' + organization + '/project/'+ id,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": user.token
          }
        })
        .then(function (response) {
          //console.log("put project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
          let dataSave = {
            "type" : 'information', //information //warning // error
            "date" : new Date(),
            "surce" : 'projectSetting',
            "category" : 'webApp',
            "even" : 'Edit project',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Edit project =>'+ values.name
          }
          THIS.saveLog(dataSave)

          axios({
            method: 'get',
            url: window.url + 'api/v1/collection/' + organization + '/branch',
            data: 'values',
            headers:{
              "Authorization": user.token
            }
          })
          .then(function (response) {
            //console.log('get branch response =>',response)
            //console.log('THIS.state.oldBranchidSelect =>',THIS.state.oldBranchidSelect)
            let branchIDDeploy=[]
            if(response.data.value){
              let branchsEdit = []
              //console.log("response.data.value =>",response.data.value)
              //console.log("values.branchID =>",values.branchID)
              if(values.branchID.length){
                for(let j = 0; j < response.data.value.length; j++){
                  for(let i = 0; i < values.branchID.length; i++){
                    if(values.branchID[i] == response.data.value[j].branchID){
                      let tmpObj = {
                        _id : response.data.value[j]._id,
                        project : values.name,
                        projectID : values.projectID
                      }
                      if(branchsEdit.find(element => element._id ==  tmpObj._id) == undefined){
                        branchsEdit.push(tmpObj)
                      }
                      let z = 0;
                      for(; z < user.branch.length; z++){
                        if(user.branch[z].branchID == values.branchID[i]) break
                      }
                      if(z >= user.branch.length) {
                        let objOnebranch = {
                          branchID: response.data.value[j].branchID,
                          branchName: response.data.value[j].branch,
                          branchType :  response.data.value[j].type,
                          projectID:  values.projectID,
                          projectName: values.name
                        }
                        user.branch.push(objOnebranch)
                        user.branchID.push(response.data.value[j].branchID)
                      }

                      let w = 0;
                      for(; w < user.BranchidSelect.length; w++){
                        if(user.BranchidSelect[w] == values.branchID[i]) break
                      }
                      if(w >= user.BranchidSelect.length) {
                        if(user.BranchidSelect){
                          user.BranchidSelect.push(response.data.value[j].branchID)
                        }else{
                          user.BranchidSelect = [response.data.value[j].branchID]
                        }
                        //console.log('user.BranchidSelect =>',user.BranchidSelect)
                      }

                      // for stamp deploy user
                      let obj = {}
                      obj.type = 'project'
                      obj.branchID = values.branchID[i]
                      obj.updateBy = user.username
                      obj.status = THIS.state.deployText
                      obj.deployTime = (new Date()).getTime()
                      branchIDDeploy.push(obj)

                    }
                  }
                }

                for(let j = 0; j < response.data.value.length; j++){
                  for(let k = 0; k < THIS.state.oldBranchidSelect.length; k++){
                    if(THIS.state.oldBranchidSelect[k].value == response.data.value[j].branchID){
                      let tmpObj = {
                        _id : response.data.value[j]._id,
                        project : null,
                        projectID : null
                      }
                      if(branchsEdit.find(element => element._id ==  tmpObj._id) == undefined){
                        branchsEdit.push(tmpObj)
                      }
                    }
                  }  
                }

              }else if(THIS.state.oldBranchidSelect.length){
                for(let j = 0; j < response.data.value.length; j++){
                  for(let k = 0; k < THIS.state.oldBranchidSelect.length; k++){
                    if(THIS.state.oldBranchidSelect[k].value == response.data.value[j].branchID){
                      let tmpObj = {
                        _id : response.data.value[j]._id,
                        project : null,
                        projectID : null
                      }
                      if(branchsEdit.find(element => element._id ==  tmpObj._id) == undefined){
                        branchsEdit.push(tmpObj)
                      }
                    }
                  }
                }
              }
              //console.log('branchsEdit =>',branchsEdit)

              axios({
                method: 'patch',
                url: window.url + 'api/v1/collection/' + organization + '/branch',
                data: branchsEdit,
                headers:{
                  "Authorization": THIS.state.user.token
                }
              })
              .then(function (response) {
                //console.log("patch branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
                let oldBranchidSelect = []
                for(let i = 0; i < values.branchID.length; i++){
                  let tmpObj = {
                    label:  values.branchID[i],
                    value:  values.branchID[i],
                  }
                  oldBranchidSelect.push(tmpObj)
                }
                THIS.setState({
                  btnSaveEnable: false,
                  btnSaveClick: false,
                  oldBranchidSelect: oldBranchidSelect
                })
                THIS.hideAlert()
                //console.log("user =>",user)
                storeGlobal.setusers(user);     //update user

                // // deploy user edit branch
                // if(!branchIDDeploy.length) return
                // axios({
                //   method: 'post',
                //   url: window.url + 'api/v1/collection/' + organization + '/deploy/many' , 
                //   data: branchIDDeploy,
                //   headers:{
                //     "Authorization": user.token
                //   }
                // })
                // .then(function (response) {
                //   //console.log("update deploy collecton =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
                // })
                // .catch(function (error) {
                //   console.log(error);
                //   THIS.setState({modalWorning: "Stamp deploy user edit error!"})
                // });
                // // end deploy user edit branch

              })
              .catch(function (error) {
                console.log('error ->', error);
                THIS.setState({modalWorning: "Update error!"})
                THIS.setState({
                  btDeployDisnabled: false
                })
              });
            }
          })
          .catch(function (error) {
            THIS.setState({
              alertShow: true,
              alertText: error.message
            })
            console.log('axios error =>',error.message);
          })
        })
        .catch(function (error) {
          console.log(error);
          THIS.hideAlert()
          //THIS.state.btnSaveClick = false
          THIS.setState({btnSaveClick: false})
        });    
      }
    }

    edit = (item) => {    
      //+-console.log('addBranch item =>',item);
      let tempModalForm = JSON.parse(JSON.stringify(item))
          tempModalForm.status = 'Modify product'
      let modalFormTitle = "Modify product"
      let modalForm = [
        { key: "image", name: "image", placeholder:"", value: item.image, disabled: window.modalFormImageConfig.modifyProductVending.disableImage, type: 'image', path:item.path, col: '1' }, 
        { key: "sku", name: "SKU", placeholder:"", value: item.sku, disabled: window.modalFormImageConfig.modifyProductVending.disableSKU, type: 'input', col: '1' },
        { key: "name", name: "Name", placeholder:"", value: item.name, disabled: window.modalFormImageConfig.modifyProductVending.disableName, type: 'input', col: '2' }, 
        { key: "price", name: "Price", placeholder:"", value: item.price, disabled: window.modalFormImageConfig.modifyProductVending.disablePrice, type: 'input', col: '2' }, 
        { key: "group", name: "Group", placeholder:"", value: item.group, disabled: window.modalFormImageConfig.modifyProductVending.disableGroup, type: 'input', col: '2' }, 
        { key: "channel", name: "Channel", placeholder:"", value: item.channel, disabled: window.modalFormImageConfig.modifyProductVending.disableChannel, type: 'input', col: '2' }, 
        { key: "count", name: "Cerrent Value", placeholder:"", value: item.count, disabled: window.modalFormImageConfig.modifyProductVending.disableCerrentValue, type: 'input', col: '2' }, 
        { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: window.modalFormImageConfig.modifyProductVending.disableMaxValue, type: 'input', col: '2' }
      ]
      // console.log('modalForm =>', modalForm);
      //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
      this.setState({ 
        tempModalForm: tempModalForm,
        modalForm: modalForm,
        modalFormSave: modalForm,
        modalFormTitleSave: modalFormTitle,
        modalFormTitle: modalFormTitle,
        modalAddbranchShow: true,
        isButtonSelectFileEnable: false,
        isFilePondEnable: false
      })
    }

    add = () => {    
      //+-console.log('addBranch item =>');
      // let tempModalForm = {
      //   name : "",
      //   sku: "",
      //   image : "blank-image.png",
      //   path:"/products/blank-image.png",
      //   price : 0,
      //   group : 0,
      //   sold : 0,
      //   max : 24,
      //   count : 0,
      //   channel : 0,
      //   saleTime:"",
      //   expireDate:"",
      //   cooling : "",
      //   recommend : true,
      //   status : "New product"
      // }
      let tempModalForm = this.state.dataResponse
      //let modalFormTitle = "New product"
      let modalFormTitle = "Add logo"
      let modalForm = [
        { key: "image", name: "image", placeholder:"", value: "blank-image.png",disabled: window.modalFormImageConfig.newProductVending.disableImage, type: 'image', path:"/image/brand/default-logo.png", col: '1' }
      ]
      //+-console.log('modalForm =>', modalForm);
      //+-console.log('tempModalForm =>', tempModalForm);
      //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
      this.setState({ 
        tempModalForm: tempModalForm,
        modalForm: modalForm,
        modalFormSave: modalForm,
        modalFormTitleSave: modalFormTitle,
        modalFormTitle: modalFormTitle,
        modalAddbranchShow: true, 
        isButtonSelectFileEnable: false,
        isFilePondEnable: true
      })
    }
  
    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let modalForm =this.state.modalForm
      //+-console.log("handleChange event  =>", event.target);
      // console.log("modalForm  =>", modalForm);
      for(let i=0; i<modalForm.length; i++) {
        if(modalForm[i].name === name) {
          modalForm[i].value = value
          break
        }
      }
      this.setState({
        modalForm: modalForm, 
        btnSaveEnable: true
      })
    }

    handleInputChangeMain = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      //+-console.log("handleChange event  =>", event.target);
      let  dataResponse = this.state.dataResponse
      if(name === 'lat' || name === 'lng'){
        if(isNaN(value)) return
      }
      dataResponse[name] = value
      console.log('dataResponse =>',dataResponse)
      this.setState({
        dataResponse: dataResponse,
        btnSaveEnable: true
      })
    }

    handleInputChangeInArry0 = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      //+-console.log("handleInputChangeInArry0  =>", event.target);
      let  dataResponse = this.state.dataResponse
      if(name === 'rateMDRCash' || name === 'rateMDRRabbit' || name === 'rateMDRLinepay'){
        if(isNaN(value)) return
      }
      dataResponse.reportAll[0][name] = value
      //+-console.log('dataResponse =>',dataResponse)
      this.setState({
        dataResponse: dataResponse,
        btnSaveEnable: true
      })
    }

    handleInputChangeInArry1 = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      //+-console.log("handleInputChangeInArry1  =>", event.target);
      let  dataResponse = this.state.dataResponse
      if(name === 'rateMDRCash' || name === 'rateMDRRabbit' || name === 'rateMDRLinepay'){
        if(isNaN(value)) return
      }
      dataResponse.reportAll[1][name] = value
      //+-console.log('dataResponse =>',dataResponse)
      this.setState({
        dataResponse: dataResponse,
        btnSaveEnable: true
      })
    }
    
    gotoCampaign = (item) => {
      // console.log('detailBranch item =>', item);
      let location = {
        pathname: '/projectman',
        state: {}
      }
      
      this.setState({
        location: location,
        redirect: true 
      })
    }

    selectFileClick = (dataIn) => {
      //+-console.log("selectFileClick =>",dataIn)
      let THIS = this
      axios({
        method: 'get',
        //url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/products?_sortNatural=-1',
        url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/products',
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("response -->",response.data);
        if(response.data.value.length){
          //sort product by name 
          let p = response.data.value
          p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 

          let modalFormTitle = "Select products"
          let modalForm = []
          for(let i = 0; i < response.data.value.length; i++){
            let item = response.data.value[i] 
            let tempObj =  JSON.parse(JSON.stringify(item))
              tempObj.type = 'imageSelect'
              tempObj.value = tempObj.name
              tempObj.col = 1
              tempObj.cardSelect = false
              if((dataIn.value === tempObj.name) || (dataIn.sku === tempObj.sku) ){
                tempObj.cardSelect = true
              }
              modalForm.push(tempObj)
          }
          //+-console.log("modalForm =>",modalForm)
          THIS.setState({
            modalForm:modalForm,
            modalFormTitle: modalFormTitle
          },() =>{
            THIS.setState({modalAddbranchShow:true})  
          })
        }
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }

    imageOnclicks = (dataIn) => {
      //+-console.log('dataIn.cardSelect =>',dataIn)
      //+-console.log('modalFormSave =>',this.state.modalFormSave)
      if(dataIn.cardSelect === true){
        this.modalClose()
        let dataEdit = this.state.modalFormSave
        //let dataEdit = this.state.modalForm
        for(let k=0; k < dataEdit.length; k++) {

          if(dataEdit[k].key === 'image'){
            dataEdit[k].value= dataIn.image
            dataEdit[k].path= dataIn.path 
          }
          if(dataEdit[k].key === 'name'){
            let name  = dataIn.value.split('.');
            dataEdit[k].value= name[0]
          }
          if(dataEdit[k].key === 'price'){
            dataEdit[k].value = dataIn.price
          }
          if(dataEdit[k].key === 'sku'){
            dataEdit[k].value = dataIn.sku
          }
        }
        //+-console.log('dataEdit =>',dataEdit)
        this.setState({ 
          modalFormSave: dataEdit,
          modalForm: dataEdit, 
          modalFormTitle: this.state.modalFormTitleSave,
          modalAddbranchShow: true
        })
      }
    }

    changSendEmail = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      //console.log('target =>',target)
      //console.log('name =>',name)
      let dataResponse = this.state.dataResponse
      if(value == 'Enable'){
        dataResponse[name] = "sendSettlementReportAll3page"
      }else{
        dataResponse[name] = "don'tSendEmail"
      }
      this.setState({
        dataResponse: dataResponse,
        btnSaveEnable: true
      })

    }

    changType = (dataCallback) => {
      console.log('dataCallback =>',dataCallback)
      let dataResponse = this.state.dataResponse 
      dataResponse.projectType = dataCallback.value
      if(dataCallback.value == 'VMC'){
        dataResponse["vendingType"] = window.configProject.vmcType
        //dataResponse["sysEmail"] = window.configEmail.sysEmail // "ppe.q.system@gmail.com",
        //dataResponse["sysEmailPass"] = window.configEmail.sysEmailPass // "ppetech@1234",
        //dataResponse["reportAll"] = [{organization:this.props.location.state.organization},{organization:this.props.location.state.organization}]
      }else{
        dataResponse["vendingType"] = window.configProject.hypoType
        dataResponse["type"] = "don'tSendEmail"
      }
      this.setState({
        projectTypeSelect: dataCallback,
        dataResponse: dataResponse,
        btnSaveEnable: true
      })
    }

    changBranchs = (dataCallback) => {
      //+-console.log("changBranchs ->",dataCallback);
      let users = storeGlobal.getusers();
      let branchidSelect = [];
      let branchsSelect = [];
      let dataResponse = this.state.dataResponse 
      let branchID = []
      for(var i = 0; i < dataCallback.length; i++){
        branchidSelect.push(dataCallback[i]);
        branchID.push(dataCallback[i].value)
      }
      dataResponse['branchID'] = branchID
      this.setState({
        BranchidSelect:branchidSelect,
        BranchsSelect:branchsSelect,
        dataResponse: dataResponse,
        btnSaveEnable: true
      });
    }

    saveLog = (dataIn) => {

      fetch('https://api.ipify.org/?format=json').then(response => {
        return response.json();
      }).then((res) => {
        console.log('res ->', res)
        let user = this.state.user
        let data = dataIn
        data.ip = res.ip
        let organization = this.state.user.OrganizationSelect
        console.log("organization =>", organization)
        // return
        let THIS = this
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + organization + '/log',
          data: data,
          headers:{
            "Authorization": user.token
          }
        })
        .then(function (response1) {
          console.log('response1 ->',organization,'=',response1)
  
        })
        .catch(function (error) {
          console.log(error);
          // alert("error: " + JSON.stringify(error) );
          THIS.setState({ alertText: error.message, alertShow: true})
          THIS.setState({loadingOverlay: false})
        });
      })
    }

    goto401 = () => {
      // console.log('detailBranch item =>', item);
      this.props.history.push('/branchman');
      this.props.history.push('/401');
    }

    changFormatDate = (dataIn) => {
      //console.log("dataIn =>",dataIn)
      let d = String(dataIn).split('.')
      let d0 = d[0].replace(/[T]/g, " ")
      let d1 = d0.split(' ')
      let d2 = d1[0].split('-')
      let nweDate = d2[2]+'/'+d2[1]+'/'+d2[0]+' '+ d1[1]
      //console.log("d =>",d)
      return(nweDate)
    }

    // changFormatDate = (dateIn) => {
    //   let tempArr = dateIn.split("/");
    //   let temparr2 = tempArr[2].split(" ");
    //   return (temparr2[0] + '-' + tempArr[1] + '-' + tempArr[0] + ' ' + temparr2[1])
    // }
    
    render() {
      // let modalClose = () => this.setState({ modalAddUserShow: false });
      //console.log('dataResponse ->',this.state.dataResponse);
      const paginationStyle = {
        display: 'table',
        margin: '0 auto',
      };
      if(this.state.redirect) return ( <Redirect to={this.state.location} /> )
      return (     
        <SiteWrapper user={this.state.user} props = {this.props}>
          
          <Page.Content title="Project Setting">
            {/* <SelectBranchs getbranchs = {this.getbranchs}/> */}
             <Grid.Row cards={true}>
              <Grid.Col lg={12}>
                <Card> 
                  <Card.Header>
                    <Card.Title>Gerneral config Organization : {this.props.location.state.organization}</Card.Title>
                    <Card.Options>
                      <Button.List>
                        <Button 
                          color="secondary"                           
                          onClick={ () => this.gotoCampaign(this) }
                        > 
                        <Icon prefix="fe" name="arrow-left" className="mr-2" />
                        Back </Button>  

                        { this.state.user.level==='write' && (!this.state.btnSaveEnable ? 
                          <Button 
                            color="green"                  
                            disabled
                            // onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                          >Save</Button>

                          :
                          ( this.state.btnSaveClick ? 
                          <Button 
                            color="green"  
                            disabled           
                          ><PulseLoader color={'#FFFFFF'} /></Button>
                          :
                          <Button 
                            color="green"                  
                            // onClick={ () => this.saveConfig() }
                            onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, name: this.state.dataResponse.name, i: 'i', data: this.state.dataResponse }) } }
                          >Save </Button> )
                        )} 
                        {this.state.isButtonLoading? <Button color="default" icon="rotate-cw" 
                          onClick={ () => { 
                            this.setState({isButtonLoading: false},() => {
                              this.refresh()
                            })
                          }}
                          />:<Button color="default" icon="rotate-cw" loading />
                        }   

                      </Button.List>            
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>

                    <Grid.Row>
                      <Grid.Col lg={6}>
                        <Form.Group label="Project Name">
                          <Form.Input
                            name="name" 
                            placeholder="" 
                            value={this.state.dataResponse.name || ""} 
                            onChange={this.handleInputChangeMain} 
                          />    
                        </Form.Group>

                        <Form.Group label="Vending ID">
                          <Select
                            name={"branchID"}
                            closeMenuOnSelect={false}
                            //components={makeAnimated()}
                            isMulti
                            defaultValue={this.state.dataResponse.branchID}
                            options={this.state.optionBranchs}
                            onChange = {this.changBranchs}
                            value = {this.state.BranchidSelect}
                          />
                        </Form.Group>
                        
                        {this.state.dataResponse.projectType == undefined && 
                        <Form.Group label="ProjectType">
                          <Select
                            name={"projectType"}
                            defaultValue={null}
                            options={this.state.optionProjectType}
                            onChange = {this.changType}
                            value = {this.state.projectTypeSelect}
                          />
                        </Form.Group>
                        }

                      </Grid.Col>

                      <Grid.Col lg={6}>
                        <Form.Group label="Description">
                          <Form.Textarea
                            name="description"
                            placeholder=""
                            value={this.state.dataResponse.description || ""}
                            onChange={this.handleInputChangeMain}
                            rows={5}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              
              {this.state.dataResponse.sysEmail && 
              <Grid.Row>
                <Grid.Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Email config</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Grid.Row>
                      <Grid.Col lg={6}>
                        <Form.Group label="System Email">
                          <Form.Input
                            disabled
                            value = {this.state.dataResponse.sysEmail}
                          />
                        </Form.Group>
                        <Form.Group label="To">
                          <Form.Input
                            name="sendEmailTo" 
                            placeholder="" 
                            value={this.state.dataResponse.sendEmailTo || ""} 
                            onChange={this.handleInputChangeMain} 
                          />
                        </Form.Group>

                        <Form.Group label= "Send email">
                          <Form.Select name= "type" onChange={this.changSendEmail} value={this.state.dataResponse.type == 'sendSettlementReportAll3page'?'Enable':'Disable'} >
                              <option >Enable </option>
                              <option >Disable </option>
                          </Form.Select>
                        </Form.Group>

                        </Grid.Col>

                        <Grid.Col lg={6}>
                          <Form.Group label="cc">
                            <Form.Textarea
                              name="ccTo"
                              placeholder=""
                              value={this.state.dataResponse.ccTo || ""}
                              onChange={this.handleInputChangeMain}
                              rows={5}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              }

              {this.state.dataResponse.reportAll &&
              <Grid.Row>
                <Grid.Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Pdf Report config</Card.Title>
                    </Card.Header>
                    <Card.Body>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                        <Form.Group label="Image">
                          <Grid.Row>
                            <Grid.Col lg={4}>
                            <Card>
                              <img 
                                alt=""
                                src={this.state.dataResponse.path}
                                // className="h-8"
                                style={{
                                  flex: 1,
                                  alignSelf: 'stretch',
                                  width: "50%",
                                  height: "50%"
                                }}
                              />
                              </Card>
                            </Grid.Col>
                            <Grid.Col lg={2}>
                              <Button 
                                color="green"                  
                                onClick={ () => this.add() }
                                > 
                                Add logo
                              </Button>
                            </Grid.Col>
                            {/* <Grid.Col lg={6}>
                            <div></div>
                            </Grid.Col> */}
                          </Grid.Row>
                        </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Company name">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="companyNameSend" 
                                placeholder="" 
                                value={this.state.dataResponse.companyNameSend || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Address line1">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="address1" 
                                placeholder="" 
                                value={this.state.dataResponse.address1 || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Company name send to">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="companyNameto" 
                                placeholder="" 
                                value={this.state.dataResponse.companyNameto || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Address line2">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="address2" 
                                placeholder="" 
                                value={this.state.dataResponse.address2 || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="Transaction" 
                                placeholder="" 
                                value={this.state.dataResponse.Transaction || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Address line3">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="address3" 
                                placeholder="" 
                                value={this.state.dataResponse.address3 || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="SP">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="SP" 
                                placeholder="" 
                                value={this.state.dataResponse.SP || ""} 
                                onChange={this.handleInputChangeMain} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              }

              {this.state.dataResponse.reportAll &&
              <Grid.Row>
                <Grid.Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Pdf Sale Report config</Card.Title>
                    </Card.Header>
                    <Card.Body>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction Cash">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="TransactionCash" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].TransactionCash || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name report">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameReport" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].nameReport || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction Rabbit">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="TransactionRabbit" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].TransactionRabbit || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name Summary">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameSummary" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].nameSummary || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction Linepay">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="TransactionLinepay" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].TransactionLinepay || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name Cover">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameCover" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[0].nameCover || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Rate MDR Cash">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="rateMDRCash" 
                                placeholder="Numbers only" 
                                value= {this.state.dataResponse.reportAll[0].rateMDRCash || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Rate MDR Rabbit">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="rateMDRRabbit" 
                                placeholder="Numbers only" 
                                value= {this.state.dataResponse.reportAll[0].rateMDRRabbit || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Rate MDR Linepay">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="rateMDRLinepay" 
                                placeholder="Numbers only" 
                                value= {this.state.dataResponse.reportAll[0].rateMDRLinepay || ""} 
                                onChange={this.handleInputChangeInArry0} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        {/* <Grid.Col lg={6}>
                          <Form.Group label="">
                            {this.state.dataResponse.reportAll? <Form.Input
                                value = {this.state.dataResponse.reportAll[0].companyNameto || ''}
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col> */}
                      </Grid.Row>

                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              }

              {this.state.dataResponse.reportAll &&
              <Grid.Row>
                <Grid.Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Pdf Top-Up Report config</Card.Title>
                    </Card.Header>
                    <Card.Body>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction Rabbit">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="TransactionRabbit" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[1].TransactionRabbit || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name Report">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameReport" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[1].nameReport || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Transaction Linepay">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="TransactionLinepay" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[1].TransactionLinepay || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name Summary">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameSummary" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[1].nameSummary || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Rate MDR Rabbit">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="rateMDRRabbit" 
                                placeholder="Numbers only" 
                                value= {this.state.dataResponse.reportAll[1].rateMDRRabbit || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6}>
                          <Form.Group label="Name Cover">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="nameCover" 
                                placeholder="" 
                                value= {this.state.dataResponse.reportAll[1].nameCover || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Col lg={6}>
                          <Form.Group label="Rate MDR Linepay">
                            {this.state.dataResponse.reportAll? <Form.Input
                                name="rateMDRLinepay" 
                                placeholder="Numbers only" 
                                value= {this.state.dataResponse.reportAll[1].rateMDRLinepay || ""} 
                                onChange={this.handleInputChangeInArry1} 
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col>
                        {/* <Grid.Col lg={6}>
                          <Form.Group label="">
                            {this.state.dataResponse.reportAll? <Form.Input
                                value = {this.state.dataResponse.reportAll[0].companyNameto || ''}
                              />:<Form.Input value = "" />
                            }
                          </Form.Group>
                        </Grid.Col> */}
                      </Grid.Row>

                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              }
            </Page.Content>
          
          <ModalFormLarge
            show={this.state.modalAddbranchShow}
            onHide={this.modalClose}
            onSave={this.getForm}
            onInputChange={this.handleInputChange}
            title={this.state.modalFormTitle}
            worning={this.state.modalWorning}
            form={this.state.modalForm}
            selectFileClick = {this.selectFileClick}
            imageOnclicks = {this.imageOnclicks}
            defaultFileUpload = {this.state.defaultFileUpload}
            isButtonSelectFileEnable={this.state.isButtonSelectFileEnable}
            isFilePondEnable={this.state.isFilePondEnable}
          />

          {this.state.alert}

          <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
         
        </SiteWrapper>   
        
      )
      
    }
  }
  
  export default branchSetting;