// @flow
import React, { Component } from 'react'
import { instanceOf } from 'prop-types';
import SiteWrapper from "../components/SiteWrapper.react";
import storeGlobal from "../components/globalStore";
import { withCookies, Cookies } from 'react-cookie';

class Home extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      homePage:"/",
      users:{}
    };
  }

  componentDidMount() {
    const { cookies } = this.props;
    let users = storeGlobal.getusers();
    console.log("componentDidMount users =>", users)
    if(users && users.username){
      users.OrganizationSelect = ""
      users.ProjectSelect = "All"
      users.BranchidSelect = []
      if(users.organization.length) users.OrganizationSelect = users.organization[0]
      if(users.project.length) users.ProjectSelect = users.project[0].projectName
      if(users.branchID.length) users.BranchidSelect = users.branchID
      this.setState({homePage:"/"+users.permission.home})
      //let tempUsers = JSON.parse(JSON.stringify(users))
      let tempUsers = {
        userID : users.userID,
        username :users.username,
        token : users.token,
        role : users.role,
        BranchidSelect : users.BranchidSelect,
        ProjectSelect : users.ProjectSelect,
        OrganizationSelect : users.OrganizationSelect
      }
      console.log("tempUsers =>",tempUsers)
      cookies.set('user',JSON.stringify(tempUsers), { path: '/' ,maxAge: window.setTimeExpire.timeExpire});// Will expire after 1hr*12 (value is in number of sec.)
      cookies.set('homePage',JSON.stringify(users.permission.home), { path: '/' ,maxAge: window.setTimeExpire.timeExpire});// Will expire after 1hr*12 (value is in number of sec.)
      this.setState({
        users:users
      });  
    }
  }

  componentWillMount(){
    let users = storeGlobal.getusers()
    console.log('componentWillMount users =>',users)
    if(users && users.username){
      this.setState({
        homePage:"/"+users.permission.home,
        users: users
      })
    }
  }

  render() {
    //console.log("HomePage this.props -->",this.props);
    //console.log("render users  =>",this.state.users)
    // return  ("username" in this.state.users) ? (
    //   <SiteWrapper user={this.state.users} props = {this.props}>
    //     <div>
    //       {this.props.history.push(this.state.homePage)} 
    //     </div>
    //   </SiteWrapper>
    // ) : (
    //   //window.location.reload()
    //   <div>
    //     {this.props.history.push('/login')} 
    //   </div>
    // )

      return (
        <div>
          {this.state.users.username &&
            <SiteWrapper user={this.state.users} props = {this.props}>
              <div>
                {this.props.history.push(this.state.homePage)}  
              </div>
            </SiteWrapper>
          }
        </div>
      )  
  }
}

//export default Home
export default withCookies(Home);


