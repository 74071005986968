import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text } from "tabler-react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class ModalFormSmall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form: {

      // },      
    };
    // this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    //+-console.log('ModalAddUser props =>', props)
  }

  // componentDidMount() {
  //   console.log('componentDidMount ...')    
  // }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate prevProps =>', prevProps)
  // }

  componentWillReceiveProps(prevProps) {
    // console.log('componentWillReceiveProps prevProps =>', prevProps)
    // if(prevProps.show) {

    // }
    
  }

  handleInputChange(event) {
    // console.log("handleChange  =>", event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value);
    // this.setState(prevState => ({
    //   form: {
    //     ...prevState.form,
    //     [name]: value
    //   }
    // }));
    this.props.onInputChange(event)

  }

  inputRender = (input, index) => {
    if(input.type=='input') {
      return <Form.Group key={index} label={input.name}>
        <Form.Input
          name={input.name}
          placeholder={input.placeholder}
          onChange={this.handleInputChange}
          value={input.value}
          disabled={input.disabled} 
        />
      </Form.Group>
    } else if(input.type=='select') {
      return <Form.Group key={index}  label={input.name}>
        <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >                
          { input.items.map( (item, i) => 
            <option key={i}> {item} </option>
          )}
        </Form.Select>
      </Form.Group>
    } else if(input.type=='textarea') {
      return <Form.Group key={index}  label={input.name}>
        <Form.Textarea
          name={input.name}
          placeholder={input.placeholder}
          rows={input.rows}
          onChange={this.handleInputChange} 
          disabled={input.disabled}
          value={input.value}
        />
      </Form.Group>
    } else if(input.type=='select-multi') {
      // console.log('input =>', input)
      let defaultValue = []
      let options = []
      input.items.map( (item, i) => {
        options.push({ value: item, label: item })
      })
      input.value.map( (item, i) => {
        defaultValue.push({ value: item, label: item })
      })

      return <Form.Group key={index}  label={input.name}>
        <Select
          closeMenuOnSelect={false}
          //components={makeAnimated()}
          isMulti
          defaultValue={defaultValue}
          options={options}
          onChange = {this.changValue} // {this.props.handleInputChange}
          name={input.name} 
        />
      </Form.Group>
    } else if(input.type=='date-picker') {
      return  <Form.Group key={index}  label={input.name}><DatePicker
        selected={input.value}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="dd/MM/yyyy HH:mm"
        onChange={ (date) => this.changeDate(date, input.name) }
        className="form-control"
      /></Form.Group>
    }
  }

  changValue = (val, opts) => {
    // console.log("Selected: %o, %o", val, opts);
    let value = []
    val.map( (v, i) => {
      value.push(v.value)
    })
    let event = { target: {type: 'multi', value: value, name: opts.name } }
    return this.props.onInputChange(event)
  }

  changeDate = (date, name) => {
    //+-console.log("changeDate =>", date, name);
    let event = { target: {type: 'date-picker', value: date, name: name } }
    return this.props.onInputChange(event)
  }

  render() {
    // console.log('this.props.form =>', this.props.form)
    return (
      <Modal
        {...this.props}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={(event) => console.log(event.target.name + 'clicked')}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Grid.Row>
            { this.props.form.length ?
            <Grid.Col>   
              { this.props.form.map( (input, i) => 
                this.inputRender(input, i)
              )}
            </Grid.Col> 
            :
            <Grid.Col> </Grid.Col> 
            }           
          </Grid.Row>
          
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>          
          <Button type="button" onClick={ this.props.onHide } >Close</Button>
          <Button color="success" type="button" onClick={() => this.props.onSave()} >Save</Button>
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

ModalFormSmall.defaultProps = {
  title: 'ModalFormSmall',
  worning: "",
  form: [],
  
}

export default ModalFormSmall