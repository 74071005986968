// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "./globalStore";
// import SelectBranchs from "./SelectBranchsAndDate";
import Pagination from 'react-bootstrap/Pagination'
import ButtonExport from "../components/buttonExports"
import { Redirect } from "react-router-dom";

import {
  Page,
  Text,
  Grid,
  Card,
  Table,
  Button,
  Icon
} from "tabler-react";

// import C3Chart from "react-c3js";
import SiteWrapper from "./SiteWrapper.react";
//let users = {}

class DetailDashboard extends Component {
  constructor(props) {
    super(props);
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      BranchsSelect:[],
      BranchidSelect:[],
      branchNameSelect:"",
      title:"Detial...",
      header:[],
      value:[
        ['-',"-","-"]
      ],
      sum:[],
      dataShow:[],
      sumShow:[],
      paginationItem: [1],
      paginationActive: 1,
      resultAll:[],
      countPerPage:20,
      user:{},

      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled:true,
      isButtonLoading:true,

      redirect: false,
      location: {}

    }
  }
  //dfasdgfghjjk42
  paginationActive(e) {
    let dataShow = this.state.resultAll
    this.paginationItemCal(dataShow,e)
  }

  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.countPerPage
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i === 6){
            paginationItem.push(">")
          }else if(i === 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i === 2){
            paginationItem.push("<")
          }else if(i === 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
            default: 
            break
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataShow.push(dataIn[i])
      }
    }

    let sumShow = []
    if(paginationActive === paginationItem[paginationItem.length-1]){
      sumShow = this.state.sum
    }

    this.setState({
      dataShow: dataShow,
      sumShow: sumShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }

  refresh = (dataIn) => {
    if(!(Object.entries(this.state.user).length === 0)){
      if(!(Object.entries(dataIn).length === 0)){
        console.log("dataIn =>",dataIn)
        let values = dataIn.value.values
        let token = this.state.user.token
        let urls = dataIn.value.urls
        console.log("values ->",values)
        //console.log("token ->",token)
        console.log("url -->",urls)
        let THIS = this
        
        let valuesNolimit = JSON.parse(JSON.stringify(values))
        valuesNolimit.countLimit = 0
        
        let parameters = {
          urls: dataIn.value.urls,
          token : this.state.user.token,
          values:valuesNolimit,
          nameOfReport : dataIn.value.title,
          headerShow : dataIn.value.headerShow,
          method: "post"
        } 
    
        this.setState({"parameters":parameters})

        axios({
          method: 'post',
          // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
          url: urls,
          data: values,
          headers:{
            "Authorization":token
          }
          //withCredentials: true
        })
        .then(function (response) {
          //console.log("response -->",response.data);
          if(response.data.value !== undefined){
            let resultAll = response.data.value
            let tpmSum = []
            if(THIS.state.title == 'Detail Sales Report '){
              tpmSum = ['SUM','','','','-','','',0,0,0,'-','-','-','-','-']
              for(let i = 0; i < resultAll.length; i++){
                if(resultAll[i][7] !== '-'){
                  tpmSum[7] = tpmSum[7]+(resultAll[i][7]*1)
                }
                if(resultAll[i][8] !== '-'){
                  tpmSum[8] = tpmSum[8]+(resultAll[i][8]*1)
                }
                if(resultAll[i][9] !== '-'){
                  tpmSum[9] = tpmSum[9]+(resultAll[i][9]*1)
                }
              }
            }else if(THIS.state.title == 'Detail Top-Up Report '){
              tpmSum = ['SUM','','','','','',0,0,'-']
              for(let i = 0; i < resultAll.length; i++){
                if(resultAll[i][6] !== '-'){
                  tpmSum[6] = tpmSum[6]+(resultAll[i][6]*1)
                }
                if(resultAll[i][7] !== '-'){
                  tpmSum[7] = tpmSum[7]+(resultAll[i][7]*1)
                }
              }
              if(!tpmSum[7]) tpmSum[7] = '-'
            }
            THIS.setState({
              resultAll: resultAll,
              sum: tpmSum,
              isButtonDisabled : false,
              isButtonLoading : true
            }, () =>{
              THIS.paginationItemCal(resultAll,THIS.state.paginationActive)
            })
            THIS.setState({"paginationItem":THIS.state.paginationItem})
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("error: " + JSON.stringify(error) );
        });
      }
    }else{
      this.goto401()
    } 
  }



  componentWillMount() {
    let users = storeGlobal.getusers();
    //this.state.user = users
    this.setState({"user":users})
    //console.log("componentWillMount users =>",users)
    //console.log("componentWillMount props =>",this.props)
    //console.log("componentWillMount props.location.state =>",this.props.location.state)
  }

  componentDidMount() {
    //console.log("this.props.location.state =>",this.props.location.state)
    let state = this.props.location.state
    if(JSON.stringify(state) !== '{}'){
      this.setState({
        title: state.value.title,
        header: state.value.headerShow
      }, () =>{
        this.refresh(state)
      })
    }
  }

  gotoRefresh(){
    //console.log("gotoRefresh =>",this.props.location.state)
    let state = this.props.location.state
    if(JSON.stringify(state) !== '{}'){
      this.setState({
        title: state.value.title,
        header: state.value.headerShow
      }, () =>{
        this.refresh(state)
      })
    }
  }



  goto401 = () => {
    console.log('goto401..');
    this.props.history.push('/dashboard');
    this.props.history.push('/401');
  }

  gotoBack = () => {
    // console.log('detailBranch item =>', item);
    let stateSave = storeGlobal.getStateSave()
    console.log("stateSave =>",stateSave)
    if(stateSave !== undefined && stateSave.gotoBack !== undefined){
      let location = {
        pathname: stateSave.gotoBack,
        state: {}
      }
      
      this.setState({
        location: location,
        redirect: true 
      })
    }
  }

  render() {
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    if (this.state.redirect) return <Redirect to={this.state.location} />;
    return (
      <SiteWrapper user={this.state.user} props = {this.props}>
        <Page.Content title={this.state.title}>
          {/* <SelectBranchs getbranchs = {this.getbranchs}/> */}
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>Vending : {this.state.branchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title}</Card.Title>
                  <Card.Options>
                    <Button.List>
                      <Button 
                        color="secondary"                           
                        onClick={ () => this.gotoBack(this) }
                        > 
                        <Icon prefix="fe" name="arrow-left" className="mr-2" />
                        Back 
                      </Button>  
                      {this.state.isButtonLoading? <Button color="default" icon="rotate-cw" 
                          onClick={ () => { 
                            this.setState({isButtonLoading: false},() => {
                              this.gotoRefresh()
                            })
                          }}
                          />:<Button color="default" icon="rotate-cw" loading />
                      }
                    </Button.List>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                        <ButtonExport
                          parameters = {this.state.parameters}
                          isButtonDisabled = {this.state.isButtonDisabled}
                        >
                        </ButtonExport>
                      }
                  </Card.Options>
                </Card.Header>
                  
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.header.map( (item, index) =>
                      <Table.ColHeader key={index}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { this.state.dataShow.length? <Table.Row></Table.Row> : <Table.Row > 
                        <Table.Col colSpan="12"	alignContent="center">
                          <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                        </Table.Col>
                      </Table.Row>
                    }
                    {/* { this.state.dataShow.length? <Table.Row></Table.Row> : <Table.Row ><Table.Col></Table.Col><Table.Col><Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text></Table.Col><Table.Col></Table.Col></Table.Row>} */}
                    {this.state.dataShow.map( (item, index) =>
                      <Table.Row key={index}>
                      {item.map( (item1, indexs) =>
                        <Table.Col key={indexs}>{item1}</Table.Col>
                      )}
                      </Table.Row>
                    )}
                    <Table.Row > 
                      {this.state.sumShow.map( (item, index) => 
                        <Table.Col key={index}>{item}</Table.Col>
                      )}
                    </Table.Row>                    
                    <Table.Row > 
                      <Table.Col colSpan="16"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default DetailDashboard
