// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
import Reports from '../components/Reports'

let users = storeGlobal.getusers();

class beatProductReport extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      value:{
        //urls:"http://localhost:3001/api/v1/report/best-product",
        urls: window.url + "api/v1/report/product",
        title:"Sales By Product Report Linepay",
        header:["product name","TRANSACTION","payValue(THB)","payChange(THB)","AMOUNT(THB)"],
        headerGetdb:["_id","count","payValue","payChange","price"],
        defaultValue:[['-','-','-','-','-','-']],
        defaultSum:['','','','','','',''],
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:20,
        payType : {$or: [{payType:'linepay'},{payType:'LINEPAY'},{payType:'LINECREDIT'}]},
        typeSelectFilter:"useDate"
      }
    }
  }
//dfasdgfghjjk42
  render() {
    users = storeGlobal.getusers();
    let props = storeGlobal.getprops();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    console.log("Products user -->",users)
    return (
      <Reports user={users} props = {this.props} value = {this.state.value}>
      </Reports>
    );
  }
}

export default beatProductReport
