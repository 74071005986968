// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
//import Reports from '../components/ReportsShowTime'
import Reports from '../components/Reports'

let users = storeGlobal.getusers();

class beatProductReport extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      value:{
        //urls:"http://localhost:3001/api/v1/report/best-product",
        urls: window.url + "api/v1/report/best-product",
        title:"Best Product Report",
        header:["ITEM","SKU","PRODUCT","TRANSACTION","AMOUNT(THB)"],
        defaultValue:[['-','-','-']],
        defaultSum:['','',''],
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:48,
        typeSelectFilter:"useDateTime"

      }
    }
  }
//dfasdgfghjjk42
  render() {
    users = storeGlobal.getusers();
    let props = storeGlobal.getprops();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    //+-console.log("Products user -->",users)
    return (
      <Reports user={users} props = {this.props} value = {this.state.value}>
      </Reports>
    );
  }
}

export default beatProductReport
