import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text, StoreCard, Card, Icon } from "tabler-react";
//import Select from 'react-select';
//import ImageUploader from 'react-images-upload';
import DatePicker from "react-datepicker";
import { FilePond, registerPlugin  } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
import SweetAlert from 'react-bootstrap-sweetalert'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
//registerPlugin(FilePondPluginFileValidateType,FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

registerPlugin(FilePondPluginFileValidateType,FilePondPluginImageExifOrientation);
//import '../App.css';

class ModalFormLarge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form: {

      // }, 
      user: storeGlobal.getusers(),
      files: {
        name: 'blank-image.png',
        path: '/products/blank-image.png'

      },
      isButtonDisabled: true,
      pictures: [],
      alertShow: false,
      alertText: ''
    };
    // this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    //+-console.log('ModalAddUser props =>', props)
  }

	onDrop(pictureFiles, pictureDataURLs) {
		this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
	}

  // componentDidMount() {
  //   console.log('componentDidMount ...')    
  // }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate prevProps =>', prevProps)
  // }

  componentWillReceiveProps(prevProps) {
    // console.log('componentWillReceiveProps prevProps =>', prevProps)
    // if(prevProps.show) {

    // }
    
  }

  enableButonSave(){
    if(this.state.isButtonDisabled == true){
      //+-console.log('isButtonDisabled ->',this.state.isButtonDisabled)
      this.setState({
        isButtonDisabled: false
      })
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("handleChange  =>", name, value, this.state.form);
    this.enableButonSave()
    if(this.props.onInputChange){
      this.props.onInputChange(event)
    }
  }

  onSave = () => {
    // first set the isButtonDisabled to true
    this.setState({
      isButtonDisabled: true
    });
    if(this.props.onSave()){
      this.props.onSave() 
    }
    // then do your thing
  }

  uploadeFiles = (dataIn) => {
    dataIn["from"] = 'uploadeFile'
    this.props.onHide(dataIn)
    //+-console.log("uploadeFile =>",dataIn)  
    if(this.props.uploadFileClick){
      this.props.uploadFileClick(dataIn)
    }
  }

  selectFile = (dataIn) => {
    dataIn["from"] = 'selectFile'
    this.props.onHide(dataIn)
    //+-console.log("selectFile =>",dataIn)  
    if(this.props.selectFileClick){
      this.props.selectFileClick(dataIn)
    }
  }

  handleChangeDate = (dataIn) => {
    // let start = new Date('2019-01-29T08:08:09.000Z') //test
    //+-console.log("date",dataIn);
    let event = { target: {type: dataIn.type, value: dataIn.value, name: dataIn.name} }
    this.enableButonSave()
    if(this.props.onInputChange){
      this.props.onInputChange(event)
    }
  }

  imageOnclick = (dataIn) => {
    // let start = new Date('2019-01-29T08:08:09.000Z') //test
    //+-console.log("dataIn",dataIn);
    // let statusImageSelect = ()
    if(dataIn.cardSelect) {
      dataIn.cardSelect = false
    }else{
      dataIn.cardSelect = true
    }
    //+-console.log('dataIn.cardSelect ->',dataIn.cardSelect)
    // let event = { target: {type: dataIn.type, value: dataIn.value, name: dataIn.name} }
    this.enableButonSave()
    if(this.props.imageOnclicks){
      this.props.imageOnclicks(dataIn)
    }
  }

  handleInit() {
    //+-console.log('FilePond instance has initialised', this.pond);
  }

  inputRender = (input) => {
    if(input.type=='input') {
      return <Form.Group label={input.name}>
        <Form.Input
          name={input.name}
          placeholder={input.placeholder}
          onChange={this.handleInputChange}
          value={input.value}
          disabled={input.disabled} 
        />
      </Form.Group>
    } else {
      return <Form.Group label={input.name}>
        <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >                
          { input.items.map( (item, i) => 
            <option key={i}> {item} </option>
          )}
        </Form.Select>
      </Form.Group>
    }
  }

  inputRender1 = (input, index) => {
    ////+-console.log('input.col ->',input.col)
    if(input.col == '1'){
      switch(input.type){
        case 'input':
            return <Form.Group key={index}  label={input.name}>
              <Form.Input
                name={input.name}
                placeholder={input.placeholder}
                onChange={this.handleInputChange}
                value={input.value}
                disabled={input.disabled} 
              />
            </Form.Group>
        break
        case 'select':
            return <Form.Group key={index}  label={input.name}>
            {/* <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >                
              { input.items.map( (item, i) => 
                <option> {item} </option>
              )}
            </Form.Select> */}
          </Form.Group>
        break
        case 'image':
          return(
            <Card key={index} >
              <Card.Body>
                {this.props.isFilePondEnable && 
                  <FilePond 
                    // ref={ref => this.pond = ref}
                    // oninit={() => this.handleInit() }
                    server={this.props.defaultFileUpload.uri+'/'+this.props.defaultFileUpload.organization+'/'+this.props.defaultFileUpload.type }
                    acceptedFileTypes={ this.props.defaultFileUpload.filetype }
                    fileValidateTypeDetectType={ (source, type) => new Promise((resolve, reject) => {                    
                      // Do custom type detection here and return with promise
                      this.setState({files:source})
                      //+-console.log('fileValidateTypeDetectType source, type =>', source, type)
                      resolve(type);
                    })}
                    onprocessfile={(error, file) => {
                      //+-console.log("error =>",error)
                      //+-console.log("file =>",file)
                      let THIS = this
                      axios({
                        method: 'get',
                        url: window.url + 'api/v1/collection/' + this.props.defaultFileUpload.organization + '/' + 'upload'+'?_sortNatural=-1'+'&_limit=1',
                        data: 'values',
                        headers:{
                          "Authorization": this.state.user.token
                        }
                      })
                      .then(function (response) {
                        //+-console.log("response -->",response.data);
                        if(response.data.value.length){
                          input.value = response.data.value[0].fileName
                          input.fileName = response.data.value[0].fileName
                          input.path =  response.data.value[0].path
                          input.createDate = response.data.value[0].createDate
                          input.cardSelect = false
                          THIS.imageOnclick(input)
                          //THIS.enableButonSave()
                        }
                      })
                      .catch(function (error) {
                        THIS.setState({
                          alertShow: true,
                          alertText: error.message
                        })
                        console.log('axios error =>',error.message);
                      });
                    }}
                  >
                  </FilePond>
                }
                <div className="mb-4 text-center">
                  <img 
                  src={input.path} 
                  alt="" 
                  style={{
                      flex: 1,
                      alignSelf: 'stretch',
                      width: "50%",
                      height: "50%"
                    }}
                  />
                </div>
                {/* <Card.Title>{input.value}</Card.Title> */}
                {/* <div className="mt-5 d-flex align-items-center"> */}
                <div align="center">
                  <div className="ml-auto">
                    {this.props.isButtonSelectFileEnable && 
                      <Button type="button" color="primary" onClick={ () => this.selectFile(input) }>
                        <Icon prefix="fe" name="grid"/>
                        Select product
                      </Button>
                    }
                  </div>
                </div>
              </Card.Body>
            </Card>
          )
        break
        case 'imageSelect':
          return(
            <Card key={index} className={(input.cardSelect) ?("campaign-card-border-select"):("")}>
              <Card.Body >
                {/* <div className="mb-4 text-center"> */}
                <div onClick={() => this.imageOnclick(input)}>
                  <img src={input.path} alt={input.name}/>
                  <div className="text" >{input.name}</div>
                </div>
              </Card.Body>
            </Card>
          )
        break
        case 'date':
            return <Form.Group key={index} label={input.name}>
            <DatePicker
            selected={this.state.startDate}
            selectsStart
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleChangeStart.bind(this)}
            className="form-control"
            />
          </Form.Group>
        break
        case 'uploadFile':
          return(
            <Grid.Row key={index}>
            <Grid.Col>   
              <FilePond 
                server={this.props.uri+'/'+this.props.organization+'/'+this.props.type }
                acceptedFileTypes={ this.props.filetype }
                fileValidateTypeDetectType={ (source, type) => new Promise((resolve, reject) => {                    
                  // Do custom type detection here and return with promise
                  console.log('fileValidateTypeDetectType source, type =>', source, type)
                  resolve(type);
                })}
              />
            </Grid.Col> 
          </Grid.Row>
          )
        break
      }
    }
  }

  inputRender2 = (input, index) => {
    if(input.col == '2'){
      switch(input.type){
        case 'input':
            return <Form.Group label={input.name} key={index}>
              <Form.Input
                name={input.name}
                placeholder={input.placeholder}
                onChange={this.handleInputChange}
                value={input.value}
                disabled={input.disabled} 
              />
            </Form.Group>
        break
        case 'select':
            return <Form.Group label={input.name} key={index}>
            <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >                
              { input.items.map( (item, i) => 
                <option key={i}> {item} </option>
              )}
            </Form.Select>
          </Form.Group>
        break
        case 'image':
            // return <StoreCard
            //           title={input.value}
            //           subtitle=""
            //           // imgUrl="https://tabler.github.io/tabler/demo/products/apple-iphone7-special.jpg"
            //           imgUrl={"/products/"+input.value}
            //         />
          return(
            <Card key={index}>
              <Card.Body>
                <div className="mb-4 text-center">
                  <img 
                  src={input.path} 
                  alt="" 
                  style={{
                      flex: 1,
                      alignSelf: 'stretch',
                      width: "50%",
                      height: "50%"
                    }}
                  />
                </div>
                <Card.Title>{input.value}</Card.Title>
                <div className="mt-5 d-flex align-items-center">
                  <div className="ml-auto">
                    <Button color="primary">
                      <Icon prefix="fe" name="plus" 
                      // onClick={() => this.props.onSave()}
                    />
                      Add Image
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
            // <ImageUploader
            //     	withIcon={true}
            //     	buttonText='Choose images'
            //     	onChange={this.onDrop}
            //     	imgExtension={['.jpg', '.gif', '.png', '.gif']}
            //     	maxFileSize={10242880}
            // />
          )
        break
        case 'imageSelect':
          return(
            <Card key={index} className={(input.cardSelect) ?("campaign-card-border-select"):("")}>
              <Card.Body >
                {/* <div className="mb-4 text-center"> */}
                <div onClick={() => this.imageOnclick(input)}>
                  <img src={input.path} alt={input.name}/>
                  {/* <div class="text">John Doe</div> */}
                </div>
              </Card.Body>
            </Card>
          )
        break
        case 'date':
            return <Form.Group  key={index} label={input.name}>
            <DatePicker
            selected={input.value}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            //onChange={this.handleChangeStart.bind(this)}
            onChange = {(even) =>{
                  {input.value = new Date(even)}
                  if(this.handleChangeDate){
                    this.handleChangeDate(input)  
                  }
                } 
              }
            className="form-control"
            />
          </Form.Group>
        break
      }
    }
  }

  render() {
    // console.log('this.props.form =>', this.props.form)
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={(event) => console.log(event.target.name + 'clicked')}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}> 
        
        {(this.props.title == "Add Products" || this.props.title == "Select products" || this.props.title == "Select campaign") ? (
          <Grid.Row>
          { this.props.form.map( (input, i) =>
          <Grid.Col key={i} lg ={3}>   
                {this.inputRender1(input)}
            </Grid.Col>  
            )} 
          </Grid.Row>
        ):(
          <Grid.Row>
            <Grid.Col lg ={6}>   
              { this.props.form.map( (input, i) => 
                this.inputRender1(input,i)
              )}
            </Grid.Col>     
            <Grid.Col lg={6}>   
              { this.props.form.map( (input, i) => 
                this.inputRender2(input,i)
              )}
            </Grid.Col>         
          </Grid.Row>
        )}
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>          
          <Button type="button" onClick={ this.props.onHide } >Close</Button>
          <Button 
            color="green"                  
            onClick={ () => this.onSave() }
            disabled={this.state.isButtonDisabled}
          > Save
          </Button>  
        </Modal.Footer>
        </Form>
        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
      </Modal>
    );
  }

}

ModalFormLarge.defaultProps = {
  title: 'ModalFormLarge',
  worning: "",
  form: [],
  
}

export default ModalFormLarge