// @flow

import React, { Component } from 'react';

import Select from 'react-select';
//import makeAnimated from 'react-select/lib/animated';
import { colourOptions } from './docs/data';
import storeGlobal from "../components/globalStore";

export default class AnimatedMulti extends Component {

  changValue = (val, opts) => {
    // console.log("Selected: %o, %o", val, opts);
    if (this.props.dataChang) {
      this.props.dataChang(val);
    }
  }

  // componentWillUpdate(){
  //   console.log('componentWillUpdate this.props =>',this.props)
  //   console.log('this.state =>',this.state)
  //   // if(this.props.options){
  //   //   //let options = this.props.options;
  //   //   //this.setState({options:options});
  //   //   this.setState({"options":this.props.options});
  //   // }
  // }

  // componentDidMount(){
  //   console.log('componentDidMount this.props =>',this.props)
  //   // this.setState({
  //   //   value:{label:this.props.value,value:this.props.value}
  //   // },
  //   //   console.log('this.state =>',this.state)
  //   // )
  // }

//dfasdgfghjjk42
  render() {
    return (
      <Select
        closeMenuOnSelect={false}
        //components={makeAnimated()}
        isMulti
        //defaultValue={this.props.defaultValue}
        options={this.props.options}
        onChange = {this.changValue}
        clearValue = {'efewfewfewfewf'}
        value={this.props.value} // notice state!
      />
    );
  }
}


// // @flow

// import React, { Component } from 'react';

// import Select from 'react-select';
// //import makeAnimated from 'react-select/lib/animated';
// import { colourOptions } from './docs/data';
// import storeGlobal from "../components/globalStore";
// let option = [{label:"All",value:"All"}];

// export default function AnimatedMulti(props) {
// //export default class AnimatedMulti extends Component {
//   let FilterBranchs = storeGlobal.getFilterBranchs();
//     //console.log("MultiSelect option ->",props.option);
//     if(FilterBranchs.Branchs){
//       option = FilterBranchs.Branchs;
//     }
//     return (
//       <Select
//         closeMenuOnSelect={false}
//         //components={makeAnimated()}
//         defaultValue={[option[0]]}
//         isMulti
//         options={option}
//       />
//     );
// }