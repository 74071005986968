import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Form,
  // Icon,
  Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalFormLarge from "../components/ModalFormLarge";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
// import SelectBranchsLess from "../components/SelectBranchsLess";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';

import 'antd/dist/antd.css';
import { Tree, Switch } from 'antd';
import { CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import { ObjectId, ObjectID } from 'bson';
// import Grid from 'antd/lib/card/Grid';

const { TreeNode } = Tree;

class RoleMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      user: storeGlobal.getusers(),
      branchs: [],
      usersRes: [],
      branchsPage: [],
      branchEdit: null,

      modalAddProjectShow: false,
      modalShow: false,
      modalEditProjectShow: false,
      modalTitle: 'Add role',
      modalWorning:'',
      modalForm: [
        { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col:1 },
        { key: "description", name: "Description", placeholder:"", value: "", disabled: false, type: 'textarea', row: 3, col:1 }, 
      ],  

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      branchsPageLimit: window.roleManPage.tableList, 

      redirect: false,
      location: {},
      home:[],

      treeData: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      currentRole: [],
      permissionDefault: [],

      searchText: '',
      searchButton: true,

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization",
      showLine: true,
      showIcon: false,
      
    };
  }

  componentDidMount() {
    this.getPermissionDefault(null)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete_or_edit':
        //console.log('e =>',e)
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              confirmBtnText="Cancel"
              confirmBtnBsStyle="default"
              cancelBtnBsStyle="success"
              title="what do you want to do ?"
              onConfirm={ this.hideAlert.bind(this) }
              >
              <div>
                item "{e.items.value}"
                <hr/>
                <Button color="success" onClick={()=> {this.editMenus(e)} } > 
                Yes, edit it!
                </Button>
                &nbsp;
                <Button color="danger" onClick={()=> {this.deleteMenu(e)} } >
                Yes, delete it!
                </Button>
                <hr/>
              </div>
            </SweetAlert>
          )
        }));
        break
        default:
        break
    }
		
  } 

  deleteMenu = (dataIn) =>{
    console.log('deleteMenu items =>',dataIn)
    if(dataIn.items.value !== ''){
      let data = {}
      let selectedKeys0 = dataIn.items.value
      let allPermissionDefault = this.state.allPermissionDefault
      let permissionDefault = allPermissionDefault.menu
      //console.log('permissionDefault =>',permissionDefault)
      for(let i=0; i<permissionDefault.length; i++) {
        if((selectedKeys0 !== permissionDefault[i].value) && permissionDefault[i].subItems.length){
          let SubMenu = permissionDefault[i].subItems
          //console.log('SubMenu =>',SubMenu)
          for(let j=0; j<SubMenu.length; j++) {
            if((selectedKeys0 !== SubMenu[j].value) && SubMenu[j].subItems.length){
              let MenuItem = SubMenu[j].subItems
              for(let k=0; k<MenuItem.length; k++) {
                if(selectedKeys0 === MenuItem[k].value){
                  MenuItem.splice(k, 1);
                  break
                }
              }
            }else if(selectedKeys0 === SubMenu[j].value){
              SubMenu.splice(j, 1);
              break
            }
          }
        }else if (selectedKeys0 === permissionDefault[i].value){
          permissionDefault.splice(i, 1);
          break
        }
      }
      //console.log('new permissionDefault =>',permissionDefault)

      if(permissionDefault){
        data.menu = permissionDefault
      }
      data.createBy = this.state.user.username
      data.updateBy = this.state.user.username
      data.flag = 'delete'
      // data.organization = this.state.user.organization[0]
      //console.log('getForm data =>', data);
      let method = 'post'
      let url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults'
      if(this.state.allPermissionDefault._id !== undefined){
        method = 'put'
        url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults/' + this.state.allPermissionDefault._id
      }
      if(this.state.user.role !== 'sadmin'){
        return this.setState({modalWorning: "Permission denied to create a menu!"})
      }

      let THIS = this
      axios({
        method: method,
        url: url,
        data: data,
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        console.log("delete menu response =>", response.data); // {n: 1, nModified: 1, ok: 1}
        if( ( (method === 'post') && response.data.value) || ( (method === 'put') &&  (response.data.value.n == 1) ) ){
          axios({
            method: 'get',
            url: window.url + 'api/v1/collection/' + '_' + '/role?', 
            data: 'values',
            headers:{
              "Authorization": THIS.state.user.token
            }
          })
          .then(function (response) {  
            //console.log('response =>',response.data.value)
            let roles = response.data.value
            if(roles.length){
              roles.forEach(currentValue => {
                //console.log('currentValue =>',currentValue)
                if(currentValue.name === 'sadmin'){
                  let users = THIS.state.user
                  users.permission.menu = permissionDefault
                  currentValue.menu = permissionDefault
                  axios({
                    method: 'put',
                    url: window.url + 'api/v1/collection/' + '_' + '/role/' + currentValue._id,
                    data: currentValue,
                    headers:{
                      "Authorization": users.token
                    }
                  })
                  .then(function (response) {  
                    //console.log('response =>',response.data.value)
                    THIS.setState({
                      user : users
                    },() =>{
                      THIS.getPermissionDefault(null)
                      THIS.hideAlert()
                    })
                  })
                  .catch(function (error) {
                    console.log(error);
                    THIS.hideAlert()
                  });
                }
              })
            }
          })
          .catch(function (error) {
            console.log(error);
            THIS.setState({modalWorning: "Add menu error!"})
            THIS.hideAlert()
          });
        }
        let dataSave = {
          "type" : 'warning', //information //warning // error
          "date" : new Date(),
          "surce" : 'permissionMan',
          "category" : 'webApp',
          "even" : 'Delete menu',
          "user" :  THIS.state.user.username,
          "ip" : "",
          "discription" : 'Delete menu =>'+ dataIn.items.value
        }
        THIS.saveLog(dataSave)
      })
      .catch(function (error) {
        console.log(error);
        THIS.setState({modalWorning: "Delete menu error!"})
        THIS.hideAlert()
      });
    }else{
      this.hideAlert()
    }
  }

  editMenus = (dataIn) =>{
    //console.log('editMenus items =>',dataIn)
    if(dataIn.items.value !== ''){
      let items = {}
      let menu = ''
      let subMenu = ''
      let menuItem = ''
      let statusSubMenu = ''
      let statusmenuItem = ''
      let selectedKeys0 = dataIn.items.value
      let allPermissionDefault = this.state.allPermissionDefault
      let permissionDefault = allPermissionDefault.menu
      //console.log('permissionDefault =>',permissionDefault)
      for(let i=0; i<permissionDefault.length; i++) {
        if((selectedKeys0 !== permissionDefault[i].value) && permissionDefault[i].subItems.length){
          let SubMenu = permissionDefault[i].subItems
          //console.log('SubMenu =>',SubMenu)
          for(let j=0; j<SubMenu.length; j++) {
            if((selectedKeys0 !== SubMenu[j].value) && SubMenu[j].subItems.length){
              let MenuItem = SubMenu[j].subItems
              for(let k=0; k<MenuItem.length; k++) {
                if(selectedKeys0 === MenuItem[k].value){
                  menu = permissionDefault[i].value
                  subMenu = SubMenu[j].value
                  menuItem = MenuItem[k].value
                  items = MenuItem[k]
                  break
                }
              }
            }else if(selectedKeys0 === SubMenu[j].value){
              menu = permissionDefault[i].value
              subMenu = SubMenu[j].value
              items = SubMenu[j]
              if(SubMenu[j].subItems.length){
                statusmenuItem = 2
              }
              break
            }
          }
        }else if (selectedKeys0 === permissionDefault[i].value){
          menu = permissionDefault[i].value
          items = permissionDefault[i]
          if(permissionDefault[i].subItems.length){
            statusSubMenu = 1
          }
          break
        }
      }
      // console.log('new items =>',items)
      // console.log('new menu =>',menu)
      // console.log('new subMenu =>',subMenu)
      // console.log('new menuItem =>',menuItem)

      this.hideAlert()
      //let obj = { key: "MenuItem", name: "MenuItem", placeholder:"", value: "", disabled: false, type: 'select',items: sub_Menu, col: 1 } 
        let permissions = ['rw','r']
      let modalForm = [
        { key: "value", name: "Name", placeholder:"", value: items.value, disabled: false, type: 'input', col: 2 }, 
        { key: "icon", name: "icon", placeholder:"book-open", value: items.icon, disabled: false, type: 'input', col: 2 },
        { key: "permission", name: "permission", placeholder:"r", value: items.permission, disabled: false, type: 'select',items: permissions, col: 2 },
      ]

      if(menu !== ''){
        modalForm.push({ key: "menu", name: "nowMenu", placeholder:"", value: menu, disabled: true, type: 'input', col: 1 })
      }else{
        modalForm.push({ key: "menu", name: "nowMenu", placeholder:"", value: "", disabled: true, type: 'input', col: 1 })
      }

      if(subMenu !== ''){
        modalForm.push({ key: "subMenu", name: "nowSubMenu", placeholder:"", value: subMenu, disabled: true, type: 'input', col: 1 }) 
      }

      if(menuItem !== ''){
        modalForm.push({ key: "MenuItem", name: "nowMenuItem", placeholder:"", value: menuItem, disabled: true, type: 'input', col: 1 }) 
      }

      if((statusmenuItem === '') &&  (statusSubMenu === '') ){
        modalForm.push({ key: "to", name: "to", placeholder:"/", value: items.to, disabled: false, type: 'input', col: 2 })  
      }



      let modalTitle = 'Edit Menu'
      this.setState({ 
        modalForm: modalForm, 
        modalAddProjectShow: true,
        modalShow: true, 
        modalTitle: modalTitle,
      })
    }
  }

  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.branchsPage(page)
  }

  branchsPage(page) {
		let branchsPage = []
    let start = (page-1) * this.state.branchsPageLimit
    let end = start + this.state.branchsPageLimit
    if(end >= this.state.branchs.length ) {
      end = this.state.branchs.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      this.state.branchs[i].index = i+1
      branchsPage.push(this.state.branchs[i])
    }
    this.setState({branchsPage: branchsPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.branchs.length; i+=this.state.branchsPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }
    //this.state.paginationItem = paginationItem
    this.setState({paginationItem: paginationItem})
  }
  
  
  // getPermissionDefault = (dataCallback) => {
  //   //+-console.log("getPermissionDefault..",dataCallback);
  //   // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
  //   if(JSON.stringify(this.state.user) !== '{}'){
  //     let users = this.state.user
  //     let urls =  window.url + 'api/v1/collection/_/permissionDefault'
  //     if(users.role !== "sadmin"){
  //       urls =  window.url + 'api/v1/collection/'+ users.OrganizationSelect +'/permissionDefault'
  //     }

  //     let THIS = this
  //     axios({
  //       method: 'get',
  //       url:urls, 
  //       data: 'values',
  //       headers:{
  //         "Authorization": users.token
  //       }
  //     })
  //     .then(function (response) {
  //       //+-console.log('getPermissionDefault response -->', response)
  //       let permissionDefault = response.data.value

  //       THIS.setState({
  //         permissionDefault: permissionDefault
  //       })
  //     }) 
  //     .catch(function (error) {
  //       console.error(error);
  //       // alert("error: " + JSON.stringify(error) );
  //     });
  //   }
  // }

  getPermissionDefault = (dataCallback) => {
    //+-console.log("getPermissionDefault..",dataCallback);
    // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    if(JSON.stringify(this.state.user) !== '{}'){
      let users = this.state.user
      let urls =  window.url + 'api/v1/collection/_/permissionDefaults'
      if(users.role !== "sadmin"){
        urls =  window.url + 'api/v1/collection/'+ users.OrganizationSelect +'/permissionDefault'
      }

      let THIS = this
      axios({
        method: 'get',
        url:urls, 
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log('getPermissionDefault response -->', response.data.value)
        if(response.data.value){
          let permissionDefault =[]
          let allPermissionDefault =  response.data.value[0]
          let treeData =[]
          response.data.value.forEach(currentValue => {
            permissionDefault = permissionDefault.concat(currentValue.menu)
            for(let i=0; i<permissionDefault.length; i++) {  
              let obj={
                title: permissionDefault[i].value,
                key: permissionDefault[i].value,
                children: [],
              }
              let subItems1 = permissionDefault[i].subItems
              if(subItems1) {
                for(let j=0; j<subItems1.length; j++) {  
                  let obj1={
                    title: subItems1[j].value,
                    key: subItems1[j].value,
                    children: [],
                  }
                  let subItems2 = permissionDefault[i].subItems[j].subItems
                  if(subItems2) {
                    for(let k=0; k<subItems2.length; k++) {  
                      let obj2={
                        title: subItems2[k].value,
                        key: subItems2[k].value,
                        children: [],
                      }
                      obj1.children.push(obj2)
                    }
                  }  
                  obj.children.push(obj1)
                }
              }    
              treeData.push(obj)
            }
          })

          //console.log('permissionDefault =>',permissionDefault)
          //console.log('allPermissionDefault =>',allPermissionDefault)
          //console.log('treeData =>',treeData)


          THIS.setState({
            allPermissionDefault : allPermissionDefault,
            permissionDefault: permissionDefault,
            treeData: treeData
          })
        }
      }) 
      .catch(function (error) {
        console.error(error);
        // alert("error: " + JSON.stringify(error) );
      });
    }
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddProjectShow: false })
    this.setState({ modalWorning: "", modalShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    //console.log('getForm modalForm =>', this.state.modalForm);   
    if(this.state.modalTitle === 'Edit Menu'){
      this.getFormEdit()
      return
    }
    let data = {}
    let modalForm = this.state.modalForm
    let allPermissionDefault = this.state.allPermissionDefault
    let permissionDefault = allPermissionDefault.menu
    let objNewMenu = {
      // _id : new ObjectID().toString(),
      subItems: []
    }
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i] && (modalForm[i].name === 'Menu')){
        if(modalForm[i].value === ''){
          for(let j = 0; j < modalForm.length; j++){
            if(modalForm[j] && ( !( (modalForm[j].name === 'Menu') || (modalForm[j].name === 'SubMenu') || (modalForm[j].name === 'MenuItem') ) ) ){
              if(modalForm[j].value === '') { 
                return this.setState({modalWorning: "Input is blank!"})
              }
              objNewMenu[modalForm[j].key] = modalForm[j].value
            }
          }
          objNewMenu._id = new ObjectID().toString()
          permissionDefault.push(objNewMenu)
        }else{
          for(let j=0; j<modalForm.length; j++) {
            if(modalForm[j] && (modalForm[j].name === 'SubMenu')){
              if(modalForm[j].value === ''){
                for(let k = 0; k < modalForm.length; k++){
                  if(modalForm[k] && (!( (modalForm[k].name === 'Menu') || (modalForm[k].name === 'SubMenu') || (modalForm[k].name === 'MenuItem') ) ) ){
                    if(modalForm[k].value === '') { 
                      return this.setState({modalWorning: "Input is blank!"})
                    }
                    objNewMenu[modalForm[k].key] = modalForm[k].value
                  }
                }
                for(let k = 0; k < permissionDefault.length; k++){
                  if(permissionDefault[k].value === modalForm[i].value){
                    if(permissionDefault[k].to !== undefined) delete permissionDefault[k].to
                    permissionDefault[k].subItems.push(objNewMenu)
                    break
                  }
                }
              }else{
                for(let k=0; k<modalForm.length; k++) {
                  if(modalForm[k] && (modalForm[k].name === 'MenuItem')){
                    if(modalForm[k].value === ''){
                      for(let l = 0; l < modalForm.length; l++){
                        if(modalForm[l] && (!( (modalForm[l].name === 'Menu') || (modalForm[l].name === 'SubMenu') || (modalForm[l].name === 'MenuItem') ) ) ){
                          if(modalForm[l].value === '') { 
                            return this.setState({modalWorning: "Input is blank!"})
                          }
                          objNewMenu[modalForm[l].key] = modalForm[l].value
                        }
                      }
                      for(let l = 0; l < permissionDefault.length; l++){
                        if(permissionDefault[l].value === modalForm[i].value){
                          let subItems = permissionDefault[l].subItems
                          for(let m = 0; m < subItems.length; m++){
                            if(subItems[m].value === modalForm[j].value){
                              if(permissionDefault[l].subItems[m].to !== undefined) delete permissionDefault[l].subItems[m].to
                              permissionDefault[l].subItems[m].subItems.push(objNewMenu) 
                              break
                            } 
                          }
                        }
                      }
                    }
                    break
                  }
                }
              }
              break
            }
          }
        }
        break
      }
    }
    //console.log('permissionDefault =>',permissionDefault)
    //console.log('allPermissionDefault =>',allPermissionDefault)

    if(permissionDefault){
      data.menu = permissionDefault
    }
    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    data.flag = 'add'
    // data.organization = this.state.user.organization[0]
    //console.log('getForm data =>', data);
    let method = 'post'
    let url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults'
    if(this.state.allPermissionDefault._id !== undefined){
      method = 'put'
      url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults/' + this.state.allPermissionDefault._id
    }
    if(this.state.user.role !== 'sadmin'){
      return this.setState({modalWorning: "Permission denied to create a menu!"})
    }
    
    let THIS = this
    axios({
      method: method,
      url: url,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("Add menu response =>", response.data); // {n: 1, nModified: 1, ok: 1}
      if( ( (method === 'post') && response.data.value) || ( (method === 'put') &&  (response.data.value.n == 1) ) ){
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + '_' + '/role?', 
          data: 'values',
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response) {  
          //console.log('response =>',response.data.value)
          let roles = response.data.value
          if(roles.length){
            roles.forEach(currentValue => {
              //console.log('currentValue =>',currentValue)
              if(currentValue.name === 'sadmin'){
                let users = THIS.state.user
                users.permission.menu = permissionDefault
                currentValue.menu = permissionDefault
                axios({
                  method: 'put',
                  url: window.url + 'api/v1/collection/' + '_' + '/role/' + currentValue._id,
                  data: currentValue,
                  headers:{
                    "Authorization": users.token
                  }
                })
                .then(function (response) {  
                  //console.log('response =>',response.data.value)
                  THIS.setState({
                    user : users
                  },() =>{
                    THIS.getPermissionDefault(null)
                  })
                })
                .catch(function (error) {
                  console.log(error);
                  THIS.setState({modalWorning: "Add menu error!"})
                  THIS.modalClose()
                });
              }
            })
          }
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Add menu error!"})
          THIS.modalClose()
        });
      }
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'permissionMan',
        "category" : 'webApp',
        "even" : 'New menu',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Add new menu =>'
      }
      THIS.saveLog(dataSave)

      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "add menu error!"})
      THIS.modalClose()
    });

  }

  getFormEdit = () => {
    //console.log('getForm modalForm =>', this.state.modalForm);
    let data = {}
    let modalForm = this.state.modalForm
    let allPermissionDefault = this.state.allPermissionDefault
    let permissionDefault = allPermissionDefault.menu
    for(let i=0; i < modalForm.length; i++) {
      if(modalForm[i] && (modalForm[i].name === 'nowMenu')){
        for(let l = 0; l < permissionDefault.length; l++){
          if(permissionDefault[l].value === modalForm[i].value){
            let j = 0
            for( ; j < modalForm.length; j++) {
              if(modalForm[j] && (modalForm[j].name === 'nowSubMenu')){
                let subMenu = permissionDefault[l].subItems
                for(let m = 0; m < subMenu.length; m++){
                  if(subMenu[m].value === modalForm[j].value){
                    let k=0
                    for(; k < modalForm.length; k++) {
                      if(modalForm[k] && (modalForm[k].name === 'nowMenuItem')){
                        let menuItem = subMenu[m].subItems
                        for(let n = 0; n < menuItem.length; n++){
                          if(menuItem[n].value === modalForm[k].value){ 
                            for(let p = 0;  p < modalForm.length; p++) {
                              if(modalForm[p] && ( !( (modalForm[p].name === 'nowMenu') || (modalForm[p].name === 'nowSubMenu') || (modalForm[p].name === 'nowMenuItem') ) ) ){
                                if(modalForm[p].value === '') { 
                                  return this.setState({modalWorning: "Input is blank!"})
                                }
                                menuItem[n][modalForm[p].key] = modalForm[p].value
                              }
                            }
                            break
                          }
                        }
                        break
                      }
                    }
                    //console.log('k =>',k)
                    if(k >= modalForm.length){
                      for(let p = 0;  p < modalForm.length; p++) {
                        if(modalForm[p] && ( !( (modalForm[p].name === 'nowMenu') || (modalForm[p].name === 'nowSubMenu') || (modalForm[p].name === 'nowMenuItem') ) ) ){
                          if(modalForm[p].value === '') { 
                            return this.setState({modalWorning: "Input is blank!"})
                          }
                          subMenu[m][modalForm[p].key] = modalForm[p].value
                        }
                      }  
                    }
                    break
                  }
                }
                break
              }
            }
            //console.log('j =>',j)
            if(j >= modalForm.length){
              for(let p = 0;  p < modalForm.length; p++) {
                if(modalForm[p] && ( !( (modalForm[p].name === 'nowMenu') || (modalForm[p].name === 'nowSubMenu') || (modalForm[p].name === 'nowMenuItem') ) ) ){
                  if(modalForm[p].value === '') { 
                    return this.setState({modalWorning: "Input is blank!"})
                  }
                  permissionDefault[l][modalForm[p].key] = modalForm[p].value
                }
              }
            }
            break
          }  
        }
        break
      }
    }
    //console.log('permissionDefault =>',permissionDefault)

    if(permissionDefault){
      data.menu = permissionDefault
    }
    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    data.flag = 'edit'
    // data.organization = this.state.user.organization[0]
    //console.log('getForm data =>', data);
    let method = 'post'
    let url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults'
    if(this.state.allPermissionDefault._id !== undefined){
      method = 'put'
      url = window.url + 'api/v1/collection/' + '_' + '/permissionDefaults/' + this.state.allPermissionDefault._id
    }
    if(this.state.user.role !== 'sadmin'){
      return this.setState({modalWorning: "Permission denied to create a menu!"})
    }
    
    let THIS = this
    axios({
      method: method,
      url: url,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("Edit menu response =>", response.data); // {n: 1, nModified: 1, ok: 1}
      if( ( (method === 'post') && response.data.value) || ( (method === 'put') &&  (response.data.value.n == 1) ) ){
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + '_' + '/role?', 
          data: 'values',
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response) {  
          //console.log('response =>',response.data.value)
          let roles = response.data.value
          if(roles.length){
            roles.forEach(currentValue => {
              //console.log('currentValue =>',currentValue)
              if(currentValue.name === 'sadmin'){
                let users = THIS.state.user
                users.permission.menu = permissionDefault
                currentValue.menu = permissionDefault
                axios({
                  method: 'put',
                  url: window.url + 'api/v1/collection/' + '_' + '/role/' + currentValue._id,
                  data: currentValue,
                  headers:{
                    "Authorization": users.token
                  }
                })
                .then(function (response) {  
                  //console.log('response =>',response.data.value)
                  THIS.setState({
                    user : users
                  },() =>{
                    THIS.getPermissionDefault(null)
                  })
                })
                .catch(function (error) {
                  console.log(error);
                  THIS.setState({modalWorning: "Add menu error!"})
                  THIS.modalClose()
                });
              }
            })
          }
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Add menu error!"})
          THIS.modalClose()
        });
      }
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'permissionMan',
        "category" : 'webApp',
        "even" : 'Edit menu',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Edit menu =>'
      }
      THIS.saveLog(dataSave)

      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
      THIS.modalClose()
    });
  }

  addMenu = () => {
    let treeData =[]
    let permissionDefault = this.state.permissionDefault
    for(let i=0; i<permissionDefault.length; i++) {  
      let item = permissionDefault[i].value 
      treeData.push(item)
    }
    let permissions = ['rw','r']
    //console.log('treeData =>',treeData)
    let modalForm = [
      { key: "menu", name: "Menu", placeholder:"", value: "", disabled: false, type: 'select',items: treeData, col: 1 }, 
      { key: "value", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: 2 }, 
      { key: "to", name: "to", placeholder:"/", value: "", disabled: false, type: 'input', col: 2 },
      { key: "icon", name: "icon", placeholder:"book-open", value: "", disabled: false, type: 'input', col: 2 },
      { key: "permission", name: "permission", placeholder:"r", value: "r", disabled: false, type: 'select',items: permissions, col: 2 },
    ]

    let modalTitle = 'New Menu'
    this.setState({ 
      modalForm: modalForm, 
      modalAddProjectShow: true,
      modalShow: true, 
      modalTitle: modalTitle,
    })
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  statusOnline = (item) => {
    let connect = new Date(item.connect)
    let disconnect = new Date(item.disconnect)
    if(connect > disconnect) return <Text color={'green'}>Online</Text>
    return <Text color={'red'}>Offline</Text>
  }

  // modal funtions ====================================================================
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange RoleMan =>", name, value);
    let modalForm = this.state.modalForm
    // console.log("modalForm  =>", modalForm);
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i]){
        if(modalForm[i].name === name) {
          modalForm[i].value = value
          break
        }
      }
    }

    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i]){
        if(modalForm[i].name === 'Menu' && name === 'Menu'){
          for(let j=0 ; j < modalForm.length; j++) {
            if(modalForm[j]){
              if(modalForm[j].name === 'SubMenu'){
                delete modalForm[j]
              }  
            }
            if(modalForm[j]){
              if(modalForm[j].name === 'MenuItem'){
                delete modalForm[j]
              } 
            }
          }
          let permissionDefault = this.state.permissionDefault
          let sub_Menu = []
          for(let j=0; j<permissionDefault.length; j++) {  
            let item = permissionDefault[j].value
            if(item === value){
              let subItems1 = permissionDefault[j].subItems
              if(subItems1) {
        
                for(let k=0; k<subItems1.length; k++) { 
                  sub_Menu.push(subItems1[k].value)
                }
              }
              break
            }
          }
          let obj = { key: "subMenu", name: "SubMenu", placeholder:"", value: "", disabled: true, type: 'input', col: 1 } 
          modalForm.push(obj)
          break
        }
      }
    }

    // for(let i=0; i<modalForm.length; i++) {
    //   if(modalForm[i]){
    //     if(modalForm[i].name === 'SubMenu' && name === 'SubMenu'){
    //       for(let j=0 ; j < modalForm.length; j++) {
    //         if(modalForm[j]){
    //           if(modalForm[j].name === 'MenuItem'){
    //             delete modalForm[j]
    //             break
    //           } 
    //         } 
    //       }
    //       let permissionDefault = this.state.permissionDefault
    //       let sub_Menu = []
    //       for(let j=0; j<permissionDefault.length; j++) {  
    //         let subItems = permissionDefault[j].subItems
    //         for(let k=0; k<subItems.length; k++) {
    //           if(subItems[k].value === value){
    //             let subItems1 = subItems[k].subItems
    //             if(subItems1) {
    //               for(let l=0; l<subItems1.length; l++) { 
    //                 sub_Menu.push(subItems1[l].value)
    //               }
    //             }
    //             break
    //           }
    //         }
    //       }
    //       let obj = { key: "MenuItem", name: "MenuItem", placeholder:"", value: "", disabled: true, type: 'select',items: sub_Menu, col: 1 } 
    //       modalForm.push(obj)
    //       break
    //     }
    //   }
    // }


    //+-console.log("handleChange modalForm =>", modalForm);
    this.setState({modalForm: modalForm})

  }
  // tree
  onExpand = expandedKeys => {
    // console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
    let modalForm = this.state.modalForm
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.expandedKeys = expandedKeys
        modalForm[i].tree.autoExpandParent = false
        break
      }
    }
    this.setState({modalForm: modalForm})
  };

  onCheck = checkedKeys => {
    //+-console.log('onCheck', checkedKeys);
    this.setState({ checkedKeys });
    let modalForm = this.state.modalForm 
    let home = this.state.home
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.checkedKeys = checkedKeys
        home = checkedKeys
        //break
      }

      if(modalForm[i].key === 'home') {
        let items = JSON.parse(JSON.stringify(checkedKeys))
        let permissionDefault = this.state.permissionDefault

        for(let i=permissionDefault.length-1; i>=0; i--) { 
          let indexOfitems = items.indexOf(permissionDefault[i].value)
          if( indexOfitems >= 0) {
            if(permissionDefault[i].subItems.length) { 
              items.splice(indexOfitems,1)
            }
          }         
        }

        home = items
        modalForm[i].items = home
        modalForm[i].value = modalForm[i].items[0]
      }
    }
    this.setState({
      modalForm: modalForm,
      home: home
    })
  };

  onSelect = (selectedKeys, info) => {
    //console.log('onSelect', info);
    //console.log('selectedKeys', selectedKeys);
    if(JSON.stringify(this.state.user) !== '{}'){
      let items = {}
      if(selectedKeys.length){
        let selectedKeys0 = selectedKeys[0]
        let permissionDefault = this.state.permissionDefault
        
        for(let i=0; i<permissionDefault.length; i++) {
          if((selectedKeys0 !== permissionDefault[i].value) && permissionDefault[i].subItems.length){
            let SubMenu = permissionDefault[i].subItems
            //console.log('SubMenu =>',SubMenu)
            for(let j=0; j<SubMenu.length; j++) {
              if((selectedKeys0 !== SubMenu[j].value) && SubMenu[j].subItems.length){
                let MenuItem = SubMenu[j].subItems
                for(let k=0; k<MenuItem.length; k++) {
                  if(selectedKeys0 === MenuItem[k].value){
                    items = MenuItem[k]
                    break
                  }
                }
              }else if(selectedKeys0 === SubMenu[j].value){
                items = SubMenu[j]
                break
              }
            }
          }else if (selectedKeys0 === permissionDefault[i].value){
            items = permissionDefault[i]
            break
          }
        }
        //console.log('items =>',items)
      }
      if((JSON.stringify(items) !== '{}') && (this.state.user.role === 'sadmin' ) ){
        this.alert({action:'delete_or_edit',items})
      }
    }
  };
  // modal funtions end ====================================================================

  // SelectBranchsLess
  // onChangeSelectBranchsLess = () => {
  //   console.log("onChangeSelectBranchsLess");
  // }

  // onGoPressSelectBranchsLess = () => {
  //   console.log("onGoPressSelectBranchsLess");
  // }
 
  onSearch = () => {
    // console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
      let out = key!=='_id' && typeof(obj[key])=='string' && obj[key].includes(search) 
      // console.log('onSearch out =>', out);
      return out
    }))
    this.setState({searchButton: false, branchs: res}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
        
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  renderTreeNodes = data =>
  data.map(item => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode key={item.key} {...item} />;
  });

  enableButonSave(){
    if(this.state.isButtonDisabled == true){
      console.log('isButtonDisabled ->',this.state.isButtonDisabled)
      this.setState({
        isButtonDisabled: false
      })
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddProjectShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content 
          title="Permission manager"
        >
          <Card>
            <Card.Header>
              <Card.Title>Permission</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button 
                  color="green"                  
                  onClick={ () => this.addMenu() }
                > New Menu </Button>                
              </Card.Options>
              }
            </Card.Header>
              <div>
                <Tree
                  showLine={this.state.showLine}
                  showIcon={this.state.showIcon}
                  defaultExpandedKeys={[]}
                  onSelect = {this.onSelect}
                  treeData={this.state.treeData}
                />
              </div>
          </Card>

        </Page.Content>
        
        <ModalFormLarge
          show={this.state.modalShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalTitle}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default RoleMan;