
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
import SingleSelect from "../components/SingleSelect";
import AnimatedMulti from "../components/MultiSelect";
import {Grid,Card,Button,Form} from "tabler-react";
import DatePicker from "react-datepicker";

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class SelectBranchsStateLess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users:storeGlobal.getusers(),
      Organization: null,
      Project: null,
      Branchs: null,
      OrganizationSelect:null,
      ProjectSelect:null,
      BranchidSelect:null,
      BranchsSelect:null,
      CollectionsSelect:null,
      CollectionsSelect2:null,
      CollectionsSelect3:null,
      Filter:"",
      showFilter: false,
    }
  }

  changOrganization = (dataCallback) => {
  let users = storeGlobal.getusers();
  // let project = [{label:"All",value:"All"}];
  let project = [];
  //+-console.log("changOrganization dataCallback ->",dataCallback);
  if(dataCallback.value === "All"){
    for(let i = 0; i < users.project.length; i++){
      project.push({label:users.project[i].projectName,value:users.project[i].projectName})  
    }
  }else{
    for(let i = 0; i < users.project.length; i++){
      if(dataCallback.value === users.project[i].organization){
        project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
      }
    }
  }
  //+-console.log("project",project);
  users.OrganizationSelect = dataCallback.value
  users.ProjectSelect = ''
  users.BranchidSelect = []
  users.branchsSelect = []
  this.setState({
    OrganizationSelect:dataCallback,
    ProjectSelect:[],
    BranchidSelect:[],
    Project:project,
    users: users
  })
}

changProject = (dataCallback) => {
  let users = storeGlobal.getusers();
  //this.state = storeGlobal.getFilterBranchs();
  let branchs = [{label:"All",value:"All"}];
  //+-console.log("changProject dataCallback ->",dataCallback)
  //+-console.log("this.state.Project =>",this.state.Project)
  //+-console.log("users.branch =>",users.branch)
  if(dataCallback.value === "All"){
    for(let j = 0; j < this.state.Project.length; j++){
      for(let i = 0; i < users.branch.length; i++){
        if(users.branch[i].projectName === this.state.Project[j].value){
          branchs.push({label:users.branch[i].branchID,value:users.branch[i].branchID})  
        }
      }
    }
  }else{
    for(let i = 0; i < users.branch.length; i++){
      if(dataCallback.value === users.branch[i].projectName){
        branchs.push({label:users.branch[i].branchID,value:users.branch[i].branchID})  
      }
    }
  }
  users.ProjectSelect = dataCallback.value
  users.BranchidSelect = []
  users.branchsSelect = []
  this.setState({
    ProjectSelect:dataCallback,
    BranchidSelect:[],
    Branchs:branchs,
    users: users
  });
  //this.setState({Branchs:branchs});
  //storeGlobal.setFilterBranchs(this.state);
  //let FilterBranchs = storeGlobal.getFilterBranchs();
  //this.Branchs = FilterBranchs.Branchs;
}

changBranchs = (dataCallback) => {
  //+-console.log("changBranchs ->",dataCallback);
  let users = storeGlobal.getusers();
  let branchidSelect = [];
  let branchsSelect = [];
  for(let i = 0; i < dataCallback.length; i++){
    branchidSelect.push(dataCallback[i]);

    for(let j = 0; j < users.branch.length; j++){
      if(users.branch[j].branchID === dataCallback[i].value){
        branchsSelect.push(users.branch[j]);
        break;
      }
    }
  }
  this.setState({
    BranchidSelect:branchidSelect,
    BranchsSelect:branchsSelect
  });
}

changBranchsSect = (dataIn) => {
  //+-console.log("changBranchs ->",dataIn);
  let users = storeGlobal.getusers();
  let branchidSelect = [];
  let branchsSelect = [];
  for(let i = 0; i < dataIn.length; i++){
    branchidSelect.push(dataIn[i].value);

    for(let j = 0; j < users.branch.length; j++){
      if(users.branch[j].branchID === dataIn[i].value){
        branchsSelect.push(users.branch[j]);
        break;
      }
    }

    if(dataIn[i].value === "All"){
      if(this.state.OrganizationSelect === "All"){
        if(this.state.ProjectSelect.value === "All"){
          branchidSelect = [];
          branchsSelect = [];
          for(let j = 0; j < users.branch.length; j++){
            branchidSelect.push(users.branch[j].branchID);
            branchsSelect.push(users.branch[j]);
          }
        }else{
          branchidSelect = [];
          branchsSelect = [];
          for(let k = 0; k < users.branch.length; k++){
            if(users.branch[k].projectName === this.state.ProjectSelect.value){
              branchidSelect.push(users.branch[k].branchID);
              branchsSelect.push(users.branch[k]);
            }
          }   
        }
      }else {
        if(this.state.ProjectSelect.value === "All"){
          branchidSelect = [];
          branchsSelect = [];
          for(let j = 0; j < users.project.length; j++){
            if(users.project[j].organization === this.state.OrganizationSelect){
              for(let k = 0; k < users.branch.length; k++){
                if(users.project[j].projectName === users.branch[k].projectName){
                  branchidSelect.push(users.branch[k].branchID);
                  branchsSelect.push(users.branch[k]);
                }
              }
            }
          }  
        }else{
          branchidSelect = [];
          branchsSelect = [];
          for(let k = 0; k < users.branch.length; k++){
            if(users.branch[k].projectName === this.state.ProjectSelect.value){
              branchidSelect.push(users.branch[k].branchID);
              branchsSelect.push(users.branch[k]);
            }
          }  
        }
      }
      break;
    }
  }
  users.BranchidSelect = branchidSelect
  users.branchsSelect = branchsSelect
  this.setState({
    users: users
  })

  return({branchidSelect,branchsSelect})
}

changCollections = (dataCallback) => {
  //console.log("changCollections ->",dataCallback)
  this.setState({CollectionsSelect:dataCallback}, () => {
    if(this.props.CollectionsChang){
      //console.log('CollectionsChang =>',dataCallback)
      this.props.CollectionsChang(dataCallback);  
    }
  })
}

changCollections2 = (dataCallback) => {
  //+-console.log("changCollections2 ->",dataCallback)
  this.setState({CollectionsSelect2:dataCallback})
}

changCollections3 = (dataCallback) => {
  //+-console.log("changCollections3 ->",dataCallback)
  this.setState({CollectionsSelect3:dataCallback})
}

changCollections4 = (dataCallback) => {
  //+-console.log("changCollections4 ->",dataCallback)
  this.setState({CollectionsSelect4:dataCallback})
}

handleChangeStart(date) {
  // let start = new Date('2019-01-29T08:08:09.000Z') //test
  //+-console.log("handleChangeStart date =>",date);
  this.setState({
    startDate: date
  });
}

handleChangeEnd(date) {
//+-console.log("handleChangeEnd date =>",date);
  this.setState({
    endDate: date
  });
}

handleChange(event) {
  //console.log("this.Filter",event.target.value)
  this.Filter = event.target.value
  this.setState({Filter: event.target.value});
  //+-console.log("this.Filter",this.Filter)
}

componentDidMount() {
  //let users = storeGlobal.getusers();
  if(JSON.stringify(this.state.users) !== '{}'){
    let start = new Date()
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});
    // console.log("componentDidMount start =>",start)
    // console.log("componentDidMount end =>",end)

    let tmpOptionProjects = []
    for(let i = 0; i < this.props.optionProjects.length; i++){
      if(this.props.optionProjects[i].label !== "All"){
        tmpOptionProjects.push(this.props.optionProjects[i])
      }
    }

    let OrganizationSelect = []
    let ProjectSelect = []
    let BranchidSelect = []

    if(this.state.users.OrganizationSelect != "All"){
      OrganizationSelect = {label:this.state.users.OrganizationSelect,value:this.state.users.OrganizationSelect}
    }

    if(this.state.users.ProjectSelect != "All"){
      ProjectSelect = {label:this.state.users.ProjectSelect,value:this.state.users.ProjectSelect}
    }else{
      if(tmpOptionProjects.length){
        ProjectSelect = tmpOptionProjects[0]
      }
    }
    //console.log('ProjectSelect = >',ProjectSelect)

    // for(let i = 0; i < this.state.users.branch.length; i++){
    //   if(ProjectSelect.value === this.state.users.branch[i].projectName){
    //     BranchidSelect.push({label:this.state.users.BranchidSelect[i],value:this.state.users.BranchidSelect[i]})
    //   }
    // }

    for(let i = 0; i < this.state.users.branch.length; i++){
      if(ProjectSelect.value === this.state.users.branch[i].projectName){
        for(let j = 0; j < this.state.users.BranchidSelect.length; j++){
          if(this.state.users.BranchidSelect[j] === this.state.users.branch[i].branchID){
            BranchidSelect.push({label:this.state.users.BranchidSelect[j],value:this.state.users.BranchidSelect[j]})
          }
        }
      }
    }


    // console.log('tmpOptionProjects =>',tmpOptionProjects)
    //console.log('BranchidSelect =>',BranchidSelect)

    this.setState({
      Organization:this.props.optionOrganizations,
      Project: tmpOptionProjects,
      Branchs:this.props.optionBranchs,
      // OrganizationSelect: this.state.users.OrganizationSelect,
      // ProjectSelect:this.state.users.ProjectSelect,
      OrganizationSelect: OrganizationSelect,
      ProjectSelect: ProjectSelect,
      BranchidSelect:BranchidSelect
      //BranchidSelect:[{label:"All",value:"All"}]
    });

    if(this.props.Collections){
      this.setState({CollectionsSelect:this.props.Collections[0]});  
    }

    if(this.props.Collections2){
      this.setState({CollectionsSelect2:this.props.Collections2[0]});  
    }

    if(this.props.Collections3){
      this.setState({
        Collections3:this.props.Collections3,
        CollectionsSelect3:this.props.Collections3[0]
      });  
    }

    if(this.props.Collections4){
      this.setState({
        CollectionsSelect4:this.props.Collections4[0]
      });  
    }

    // let dataCallback = {lable: this.state.users.ProjectSelect,value: this.state.users.ProjectSelect }
    // this.changProject(dataCallback)

    // dataCallback = {lable: this.state.users.OrganizationSelect,value: this.state.users.OrganizationSelect }
    // this.changOrganization(dataCallback)
  }

}

// refresh= () => {
//   console.log("this.state.users =>",this.state.users)
//   if(this.state.BranchidSelect.length){
//     //+-console.log("refresh ->",this.state.BranchidSelect)
//     // let tempBranchidSelect = []
//     // for(let i = 0; i < this.state.BranchidSelect.length; i++){
//     //   tempBranchidSelect.push(this.state.BranchidSelect[i].value)
//     // }
//     let tempBranchidSelect = this.changBranchsSect(this.state.BranchidSelect)
//     console.log("tempBranchidSelect =>",tempBranchidSelect)

//     if (this.props.getbranchs) {
//       let values = {
//         OrganizationSelect: this.state.OrganizationSelect,
//         ProjectSelect: this.state.ProjectSelect,
//         BranchidSelect: tempBranchidSelect.branchidSelect,
//         BranchsSelect: tempBranchidSelect.branchsSelect
//         // startDate: this.state.startDate,
//         // endDate: this.state.endDate
//       }
//       //console.log("refresh values =>",values)
//       switch(this.props.typeSelectFilter){
//         case "useDate":
//           values["CollectionsSelect3"] = this.state.CollectionsSelect3
//         case "useDateTime":
//           values["startDate"] = this.state.startDate   
//           values["endDate"] = this.state.endDate
//           this.props.getbranchs(values);
//         break
//         case "useIputSelect":
//             values["startDate"] = this.state.startDate   
//             values["endDate"] = this.state.endDate
//             values["CollectionsSelect"] = this.state.CollectionsSelect
//             values["CollectionsSelect2"] = this.state.CollectionsSelect2
//             values["CollectionsSelect3"] = this.state.CollectionsSelect3
//             values["CollectionsSelect4"] = this.state.CollectionsSelect4
//             values["Filter"] = this.state.Filter
//             //console.log("refresh values2 =>",values)
//             this.props.getbranchs(values);
//         break
//         case "useOrganization":
//         case "noDate":
//         default:
//             this.props.getbranchs(values)
//         break
//       }
//     }
//   }else{
//     if(this.props.typeSelectFilter !== "useOrganization"){
//       alert("Select Vending id again !");
//     }else{
//       if (this.props.getbranchs) {
//         let values = {
//           OrganizationSelect: this.state.OrganizationSelect,
//           ProjectSelect: this.state.ProjectSelect,
//         }
//         this.props.getbranchs(values)
//       }
//     }
//   }
// }

refresh= () => {
  //console.log("this.state.users =>",this.state.users)
  if(this.state.BranchidSelect.length){
    //+-console.log("refresh ->",this.state.BranchidSelect)
    // let tempBranchidSelect = []
    // for(let i = 0; i < this.state.BranchidSelect.length; i++){
    //   tempBranchidSelect.push(this.state.BranchidSelect[i].value)
    // }
    let tempBranchidSelect = this.changBranchsSect(this.state.BranchidSelect)
    //console.log("tempBranchidSelect =>",tempBranchidSelect)

    if (this.props.getbranchs) {
      let values = {
        OrganizationSelect: this.state.OrganizationSelect.value,
        ProjectSelect: this.state.ProjectSelect.value,
        BranchidSelect: tempBranchidSelect.branchidSelect,
        BranchsSelect: tempBranchidSelect.branchsSelect
        // startDate: this.state.startDate,
        // endDate: this.state.endDate
      }
      //console.log("refresh values =>",values)
      switch(this.props.typeSelectFilter){
        case "useDate":
          if(this.props.labelSelectSort && this.props.Collections3) {     //sort
            values["CollectionsSelect3"] = this.state.CollectionsSelect3.value
          }
        case "useDateTime":
          values["startDate"] = this.state.startDate   
          values["endDate"] = this.state.endDate
          this.props.getbranchs(values);
        break
        case "useIputSelect":
            values["startDate"] = this.state.startDate   
            values["endDate"] = this.state.endDate
            if(this.props.labelSelectName && this.props.Collections){
              values["CollectionsSelect"] = this.state.CollectionsSelect.value
            }
            if(this.props.labelSelectName2 && this.props.Collections2){
              values["CollectionsSelect2"] = this.state.CollectionsSelect2.value
            }
            if(this.props.labelSelectSort && this.props.Collections3) {     //sort
              values["CollectionsSelect3"] = this.state.CollectionsSelect3.value
            }
            if(this.props.labelSelectName4 && this.props.Collections4){
              values["CollectionsSelect4"] = this.state.CollectionsSelect4.value
            }
            values["Filter"] = this.state.Filter
            //console.log("refresh values2 =>",values)
            this.props.getbranchs(values);
        break
        case "useOrganization":
        case "noDate":
        default:
            this.props.getbranchs(values)
        break
      }
    }
  }else{
    if(this.props.typeSelectFilter !== "useOrganization"){
      alert("Select Vending id again !");
    }else{
      if (this.props.getbranchs) {
        let values = {
          OrganizationSelect: this.state.OrganizationSelect.value,
          ProjectSelect: this.state.ProjectSelect.value,
        }
        this.props.getbranchs(values)
      }
    }
  }
}

  //dfasdgfghjjk42
  typeRender = () => {
    switch(this.props.typeSelectFilter){
      case "useDate":
        return( 
          <Card.Body>
            <Grid.Row cards={true}>
              <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" > 
                <Form.Group label="Start date">
                  <DatePicker
                    selected={this.state.startDate}
                    selectsStart
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    startDate={this.state.startDate}
                    onChange={this.handleChangeStart.bind(this)}
                    className="form-control ml-2 mr-4 "
                  />
                </Form.Group>
                <Form.Group label="End date" className="ml-4">
                  <DatePicker
                    selected={this.state.endDate}
                    selectsEnd
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    endDate={this.state.endDate}
                    onChange={this.handleChangeEnd.bind(this)}
                    className="form-control ml-2 mr-4 "
                  />
                </Form.Group>
              </Form>
            </Grid.Row>
            
            {this.props.labelSelectSort && this.props.Collections3 && 
            <Grid.Row>
              <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" > 
                <Grid.Col lg={6}>
                  {this.props.labelSelectSort}
                  <SingleSelect  value = {this.state.CollectionsSelect3} options = {this.props.Collections3} dataChang = {this.changCollections3}/> 
                </Grid.Col>
              </Form>
            </Grid.Row>
            }

            {(this.state.Organization && this.state.Project && this.state.Branchs) &&
              <Grid.Row cards={true}>
                {/* <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" >  */}
                  <Grid.Col lg={3}>
                    Organization
                    <SingleSelect  value =  {this.state.OrganizationSelect} options = {this.state.Organization} dataChang = {this.changOrganization}/>
                  </Grid.Col>
                  <Grid.Col lg={3}>
                    Project
                    <SingleSelect  value =  {this.state.ProjectSelect} options = {this.state.Project} dataChang = {this.changProject}/>
                  </Grid.Col>
                  <Grid.Col lg={5}>
                    Vending ID
                    <AnimatedMulti   options = {this.state.Branchs} dataChang = {this.changBranchs} value = {this.state.BranchidSelect}/>
                  </Grid.Col>
                  <Grid.Col lg={1}>
                    <Button type="button" color="primary" className="mt-5 text-center" onClick={this.refresh}>
                      Go!
                    </Button>
                  </Grid.Col>
                {/* </Form> */}
              </Grid.Row>
            }
          </Card.Body>
        )
      //break
      case "useDateTime":
          return( 
            <Card.Body>
              <Grid.Row cards={true}>
                <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" > 
                  <Form.Group label="Start date">
                    <DatePicker
                    selected={this.state.startDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={this.handleChangeStart.bind(this)}
                    className="form-control ml-2 mr-4 "
                    />
                  </Form.Group>
                  <Form.Group label="End date" className="ml-4">
                    <DatePicker
                      selected={this.state.endDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={this.handleChangeEnd.bind(this)}
                      className="form-control ml-2 mr-4 "
                    />
                  </Form.Group>
                </Form>
              </Grid.Row>

              {(this.state.Organization && this.state.Project && this.state.Branchs) &&
                <Grid.Row cards={true}>
                  {/* <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" >  */}
                    <Grid.Col lg={3}>
                      Organization
                      <SingleSelect  value =  {this.state.OrganizationSelect} options = {this.state.Organization} dataChang = {this.changOrganization}/>
                    </Grid.Col>
                    <Grid.Col lg={3}>
                      Project
                      <SingleSelect  value =  {this.state.ProjectSelect} options = {this.state.Project} dataChang = {this.changProject}/>
                    </Grid.Col>
                    <Grid.Col lg={5}>
                      Vending ID
                      <AnimatedMulti   options = {this.state.Branchs} dataChang = {this.changBranchs} value = {this.state.BranchidSelect}/>
                    </Grid.Col>
                    <Grid.Col lg={1}>
                    {( this.props.btLoading ? <Button type="button" color="primary" className="mt-5 text-center" loading> Go! </Button>
                      : <Button type="button" color="primary" className="mt-5 text-center" onClick={this.refresh}> Go! </Button>
                    )}
                    </Grid.Col>
                  {/* </Form> */}
                </Grid.Row>
              }
            </Card.Body>
          )
      //break
      case "useIputSelect":
          return( 
            <Card.Body>
              <Grid.Row cards={true}>
                <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" > 
                  <Form.Group label="Start date">
                    <DatePicker
                    selected={this.state.startDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    onChange={this.handleChangeStart.bind(this)}
                    className="form-control ml-2 mr-4 "
                    />
                  </Form.Group>
                  <Form.Group label="End date" className="ml-4">
                    <DatePicker
                      selected={this.state.endDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={this.handleChangeEnd.bind(this)}
                      className="form-control ml-2 mr-4 "
                    />
                  </Form.Group>
                </Form>
              </Grid.Row>

              <Grid.Row>
              <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" > 
                {this.props.labelSelectName && 
                  <Grid.Col lg={3}>
                  {this.props.labelSelectName}
                    <SingleSelect  value = {this.state.CollectionsSelect}  options = {this.props.Collections} dataChang = {this.changCollections}/> 
                  </Grid.Col>
                }
                {this.props.labelSelectName2 && 
                  <Grid.Col>
                  {this.props.labelSelectName2}
                    <AnimatedMulti  value = {this.state.CollectionsSelect2} options = {this.props.Collections2} dataChang = {this.changCollections2}/> 
                  </Grid.Col>
                }
                {this.props.labelSelectName4 && 
                  <Grid.Col >
                  {this.props.labelSelectName4}
                    <SingleSelect  value = {this.state.CollectionsSelect4} options = {this.props.Collections4} dataChang = {this.changCollections4}/> 
                  </Grid.Col>
                }
                {this.props.labelSelectSort && this.props.Collections3 && 
                  <Grid.Col>
                  {this.props.labelSelectSort}
                    <SingleSelect  value = {this.state.CollectionsSelect3} options = {this.props.Collections3} dataChang = {this.changCollections3}/> 
                  </Grid.Col>
                }
                {this.props.labelInputName && 
                  <Grid.Col lg={3}>
                  {this.props.labelInputName}
                    <Form.Input
                      name="example-text-input"
                      placeholder="example กระทิงแดง"
                      onChange = {this.handleChange.bind(this)}
                    />
                  </Grid.Col>
                }
                </Form>
              </Grid.Row>
              
              {(this.state.Organization && this.state.Project && this.state.Branchs) &&
                <Grid.Row cards={true}>
                  {/* <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" >  */}
                    <Grid.Col lg={3}>
                      Organization
                      <SingleSelect  value =  {this.state.OrganizationSelect} options = {this.state.Organization} dataChang = {this.changOrganization}/>
                    </Grid.Col>
                    <Grid.Col lg={3}>
                      Project
                      <SingleSelect  value =  {this.state.ProjectSelect} options = {this.state.Project} dataChang = {this.changProject}/>
                    </Grid.Col>
                    <Grid.Col lg={5}>
                      Vending ID
                      <AnimatedMulti   options = {this.state.Branchs} dataChang = {this.changBranchs} value = {this.state.BranchidSelect}/>
                    </Grid.Col>
                    <Grid.Col lg={1}>
                      <Button type="button" color="primary" className="mt-5 text-center" onClick={this.refresh}>
                        Go!
                      </Button>
                    </Grid.Col>
                  {/* </Form> */}
                </Grid.Row>
              }
            </Card.Body>
          )
      //break
      case "useOrganization":
        return( 
          <Card.Body>
            {(this.state.Organization && this.state.Project && this.state.Branchs) &&
              <Grid.Row cards={true}>
                {/* <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" >  */}
                  <Grid.Col lg={11}>
                    Organization
                    <SingleSelect value =  {this.state.OrganizationSelect} options = {this.state.Organization} dataChang = {this.changOrganization}/>
                  </Grid.Col>
                  <Grid.Col lg={1}>
                    {
                      ( this.props.btLoading ? <Button type="button" color="primary" className="mt-5 text-center" loading> Go! </Button>
                        : <Button type="button" color="primary" className="mt-5 text-center" onClick={this.refresh}> Go! </Button>
                      )
                    }
                  </Grid.Col>
                {/* </Form> */}
              </Grid.Row>
            }
          </Card.Body>
        )
      case "noDate":
      default:
        return( 
          <Card.Body>
            {(this.state.Organization && this.state.Project && this.state.Branchs) &&
              <Grid.Row cards={true}>
                {/* <Form className="form-inline form-control ml-2 mr-4 mb-4 py-2" >  */}
                  <Grid.Col lg={3}>
                    Organization
                    <SingleSelect  value =  {this.state.OrganizationSelect} options = {this.state.Organization} dataChang = {this.changOrganization}/>
                  </Grid.Col>
                  <Grid.Col lg={3}>
                    Project
                    <SingleSelect  value =  {this.state.ProjectSelect} options = {this.state.Project} dataChang = {this.changProject}/>
                  </Grid.Col>
                  <Grid.Col lg={5}>
                    Vending ID
                    <AnimatedMulti   options = {this.state.Branchs} dataChang = {this.changBranchs} value = {this.state.BranchidSelect}/>
                  </Grid.Col>
                  <Grid.Col lg={1}>
                    {
                      ( this.props.btLoading ? <Button type="button" color="primary" className="mt-5 text-center" loading> Go! </Button>
                        : <Button type="button" color="primary" className="mt-5 text-center" onClick={this.refresh}> Go! </Button>
                      )
                    }
                  </Grid.Col>
                {/* </Form> */}
              </Grid.Row>
            }
          </Card.Body>
        )
      //break
    }  
  }

  showFilterClick = () =>{
    //console.log("showFilterClick =>")
    if(this.state.showFilter){
      this.setState({showFilter: false})  
    }else{
      this.setState({showFilter: true})
    }
  }

  render() {
    // console.log("render state =>",this.state)
    return(
      // this.typeRender()
   
        <Grid.Row cards={true}>
          <Grid.Col lg={12}>
            <Card >
              <div onClick={ () => this.showFilterClick()}>
                <Card.Header>
                  <Card.Title>{ this.props.title }</Card.Title>
                  <Card.Options>
                    { this.state.showFilter? <Card.OptionsItem icon="chevron-up" />
                      : <Card.OptionsItem icon="chevron-down" /> }
                  </Card.Options>
                </Card.Header>
              </div>
              {/* <Card.Body> */}
                {this.state.showFilter && this.typeRender()}
              {/* </Card.Body> */}
            </Card>
          </Grid.Col>
        </Grid.Row>
   
    )
  }
}

SelectBranchsStateLess.defaultProps = {
  title: 'Select filter...',
  // organization: {label:"All1",value:"All1"},
  // project: [{label:"All1",value:"All1"}],
  // branchs: [{label:"All1",value:"All1"}],
  optionOrganizations: [{ value: 'organizations', label: 'organizations' }],
  optionProjects:  [{ value: 'projects', label: 'projects' }],
  optionBranchs:  [{ value: 'branchs', label: 'branchs' }],
  Collections:[
    {label:"Users",value:"__users"},
    {label:"Branch",value:"branch"},
    {label:"Transaction",value:"transaction"},
    {label:"Transaction_update",value:"transaction_update"},
    {label:"Log_config",value:"configSave"},
    {label:"Alert",value:"alert"}
  ],
  btLoading: false,
}

export default SelectBranchsStateLess;
