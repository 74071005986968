import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
  Form
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
// import ModalFormSmall from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
// import SelectBranchsLess from "../components/SelectBranchsLess";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

class DeployLog extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      branchs: [],
      branchsBuf: [],
      branchsPage: [],
      branchEdit: null,

      // modalAddProjectShow: false,
      // modalEditProjectShow: false,
      // modalWorning:'',
      // modalForm: [
      //   { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' },
      //   { key: "description", name: "Description", placeholder:"", value: "", disabled: false, type: 'textarea', row: 3 }, 
      //   { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi' , items: [
      //       'p1', 'p2', 'p3',
      //   ] }, 
      //   // { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input' }, 
      //   // { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select', items: [
      //   //   'p1', 'p2', 'p3',
      //   // ] }, 
      // ],  

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      branchsPageLimit: 20,

      redirect: false,
      location: {},

      typeFilterOptions: [
        { label: 'user', value: 'user' },
        { label: 'product', value: 'product' },
        { label: 'campaign', value: 'campaign', selected: true },
      ],
      statusFilterOptions: [],

      searchText: '',
      searchButton: true,
      usersRes:[],

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization",

      // SelectBranchsLess
      // SelectBranchsLess : {
      //   title: 'Select filter...',
      //   worning: "",
      //   organizations: [], //[ { value: 'organizations', label: 'Organizations' }, ],
      //   projects: [],//[ { value: 'projects', label: 'Projects' }, ],
      //   branchs: [],//[ { value: 'branchs', label: 'Branchs' }, ],
      // }
      
    };


    // this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){

      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: this.state.user.OrganizationSelect,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }

      this.setState({
        OrganizationSelect: users.OrganizationSelect,
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },() =>{
        this.getbranchs(dataCallback)  
      })
    }
  }

  componentWillMount() {       
    //+-console.log('window.url --->', window.url)
  }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "type:{e.type}, branchID:{e.branchID}"
            </SweetAlert>
          )
        }));
        break
      case 'redeploy':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, redeploy it!"
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.editBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Redeploy "type:{e.type}, branchID:{e.branchID}"
            </SweetAlert>
          )
        }));
        break

    }
		
  } 

  deleteBranch(branch) {
    //+-console.log('deleteBranch =>', branch)
    this.hideAlert() 
    let THIS = this
    axios({
      method: 'delete',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/' + 'deploy' + '/' + branch.id,
      data: { user: this.state.user },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // console.log('deleteUser response.data.value =>', response.data.value);  
      let branchs = THIS.state.branchs 
      let branchsBuf = THIS.state.branchsBuf
      branchs = branchs.filter(person => person._id != branch.id)
      branchsBuf = branchsBuf.filter(person => person._id != branch.id)
      THIS.setState({ 
        branchs: branchs,
        branchsBuf: branchsBuf,
      },() => {   
        THIS.paginationItemCal(branchs,THIS.state.paginationActive)       
      })
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
    
  }
  
  // paginationActive(page) {
	// 	this.setState(state => ({
	// 		paginationActive: page
  //   }));

  //   this.branchsPage(page)
  // }

  // branchsPage(page) {
	// 	let branchsPage = []
  //   let start = (page-1) * this.state.branchsPageLimit
  //   let end = start + this.state.branchsPageLimit
  //   if(end >= this.state.branchs.length ) {
  //     end = this.state.branchs.length
  //   }

  //   for(let i=start; i<end; i++) {
  //     // console.log('i -->', i)
  //     branchsPage.push(this.state.branchs[i])
  //   }
  //   this.setState({branchsPage: branchsPage})
  // }

  // paginationItemCal() {
	// 	let n = 1
  //   let paginationItem = []
  //   for(let i=0; i<this.state.branchs.length; i+=this.state.branchsPageLimit) {
  //     // console.log('n -->', n, THIS.state.paginationItem)
  //     paginationItem.push(n++)
  //   }
  //   this.state.paginationItem = paginationItem
  //   this.setState({paginationItem: paginationItem})
  // }

  branchsPage(page) {
    let dataShow = this.state.branchs
    this.paginationItemCal(dataShow,page)
  }

  paginationActive(e) {
    let dataShow = this.state.branchs
    this.paginationItemCal(dataShow,e)
  }

  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.branchsPageLimit
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i == 6){
            paginationItem.push(">")
          }else if(i == 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i == 2){
            paginationItem.push("<")
          }else if(i == 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataShow.push(dataIn[i])
      }
    }

    this.setState({
      branchsPage: dataShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }
  
  getbranchs = (dataCallback) => {
    //+-console.log("getbranchs ->",dataCallback);
    let OrganizationSelect = ''
    if(dataCallback){
      OrganizationSelect = dataCallback.OrganizationSelect
      this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    }else{
      OrganizationSelect = this.state.OrganizationSelect
    }
    let THIS = this
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/' + OrganizationSelect + '/' + 'deploy', 
      // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //console.log('response.data.value =>', response.data.value);  
      // return
      let branchs = response.data.value
      // console.log('=====>', dataCallback.BranchidSelect); 
      // filter
      // branchs = branchs.filter(person => {
      //   if(person.branchID) {
      //     if(dataCallback.BranchidSelect.indexOf(person.branchID) >= 0) {
      //       // console.log('=====> 2', person.branchID); 
      //       return true
      //     }          
      //   }
        
      // });
      //+-console.log('branchs 1 -->', branchs)
      // sort by value
      // items.sort(function (a, b) {
      //   return a.value - b.value;
      // });
      // sort by name
      branchs.sort(function(a, b) {
        var nameA = b.deployTime // a.branchID.toUpperCase(); // ignore upper and lowercase
        var nameB = a.deployTime // b.branchID.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      //console.log('data =>',branchs)

      // THIS.setState({users: response.data.value})
      // console.log('branchs 2 -->', branchs)
      THIS.setState({
        buttonRefresh: true,
        branchs: branchs,
        branchsBuf: branchs,
        usersRes: branchs
      },() => {
        
        //THIS.paginationItemCal()
        THIS.branchsPage(1)  
        THIS.defaultFilterOption(THIS.state.branchsBuf)      

      });
    }) 
    .catch(function (error) {
      console.error(error);
      // alert("error: " + JSON.stringify(error) );
    });
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddProjectShow: false })
    this.setState({ modalWorning: "", modalEditProjectShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    // console.log('getForm modalForm =>', this.state.modalForm);    
    let data = {}
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].value == '') {
        return this.setState({modalWorning: "Input is blank!"})
      }
      data[this.state.modalForm[i].key] = this.state.modalForm[i].value
    }

    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    // return
    let THIS = this
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/' + 'project',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Add project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let branchAdd = response.data.value[0]
      THIS.state.branchsPage.unshift(branchAdd)
      THIS.setState({branchsPage: THIS.state.branchsPage})
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
    });

  }

  getFormEdit = () => {
    //+-console.log('getFormEdit modalForm =>', this.state.modalForm);  
    let data = {}
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].value == '' || this.state.modalForm[i].value == undefined) {
        return this.setState({modalWorning: "Input is blank!"})
      }
      data[this.state.modalForm[i].key] = this.state.modalForm[i].value
    }

    // data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    // return
    let THIS = this
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/' + 'project' + '/' + this.state.branchEdit._id,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Edit api response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}

      for (var k in data) {
        THIS.state.branchEdit[k] = data[k]
      }
      for(let i=0; i<THIS.state.branchsPage.length; i++) {
        if(THIS.state.branchsPage[i]._id == THIS.state.branchEdit._id) {
          THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      for(let i=0; i<THIS.state.branchs.length; i++) {
        if(THIS.state.branchs[i]._id == THIS.state.branchEdit._id) {
          THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
      // THIS.state.branchPage.unshift(userAdd)
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit api error!"})
    });

  }

  addBranch = () => {
    //+-console.log('addBranch item =>');
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }
    this.state.modalForm = [
      { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: "", disabled: false, type: 'textarea', rows: 3 },
      { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]
    // console.log('this.state.modalForm =>', this.state.modalForm);
    this.setState({ modalForm: this.state.modalForm, modalAddProjectShow: true })
  }

  editBranch = (branch) => {
    //+-console.log('editBranch item =>', branch);    
    let THIS = this
    let data = { status: 'Redeploy', updateBy: this.state.user.username }
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/' + 'deploy' + '/' + branch.id,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log('editBranch response.data.value =>', response.data.value);  
      for (var k in data) {
        THIS.state.branchEdit[k] = data[k]
      }
      for(let i=0; i<THIS.state.branchsPage.length; i++) {
        if(THIS.state.branchsPage[i]._id == THIS.state.branchEdit._id) {
          THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      for(let i=0; i<THIS.state.branchs.length; i++) {
        if(THIS.state.branchs[i]._id == THIS.state.branchEdit._id) {
          THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
      THIS.hideAlert()

    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
  }

  statusOnline = (item) => {
    let connect = new Date(item.connect)
    let disconnect = new Date(item.disconnect)
    if(connect > disconnect) return <Text color={'green'}>Online</Text>
    return <Text color={'red'}>Offline</Text>
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].name == name) {
        this.state.modalForm[i].value = value
        break
      }
    }

    this.setState({modalForm: this.state.modalForm})

  }

  sortClick = (obj) => {
    // console.log("sortClick obj =>", obj);
    if(!obj) {
      return
    }
    let branchs = this.state.branchs
    switch(obj.name) {
      case 'deployTime':
        if(obj.sort==1) {
          branchs.sort(function(a, b) {
            var nameA = b.deployTime // a.branchID.toUpperCase(); // ignore upper and lowercase
            var nameB = a.deployTime // b.branchID.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
          
        } else {
          branchs.sort(function(a, b) {
            var nameA = a.deployTime // a.branchID.toUpperCase(); // ignore upper and lowercase
            var nameB = b.deployTime // b.branchID.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          });
        }
        this.setState({
          branchs: branchs
        },() => {            
          //this.paginationItemCal()
          this.branchsPage(1)      
        });
        break

    }

  }

  filterChange = (filter, selectedOption) => {
    // console.log("filterChange selectedOption  =>", filter, selectedOption );
    let branchs = JSON.parse(JSON.stringify(this.state.branchsBuf)) //
    let options = []
    switch(filter) {
      case 'type':
        if(!selectedOption.length) {
          branchs = []
        } else {
          for(let i=0; i<selectedOption.length; i++) {
            options.push(selectedOption[i].value)
          }
          branchs = branchs.filter(person => {
            if(options.indexOf(person.type) >= 0) {
              return true
            }    
          })
        }        
        break
      case 'status':
        if(!selectedOption.length) {
          branchs = []
        } else {
          for(let i=0; i<selectedOption.length; i++) {
            options.push(selectedOption[i].value)
          }
          branchs = branchs.filter(person => {
            if(options.indexOf(person.status) >= 0) {
              return true
            }    
          })
        }        
        break

    }
    this.setState({
      branchs: branchs,
      // branchsBuf: branchs,
    },() => {          
      //this.paginationItemCal()
      this.branchsPage(1)   
    });
  }

  defaultFilterOption = (data) => {
    //+-console.log("defaultFilterOption data =>", data);
    let typeFilterOptions = []    
    let typeFilter = []
    let statusFilterOptions = []
    let statusFilter = []

    for(let i=0; i<data.length; i++) {
      if(typeFilter.indexOf(data[i].type) < 0) {
        typeFilter.push(data[i].type)
        typeFilterOptions.push({ label: data[i].type, value: data[i].type, })
      }  
      if(statusFilter.indexOf(data[i].status) < 0) {
        statusFilter.push(data[i].status)
        statusFilterOptions.push({ label: data[i].status, value: data[i].status, })
      }      
    }

    this.setState({
      typeFilterOptions: typeFilterOptions,
      statusFilterOptions: statusFilterOptions,

    },() => {          

    });
  }

  // SelectBranchsLess
  // onChangeSelectBranchsLess = () => {
  //   console.log("onChangeSelectBranchsLess");
  // }

  // onGoPressSelectBranchsLess = () => {
  //   console.log("onGoPressSelectBranchsLess");
  // }

  onSearch = () => {
    //console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
      return key!='_id' && typeof(obj[key])=='string' && key!='deployTime' && key!='createDate' && key!='updateDate' &&  obj[key].includes(search) 
    }))
    //console.log("res =>",res)
    this.setState({searchButton: false, branchs: res}, () => {
      let products = res
      let paginationActive = this.state.paginationActive
      this.paginationItemCal(products, paginationActive)
    })
  }

  onSearchClear = () => {
    //console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      let products = this.state.usersRes
      let paginationActive = this.state.paginationActive
      this.paginationItemCal(products, paginationActive)
    })          
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddProjectShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content 
          title="Deploy status"
          options={
            <Form.Group label="">
              {((this.state.usersRes.length) || (this.state.branchsPage.length) || (this.state.buttonRefresh)) &&
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
                  {(this.state.branchsPage.length || this.state.buttonRefresh)? <Button color="default" icon="rotate-cw" 
                    onClick={ () => { 
                        this.setState({
                          branchsPage: [],
                          buttonRefresh: false
                          },() => {
                          this.getbranchs(null)
                        })                 
                      }}
                    /> :
                    <Button color="default" icon="rotate-cw" loading />   
                  } 
                </Form.InputGroup>
              }
            </Form.Group>   
          }  
        >
        { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
          <Card>
            <Card.Header>
              <Card.Title>Deploy</Card.Title>
              <Card.Options>
                <Dropdown
                  type="button"
                  toggle={false}
                  color="primary"
                  arrow
                  icon="list"
                  triggerContent="Sorting.."
                  itemsObject={[
                    {
                      value: "DEPLOY TIME: newest => oldest",
                      onClick: () => this.sortClick({name:'deployTime', sort: -1}),
                    },
                    {
                      value: "DEPLOY TIME: oldest => newest",
                      onClick: () => this.sortClick({name:'deployTime', sort: 1}),
                    },
                    // { isDivider: true },
                    // { value: "Logout" },
                  ]}
                />
              </Card.Options>
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>ITEM</Table.ColHeader>
                  <Table.ColHeader> type
                    {/* <Icon prefix="fe" name="filter" className="ml-2" 
                    
                    />   */}
                    
                  </Table.ColHeader> 
                  <Table.ColHeader>Vending name</Table.ColHeader> 
                  <Table.ColHeader>Vending id</Table.ColHeader>    
                  <Table.ColHeader>update By</Table.ColHeader>
                  <Table.ColHeader>deploy time</Table.ColHeader>
                  <Table.ColHeader>update date</Table.ColHeader>
                  <Table.ColHeader>status</Table.ColHeader>
                  { this.state.user.level=='write' && <Table.ColHeader className="text-center">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Col></Table.Col>
                  <Table.Col>
                    <ReactMultiSelectCheckboxes 
                      options={ this.state.typeFilterOptions } 
                      defaultValue={ this.state.typeFilterOptions } 
                      placeholderButtonLabel={'Filter..'}
                      onChange={ (selectedOption) => this.filterChange('type', selectedOption) }
                      // getDropdownButtonLabel={this.filterDrop}
                    />
                  </Table.Col>
                  <Table.Col></Table.Col>
                  <Table.Col></Table.Col>
                  <Table.Col></Table.Col>
                  <Table.Col></Table.Col>
                  <Table.Col></Table.Col>
                  <Table.Col>
                    <ReactMultiSelectCheckboxes 
                      options={ this.state.statusFilterOptions } 
                      defaultValue={ this.state.statusFilterOptions } 
                      placeholderButtonLabel={'Filter..'}
                      onChange={ (selectedOption) => this.filterChange('status', selectedOption) }
                      // getDropdownButtonLabel={this.filterDrop}
                    />
                  </Table.Col>
                  <Table.Col></Table.Col>
                </Table.Row>
                                
                { this.state.branchsPage.length ? this.state.branchsPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    <Table.Col> { i+1 } </Table.Col>
                    <Table.Col>{ item.type }</Table.Col>
                    <Table.Col>{ item.name }</Table.Col>
                    <Table.Col>{ item.branchID }</Table.Col>
                    <Table.Col>{ item.updateBy }</Table.Col> 
                    {/* <Table.Col>{ item.createDate }</Table.Col> */}
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.deployTime }
                      </Moment>
                    </Table.Col>
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.updateDate }
                      </Moment>
                    </Table.Col>
                    <Table.Col>{ item.status }</Table.Col>  
                    
                    { this.state.user.level=='write' && <Table.Col className="text-center">
                      <Button.List>                        
                        <Button size="sm" color="info"
                          onClick={ ()=> { this.state.branchEdit = item; this.alert({action:'redeploy', id: item._id, type: item.type, branchID:item.branchID, i: i }) } } >
                          Redeploy
                        </Button>
                        <Button size="sm" color="danger" 
                          onClick={ ()=> { this.alert({action:'delete', id: item._id, type: item.type, branchID:item.branchID, i: i }) } } >
                          Delete
                        </Button>                        
                      </Button.List>
                    </Table.Col> 
                    }          
                  </Table.Row>
                ) : <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>
        
        {/* <ModalFormSmall
          show={this.state.modalAddProjectShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={'Add Project'}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />
        <ModalFormSmall
          show={this.state.modalEditProjectShow}
          onHide={this.modalClose}
          onSave={this.getFormEdit}
          onInputChange={this.handleInputChange}
          title={'Add Project'}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        /> */}

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default DeployLog;