// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchsAndDate";
import SelectBranchs from "../components/SelectBranchsStateLess";
import Pagination from 'react-bootstrap/Pagination'
import ButtonExport from "../components/buttonExports"
import SweetAlert from 'react-bootstrap-sweetalert'

import {Page, Grid, Card,  Text,  Table} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";

import ModalFormLarge from "../components/ModalFormLarge";
import Moment from "react-moment";

let users = storeGlobal.getusers();

class TransactionsAll extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchidSelect:[],
      brabchNameSelect:"",
      key: 'cash',
      title:"Transactions",
      //urls:"http://localhost:3001/api/v1/report/row-data",
      urls: window.url + "api/v1/report/row-data",
      headerShow: ["item","date","vending name","product","channel","Report type","Payment Type","Value","Change","Last count","Last added","Max count","Card no/Ref no"],
      header:[],
      value:[
      ],
      sum:[],
      sumShow:[],
      paginationItem: [],
      paginationActive: 1,
      countPerPage:20,
      dataCallback:{},
      resultCount:"",
      resultAll:[],

      optionOrganizations: null,  //"../components/SelectBranchsStateLess";
      optionProjects: null,
      optionBranchs: null,
      //typeSelectFilter:"useDate"
      typeSelectFilter:"useIputSelect",
      Collections: null,
      Collections2: null,
      
      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled:true,
      alertShow: false,
      alertText: '',
      labelSelectSort: "Sorting",
      Collections3: window.transactionsPageList.sorting,

      modalDetailShow: false,
    }
  }


//dfasdgfghjjk42
getbranchs = (dataCallback) => {
  //+-console.log("getbranchs ->",dataCallback);
  this.state.brabchNameSelect = ""
  for(var i = 0; i < dataCallback.BranchsSelect.length; i++){
    this.state.brabchNameSelect += "("+dataCallback.BranchsSelect[i].branchName +"),"
  }

  this.setState({dataCallback:dataCallback})
  this.setState({brabchNameSelect:this.state.brabchNameSelect});
  this.setState({brabchidSelect:dataCallback.BranchidSelect});
  this.setState({organization:dataCallback.OrganizationSelect});
  this.setState({startDate:dataCallback.startDate});
  this.setState({endDate:dataCallback.endDate});
  this.setState({CollectionsSelect:dataCallback.CollectionsSelect})
  this.setState({CollectionsSelect2:dataCallback.CollectionsSelect2})
  this.setState({paginationActive:1});
  let branchID = dataCallback.BranchidSelect
  let branchIDs = []
  let header = []
  for (let x in branchID) {
    branchIDs.push({branchID:branchID[x]})
  }

  // //let start = new Date('2019-01-01T08:08:09.000Z') //test
  // let start = new Date(startDate)
  // start.setHours(0); start.setMinutes(0); start.setSeconds(0)
  // //let end = new Date('2019-02-01T08:08:09.000Z') //test
  //   let end = new Date(endDate)
  // end.setHours(23); end.setMinutes(59); end.setSeconds(59);

  if(!(Object.entries(users).length === 0)){
    this.setState({ user:users});
    let THIS = this
    let token = users.token
    let urls = this.state.urls
    let values = {
      "organization":"",
      "branchID": [],
      startDate:dataCallback.startDate,
      endDate:dataCallback.endDate
    }

    if(dataCallback.CollectionsSelect3){
      switch(dataCallback.CollectionsSelect3.value){
        case "Oldest":
          values["sortNatural"] = 1
        break
        case "Newest":
          values["sortNatural"] = -1
        break
      }
    }

    header = ["date","branch","name","channel","type","payType","payValue","payChange","count","countAdd","max","cardNoRef"] 
    this.setState({header:header})
    this.setState({value:[]})
    this.setState({sum:[]})
    // values.projection = { date:1,branch:1,name:1,channel:1,type:1,payType:1,payValue:1,payChange:1,count:1,countAdd:1,max:1,cardNoRef:1 }
    values.projection = {}
    values.collections=dataCallback.OrganizationSelect+"_transaction"
    values.countLimit = this.state.countPerPage
    values.countSkip = 0
    values.header = header;
    values.query = { 
      $and:[
        {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
        {$or:branchIDs}
      ]
    }

    if(dataCallback.CollectionsSelect.label !== 'All'){
      values.query.$and.push({$or:[{type:dataCallback.CollectionsSelect.value}]})
    }

    let tempPayType = []
    for(var i = 0; i < dataCallback.CollectionsSelect2.length; i++){
      if(dataCallback.CollectionsSelect2[i].label !== 'All'){
        tempPayType.push({payType:dataCallback.CollectionsSelect2[i].value})
      }else{
        tempPayType = []  
        break
      }
    }

    if(tempPayType.length){
      values.query.$and.push({$or:tempPayType})  
    }

    if(dataCallback.Filter !== ''){
      values.query.$and.push({$or:[{name:dataCallback.Filter}]})  
    }

  
    values.branchID = dataCallback.BranchidSelect; 
    values.organization = dataCallback.OrganizationSelect;
    //console.log('values =>',values)


    let valuesNolimit = JSON.parse(JSON.stringify(values))
    valuesNolimit.countLimit = 0
    
    let parameters = {
      urls: this.state.urls,
      token : users.token,
      values:valuesNolimit,
      nameOfReport : this.state.title,
      headerShow : this.state.headerShow,
      method: "post"
    } 

    this.setState({"parameters":parameters, headers: header})

    //+-console.log("values ->",values)
    //console.log("token ->",token)
    //console.log("url -->",urls)
    //console.log("this.state.user.token -->",THIS.state)
    axios({
      method: 'post',
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: urls,
      data: values,
      headers:{
        "Authorization":token
      }
      //withCredentials: true
    })
    .then(function (response) {
      // console.log("------- response -->",response.data);
      let page = THIS.state.countPerPage
      let resultAll = response.data.value
      let resultCount = response.data.resultCount>>0
      let pageCount =  Math.ceil(resultCount / page)
      let paginationActive = THIS.state.paginationActive
      THIS.state.paginationItem = []
      for(let i = 1; i <= pageCount; i++){
        if(i < 6){
          THIS.state.paginationItem.push(i)
        }else if(i==6){
          THIS.state.paginationItem.push(">")
        }else if(i==7){
          THIS.state.paginationItem.push(">|")
        }
      }

      THIS.state.value = []
      // for(let i = page * (paginationActive -1); ((i < page*paginationActive) &&  (i < resultAll.length)); i++){
      //   THIS.state.value.push(resultAll[i])
      // }
      for(let i = 0; i < resultAll.length; i++){
        THIS.state.value.push(resultAll[i])
        response.data.transactions[i].index = i+1
      }

      THIS.setState({"resultAll":resultAll})
      // THIS.setState({ "value":THIS.state.value});
      THIS.setState({ "value": response.data.transactions});
      THIS.setState({"paginationItem":THIS.state.paginationItem})
      THIS.setState({ "resultCount":response.data.resultCount});
      THIS.setState({"isButtonDisabled":false})
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }
}

  paginationActive(e) {
    //this.state.paginationActive = e
    let page = this.state.countPerPage
    let resultCount = this.state.resultCount
    let pageCount =  Math.ceil(resultCount / page)
    if(e=='>'){
      this.state.paginationActive++;
    }else if(e=='<'){
      this.state.paginationActive--;
    }else if(e=='>|'){
      this.state.paginationActive = pageCount;
    }else if(e=='|<'){
      this.state.paginationActive  = 1;
    }else{
      this.state.paginationActive = e
    }
    e = this.state.paginationActive
    this.setState(state => ({
      paginationActive: this.state.paginationActive>>0
    }));
    //+-console.log("paginationActive e ->",e)
    if(!(Object.entries(users).length === 0)){
      this.setState({ user:users});
      let THIS = this
      let token = users.token
      let urls = this.state.urls
      let branchID = this.state.dataCallback.BranchidSelect
      let branchIDs = []
      let header = []
      for (let x in branchID) {
        branchIDs.push({branchID:branchID[x]})
      }
      let values = {
        "organization":"",
        "branchID": [],
        startDate:this.state.startDate,
        endDate:this.state.endDate
      }

      if(this.state.dataCallback.CollectionsSelect3){
        switch(this.state.dataCallback.CollectionsSelect3.value){
          case "Oldest":
            values["sortNatural"] = 1
          break
          case "Newest":
            values["sortNatural"] = -1
          break
        }
      }
      
      header = ["date","branch","name","channel","type","payType","payValue","payChange","count","countAdd","max","cardNoRef"]
      this.setState({header:header})
      this.setState({value:[]})
      this.setState({sum:[]})
      // values.projection = {date:1,branch:1,name:1,channel:1,type:1,payType:1,payValue:1,payChange:1,count:1,countAdd:1,max:1,cardNoRef:1 }
      values.projection = {}
      values.collections=this.state.dataCallback.OrganizationSelect+"_transaction"
      values.countLimit = this.state.countPerPage
      values.countSkip = (e-1)*this.state.countPerPage
      values.header = header;
      values.query = { 
        $and:[
          {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
          {$or:branchIDs}
        ]
      }

      if(this.state.dataCallback.CollectionsSelect.label !== 'All'){
        values.query.$and.push({$or:[{type:this.state.dataCallback.CollectionsSelect.value}]})
      }

      let tempPayType = []
      for(var i = 0; i < this.state.dataCallback.CollectionsSelect2.length; i++){
        if(this.state.dataCallback.CollectionsSelect2[i].label !== 'All'){
          tempPayType.push({payType:this.state.dataCallback.CollectionsSelect2[i].value})
        }else{
          tempPayType = []  
          break
        }
      }
  
      if(tempPayType.length){
        values.query.$and.push({$or:tempPayType})  
      }

      if(this.state.dataCallback.Filter !== ''){
        values.query.$and.push({$or:[{name:this.state.dataCallback.Filter}]})  
      }

      values.branchID = this.state.dataCallback.BranchidSelect; 
      values.organization = this.state.dataCallback.OrganizationSelect;

      //+-console.log("values ->",values)
      //console.log("token ->",token)
      //+-console.log("url -->",urls)
      //console.log("this.state.user.token -->",THIS.state)
      axios({
        method: 'post',
        // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
        url: urls,
        data: values,
        headers:{
          "Authorization":token
        }
        //withCredentials: true
      })
      .then(function (response) {
        //+-console.log("response -->",response.data);
        let resultAll = response.data.value
        //let paginationActive = THIS.state.paginationActive
        THIS.state.paginationItem = []
        if(pageCount < 6){
          for(let i = 1; i <= pageCount; i++){
              THIS.state.paginationItem.push(i)
          }
        }else{
          if(THIS.state.paginationActive < 4){
            for(let i = 1; i <= pageCount; i++){
              if(i < 6){
                THIS.state.paginationItem.push(i)
              }else if(i == 6){
                THIS.state.paginationItem.push(">")
              }else if(i == 7){
                THIS.state.paginationItem.push(">|")
              }else{
                break;
              }  
            }
          }else if(THIS.state.paginationActive > (pageCount-3)){
            for(let i = 1; i < 8; i++){
              if(i > 2){
                THIS.state.paginationItem.push((pageCount - (7-i)))
              }else if(i == 2){
                THIS.state.paginationItem.push("<")
              }else if(i == 1){
                THIS.state.paginationItem.push("|<")
              }else{
                break;
              }  
            }  
          }else{
            for(let i = 1; i < 10; i++){
              switch(i){
                case 1:
                  THIS.state.paginationItem.push("|<")
                break;
                case 2:
                  THIS.state.paginationItem.push("<")
                break;
                case 3:
                    THIS.state.paginationItem.push(THIS.state.paginationActive-2)
                break;
                case 4:
                    THIS.state.paginationItem.push(THIS.state.paginationActive-1)
                break;
                case 5:
                  THIS.state.paginationItem.push(THIS.state.paginationActive)
                break;
                case 6:
                    THIS.state.paginationItem.push(THIS.state.paginationActive+1)
                break;
                case 7:
                  THIS.state.paginationItem.push(THIS.state.paginationActive+2)
                break;
                case 8:
                    THIS.state.paginationItem.push(">")
                break;
                case 9:
                    THIS.state.paginationItem.push(">|")
                break;
              }
            }  
          }
        }
        THIS.state.value = []
        for(let i = 0; i < resultAll.length; i++){
          THIS.state.value.push(resultAll[i])
          response.data.transactions[i].index = i+1+(page*(e-1))
        }

        THIS.setState({"resultAll":resultAll})
        // THIS.setState({ "value":THIS.state.value});
        THIS.setState({ "value": response.data.transactions});
        THIS.setState({"paginationItem":THIS.state.paginationItem})
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }

  componentDidMount() {
    let start = new Date()
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});
    if(!(Object.entries(users).length === 0)){
      let organization = []
      for(var i = 0; i < users.organization.length; i++){
        organization.push({label:users.organization[i],value:users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < users.branch.length; j++){
        branchID.push({label:users.branch[j].branchID,value:users.branch[j].branchID});
      }
      this.setState({optionBranchs:branchID});

      let project = [{label:"All",value:"All"}]
      for(var i = 0; i < users.project.length; i++){
        if(organization[0].value == users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      this.setState({optionProjects:project});

      this.setState({Collections:window.transactionsPageList.type});
      this.setState({Collections2:window.transactionsPageList.payType});

      let parameters = {
        urls: this.state.urls,
        token : users.token,
        values:{},
        nameOfReport : this.state.title,
        headerShow : this.state.headerShow
      } 
      this.setState({"parameters":parameters})

      let dataCallback = {
        BranchsSelect: users.branch, 
        BranchidSelect: users.BranchidSelect,
        OrganizationSelect: users.OrganizationSelect,
        startDate: start,
        endDate: end,
        CollectionsSelect: window.transactionsPageList.type[0],
        CollectionsSelect2: window.transactionsPageList.payType[0],
        CollectionsSelect3: window.transactionsPageList.sorting[0]
      }
      this.getbranchs(dataCallback)

      //+-console.log("optionBranchs =>",branchID)
      //+-console.log("optionProjects =>",project)
      //+-console.log("optionOrganization =>",organization)
    }
  }

  showDetail = (itemIn,index) =>{
    // console.log('-------- itemIn,index =>', itemIn, index)
    if(!itemIn.sensor) return

    let dataShow = {}
    if(itemIn.branchID) { dataShow['branchID'] = itemIn.branchID} else {dataShow['branchID'] = '-'}
    if(itemIn.branch) { dataShow['branch'] = itemIn.branch} else {dataShow['branch'] = '-'}
    if(itemIn.value) { dataShow['value'] = itemIn.value} else {dataShow['value'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_1 != undefined) { dataShow['volt_1'] = itemIn.sensor.volt_1} else {dataShow['volt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_2 != undefined) { dataShow['volt_2'] = itemIn.sensor.volt_2} else {dataShow['volt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_1 != undefined) { dataShow['current_1'] = itemIn.sensor.current_1} else {dataShow['current_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_2 != undefined) { dataShow['current_2'] = itemIn.sensor.current_2} else {dataShow['current_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_1 != undefined) { dataShow['watt_1'] = itemIn.sensor.watt_1} else {dataShow['watt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_2 != undefined) { dataShow['watt_2'] = itemIn.sensor.watt_2} else {dataShow['watt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_PH != undefined) { dataShow['var_PH'] = itemIn.sensor.var_PH} else {dataShow['var_PH'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_ORP != undefined) { dataShow['var_ORP'] = itemIn.sensor.var_ORP} else {dataShow['var_ORP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_EC != undefined) { dataShow['var_EC'] = itemIn.sensor.var_EC} else {dataShow['var_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.Set_EC != undefined) { dataShow['Set_EC'] = itemIn.sensor.Set_EC} else {dataShow['Set_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_V != undefined) { dataShow['M_V'] = itemIn.sensor.M_V} else {dataShow['M_V'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_A != undefined) { dataShow['M_A'] = itemIn.sensor.M_A} else {dataShow['M_A'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PP != undefined) { dataShow['M_PP'] = itemIn.sensor.M_PP} else {dataShow['M_PP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PN != undefined) { dataShow['M_PN'] = itemIn.sensor.M_PN} else {dataShow['M_PN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VL != undefined) { dataShow['M_VL'] = itemIn.sensor.M_VL} else {dataShow['M_VL'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VT != undefined) { dataShow['M_VT'] = itemIn.sensor.M_VT} else {dataShow['M_VT'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EP != undefined) { dataShow['M_EP'] = itemIn.sensor.M_EP} else {dataShow['M_EP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EN != undefined) { dataShow['M_EN'] = itemIn.sensor.M_EN} else {dataShow['M_EN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VF != undefined) { dataShow['M_VF'] = itemIn.sensor.M_VF} else {dataShow['M_VF'] = '-'}

    let modalForm = [
      { key: "branchID", name: "ID", placeholder: "", value: dataShow.branchID, disabled: true, type: 'input', col : 1}, 
      { key: "branch", name: "Name", placeholder:"", value: dataShow.branch, disabled: true, type: 'input', col:2 }, 
      { key: "Value", name: "Value", placeholder:"", value: dataShow.value, disabled: true, type: 'input', col:1 }, 
      { key: "var_PH", name: "var_PH", placeholder:"", value: dataShow.var_PH, disabled: true, type: 'input', col:2 }, 
      { key: "var_ORP", name: "var_ORP", placeholder:"", value: dataShow.var_ORP, disabled: true, type: 'input', col:1 }, 
      { key: "var_EC", name: "var_EC", placeholder:"", value: dataShow.var_EC, disabled: true, type: 'input', col:2 }, 
      { key: "Set_EC", name: "Set_EC", placeholder:"", value: dataShow.Set_EC, disabled: true, type: 'input', col:1 },
      { key: "M_VF", name: "M_VF", placeholder:"", value: dataShow.M_VF, disabled: true, type: 'input', col:2 }, 
      { key: "M_VL", name: "M_VL", placeholder:"", value: dataShow.M_VL, disabled: true, type: 'input', col:1 }, 
      { key: "M_VT", name: "M_VT", placeholder:"", value: dataShow.M_VT, disabled: true, type: 'input', col:2 }, 
      { key: "volt_1", name: "volt_1", placeholder:"", value: dataShow.volt_1, disabled: true, type: 'input', col:1 }, 
      { key: "volt_2", name: "volt_2", placeholder:"", value: dataShow.volt_2, disabled: true, type: 'input', col:2 }, 
      { key: "current_1", name: "current_1", placeholder:"", value: dataShow.current_1, disabled: true, type: 'input', col:1 }, 
      { key: "current_2", name: "current_2", placeholder:"", value: dataShow.current_2, disabled: true, type: 'input', col:2 }, 
      { key: "watt_1", name: "watt_1", placeholder:"", value: dataShow.watt_1, disabled: true, type: 'input', col:1 }, 
      { key: "watt_2", name: "watt_2", placeholder:"", value: dataShow.watt_2, disabled: true, type: 'input', col:2 },  
      { key: "M_V", name: "M_V", placeholder:"", value: dataShow.M_V, disabled: true, type: 'input', col:1 }, 
      { key: "M_A", name: "M_A", placeholder:"", value: dataShow.M_A, disabled: true, type: 'input', col:2 }, 
      { key: "M_PP", name: "M_PP", placeholder:"", value: dataShow.M_PP, disabled: true, type: 'input', col:1 }, 
      { key: "M_PN", name: "M_PN", placeholder:"", value: dataShow.M_PN, disabled: true, type: 'input', col:2 }, 
      { key: "M_EP", name: "M_EP", placeholder:"", value: dataShow.M_EP, disabled: true, type: 'input', col:1 }, 
      { key: "M_EN", name: "M_EN", placeholder:"", value: dataShow.M_EN, disabled: true, type: 'input', col:2 }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({
      modalForm: modalForm,
      modalDetailShow: true
    });
  }

  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalDetailShow: false });
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  render() {
    users = storeGlobal.getusers();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // console.log("Products user -->",users)
    // console.log("Products branchs -->",this.state.branchs)
    // console.log("Products branchs -->",this.state.organization)

    return (
      <SiteWrapper user={users} props = {this.props}>
        <Page.Content title={this.state.title}>
        {(this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
          <SelectBranchs 
            typeSelectFilter = {this.state.typeSelectFilter}
            getbranchs = {this.getbranchs} 
            optionOrganizations = {this.state.optionOrganizations}
            optionProjects = {this.state.optionProjects}
            optionBranchs = {this.state.optionBranchs}
            Collections = {this.state.Collections}
            labelSelectName = {"REPORT TYPE"}
            Collections2 = {this.state.Collections2}
            labelSelectName2 = {"PAYMENT TYPE"}
            labelInputName = {'Filter PRODUCT'}
            Collections3 = {this.state.Collections3}
            labelSelectSort = {this.state.labelSelectSort}
          />
        }
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>{this.state.title} : {this.state.brabchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title} </Card.Title>
                  <Card.Options>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                      <ButtonExport
                        parameters = {this.state.parameters}
                        isButtonDisabled = {this.state.isButtonDisabled}
                      >
                      </ButtonExport>
                    }
                  </Card.Options>
                </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.headerShow.map( (item, index) =>
                      <Table.ColHeader key={index} style={{ textTransform: 'none'}}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.value.length ? 
                      this.state.value.map( (items, indexs) =>
                        <Table.Row key={indexs} onClick = {() =>this.showDetail(items, indexs)}>
                          {/* {items.map( (item, index) =>
                            <Table.Col key={index}>{item}</Table.Col> 
                          )} */}

                          <Table.Col>{items.index}</Table.Col>

                          {/* <Table.Col>{items.date}</Table.Col>
                          <Table.Col>{items.branch}</Table.Col>
                          <Table.Col>{items.name}</Table.Col>
                          <Table.Col>{items.channel}</Table.Col>
                          <Table.Col>{items.channel}</Table.Col> */}

                          {this.state.headers.map( (item, index) =>
                            (item==='date')? <Table.Col key={index}>
                              <Moment format="DD/MM/YYYY HH:mm:ss">
                                { items[item] }
                              </Moment></Table.Col> :
                            <Table.Col key={index}>{items[item]? items[item]: '-'}</Table.Col> 
                          )}                        

                        </Table.Row>
                      ) : <Table.Row > 
                            <Table.Col colSpan="12"	alignContent="center">
                              <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                            </Table.Col>
                          </Table.Row>
                    }
                    <Table.Row > 
                    <Table.Col colSpan="13"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>

        <ModalFormLarge
          show={this.state.modalDetailShow}
          onHide={this.modalClose}
          title={"Show more details"}
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

      </SiteWrapper>
    );
  }
}

export default TransactionsAll