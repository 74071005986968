// @flow
//import * as React from "react";
import React, { Component } from 'react'
import axios from 'axios'
import storeGlobal from "./globalStore"
//import SelectBranchs from "./SelectBranchsAndDate";
import SelectBranchs from "./SelectBranchsStateLess"
import Pagination from 'react-bootstrap/Pagination'
import ButtonExport from './buttonExports'
import SweetAlert from 'react-bootstrap-sweetalert'

import {Page,  Grid,  Card,  Text,  Table, Button } from "tabler-react";

import SiteWrapper from "./SiteWrapper.react";
//import { isThisHour } from 'date-fns'
//let users = storeGlobal.getusers();

class reports extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      user : storeGlobal.getusers(),
      organization:"",
      branchs:[],
      brabchidSelect:[],
      brabchNameSelect:"",
      key: 'cash',
      header:["-","-","-"],
      value:[
        ['-',"-","-"]
      ],
      sum:["SUM",0,0],
      dataShow:[],
      sumShow:[],
      paginationItem: [],
      paginationActive: '1',
      resultAll:[],

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useDate",
      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled:true,
      alertShow: false,
      alertText: '',
      labelSelectSort:null,
    }
  }
//dfasdgfghjjk42
getbranchs = (dataCallback) => {
  //+-console.log("getbranchs ->",dataCallback);
  let users = storeGlobal.getusers()
  let brabchNameSelect = ""
  // for(var i = 0; i < dataCallback.BranchsSelect.length; i++){
  //   this.state.brabchNameSelect += "("+dataCallback.BranchsSelect[i].branchName +"),"
  // }
  this.setState({brabchNameSelect: brabchNameSelect});
  //this.setState({brabchidSelect:dataCallback.BranchidSelect});
  this.setState({organization:dataCallback.OrganizationSelect});
  this.setState({startDate:dataCallback.startDate});
  this.setState({endDate:dataCallback.endDate});
  this.setState({paginationActive:1});
  if(!(Object.entries(users).length === 0)){
    this.setState({ user:users});
    let THIS = this
    let token = users.token
    let urls = this.props.value.urls
    let values = {
      "organization":"",
      "branchID": [],
      startDate:new Date(),
      endDate:new Date()
    }
    let branchShow = [] 
    if(dataCallback.branchShow !== undefined){
      branchShow = this.state.branchShow  
    }else{
      for(let i = 0; i < dataCallback.BranchidSelect.length; i++){
        branchShow.push(dataCallback.BranchidSelect[i])
      }
      this.setState({
        branchShow:branchShow,
        brabchidSelect: dataCallback.BranchidSelect
      })
    }
    values.branchShow = branchShow
    values.branchID = dataCallback.BranchidSelect; 
    values.organization = dataCallback.OrganizationSelect;
    values.startDate = dataCallback.startDate;
    values.endDate = dataCallback.endDate;
    values.type = ""
    values.search = ""
    values.username = users.username

    if(this.props.value.payType){
      if(typeof(this.props.value.payType) =="object"){
        values.payType = this.props.value.payType  
      }
    }

    if(this.props.value.hasOwnProperty('headerGetdb')){
      values.headerGetdb = this.props.value.headerGetdb  
    }

    if(dataCallback.CollectionsSelect3){
      switch(dataCallback.CollectionsSelect3){
        case "Oldest":
          values["_sortNatural"] = 1
        break
        case "Newest":
          values["_sortNatural"] = -1
        break
        default:
          values["_sortNatural"] = -1
        break
      }
    }

    if(dataCallback.CollectionsSelect4){
      //console.log('labelSelectName4 =>',this.props.value.labelSelectName4)
      if(this.props.value.labelSelectName4){
        values[this.props.value.labelSelectName4] = dataCallback.CollectionsSelect4
      }
    }

    if(dataCallback.Filter !== ''){
      values.search = dataCallback.Filter
    }

    let urlExport = this.props.value.urls 
    if(this.props.value.urlExport !== undefined){
      urlExport = this.props.value.urlExport 
    }

    let headerExport = this.props.value.header
    if(this.props.value.headerExport !== undefined){
      headerExport = this.props.value.headerExport 
    }
    let parameters = {
      urls: urlExport,
      token : users.token,
      values:values,
      nameOfReport : this.props.value.title,
      headerShow : headerExport,
      method: "post"
    } 

    this.setState({parameters:parameters})

    //console.log("values ->",values)
    // console.log("token ->",token)
    //console.log("url -->",urls)
    //console.log("this.state.user.token -->",THIS.state)
    axios({
      method: 'post',
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: urls,
      data: values,
      headers:{
        "Authorization":token
      }
      //withCredentials: true
    })
    .then(function (response) {
      let resultAll = response.data.value
      THIS.setState({
        "resultAll": resultAll,
        "sum": response.data.sum,
        "isButtonDisabled":false
      }, () =>{
        THIS.paginationItemCal(resultAll,THIS.state.paginationActive)
      })
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }
}


  paginationActive(e) {
    let dataShow = this.state.resultAll
    this.paginationItemCal(dataShow,e)
  }

  showDetail = (itemIn,index) =>{
    // itemIn = <<< click >>> 0306
    //console.log("index =>",index,"itemIn =>",itemIn)
    //console.log("index =>",index,"itemIn =>",itemIn)
    for(let i = 0; i < itemIn.length; i++){
      let item = itemIn[i] +''
      //console.log(item)
      let words = item.split(' ');
      //console.log(words[0])
      if((words[0] === '<<<') && (words[2] === '>>>')){
        //+-console.log(words[1])
        //+-console.log(words[3])
        let branchID = []
        if(words[3] === 'All'){
          for(var j = 0; j < this.state.brabchidSelect.length; j++){
            branchID.push(this.state.brabchidSelect[j])
          }
        }else{
          branchID.push(words[3])
        }

        let title = this.props.value.title + " : " + words[3]
        this.setState({"title":title})

        let dataCallback = {
          branchShow: this.state.branchShow,
          BranchsSelect: this.state.brabchNameSelect, 
          BranchidSelect: branchID,
          OrganizationSelect: this.state.user.OrganizationSelect,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        }
        this.getbranchs(dataCallback)
      }
    }

    if(this.props.showDetail){
      let dataCalback = {
        branchID: this.state.brabchidSelect,
        organization: this.state.user.OrganizationSelect,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        item: itemIn,
        index: index
      }
      this.props.showDetail(dataCalback)  
    }
  }
  
  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.countPerPage
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i === 6){
            paginationItem.push(">")
          }else if(i === 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i === 2){
            paginationItem.push("<")
          }else if(i === 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
            default: 
            break
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataShow.push(dataIn[i])
      }
    }

    let sumShow = []
    if(paginationActive === paginationItem[paginationItem.length-1]){
      sumShow = this.state.sum
    }

    this.setState({
      dataShow: dataShow,
      sumShow: sumShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }
  
  componentDidMount() {
    //+-console.log(":this.props.value =>",this.props.value)
    let users = storeGlobal.getusers()
    this.setState({"value":this.props.value.defaultValue})
    this.setState({"sum":this.props.value.defaultSum})
    let title = this.props.value.title
    if(this.props.value.firstBranchID){
      //console.log(this.props.value.firstBranchID)
      title = title +': '+ this.props.value.firstBranchID
    }
    this.setState({"title":title})
    let start = new Date()
    if(this.props.value.startDate !== undefined) start = new Date(this.props.value.startDate) 
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    if(this.props.value.endDate !== undefined) end = new Date(this.props.value.endDate) 
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});

    if(!(Object.entries(users).length === 0)){
      let organization = []
      for(let i = 0; i < users.organization.length; i++){
        organization.push({label:users.organization[i],value:users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      let brabchidSelect = []
      for(let j = 0; j < users.branch.length; j++){
        branchID.push({label:users.branch[j].branchID,value:users.branch[j].branchID});
        brabchidSelect.push(users.branch[j].branchID)
      }
      this.setState({optionBranchs:branchID});

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < users.project.length; i++){
        if(organization[0].value === users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      this.setState({optionProjects:project});

      if(this.props.value.typeSelectFilter){
        this.setState({typeSelectFilter:this.props.value.typeSelectFilter});  
      }
      
      //+-console.log("optionBranchs =>",branchID)
      //+-console.log("optionProjects =>",project)
      //+-console.log("optionOrganization =>",organization)
      if(users.BranchidSelect.length){
        brabchidSelect = users.BranchidSelect
      }

      if(this.props.value.brabchidSelect !== undefined && this.props.value.brabchidSelect.length !== 0) brabchidSelect = this.props.value.brabchidSelect


      let values = {
        "organization":"",
        "branchID": [],
        startDate:new Date(),
        endDate:new Date()
      }
      values.branchID = brabchidSelect; 
      values.organization = users.OrganizationSelect;
      values.startDate = end;
      values.endDate = end;
      values.type = ""
      values.search = ""

      if(this.props.value.payType){
        if(typeof(this.props.value.payType) =="object"){
          values.payType = this.props.value.payType  
        }
      }

      if(this.props.value.hasOwnProperty('headerGetdb')){
        values.headerGetdb = this.props.value.headerGetdb  
      }

      if(this.props.value.countPerPage){
        this.setState({countPerPage:this.props.value.countPerPage});  
      }

      if(this.props.value.labelSelectSort){
        this.setState({labelSelectSort:this.props.value.labelSelectSort});  
      }

      if(this.props.value.Collections3){
        this.setState({Collections3:this.props.value.Collections3});  
      }

      if(this.props.value.Collections4){
        this.setState({Collections4:this.props.value.Collections4});  
      }

      if(this.props.value.labelSelectName4){
        this.setState({labelSelectName4:this.props.value.labelSelectName4});  
      }

      if(this.props.value.labelInputName){
        this.setState({labelInputName:this.props.value.labelInputName});  
      }
      let branchShow = []
      for(let i = 0; i < brabchidSelect.length; i++){
        branchShow.push(brabchidSelect[i])
      }

      let urlExport = this.props.value.urls 
      if(this.props.value.urlExport !== undefined){
        urlExport = this.props.value.urlExport 
      }

      let headerExport = this.props.value.header
      if(this.props.value.headerExport !== undefined){
        headerExport = this.props.value.headerExport 
      }

      let parameters = {
        urls: urlExport,
        token : users.token,
        values:values,
        nameOfReport : this.props.value.title,
        headerShow : headerExport
      } 

      let dataCallback = {
        branchShow: branchShow,
        BranchsSelect: users.branch, 
        BranchidSelect: brabchidSelect,
        OrganizationSelect: users.OrganizationSelect,
        startDate: start,
        endDate: end,
      }
      if(this.props.value.Collections3){
        dataCallback["CollectionsSelect3"] = this.props.value.Collections3[0]
      }

      this.setState({
        parameters: parameters,
        branchShow: branchShow,
        brabchidSelect: brabchidSelect
      },() =>{
        this.getbranchs(dataCallback)
      })
    }
  }

  alertBadge = (item, index) => {
    item+=''
    if(index!==6) return <Table.Col key={index}>{item}</Table.Col>
    //console.log(item)
    let words = item.split(' ');
    //console.log(words[0])
    if(words[0] !== '<<<') return <Table.Col key={index}>{item}</Table.Col>

    //return <Table.Col key={index}><Badge color={badge} className="my-2" key={index}>{words[1]}</Badge></Table.Col>

    return <Table.Col key={index}><Button type="button" color="info"  size="sm" >{words[1]}</Button></Table.Col>
  }

  render() {
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // console.log("Products user -->",users)
    // console.log("Products branchs -->",this.state.branchs)
    // console.log("Products branchs -->",this.state.organization)

    return (
      <SiteWrapper user={this.state.user} props = {this.props.props}>
        <Page.Content title={this.props.value.title}>
        {(this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
          <SelectBranchs 
            typeSelectFilter = {this.state.typeSelectFilter}
            getbranchs = {this.getbranchs} 
            optionOrganizations = {this.state.optionOrganizations}
            optionProjects = {this.state.optionProjects}
            optionBranchs = {this.state.optionBranchs}
            Collections3 = {this.state.Collections3}
            labelSelectSort = {this.state.labelSelectSort}
            labelInputName = {this.state.labelInputName}
            Collections4 = {this.state.Collections4}
            labelSelectName4 = {this.state.labelSelectName4}
          />
        }
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>{this.props.value.title} : {this.state.brabchNameSelect}</Card.Title> */}
                  {/* <Card.Title>{this.props.value.title}</Card.Title> */}
                  <Card.Title>{this.state.title}</Card.Title>
                  <Card.Options>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                      <ButtonExport
                        parameters = {this.state.parameters}
                        isButtonDisabled = {this.state.isButtonDisabled}
                      >
                      </ButtonExport>
                    }
                  </Card.Options>
                </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.props.value.header.map( (item, index) =>
                      <Table.ColHeader key={index}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.dataShow.length ? 
                      this.state.dataShow.map( (items, indexs) =>
                        <Table.Row key={indexs} onClick = {() =>this.showDetail(items,indexs)}>
                          {items.length &&  items.map((item, index) =>
                            this.alertBadge(item, index)
                          )}
                        </Table.Row>
                      ) : <Table.Row > 
                          <Table.Col colSpan="12"	alignContent="center">
                            <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                          </Table.Col>
                        </Table.Row>
                    }
                    <Table.Row > 
                      {this.state.sumShow.map( (item, index) => 
                        <Table.Col key={index}>{item}</Table.Col>
                      )}
                    </Table.Row>
                    <Table.Row > 
                      <Table.Col colSpan="13"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
      </SiteWrapper>
    );
  }
}

export default reports
