import React, { Component } from 'react'
import { withCookies, Cookies } from 'react-cookie';
import { Redirect } from "react-router-dom";
import { instanceOf } from 'prop-types';
import storeGlobal from "./globalStore";

class Logout extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      users: cookies.get('user')
    };
    console.log(" ============= cookies.get users ->",this.state.users)
  }

  render() {
    console.log('logout')
    const { cookies } = this.props;
    cookies.remove('user', { path: '/'});
    cookies.remove('permission', { path: '/'});
    cookies.remove('token', { path: '/'});
    cookies.remove('homePage', { path: '/'});
    storeGlobal.setusers(null);
    return (      
      //window.location.reload()
      <Redirect to={'/login'} />
    );
  }
}

export default withCookies(Logout)