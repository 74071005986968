import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Form,
  // Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalFormSmall from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
// import SelectBranchsLess from "../components/SelectBranchsLess";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';

class BranchMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      branchs: [],
      usersRes: [],
      branchsPage: [],
      branchEdit: null,

      modalAddProjectShow: false,
      modalEditProjectShow: false,
      modalWorning:'',
      modalForm: [
        { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' },
        { key: "description", name: "Description", placeholder:"", value: "", disabled: false, type: 'textarea', row: 3 }, 
        { key: "branchID", name: "Vending ID", placeholder:"", value: [], disabled: false, type: 'select-multi' , items: [
            'p1', 'p2', 'p3',
        ] }, 
        // { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input' }, 
        // { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select', items: [
        //   'p1', 'p2', 'p3',
        // ] }, 
      ],  

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: null,
      branchsPageLimit: 7,

      redirect: false,
      location: {},

      searchText: '',
      searchButton: true,

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization"

      // SelectBranchsLess
      // SelectBranchsLess : {
      //   title: 'Select filter...',
      //   worning: "",
      //   organizations: [], //[ { value: 'organizations', label: 'Organizations' }, ],
      //   projects: [],//[ { value: 'projects', label: 'Projects' }, ],
      //   branchs: [],//[ { value: 'branchs', label: 'Branchs' }, ],
      // }
      
    };
    // this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    let users = storeGlobal.getusers();
    // console.log('users now =>',users)
    if(users && users.username){

      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      // let values = {
      //   organization: this.state.user.OrganizationSelect,
      //   branchID: this.state.user.branchID,
      //   startDate: (new Date()).setHours(0,0,0,0),
      //   endDate: new Date()
      // }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: this.state.user.OrganizationSelect,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }

      this.setState({
        OrganizationSelect: users.OrganizationSelect,
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },
        this.getbranchs(dataCallback)
      )
    }
  }

  componentWillMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){
      this.setState({
        OrganizationSelect: users.OrganizationSelect
      })
    }
  }

  // componentWillMount() {       
  //   //+-console.log('window.url --->', window.url)

  //   // SelectBranchsLess
  //   // let SelectBranchsLess = {
  //   //   title: 'Select filter',
  //   //   worning: "",
  //   //   organizations: [],
  //   //   projects: [ { value: 'all', label: 'ALL' }, ],
  //   //   branchs: [ { value: 'all', label: 'ALL' }, ],
  //   // } 
  //   // for(let i=0; i<this.state.user.organization.length; i++) {
  //   //   SelectBranchsLess.organizations.push({ value: this.state.user.organization[i], label: this.state.user.organization[i] })
  //   // }
  //   // for(let i=0; i<this.state.user.project.length; i++) { 
  //   //   SelectBranchsLess.projects.push({ value: this.state.user.project[i].projectName, label: this.state.user.project[i].projectName })
  //   // }
  //   // for(let i=0; i<this.state.user.branchID.length; i++) {
  //   //   SelectBranchsLess.branchs.push({ value: this.state.user.branchID[i], label: this.state.user.branchID[i] })
  //   // }
  //   // this.setState({SelectBranchsLess: SelectBranchsLess}); 
  //   this.setState({
  //     OrganizationSelect: this.state.user.organization[0]
  //   })

  // }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.branch}"
            </SweetAlert>
          )
        }));
        break
        default:
        break

    }
		
  } 

  deleteBranch(branch) {
    console.log('deleteBranch =>', branch)
    let THIS = this
    axios({
      method: 'delete',
      // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/' + branch.id,
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/project/' + branch.id,
      data: { user: this.state.user },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log('deleteUser response.data.value =>', response.data.value);  
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'projectMan',
        "category" : 'webApp',
        "even" : 'Delete project',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete project =>'+ branch.branch
      }
      THIS.saveLog(dataSave)

      let branchs = THIS.state.branchs 
      branchs = branchs.filter(person => person._id !== branch.id)

      let user = THIS.state.user
      user.project = user.project.filter(person => person.projectName !== branch.branch)

      let id = user._id
      let values = {
        project : user.project  
      }
      axios({
        method: 'put',
        //url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/'+ id,
        url: window.url + 'api/v1/collection/' +'_'+ '/users/'+ id,
        // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
        data: values,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response) {
        console.log('response =>',response.data)
      })
      .catch(function (error) {
        console.log(error);
        THIS.setState({modalWorning: "Edit user error!"})
      });
      
      THIS.setState({ 
        branchs: branchs,
        user : user
      },() => {        
        THIS.paginationItemCal()
        // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
        if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
      })
      THIS.hideAlert()

      let branchsPage = THIS.state.branchsPage 
      branchsPage = branchsPage.filter(person => person._id !== branch.id)
      // console.log('branchsPage =>', branchsPage); 
      //THIS.state.branchsPage = branchsPage
      THIS.setState({branchsPage: branchsPage})
      if(!branchsPage.length) THIS.paginationActive(1)
      
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
    
  }
  
  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.branchsPage(page)
  }

  branchsPage(page) {
		let branchsPage = []
    let start = (page-1) * this.state.branchsPageLimit
    let end = start + this.state.branchsPageLimit
    if(end >= this.state.branchs.length ) {
      end = this.state.branchs.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      this.state.branchs[i].index = i+1
      branchsPage.push(this.state.branchs[i])
    }
    this.setState({branchsPage: branchsPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.branchs.length; i+=this.state.branchsPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }

    this.setState({paginationItem: paginationItem})
  }
  
  getbranchs = (dataCallback) => {
    //+-console.log("getbranchs ->",dataCallback);
    this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    let users = this.state.user
    if(users && users.username){
      let THIS = this
      axios({
        method: 'get',
        // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project', 
        url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/project',
        // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log('response.data.value =>', response.data.value);
        // return
        if(response.data.value){
          let projectAll = response.data.value 
          let resultProject = []
          for(let i = 0; i < users.project.length; i++){
            for(let j = 0; j < projectAll.length; j++){
              if((projectAll[j].projectID === users.project[i].projectID) && (projectAll[j].name === users.project[i].projectName)){
                resultProject.push(projectAll[j])
              }
            }
          }
          axios({
            method: 'get',
            // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project', 
            url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/branch',
            // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
            data: 'values',
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            if(response.data.value){
              let resultBranch = response.data.value  
              for(let i = 0; i < resultProject.length; i++){
                let tmpBranchID = []
                for(let j = 0; j < resultBranch.length; j++){
                  if(resultProject[i].projectID === resultBranch[j].projectID){
                    tmpBranchID.push(resultBranch[j].branchID)
                  }
                }
                resultProject[i].branchID = tmpBranchID
              }

              let p = resultProject
              p.sort((a,b) => ((a.projectID*1) > (b.projectID*1)) ? 1 : (((b.projectID*1) > (a.projectID*1)) ? -1 : 0)); 

              THIS.setState({
                branchs: resultProject,
                projectAll: projectAll,
                usersRes: resultProject,
              },() => {
                
                THIS.paginationItemCal()
                THIS.branchsPage(1)        
    
              });
            }
          })
          .catch(function (error) {
            console.error(error);
            // alert("error: " + JSON.stringify(error) );
          });
        }
      }) 
      .catch(function (error) {
        console.error(error);
        // alert("error: " + JSON.stringify(error) );
      });
    }
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddProjectShow: false })
    this.setState({ modalWorning: "", modalEditProjectShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    console.log('getForm modalForm =>', this.state.modalForm);    
    let data = {}
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].key === 'projectType') {
        if(this.state.modalForm[i].value === 'VMC') {
          data = {
            "name" : "306 P&P Kiosk",
            "description" : "BSS and Kserv Prototype project",
            "branchID" : [ 
            ],
            "organization" : this.state.OrganizationSelect,
            "vendingType" : window.configProject.vmcType,
            "path": "/image/brand/default-logo.png",
            "type" : "sendSettlementReportAll3page",
            "schedule" : "0",
            "sysEmail" : window.configEmail.sysEmail, // "ppe.q.system@gmail.com",
            "sysEmailPass" :  "ppetech@1234", // "ppetech@1234",
            "sendEmailTo" : "lookmun_adeam@hotmail.com",
            "ccTo" : "exaple1@rabbit.co.th , exaple1@rabbit.co.th",
            "removeRotateFileDay" : "60",
            "manualGen" : {
                "status" : false,
                "startDate" : "2020-02-25T17:00:00.000Z",
                "endDate" : "2020-02-29T16:59:59.000Z"
            },
            "SP" : "306",
            "Transaction" : "Usage Fee (Charged Fee)",
            "logoOrganization" : "KservLogo.png",
            "companyNameSend" : "K SERV COMPANY LIMITED",
            "companyNameto" : "Bangkok Smartcard System Co.,Ltd.",
            "address1" : "21 TST Tower 24 Floor Zone A3 Vibhavadi-Rangsit Road",
            "address2" : "Chompon, Chatuchak, Bangkok 10900",
            "address3" : "Registration No. 0105542045650",
            "reportAll" : [ 
                {
                    "organization" : this.state.OrganizationSelect,
                    "payType" : [ 
                        "cash", 
                        "Cash", 
                        "CASH", 
                        "rabbit", 
                        "RABBIT", 
                        "linepay", 
                        "LINEPAY", 
                        "LINECREDIT"
                    ],
                    "type" : [ 
                        "Sale"
                    ],
                    "name" : [],
                    "rateMDRCash" : "10",
                    "rateMDRRabbit" : "8.4",
                    "rateMDRLinepay" : "8.4",
                    "TransactionCash" : "Cash",
                    "TransactionRabbit" : "Rabbit Card",
                    "TransactionLinepay" : "Rabbit Line Pay",
                    "nameReport" : "Outstanding sales report",
                    "nameSummary" : "Summary monthly outstanding sales report",
                    "nameCover" : "Cover summary monthly outstanding sales report"
                }, 
                {
                    "organization" : this.state.OrganizationSelect,
                    "payType" : [ 
                        "cash", 
                        "Cash", 
                        "CASH", 
                        "rabbit", 
                        "RABBIT", 
                        "linepay", 
                        "LINEPAY"
                    ],
                    "type" : [ 
                        "Top-Up"
                    ],
                    "name" : [],
                    "rateMDRRabbit" : "0.2",
                    "rateMDRLinepay" : "0.2",
                    "TransactionRabbit" : "Rabbit Card",
                    "TransactionLinepay" : "Rabbit Line Pay",
                    "nameReport" : "Topup report",
                    "nameSummary" : "Summary monthly topup report",
                    "nameCover" : "Cover summary monthly topup report"
                }
            ]
          }
        }else{
          data = {
            "name" : "306 P&P Kiosk",
            "description" : "BSS and Kserv Prototype project",
            "branchID" : [ 
            ],
            "organization" : this.state.OrganizationSelect,
            "vendingType" : window.configProject.hypoType,
            "type" : "don'tSendEmail",
          }
        }
      }
    }
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].value === '') {
        return this.setState({modalWorning: "Input is blank!"})
      }
      data[this.state.modalForm[i].key] = this.state.modalForm[i].value
    }
    // console.log('1 getForm data =>', data);
    for(let i=0; i<this.state.projectAll.length; i++) {
      if(this.state.projectAll[i].name === data.name) return this.setState({modalWorning: data.name+ " Name is duplicate!"}) 
    }

    if(this.state.projectAll.length){
      let p = this.state.projectAll
      p.sort((a,b) => (a.projectID < b.projectID) ? 1 : ((b.projectID < a.projectID) ? -1 : 0)); 
      console.log('p =>',p)
      let count = ((p[0].projectID * 1) + 1)
      data.projectID = ('0000' + count.toString()).slice(-4)
      console.log("data.projectID =>",data.projectID) 
    }else{
      data.projectID = '0001'  
    }
    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    // return
    let THIS = this
    axios({
      method: 'post',
      // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/project',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      if(response.data.value){
        console.log("Add project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
        let dataSave = {
          "type" : 'information', //information //warning // error
          "date" : new Date(),
          "surce" : 'projectMan',
          "category" : 'webApp',
          "even" : 'New project',
          "user" :  THIS.state.user.username,
          "ip" : "",
          "discription" : 'Add new project =>'+ data.name
        }
        THIS.saveLog(dataSave)

        let branchAdd = response.data.value[0]
        THIS.state.branchsPage.unshift(branchAdd)
        let user = THIS.state.user
        let tmpProject = {
          organization: branchAdd.organization,
          projectID: branchAdd.projectID,
          projectName: branchAdd.name
        }

        user.project.push(tmpProject)
        console.log('user.project =>',user.project)
        THIS.setState({
          branchsPage: THIS.state.branchsPage,
          user : user
        })

        let id = user._id
        let values = {
          project : user.project  
        }
        axios({
          method: 'put',
          //url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/'+ id,
          url: window.url + 'api/v1/collection/' +'_'+ '/users/'+ id,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": user.token
          }
        })
        .then(function (response) {
          console.log('response =>',response.data)
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Edit user error!"})
        });
        THIS.modalClose()
      }
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
    });

  }

  getFormEdit = () => {
    //+-console.log('getFormEdit modalForm =>', this.state.modalForm);  
    let data = {}
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].value === '' || this.state.modalForm[i].value === undefined) {
        return this.setState({modalWorning: "Input is blank!"})
      }
      data[this.state.modalForm[i].key] = this.state.modalForm[i].value
    }

    // data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    // return
    let THIS = this
    axios({
      method: 'put',
      // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/' + this.state.branchEdit._id,
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/project/' + this.state.branchEdit._id,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Edit api response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'projectMan',
        "category" : 'webApp',
        "even" : 'Edit project',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Edit project =>'+ data.name
      }
      THIS.saveLog(dataSave)

      for (var k in data) {
        THIS.state.branchEdit[k] = data[k]
      }
      for(let i=0; i<THIS.state.branchsPage.length; i++) {
        if(THIS.state.branchsPage[i]._id === THIS.state.branchEdit._id) {
          THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      for(let i=0; i<THIS.state.branchs.length; i++) {
        if(THIS.state.branchs[i]._id === THIS.state.branchEdit._id) {
          THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
      // THIS.state.branchPage.unshift(userAdd)
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit api error!"})
    });

  }

  addBranch = () => {
    //+-console.log('addBranch item =>');
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }
    //configProject
    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' }, 
      { key: "projectType", name: "ProjectType", placeholder:"", value: window.configProject.typeItems[0], disabled: false, type: 'select', items:  window.configProject.typeItems }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: "", disabled: false, type: 'textarea', rows: 3 },
      //{ key: "branchID", name: "Vending ID", placeholder:"", value: [], disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({ modalForm: modalForm, modalAddProjectShow: true })
  }

  editBranch = (item) => {
    //+-console.log('editBranch item =>', item);
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }
    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: true, type: 'input' }, 
      { key: "description", name: "Description", placeholder:"", value: item.description, disabled: false, type: 'textarea', rows: 3 },
      //{ key: "branchID", name: "Vending ID", placeholder:"", value: item.branchID, disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]
    //+-console.log('modalForm =>', modalForm);
    this.setState({ 
      modalForm: modalForm, 
      modalEditProjectShow: true,
      branchEdit: item, 
    })
  }

  statusOnline = (item) => {
    let connect = new Date(item.connect)
    let disconnect = new Date(item.disconnect)
    if(connect > disconnect) return <Text color={'green'}>Online</Text>
    return <Text color={'red'}>Offline</Text>
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value);
    // console.log("modalForm  =>", modalForm);
    let modalForm = this.state.modalForm 
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value
        break
      }
    }

    this.setState({modalForm: modalForm})

  }

  // SelectBranchsLess
  // onChangeSelectBranchsLess = () => {
  //   console.log("onChangeSelectBranchsLess");
  // }

  // onGoPressSelectBranchsLess = () => {
  //   console.log("onGoPressSelectBranchsLess");
  // }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  onSearch = () => {
    // console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some( (key) => { 
      let out =  false
      if(typeof(obj[key])!='string' && key!='branchID') {
        return out
      }
      if(key!='branchID') {
        return obj[key].includes(search) 
      }  
      Object.values(obj[key]).some(val => {
        out = out || val.includes(search)
      })     
      return out
    }));   
    this.setState({searchButton: false, branchs: res}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
        
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  detailProject = item => {
    //console.log('detailProject item =>', item);
    let tmpItem = {}
    tmpItem.id = item._id 
    tmpItem.organization = this.state.OrganizationSelect
    
    let location = {
      pathname: "/projectsetting",
      state: tmpItem
    };

    this.setState(state => ({
      location: location,
      redirect: true
    }));
  };

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddProjectShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content title="Projects manager"
          options={ this.state.usersRes.length &&
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>            
          }
        >
          { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
         
          <Card>
            <Card.Header>
              <Card.Title>Projects</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button 
                  color="green"                  
                  onClick={ () => this.addBranch() }
                > New Project </Button>                
              </Card.Options>
              }
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  {/* <Table.ColHeader className="d-none">_id</Table.ColHeader> */}
                  <Table.ColHeader>Item</Table.ColHeader>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader> 
                  <Table.ColHeader>Description</Table.ColHeader>    
                  <Table.ColHeader>update By</Table.ColHeader>
                  <Table.ColHeader>update date</Table.ColHeader>
                  <Table.ColHeader>vending id</Table.ColHeader>
                  { this.state.user.level==='write' && <Table.ColHeader className="text-center">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { this.state.branchsPage.length ? this.state.branchsPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    {/* <Table.Col>{ i+1 }</Table.Col> */}
                    <Table.Col>{ item.index }</Table.Col>
                    <Table.Col>{ item.projectID }</Table.Col>
                    <Table.Col>{ item.name }</Table.Col>
                    <Table.Col>{ item.description }</Table.Col>
                    <Table.Col>{ item.createBy }</Table.Col> 
                    {/* <Table.Col>{ item.createDate }</Table.Col> */}
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.createDate }
                      </Moment>
                    </Table.Col>
                    <Table.Col>{ item.branchID.toString().length>15? 
                      item.branchID.toString().substring(0, 15)+'...' 
                      : item.branchID.toString()
                    }</Table.Col>  
                    
                    { this.state.user.level==='write' && <Table.Col className="text-center">
                      <Button.List>                        
                        {/* <Button size="sm" color="info"
                          onClick={ () => this.editBranch(item) }
                          >
                          Edit
                        </Button> */}
                        <Button size="sm" color="info"
                          onClick={ () => this.detailProject(item) }
                          >
                            Detail
                          </Button>
                        <Button size="sm" color="danger" 
                          onClick={ ()=> { this.alert({action:'delete', id: item._id, branch: item.name, i: i }) } } >
                          Delete
                        </Button>                        
                      </Button.List>
                    </Table.Col> 
                    }          
                  </Table.Row>
                ): <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>
        
        <ModalFormSmall
          show={this.state.modalAddProjectShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={'New Project'}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />
        {/* <ModalFormSmall
          show={this.state.modalEditProjectShow}
          onHide={this.modalClose}
          onSave={this.getFormEdit}
          onInputChange={this.handleInputChange}
          title={'Modify Project'}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        /> */}

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default BranchMan;