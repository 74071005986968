// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
import Reports from '../components/Reports'

let users = storeGlobal.getusers();

class alertReports extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      value:{
        //urls:"http://localhost:3001/api/v1/report/alert-report",
        urls: window.url + "api/v1/report/user-log-report",
        title:"User log Report",
        header:["ITEM","USER","TYPE","CATEGORY","SURCE","EVEN","DISCRIPTION","IP","DATE"],
        defaultValue:[['-','-','-','-','-','-','-','-','-']],
        defaultSum:['','','','','','','','',''],
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:20,
        //typeSelectFilter:"useDate",
        typeSelectFilter:"useIputSelect",
        labelSelectSort: "Sorting",
        Collections3: window.transactionsPageList.sorting,
        labelInputName : "Filter User",
        Collections4 :window.transactionsPageList.alertStatus,
        labelSelectName4 : "Type"
      }
    }
  }
//dfasdgfghjjk42
  render() {
    users = storeGlobal.getusers();
    //+-console.log("Products user -->",users)
    return (
      <Reports user={users} props = {this.props} value = {this.state.value}>
      </Reports>
    );
  }
}

export default alertReports
