import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Form,
  // Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalAddUser from "../components/ModalAddUser";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
import SelectBranchs from "../components/SelectBranchsStateLess";
import ModalResetPassword from "../components/ModalResetPassword";
import Moment from 'react-moment';
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'

class UserMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      users: [],
      usersRes: [],
      usersPage: [],
      userEdit: null,

      modalAddUserShow: false,
      modalEditUserShow: false,
      modalWorning:'',
      modalForm: {
        userID: '',
        username: '',
        password: '',
        fullname: '',
        // role: props.roleAll[0] || '',
        branchID: [],
        // organization: props.organizationAll[0] || '',
      },
      modalFormDisabled: {
        userID: false,
        username: false,
        password: false,
        // fullname: false,
        // role: false,
        // branchID: false,
        // organization: false,
      },
      modalFormSelected: {
        role: '',
        // branchID: [],
        organization: '',
      },

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: 'ppe',
      usersPageLimit: window.userManPage.tableList, 
      
      btDeployDisnabled: true,
      modalResetPasswordShow: false,
      resetId: null,

      searchText: '',
      searchButton: true, 
      deployText: 'Synchronize',
      syncText: 'Success',

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization",

      btLoading: false,

      alertShow: false,
      alertText: ''

    };

  }

  // componentDidMount() {
  //   this.setState({title: 'company'});
  //   //+-console.log('this.state 1 -->', this.state)
  //   this.getbranchs({
  //     OrganizationSelect: this.state.user.organization[0],
  //     BranchidSelect: this.state.user.branchID,
  //   })
  // }

  componentDidMount() {
    //+-console.log("componentDidMount......");
    if(!(Object.entries(this.state.user).length === 0)){
      //console.log('this.state.user =>',this.state.user)
      //this.setState({branchs:dataCallback.BranchsSelect});
      //this.setState({branchID:this.state.user.branchID});
      //this.setState({ user:this.state.user});

      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }
      // this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }
      // this.setState({optionBranchs:branchID});
      // this.setState({branchID:this.state.user.branchID});
      // this.setState({branchs:this.state.user.branch})
      // this.setState({organization:this.state.user.organization})

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }
      this.setState({
        title: 'company',
        optionOrganizations:organization,
        optionProjects:project,
        optionBranchs:branchID,
        OrganizationSelect: this.state.user.OrganizationSelect
      })

      // let THIS = this
      // let token = user.token
      let values = {
        organization: this.state.user.OrganizationSelect,
        branchID: this.state.user.branchID,
        startDate: (new Date()).setHours(0,0,0,0),
        endDate: new Date()
      }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        BranchidSelect: values.branchID,
        OrganizationSelect: values.organization,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }
      this.getbranchs(dataCallback)
      
    }
  }

  handleClick(e) {
    //+-console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteUser(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.fullname}"
            </SweetAlert>
          )
        }));
        break
      case 'reset':
        this.setState( state => ({
          alert: (
            <SweetAlert
              input
              showCancel
              cancelBtnBsStyle="default"
              title="Reset Password"
              // placeHolder="New password"
              onConfirm={(value)=> { e.value = value; this.resetPwd(e)} }
              onCancel={this.hideAlert.bind(this)}
              >
              Enter new password
            </SweetAlert>
          )
        }));
        break
        default:
        break
    }
		
  } 
  
  resetPwd = (e) => {
    console.log('resetPwd', e)
    // return
    let THIS = this
    axios({
      method: 'put',
      //url: 'http://localhost:3001/api/v1/users/' + this.state.user.organization + '/' + e.id,
      // url: window.url + 'api/v1/users/' + this.state.user.organization + '/' + e.id,
      url: window.url + 'api/v1/users/' + this.state.OrganizationSelect + '/' + e.id,
      data: {password: e.value, status:'Reset password' },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log(response.data.value);  

      for (var k in THIS.state.users) {
        // console.log('k =>', k); 
        if(THIS.state.users[k]._id == e.id) {
          // console.log('THIS.state.users[k] =>', THIS.state.users[k]); 
          THIS.state.users[k].status = 'Reset password'
          THIS.state.userEdit = THIS.state.users[k]
          let dataSave = {
            "type" : 'warning', //information //warning // error
            "date" : new Date(),
            "surce" : 'userMan',
            "category" : 'webApp',
            "even" : 'Reset password',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Reset password =>'+ THIS.state.userEdit.username
          }
          THIS.saveLog(dataSave)
          break
        }        
      }
      // console.log('THIS.state.userEdit =>', THIS.state.userEdit); 
      for(let i=0; i<THIS.state.usersPage.length; i++) {
        if(THIS.state.usersPage[i]._id === THIS.state.userEdit._id) {
          THIS.state.usersPage[i] = JSON.parse(JSON.stringify(THIS.state.userEdit))
        }
      }
      for(let i=0; i<THIS.state.users.length; i++) {
        if(THIS.state.users[i]._id === THIS.state.userEdit._id) {
          THIS.state.users[i] = JSON.parse(JSON.stringify(THIS.state.userEdit))
        }
      }
      THIS.setState({
        usersPage: THIS.state.usersPage, 
        users: THIS.state.users,
        btDeployDisnabled: false,
      })

      THIS.hideAlert()
      THIS.modalClose()
    })

    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      },() =>{
        THIS.hideAlert()
        THIS.modalClose()  
      })
      console.log('axios error =>',error.message);
    });
  }

  deleteUser(user) {
    console.log('deleteUser =>', user)
    let THIS = this
    axios({
      method: 'put',
      //url: window.url + 'api/v1/users/' + this.state.user.organization + '/' + user.id,
      url: window.url + 'api/v1/users/' + this.state.OrganizationSelect + '/' + user.id,
      data: {flag: 'delete', updateBy: this.state.user.username, status: 'delete'},
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // console.log('deleteUser response.data.value =>', response.data.value);  
      // let users = [...THIS.state.users]
      // users.splice(user.i, 1)  
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'userMan',
        "category" : 'webApp',
        "even" : 'Delete user',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete user =>'+ user.username
      }
      THIS.saveLog(dataSave)

      let users = THIS.state.users 
      users = users.filter(person => person._id !== user.id)
      THIS.setState({ 
        users: users
      },() => {        
        THIS.paginationItemCal()
        // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
        if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
      })
      THIS.hideAlert()

      let usersPage = THIS.state.usersPage 
      usersPage = usersPage.filter(person => person._id !== user.id)
      // console.log('usersPage =>', usersPage); 
      THIS.state.usersPage = usersPage
      THIS.setState({usersPage: usersPage})
      if(!usersPage.length) THIS.paginationActive(1)
    
      
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      },() =>{
        THIS.hideAlert()
      })
      console.log('axios error =>',error.message);
    });
  }
  
  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.usersPage(page)
  }

  usersPage(page) {
		let usersPage = []
    let start = (page-1) * this.state.usersPageLimit
    let end = start + this.state.usersPageLimit
    if(end >= this.state.users.length ) {
      end = this.state.users.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      this.state.users[i].index = i+1
      usersPage.push(this.state.users[i])
    }
    this.setState({usersPage: usersPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.users.length; i+=this.state.usersPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }
    this.setState({paginationItem: paginationItem})
  }
  
  getbranchs = (dataCallback) => {
    //console.log("getbranchs ->",dataCallback);
    let user = this.state.user
    if(user && user.username){
      //users.OrganizationSelect = dataCallback.OrganizationSelect
      this.setState({
        OrganizationSelect: dataCallback.OrganizationSelect
        //user: users
      });
      let THIS = this
      axios({
        method: 'get',
        // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization[0],
        //url: 'http://localhost:3001/api/v1/users/' + dataCallback.OrganizationSelect,
        url: window.url + 'api/v1/users/' + dataCallback.OrganizationSelect,
        data: 'values',
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response) {
        //+-console.log('response.data.value =>', response.data.value);  
        let users = response.data.value
        users = users.filter(person => {
          if((person.role === 'sadmin') && user.role !== 'sadmin') return false   
          if((person.role === 'owner') && !((user.role === 'sadmin') || (user.role === 'owner')) ) return false   
          return person.flag !== 'delete'  
          // if(person.flag !== 'delete' ){
          //   return true  
          // }
        })

        for(let i=0; i<users.length; i++) {
          if(users[i].status !== THIS.state.deployText && users[i].status !== THIS.state.syncText) {
            THIS.setState({
              btDeployDisnabled: false
            })
            break
          }
        }
        
        users.sort(function(a, b) {
          var nameA = a.username.toUpperCase(); // ignore upper and lowercase
          var nameB = b.username.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/role?', 
          // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
          data: 'values',
          headers:{
            "Authorization": user.token
          }
        })
        .then(function (response) {
          //console.log('get role response =>', response.data.value); 
          if(response.data.value){
            let roles = response.data.value
            roles = roles.filter(person => {
              if(person.name === 'sadmin' && user.role !== 'sadmin') return false   
              if(person.name === 'owner' && !(user.role === 'sadmin') || (user.role === 'owner') ) return false   
              return person.flag !== 'delete'  
            });
            let tmpRole = []
            roles.forEach(doc => {
              tmpRole.push(doc.name)
            })
            if(tmpRole.length){
              user.roleAll = tmpRole
            }
          }
          THIS.setState({
            user: user,
            users: users,
            usersRes: users,
          },() => {
            THIS.paginationItemCal()
            THIS.usersPage(1)        
          });
        })
        .catch(function (error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          })
          console.log('axios error =>',error.message);
        })
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      })
    }
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddUserShow: false, modalEditUserShow: false, modalResetPasswordShow: false })
    //+-console.log('this.state.modalForm =>', this.state.modalForm)
  };

  hasWhiteSpace = (s) => {
    return /\s/g.test(s);
  }

  getForm = (form) => {
    console.log('getForm =>', form);
    // console.log("saveAddUser =>", this.state.form) 
    this.setState({modalWorning: ""})
    let data = JSON.parse(JSON.stringify(form)) // form pointer to component state 
    this.setState({ 
      modalForm: form, 
    })

    if(!data.branchID) return this.setState({modalWorning: "branchID data is blank!"})

    for (var key in data) {
      //console.log(key + ": " + data[key] ); 
      if(key==='username'){
        if(this.hasWhiteSpace(data[key])){
          return this.setState({modalWorning: "User Name must not contain spaces!"})
        }else if((data[key].length < window.configUser.userNameMinLengh) || (data[key].length > window.configUser.userNameMaxLengh)){
          return this.setState({modalWorning: "Invalid 'User Name' length!"})
        }else{
          data[key] = data[key].toLowerCase().split(' ').join('')
        }
      }

      if(key==='password'){
        if(this.hasWhiteSpace(data[key])){
          return this.setState({modalWorning: "Password must not contain spaces!"})
        }else if((data[key].length < window.configUser.passwordMinLengh) || (data[key].length > window.configUser.passwordMaxLengh)){
          return this.setState({modalWorning: "Invalid 'Password' length!"})
        }
      }

      if(data[key]==='') {
        return this.setState({modalWorning: "Input data is blank!"})
      }
    }

    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    if( (data.organizationSelect !== undefined) &&  (data.organizationSelect.length)){
      let organization = []
      for(let i = 0; i < data.organizationSelect.length; i++){
        organization.push(data.organizationSelect[i].value)
      }
      data.organization = organization
    }else{
      return this.setState({modalWorning: "Input data is blank!"})  
    }

    let project =[]
    for(let i = 0; i < data.projectSelect.length; i++){
      for(let j = 0; j < this.state.user.project.length; j++){
        if(data.projectSelect[i].value === this.state.user.project[j].projectName){
          project.push(this.state.user.project[j])
        }
      }
    }
    data.project = project

    let branchID = []
    if((data.branchIDSelect !== undefined) && (data.branchIDSelect.length)){
      for(let i = 0; i < data.branchIDSelect.length; i++){
        branchID.push(data.branchIDSelect[i].value)
      }
    }
    data.branchID = branchID

    // data.createDate = new Date()
    // data.updateDate = new Date()
    data.profile = { fullname: data.fullname }
    delete data.fullname
    delete data.organizationDefault
    delete data.organizationSelect
    delete data.projectSelect
    delete data.branchIDAll
    delete data.branchIDSelect

    data.status = 'New'
    //console.log("new Data to Save =>", data)
    let THIS = this
    axios({
      method: 'post',
      //url: 'http://localhost:3001/api/v1/users/' + this.state.user.organization,
      //url: window.url + 'api/v1/users/' + this.state.user.organization,
      url: window.url + 'api/v1/users/' + this.state.OrganizationSelect,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      let userAdd = response.data.value[0]
      //+-console.log("saveAddUser response =>", response.data.value[0]);  
      THIS.state.usersPage.unshift(userAdd)
      THIS.setState({usersPage: THIS.state.usersPage})
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'userMan',
        "category" : 'webApp',
        "even" : 'New user',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Add new user =>'+ data.username
      }
      THIS.saveLog(dataSave)
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log('axios error =>', error, error.response.data);
      if(error.response.data) {
        return THIS.setState({modalWorning: error.response.data.error})
      }
      THIS.setState({modalWorning: "Add user error!"})
    });

  }

  getFormEdit = (form) => {
    //+-console.log('getFormEdit form =>', form);
    this.setState({modalWorning: ""})
    let data = JSON.parse(JSON.stringify(form)) // form pointer to component state 
    this.setState({ 
      modalForm: form, 
    })

    for (var key in data) {
      //console.log(key + ": " + data[key] ); 
      if(key==='username'){
        if(this.hasWhiteSpace(data[key])){
          return this.setState({modalWorning: "User Name must not contain spaces!"})
        }else if((data[key].length < window.configUser.userNameMinLengh) || (data[key].length > window.configUser.userNameMaxLengh)){
          return this.setState({modalWorning: "Invalid 'User Name' length!"})
        }else{
          data[key] = data[key].toLowerCase().split(' ').join('')
        }
      }

      if(key==='password'){
        if(this.hasWhiteSpace(data[key])){
          return this.setState({modalWorning: "Password must not contain spaces!"})
        }else if((data[key].length < window.configUser.passwordMinLengh) || (data[key].length > window.configUser.passwordMaxLengh)){
          return this.setState({modalWorning: "Invalid 'Password' length!"})
        }
      }

      if(data[key]==='') {
        return this.setState({modalWorning: "Input data is blank!"})
      }
    }

    // data.updateBy = this.state.user.username
    // data.organization = [data.organization]
    // data.profile = { fullname: data.fullname }
    // delete data.fullname
    // delete data.userID
    // delete data.username
    // delete data.password

    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    let organization = []
    for(let i = 0; i < data.organizationSelect.length; i++){
      organization.push(data.organizationSelect[i].value)
    }
    data.organization = organization

    let project =[]
    for(let i = 0; i < data.projectSelect.length; i++){
      for(let j = 0; j < this.state.user.project.length; j++){
        if(data.projectSelect[i].value === this.state.user.project[j].projectName){
          project.push(this.state.user.project[j])
        }
      }
    }
    data.project = project

    let branchID = []
    if((data.branchIDSelect !== undefined) && (data.branchIDSelect.length)){
      for(let i = 0; i < data.branchIDSelect.length; i++){
        branchID.push(data.branchIDSelect[i].value)
      }
    }
    data.branchID = branchID

    let dataSave = {
      "type" : 'warning', //information //warning // error
      "date" : new Date(),
      "surce" : 'userMan',
      "category" : 'webApp',
      "even" : 'Edit user',
      "user" :  this.state.user.username,
      "ip" : "",
      "discription" : 'Edit user =>'+ data.username
    }

    data.profile = { fullname: data.fullname }
    delete data.fullname
    delete data.userID
    delete data.username
    delete data.password
    delete data.organizationDefault
    delete data.organizationSelect
    delete data.projectSelect
    delete data.branchIDSelect
    delete data.branchIDAll

    data.status = 'Modify'

    //+-console.log('getFormEdit data =>', data, this.state.userEdit.organization[0], this.state.userEdit._id);
    // return
    // console.log("saveAddUser 2 =>", this.state.form)
    let THIS = this
    axios({
      method: 'put',
      //url: 'http://localhost:3001/api/v1/users/' + this.state.userEdit.organization[0] + '/' + this.state.userEdit._id ,
      //url: window.url + 'api/v1/users/' + this.state.userEdit.organization[0] + '/' + this.state.userEdit._id ,
      url: window.url + 'api/v1/users/' + this.state.OrganizationSelect + '/' + this.state.userEdit._id ,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // let userAdd = response.data.value[0]
      //+-console.log("Edit User response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}

      for (var k in data) {
        THIS.state.userEdit[k] = data[k]
      }
      let tmpUsersPage = THIS.state.usersPage
      for(let i=0; i<tmpUsersPage.length; i++) {
        if(tmpUsersPage[i]._id === THIS.state.userEdit._id) {
          tmpUsersPage[i] = JSON.parse(JSON.stringify(THIS.state.userEdit))
        }
      }
      let tmpUser = THIS.state.users 
      for(let i=0; i<tmpUser.length; i++) {
        if(tmpUser[i]._id === THIS.state.userEdit._id) {
          tmpUser[i] = JSON.parse(JSON.stringify(THIS.state.userEdit))
        }
      }
      THIS.setState({
        usersPage: tmpUsersPage, 
        users: tmpUser,
        btDeployDisnabled: false
      })

      THIS.saveLog(dataSave)  //save log
      // THIS.state.usersPage.unshift(userAdd)
      // THIS.setState({usersPage: THIS.state.usersPage})
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
    });

  }

  editUser = (item) => {    
    console.log('editUser item =>', item);
    let modalAddUserTitle = 'Modify User'
    let modalForm = this.state.modalForm

    let organizationSelect = []
    if((item.organization !== undefined) && (item.organization.length)){
      for(let i = 0; i < item.organization.length; i++){
        organizationSelect.push({label: item.organization[i],value:item.organization[i]})
      }
    }

    let projectSelect = []
    if((item.project !== undefined) && (item.project.length)){
      for(let i = 0; i < item.project.length; i++){
        projectSelect.push({label: item.project[i].projectName,value:item.project[i].projectName})
      }
    }

    let branchIDSelect = []
    if((item.branchID !== undefined) && (item.branchID.length)){
      for(let i = 0; i < item.branchID.length; i++){
        branchIDSelect.push({label: item.branchID[i],value:item.branchID[i]})
      }
    }
    modalForm = {
      // userID: item.userID,
      username: item.username,
      password: '********',
      fullname: item.profile.fullname,
      branchID: item.branchID,
      routeman: item.routeman,
      projectSelect: projectSelect,
      branchIDSelect: branchIDSelect,
      organizationSelect : organizationSelect

    }
    let modalFormDisabled = this.state.modalFormDisabled
    modalFormDisabled = {
      userID: true,
      username: true,
      password: true,
      // fullname: false,
      // role: false,
      // branchID: false,
      // organization: false,
    }
    let modalFormSelected = this.state.modalFormSelected
    modalFormSelected = {
      role: item.role,
      // branchID: '',
      organization: item.organization[0],
    }

    this.setState({ 
      modalAddUserTitle: modalAddUserTitle, 
      modalForm: modalForm,
      modalFormDisabled: modalFormDisabled, 
      modalFormSelected: modalFormSelected, 
      modalEditUserShow: true, 
      userEdit: item,
    })

  }

  addUser = () => {
    //+-console.log('addUser item =>');
    // this.setState({ modalAddUserTitle: 'Edit User' })
    let modalAddUserTitle = 'New User'

    let modalForm = {
      // userID: '',
      username: '',
      password: '',
      fullname: '',
      routeman: true,
      branchID: []
    }
    //+-console.log('this.state.modalForm =>', modalForm);
    this.setState({ 
      modalForm: modalForm,
      modalAddUserTitle: modalAddUserTitle,
      modalAddUserShow: true 
    })
    // this.setState({ modalForm: this.state.modalForm},() => this.setState({modalAddUserShow: true }) )
  }

  deployUser = async() => {
    //+-console.log('deployUser =>',);
    let userDeploy = []
    let branchIDs = []
    let branchIDDeploy = []
    let tmpUsers = this.state.users 
    let projects = []

    for(let i=0; i<tmpUsers.length; i++) {
      if(tmpUsers[i].status !== this.state.deployText && tmpUsers[i].status !== this.state.syncText) {
        tmpUsers[i].status = this.state.deployText
        userDeploy.push({_id: tmpUsers[i]._id, status: tmpUsers[i].status, updateBy: this.state.user.username})
        // console.log('users[i].status =>', i, tmpUsers[i]);

        const project = tmpUsers[i].project
        for(const p of project) {
          projects.indexOf(p.projectName) === -1 ? projects.push(p.projectName) : console.log("This item already exists");
        }

        // let branchID = tmpUsers[i].branchID
        // for(let j=0; j<branchID.length; j++) {
        //   if(branchIDs.indexOf(branchID[j]) < 0) {
        //     branchIDs.push(branchID[j])

        //     let obj = {}
        //     obj.type = 'user'
        //     obj.branchID = branchID[j]
        //     obj.updateBy = this.state.user.username
        //     obj.status = this.state.deployText
        //     obj.deployTime = (new Date()).getTime()
        //     branchIDDeploy.push(obj)
        //   }
        // }

      }
    }
     
    // console.log('projects =>', projects); 
    for(const projectName of projects) {
      // projects.indexOf(p.projectName) === -1 ? projects.push(p.projectName) : console.log("This item already exists");
      const res = await axios.get(window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/branch?project='+projectName, { headers:{
        "Authorization": this.state.user.token}
      });
      const { data } = await res;
      // console.log('data =>', data);
      const branchs = data.value
      if(!branchs.length) continue

      for(const branch of branchs) {
        if(branchIDs.indexOf(branch.branchID) === -1) {
          branchIDs.push(branch)
          const obj = {
            type: 'user',
            branchID: branch.branchID,
            updateBy: this.state.user.username,
            status: this.state.deployText,
            deployTime: (new Date()).getTime()
          }
          
          const branch_type = window.notDeployUserType //['HVM2S']
          if(branch_type.indexOf(branch.type) >= 0) continue

          branchIDDeploy.push(obj)
        } 
      }
    }
    console.log('branchIDDeploy =>', branchIDDeploy);

    let usersPage = this.state.usersPage
    for(let i=0; i<this.state.usersPage.length; i++) {
      if(this.state.usersPage[i].status !== this.state.deployText && this.state.users[i].status !== this.state.syncText) {
        usersPage[i].status = this.state.deployText
      }
    } 

    this.setState({ 
      users: tmpUsers, 
      usersPage: usersPage, 
      btDeployDisnabled: true 
    })
    
    let THIS = this
    axios({
      method: 'patch',
      //url: window.url + 'api/v1/users/' + THIS.state.user.organization[0] + '/users' , //ต้องดู organization ที่ filter
      url: window.url + 'api/v1/users/' + THIS.state.OrganizationSelect + '/users' , //ต้องดู organization ที่ filter
      data: userDeploy,
      headers:{
        "Authorization": THIS.state.user.token
      }
    })
    .then(function (response) {
      // let userAdd = response.data.value[0]
      //+-console.log("deployUser response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}

      axios({
        method: 'post',
        //url: window.url + 'api/v1/collection/' + THIS.state.user.organization[0] + '/deploy/many' , //ต้องดู organization ที่ filter
        url: window.url + 'api/v1/collection/' + THIS.state.OrganizationSelect + '/deploy/many' , //ต้องดู organization ที่ filter
        data: branchIDDeploy,
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {
        // let userAdd = response.data.value[0]
        //+-console.log("update deploy collecton =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      })
      .catch(function (error) {
        console.log(error);
        THIS.setState({modalWorning: "2-Edit user error!"})
      });

    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "1-Edit user error!"})
    });

  }


  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  onSearch = () => {
    // console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some( (key) => { 
      let out =  false
      if(key==='userID' || key==='username' || key==='role' || key==='status' ) {
        return obj[key].includes(search) 
      }
      else if(key==='profile') {
        Object.values(obj[key]).some(val => {
          out = out || val.includes(search)
        }) 
        
      }
      return out
    }));   
    this.setState({searchButton: false, users: res}, () => {
      this.paginationItemCal()
      this.usersPage(1)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, users: this.state.usersRes}, () => {
      this.paginationItemCal()
      this.usersPage(1)
    })
        
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddUserShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props} >
          
        <Page.Content 
          title="Users manager"
          options={ this.state.usersRes.length &&
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>            
          }
        >
          { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
         
          <Card>
            <Card.Header>
              <Card.Title>Users</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button.List>
                { this.state.btDeployDisnabled ?                 
                  <Button 
                    color="secondary"  
                    disabled           
                  > Deploy </Button> 
                  :        
                  <Button 
                    color="green"                           
                    onClick={ () => this.deployUser(this) }
                  > Deploy </Button>
                }   
                  <Button 
                    color="green"                  
                    onClick={ () => this.addUser() }
                  > New User </Button>  
                </Button.List> 
              </Card.Options>
              }
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  {/* <Table.ColHeader className="d-none">_id</Table.ColHeader> */}
                  <Table.ColHeader className="w-1">ITEM</Table.ColHeader>
                  <Table.ColHeader className="w-1">User ID</Table.ColHeader>
                  <Table.ColHeader>Username</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader> 
                  <Table.ColHeader>Role</Table.ColHeader>    
                  <Table.ColHeader>Organization</Table.ColHeader>
                  <Table.ColHeader>status</Table.ColHeader>
                  <Table.ColHeader>Last login</Table.ColHeader>
                  { this.state.user.level==='write' && <Table.ColHeader className="text-center">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { this.state.usersPage.length ? this.state.usersPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    {/* <Table.Col>{ i+1 }</Table.Col> */}
                    <Table.Col>{ item.index }</Table.Col>
                    <Table.Col><Text RootComponent="span" muted> { item.userID } </Text></Table.Col>
                    <Table.Col>{ item.username }</Table.Col>
                    <Table.Col>{ item.profile.fullname }</Table.Col> 
                    <Table.Col>{ item.role }</Table.Col> 
                    {/* <Table.Col>{ item.organization }</Table.Col> */}
                    <Table.Col>{ this.state.OrganizationSelect }</Table.Col>  
                    <Table.Col>{ item.status }</Table.Col> 
                    {/* <Table.Col>{ item.lastLogin }</Table.Col>  */}
                    <Table.Col>
                      { !item.lastLogin? '' :
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.lastLogin }
                      </Moment>
                      }
                    </Table.Col>
                    { this.state.user.level==='write' && <Table.Col className="text-center">
                      <Button.List>
                        <Button size="sm" color="info"
                          onClick={ () => this.editUser(item) }
                          >
                          Edit
                        </Button>
                        <Button size="sm" color="warning"
                          onClick={ ()=> { 
                            // this.alert({action:'reset', id: item._id, fullname: item.profile.fullname, i: i }) 
                            // console.log(' item._id =>',  item._id)
                            this.setState({ modalResetPasswordShow: true, resetId: item._id})
                          } } >
                          Reset
                        </Button>
                        <Button size="sm" color="danger" 
                          onClick={ ()=> { this.alert({action:'delete', id: item._id, username: item.username, fullname: item.profile.fullname, i: i }) } } >
                          Delete
                        </Button>                        
                      </Button.List>
                    </Table.Col> 
                    }          
                  </Table.Row>
                ) : <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>
        
        <ModalAddUser
          show={this.state.modalAddUserShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          title={this.state.modalAddUserTitle}      
          worning={this.state.modalWorning}
          branchIDAll={this.state.user.branchID}
          roleAll={this.state.user.roleAll}
          organizationAll={this.state.user.organization}
          form={this.state.modalForm}
          nowUserNane = {this.state.user.username}
        />

        <ModalAddUser
          show={this.state.modalEditUserShow}
          onHide={this.modalClose}
          onSave={this.getFormEdit}
          title={this.state.modalAddUserTitle}    
          worning={this.state.modalWorning}
          branchIDAll={this.state.user.branchID}
          roleAll={this.state.user.roleAll}
          organizationAll={this.state.user.organization}
          form={this.state.modalForm}
          formDisabled={this.state.modalFormDisabled}
          formSelected={this.state.modalFormSelected}
          nowUserNane = {this.state.user.username}
        />

        <ModalResetPassword
          show={this.state.modalResetPasswordShow}
          onHide={this.modalClose}
          onSave={this.resetPwd}
          resetId={this.state.resetId}
        />

        {this.state.alert}

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
       
      </SiteWrapper>   
      
    )
    
  }
}

export default UserMan;