import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text, Icon } from "tabler-react";
// import Select from 'react-select';
import { FilePond, registerPlugin  } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType);

class ModalUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form: {

      // },      
    };
    // this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {
    console.log('componentDidMount ...')    
    
  }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate prevProps =>', prevProps)
  // }

  componentWillReceiveProps(prevProps) {
    // console.log('componentWillReceiveProps prevProps =>', prevProps)
    // if(prevProps.show) {

    // }
    
  }

  render() {
    // console.log('this.props.form =>', this.props.form)
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={(event) => console.log(event.target.name + 'clicked')}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Grid.Row>
            <Grid.Col>   
              <FilePond 
                server={this.props.uri+'/'+this.props.organization+'/'+this.props.type }
                acceptedFileTypes={ this.props.filetype }
                fileValidateTypeDetectType={ (source, type) => new Promise((resolve, reject) => {                    
                  // Do custom type detection here and return with promise
                  console.log('fileValidateTypeDetectType source, type =>', source, type)
                  resolve(type);
                })}
              />
            </Grid.Col> 
          </Grid.Row>
          
        </Modal.Body>
        <Modal.Footer>
          <div class="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>          
          <Button type="button" onClick={ this.props.onHide } >
            <Icon prefix="fe" name="arrow-left" className="mr-2" />
            BACK</Button>
          {/* <Button color="success" type="button" onClick={ this.props.onSave } >Save</Button> */}
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

ModalUpload.defaultProps = {
  title: 'ModalUpload',
  worning: "",
  organization: 'organization' ,
  uri: 'localhost/upload',
  type: 'software', 
  filetype: ['*/*'], // all file 
}

export default ModalUpload