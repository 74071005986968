import React, { Component, Fragment } from 'react';

import Select from 'react-select';
import { colourOptions } from './docs/data';
//import { Note } from './docs/styled-components';
import storeGlobal from "../components/globalStore";
//let option = [{label:"All",value:"All"}];

export default class SingleSelect extends Component {
  // state = {
  //   options: [{label:"All",value:"All"}]
  // }
  // changValue = (val, opts) => {
  //   //console.log("Selected: %o, %o", val, opts);
  //   this.setState({
  //     value: val
  //   })
  //   if (this.props.dataChang) {
  //     this.props.dataChang(val);
  //   }
  // }

  changValue = (val, opts) => {
    // console.log("Selected: %o, %o", val, opts);
    if (this.props.dataChang) {
      this.props.dataChang(val);
    }
  }
  // componentWillUpdate(){
  //   //console.log('componentWillUpdate this.props =>',this.props)
  //   //console.log('this.state =>',this.state)
  //   // if(this.props.options){
  //   //   //let options = this.props.options;
  //   //   //this.setState({options:options});
  //   //   this.setState({"options":this.props.options});
  //   // }
  // }
  // componentDidMount(){
  //   //console.log('componentDidMount this.props =>',this.props)
  //   this.setState({
  //     value:{label:this.props.value,value:this.props.value}
  //   },
  //     //console.log('this.state =>',this.state)
  //   )
  // }
  
// //dfasdgfghjjk42
//   render() {
//     // if(this.props.options){
//     //   //let options = this.props.options;
//     //   //this.setState({options:options});
//     //   this.setState({"options":this.props.options});
//     // }
//     //console.log("SingleSelect option ->",this.props.options)
//     return (
//       <Fragment>
//         <Select
//           className="basic-single"
//           classNamePrefix="select"
//           defaultValue={this.props.defaultValue}
//           options={this.props.options}
//           onChange = {this.changValue}
//           value = {this.state.value}
//         />
//       </Fragment>
//     );
//   }

  render() {
    return (
      <Select
        closeMenuOnSelect={true}
        options={this.props.options}
        onChange = {this.changValue}
        value={this.props.value} // notice state!
      />
    );
  }
}
