// @flow

import * as React from "react";
import { Route, Redirect } from 'react-router-dom';
import { Formik } from "formik";
// import { LoginPage as TablerLoginPage } from "tabler-react";
import TablerLoginPage from "./LoginPage";
import axios from 'axios';
import storeGlobal from "../components/globalStore";
import LoadingOverlay from 'react-loading-overlay'
import SyncLoader from 'react-spinners/SyncLoader'
import { write } from "fs";
import SweetAlert from 'react-bootstrap-sweetalert'
import { forEach } from "underscore";


// type Props = {||};

// function LoginPage(props: Props): React.Node {
class LoginPage extends React.Component {
  // console.log('props ->', props)
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      loadingOverlay: false,
      alertShow: false,
      alertText: '',
    };
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.loadingOverlay}
        spinner={<SyncLoader />}
        styles={{
          spinner: (base) => ({
            ...base,
            width: '100px',
            '& svg circle': {
              stroke: 'rgba(255, 0, 0, 0.5)'
            },
          }),
          overlay: (base) => ({
            ...base,
            background: 'rgba(255, 255, 255, 0.5)'
          }),
          wrapper: {
            width: window.innerWidth-20,
            // height: window.innerHeight-200,
            overflow: this.state.loadingOverlay ? 'hidden' : 'scroll'
          }
        }}
      >
      <Formik
        initialValues={{
          // email: "",
          // organization: "",
          username: "",
          password: "",
        }}
        validate={values => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          // if (!values.email) {
          //   errors.email = "Required";
          // } else if ( 
          //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          // ) {
          //   errors.email = "Invalid email address";
          // }
          
          if (!values.username) {
            errors.username = "Required";
          } else if (!values.password) {
            errors.password = "Required";
          } 
          return errors;
        }}
        onSubmit={(
          values,
          { setSubmitting, setErrors /* setValues and other goodies */ }
        ) => {
          // alert("Done! " + JSON.stringify(values) );
          //axios.defaults.withCredentials = true
          this.setState({loadingOverlay: true}, () => {
            // console.log('this.state =>', this.state)
          })

          // if (values.username) {
          //   values.username = values.username.toLowerCase().split(' ').join('')
          // }

          // if (values.password) {
          //   values.password = values.password.split(' ').join('')
          // }

          const THIS = this
          axios({
            method: 'post',
            //url: 'http://13.251.52.58:3001/api/v1/users/login',
            url: window.url + "api/v1/users/login",
            data: values
            //withCredentials: true
          })
          .then(function (response) {
            console.log('response ->',response.data);

            if(response.data.message) {
              // alert(JSON.stringify(response.data) );
              THIS.setState({ alertText: response.data.message, alertShow: true})
            } else if(response.data.user) {

              storeGlobal.setusers(response.data.user);
              storeGlobal.setprops(THIS.props);
              THIS.props.history.push({
                pathname: '/',
                state: response.data
              })

              fetch('https://api.ipify.org/?format=json').then(response => {
                return response.json();
              }).then((res) => {
                console.log('res ->', res)
                let user = response.data.user
                let data = {  
                  "type" : 'information', //warning // error
                  "date" : new Date(),
                  "surce" : 'page login',
                  "category" : 'webApp',
                  "even" : 'Login',
                  "user" : user.username,
                  "ip" : res.ip,
                  "discription" : "Login complete"
                }  

                //for(let i = 0; i < user.organization.length; i++){
                  // let organization = user.organization[i]
                  let organization = user.organization[0]
                  console.log("organization =>", organization)
                  // return
                  let THIS1 = THIS
                  axios({
                    method: 'post',
                    url: window.url + 'api/v1/collection/' + organization + '/log',
                    data: data,
                    headers:{
                      "Authorization": user.token
                    }
                  })
                  .then(function (response1) {
                    console.log('response1 ->',organization,'=',response1)

                  })
                  .catch(function (error) {
                    console.log(error);
                    // alert("error: " + JSON.stringify(error) );
                    THIS1.setState({ alertText: error.message, alertShow: true})
                    THIS1.setState({loadingOverlay: false})
                  });

                //}
              })
              .catch(function (error) {
                console.log('Problem fetching my IP', error);
                // alert("error: " + JSON.stringify(error) );
                THIS.setState({ alertText: error.message, alertShow: true})
                THIS.setState({loadingOverlay: false})
              });
            }
            THIS.setState({loadingOverlay: false})
          })
          .catch(function (error) {
            console.log(error);
            // alert("error: " + JSON.stringify(error) );
            THIS.setState({ alertText: error.message, alertShow: true})
            THIS.setState({loadingOverlay: false})
          });
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <TablerLoginPage
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />          
        )}
      />      
         <SweetAlert
           warning
           show={this.state.alertShow}
           title="Oops!"
           onConfirm={() => this.setState({ alertShow: false })}
         >
           {this.state.alertText}
         </SweetAlert>

      </LoadingOverlay>
    );
  }
}

export default LoginPage;
