// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchsAndMore";
import SelectBranchs from "../components/SelectBranchsStateLess";
import Pagination from 'react-bootstrap/Pagination'
import {  Page,  Grid,  Card,  Text,  Table} from "tabler-react";
import ButtonExport from "../components/buttonExports"
import SiteWrapper from "../components/SiteWrapper.react";
import SweetAlert from 'react-bootstrap-sweetalert'

let users = storeGlobal.getusers();

class rawDataReports extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchidSelect:[],
      brabchNameSelect:"",
      key: 'cash',
      title:"Raw data reports",
      //urls:"http://localhost:3001/api/v1/report/row-data",
      urls: window.url + "api/v1/report/row-data",
      header:[],
      headerShow:[],
      value:[],
      sum:[],
      sumShow:[],
      paginationItem: [],
      paginationActive: '1',
      countPerPage:20,
      dataCallback:{},
      resultCount:"",
      resultAll:[],

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useIputSelect",
      Collections:[
        {label:"Transaction",value:"transaction"},
        {label:"Log_config",value:"configSave"},
        {label:"Alert",value:"alert"}
      ],

      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled: true,
      alertShow: false,
      alertText: '',
      labelSelectSort: "Sorting",
      Collections3: window.transactionsPageList.sorting
    }
  }
//dfasdgfghjjk42
getbranchs = (dataCallback) => {
  console.log("getbranchs ->",dataCallback);
  //let test11 = '{"type":"topup"}'
  // let Filter =JSON.parse(dataCallback.Filter)
  // console.log("Filter ->",Filter.type)
  this.state.brabchNameSelect = ""
  for(var i = 0; i < dataCallback.BranchsSelect.length; i++){
    this.state.brabchNameSelect += "("+dataCallback.BranchsSelect[i].branchName +"),"
  }
  this.setState({dataCallback:dataCallback})
  this.setState({brabchNameSelect:this.state.brabchNameSelect});
  this.setState({brabchidSelect:dataCallback.BranchidSelect});
  this.setState({organization:dataCallback.OrganizationSelect});
  this.setState({startDate:dataCallback.startDate});
  this.setState({endDate:dataCallback.endDate});
  this.setState({CollectionsSelect:dataCallback.CollectionsSelect})
  this.setState({paginationActive:1});
  let branchID = dataCallback.BranchidSelect
  let branchIDs = []
  let header = []
  let headerShow = []
  for (let x in branchID) {
    branchIDs.push({branchID:branchID[x]})
  }

  // //let start = new Date('2019-01-01T08:08:09.000Z') //test
  // let start = new Date(startDate)
  // start.setHours(0); start.setMinutes(0); start.setSeconds(0)
  // //let end = new Date('2019-02-01T08:08:09.000Z') //test
  //   let end = new Date(endDate)
  // end.setHours(23); end.setMinutes(59); end.setSeconds(59);

  if(!(Object.entries(users).length === 0)){
    this.setState({ user:users});
    let THIS = this
    let token = users.token
    let urls = this.state.urls
    let values = {
      "organization":"",
      "branchID": [],
      startDate:dataCallback.startDate,
      endDate:dataCallback.endDate
    }

    if(dataCallback.CollectionsSelect3){
      switch(dataCallback.CollectionsSelect3.value){
        case "Oldest":
          values["sortNatural"] = 1
        break
        case "Newest":
          values["sortNatural"] = -1
        break
      }
    }

    switch(dataCallback.CollectionsSelect.label){
      case "Users":
        header = ["userID","username","organization","role","status"]
        this.setState({header:header})
        this.setState({value:[["-","-","-"]]})
        this.setState({sum:["","",""]})
        values.projection = {userID:1,username:1,organization:1,role:1,status:1}
        values.collections=dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Branch":
        //header = ["branchID","branch","location","organization","project","products","version","connect","disconnect"]
        header = ["branchID","branch","location","organization","version"]
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-"]]})
        this.setState({sum:["","","","",""]})
        //values.projection = {branchID:1,branch:1,location:1,organization:1,project:1,products:1,version:1,connect:1,disconnect:1}
        values.projection = {branchID:1,branch:1,location:1,organization:1,version:1}
        values.collections=dataCallback.OrganizationSelect+'_'+dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Transaction":
        headerShow = ["item","date","product","Report type","Payment Type","Pay value","Pay change","Vending name","Card no/Ref no"]
        header = ["date","name","type","payType","payValue","payChange","branch","cardNoRef"] 
        this.setState({header:header,headerShow: headerShow})
        this.setState({value:[["-","-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,payChange:1,branch:1,cardNoRef:1}
        values.collections=dataCallback.OrganizationSelect+'_'+dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Transaction_update":
        headerShow = ["date","product","Report type","Payment Type","Pay value","Pay change","Vending name","Card no/Ref no"]
        header = ["date","name","type","payType","payValue","payChange","branch","cardNoRef"] 
        this.setState({header: header,headerShow: headerShow})
        this.setState({value:[["-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,payChange:1,branch:1,cardNoRef:1}
        values.collections=dataCallback.OrganizationSelect+'_'+dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
        // values.query  = { 
        //   $and:[
        //     {date: {$gte: "2019-02-29T08:08:09.000Z", $lt:"2019-06-29T08:08:09.000Z" }},
        //     {$or:[
        //       { branchID: '0184' },
        //       { branchID: '0101' }]},
        //     {$or:[
        //       {name:'RABBIT'}, 
        //       {type:'RABBIT'}
        //     ]}
        //   ]
        // }
      break;
      case "Log_config":
        headerShow = ["userID","userName","Vending ID","Date"]
        header = ["userID","userName","branchID","date"] 
        this.setState({header:header,headerShow: headerShow})
        this.setState({value:[["-","-","-"]]})
        this.setState({sum:["","","",""]})
        values.projection = {userID:1,userName:1,branchID:1,date:1}
        values.collections=dataCallback.OrganizationSelect+'_'+dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
        // values.query  = { 
        //   $and:[
        //     {date: {$gte: "2019-02-29T08:08:09.000Z", $lt:"2019-06-29T08:08:09.000Z" }},
        //     {$or:[
        //       { branchID: '0184' },
        //       { branchID: '0101' }]},
        //     {$or:[
        //       {name:'RABBIT'}, 
        //       {type:'RABBIT'}
        //     ]}
        //   ]
        // }
      break;
      case "Alert":
        headerShow = ["item","Vending ID","Vennding name","Alert type","Message","Date"]
        header = ["branchID","branch","type","message","date"] 
        this.setState({header:header,headerShow: headerShow})
        this.setState({value:[["-","-","-","-","-"]]})
        this.setState({sum:["","","","",""]})
        values.projection = {branchID:1,branch:1,type:1,message:1,date:1}
        values.collections=dataCallback.OrganizationSelect+'_'+dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;
        if(dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
        // values.query  = { 
        //   $and:[
        //     {date: {$gte: "2019-02-29T08:08:09.000Z", $lt:"2019-06-29T08:08:09.000Z" }},
        //     {$or:[
        //       { branchID: '0184' },
        //       { branchID: '0101' }]},
        //     {$or:[
        //       {name:'RABBIT'}, 
        //       {type:'RABBIT'}
        //     ]}
        //   ]
        // }
      break;

    }

    values.branchID = dataCallback.BranchidSelect; 
    values.organization = dataCallback.OrganizationSelect;

    let valuesNolimit = JSON.parse(JSON.stringify(values))
    valuesNolimit.countLimit = 0

    let parameters = {
      urls: this.state.urls,
      token : users.token,
      values:valuesNolimit,
      nameOfReport : this.state.title,
      headerShow : headerShow,
      method: "post"
    } 

    this.setState({"parameters":parameters})

    console.log("values ->",values)
    //console.log("token ->",token)
    console.log("url -->",urls)
    //console.log("this.state.user.token -->",THIS.state)
    axios({
      method: 'post',
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: urls,
      data: values,
      headers:{
        "Authorization":token
      }
      //withCredentials: true
    })
    .then(function (response) {
      //console.log("response -->",response.data);
      let page = THIS.state.countPerPage
      let resultAll = response.data.value
      let resultCount = response.data.resultCount>>0
      let pageCount = Math.floor(resultCount / page)
      let paginationActive = THIS.state.paginationActive
      THIS.state.paginationItem = []
      for(let i = 1; i <= pageCount; i++){
        if(i < 6){
          THIS.state.paginationItem.push(i)
        }else if(i==6){
          THIS.state.paginationItem.push(">")
        }else if(i==7){
          THIS.state.paginationItem.push(">|")
        }
      }

      THIS.state.value = []
      // for(let i = page * (paginationActive -1); ((i < page*paginationActive) &&  (i < resultAll.length)); i++){
      //   THIS.state.value.push(resultAll[i])
      // }
      for(let i = 0; i < resultAll.length; i++){
        THIS.state.value.push(resultAll[i])
      }

      THIS.setState({
        "resultAll":resultAll,
        "value":THIS.state.value,
        "paginationItem":THIS.state.paginationItem,
        "resultCount":response.data.resultCount,
        "isButtonDisabled":false
      })
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }
}

  paginationActive(e) {
    //this.state.paginationActive = e
    let page = this.state.countPerPage
    let resultCount = this.state.resultCount
    let pageCount = Math.floor(resultCount / page)
    if(e=='>'){
      this.state.paginationActive++;
    }else if(e=='<'){
      this.state.paginationActive--;
    }else if(e=='>|'){
      this.state.paginationActive = pageCount;
    }else if(e=='|<'){
      this.state.paginationActive  = 1;
    }else{
      this.state.paginationActive = e
    }
    e = this.state.paginationActive
    this.setState(state => ({
      paginationActive: this.state.paginationActive>>0
    }));
    //console.log("paginationActive e ->",e)
    if(!(Object.entries(users).length === 0)){
      this.setState({ user:users});
      let THIS = this
      let token = users.token
      let urls = this.state.urls
      let branchID = this.state.dataCallback.BranchidSelect
      let branchIDs = []
      let header = []
      for (let x in branchID) {
        branchIDs.push({branchID:branchID[x]})
      }
      let values = {
        "organization":"",
        "branchID": [],
        startDate:new Date(),
        endDate:new Date()
      }
      if(this.state.dataCallback.CollectionsSelect3){
        switch(this.state.dataCallback.CollectionsSelect3.value){
          case "Oldest":
            values["sortNatural"] = 1
          break
          case "Newest":
            values["sortNatural"] = -1
          break
        }
      }
      switch(this.state.dataCallback.CollectionsSelect.label){
      case "Users":
        header = ["item","userID","username","organization","role","status"]
        this.setState({header:header})
        this.setState({value:[["-","-","-"]]})
        this.setState({sum:["","",""]})
        values.projection = {userID:1,username:1,organization:1,role:1,status:1}
        values.collections=this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Branch":
        //header = ["branchID","branch","location","organization","project","products","version","connect","disconnect"]
        header = ["branchID","branch","location","organization","version"]
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-"]]})
        this.setState({sum:["","","","",""]})
        //values.projection = {branchID:1,branch:1,location:1,organization:1,project:1,products:1,version:1,connect:1,disconnect:1}
        values.projection = {branchID:1,branch:1,location:1,organization:1,version:1}
        values.collections=this.state.dataCallback.OrganizationSelect+'_'+this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Transaction":
        header = ["item","date","name","type","payType","payValue","value","branch","receiptNO"] 
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,value:1,branch:1,receiptNO:1}
        values.collections=this.state.dataCallback.OrganizationSelect+'_'+this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Transaction_update":
        header = ["date","name","type","payType","payValue","value","branch","receiptNO"] 
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,value:1,branch:1,receiptNO:1}
        values.collections=this.state.dataCallback.OrganizationSelect+'_'+this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Log_config":
        header = ["userID","userName","branchID","date"] 
        this.setState({header:header})
        this.setState({value:[["-","-","-"]]})
        this.setState({sum:["","","",""]})
        values.projection = {userID:1,userName:1,branchID:1,date:1}
        values.collections=this.state.dataCallback.OrganizationSelect+'_'+this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
      break;
      case "Alert":
        header = ["branchID","branch","type","message","date"] 
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-"]]})
        this.setState({sum:["","","","",""]})
        values.projection = {branchID:1,branch:1,type:1,message:1,date:1}
        values.collections=this.state.dataCallback.OrganizationSelect+'_'+this.state.dataCallback.CollectionsSelect.value
        values.countLimit = this.state.countPerPage
        values.countSkip = (e-1)*this.state.countPerPage
        values.header = header;
        if(this.state.dataCallback.Filter){
          let filters = []
          for(let i = 0; i < header.length; i++){
            let obj=new Object();
            obj[""+header[i]+""]=this.state.dataCallback.Filter
            filters.push(obj);
          }
          console.log("filters ->",filters)
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs},
              {$or:filters}
            ]
          }
        }else{
          values.query = { 
            $and:[
              {date: {$gte: this.state.dataCallback.startDate, $lt:this.state.dataCallback.endDate }},
              {$or:branchIDs}
            ]
          }
        }
      break;
      }
      values.branchID = this.state.dataCallback.BranchidSelect; 
      values.organization = this.state.dataCallback.OrganizationSelect;
      console.log("values ->",values)
      //console.log("token ->",token)
      //console.log("url -->",urls)
      //console.log("this.state.user.token -->",THIS.state)
      axios({
        method: 'post',
        // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
        url: urls,
        data: values,
        headers:{
          "Authorization":token
        }
        //withCredentials: true
      })
      .then(function (response) {
        //console.log("response -->",response.data);
        let resultAll = response.data.value
        //let paginationActive = THIS.state.paginationActive
        THIS.state.paginationItem = []
        if(pageCount < 6){
          for(let i = 1; i <= pageCount; i++){
              THIS.state.paginationItem.push(i)
          }
        }else{
          if(THIS.state.paginationActive < 4){
            for(let i = 1; i <= pageCount; i++){
              if(i < 6){
                THIS.state.paginationItem.push(i)
              }else if(i == 6){
                THIS.state.paginationItem.push(">")
              }else if(i == 7){
                THIS.state.paginationItem.push(">|")
              }else{
                break;
              }  
            }
          }else if(THIS.state.paginationActive > (pageCount-3)){
            for(let i = 1; i < 8; i++){
              if(i > 2){
                THIS.state.paginationItem.push((pageCount - (7-i)))
              }else if(i == 2){
                THIS.state.paginationItem.push("<")
              }else if(i == 1){
                THIS.state.paginationItem.push("|<")
              }else{
                break;
              }  
            }  
          }else{
            for(let i = 1; i < 10; i++){
              switch(i){
                case 1:
                  THIS.state.paginationItem.push("|<")
                break;
                case 2:
                  THIS.state.paginationItem.push("<")
                break;
                case 3:
                    THIS.state.paginationItem.push(THIS.state.paginationActive-2)
                break;
                case 4:
                    THIS.state.paginationItem.push(THIS.state.paginationActive-1)
                break;
                case 5:
                  THIS.state.paginationItem.push(THIS.state.paginationActive)
                break;
                case 6:
                    THIS.state.paginationItem.push(THIS.state.paginationActive+1)
                break;
                case 7:
                  THIS.state.paginationItem.push(THIS.state.paginationActive+2)
                break;
                case 8:
                    THIS.state.paginationItem.push(">")
                break;
                case 9:
                    THIS.state.paginationItem.push(">|")
                break;
              }
            }  
          }
        }
        THIS.state.value = []
        for(let i = 0; i < resultAll.length; i++){
          THIS.state.value.push(resultAll[i])
        }

        THIS.setState({
          "resultAll":resultAll,
          "value":THIS.state.value,
          "paginationItem":THIS.state.paginationItem,
          "isButtonDisabled":false
        })
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }

  componentDidMount() {
    let start = new Date()
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});
    if(!(Object.entries(users).length === 0)){
      let organization = []
      for(var i = 0; i < users.organization.length; i++){
        organization.push({label:users.organization[i],value:users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < users.branch.length; j++){
        branchID.push({label:users.branch[j].branchID,value:users.branch[j].branchID});
      }
      this.setState({optionBranchs:branchID});

      let project = [{label:"All",value:"All"}]
      for(var i = 0; i < users.project.length; i++){
        if(organization[0].value == users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      this.setState({optionProjects:project});

      let parameters = {
        urls: this.state.urls,
        token : users.token,
        values:{},
        nameOfReport : this.state.title,
        headerShow : this.state.headerShow
      } 
      this.setState({"parameters":parameters})

      let dataCallback = {
        BranchsSelect: users.branch, 
        BranchidSelect: users.branchID,
        OrganizationSelect: organization[0].value,
        startDate: start,
        endDate: end,
        CollectionsSelect: this.state.Collections[0],
        labelSelectSort: this.state.labelSelectSort,
        CollectionsSelect3: this.state.Collections3[0]
      }
      this.getbranchs(dataCallback)
      
      console.log("optionBranchs =>",branchID)
      console.log("optionProjects =>",project)
      console.log("optionOrganization =>",organization)
    }
  }

  render() {
    users = storeGlobal.getusers();
    let props = storeGlobal.getprops();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // console.log("Products user -->",users)
    // console.log("Products branchs -->",this.state.branchs)
    // console.log("Products branchs -->",this.state.organization)

    return (
      <SiteWrapper user={users} props = {this.props}>
        <Page.Content title={this.state.title}>
          {(this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              Collections = {this.state.Collections}
              labelSelectName = {"Collection"}
              labelInputName = {"Filter"}
              Collections3 = {this.state.Collections3}
              labelSelectSort = {this.state.labelSelectSort}
            />
          }
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>{this.state.title} : {this.state.brabchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title} </Card.Title>
                  <Card.Options>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                      <ButtonExport
                        parameters = {this.state.parameters}
                        isButtonDisabled = {this.state.isButtonDisabled}
                      >
                      </ButtonExport>
                    }
                  </Card.Options>
                </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.headerShow.map( (item, index) =>
                      <Table.ColHeader key={index} style={{ textTransform: 'none'}}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.value.length ? 
                      this.state.value.map( (items, indexs) =>
                        <Table.Row key={indexs}>
                        {items.map( (item, index) =>
                          <Table.Col key={index}>{item}</Table.Col>
                        )}
                        </Table.Row>
                      ) : <Table.Row > 
                          <Table.Col colSpan="12"	alignContent="center">
                            <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                          </Table.Col>
                        </Table.Row>
                    }
                    <Table.Row > 
                      <Table.Col colSpan="12"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
      </SiteWrapper>
    );
  }
}

export default rawDataReports