//@flow
const strings = {
  title: "Login to your Account",
  buttonText: "Login",
  emailLabel: "Email Address",
  emailPlaceholder: "Enter email",
  organizationLabel: "Organization",
  organizationPlaceholder: "Enter Organization",
  usernameLabel: "Username",
  usernamePlaceholder: "Enter username",
  passwordLabel: "Password",
  passwordPlaceholder: "Password",
};

export default strings;

export type stringTypes = { [$Keys<typeof strings>]: string };

