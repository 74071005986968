// @flow

//import * as React from "react";
import React, { Component } from 'react';
import storeGlobal from "../components/globalStore";
import Reports from '../components/Reports'

let users = storeGlobal.getusers();

class HypoReports extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchNameSelect:"",
      key: 'cash',
      // value:{
      //   //urls:"http://13.251.52.58:3001/api/v1/report/best-branchs",
      //   urls: window.url + "api/v1/report/hypo",
      //   title:"Best vending Report",
      //   header:["ITEM","VENDING ID","VENDING NAME","LOCATION","TRANSACTION","AMOUNT(THB)"],
      //   defaultValue:[['-','-','-','-','-','-']],
      //   defaultSum:['','','','','',''],
      //   paginationItem: [1],
      //   paginationActive: 1,
      //   countPerPage:20,
      //   typeSelectFilter:"useDate"
      // },
      value: window.hypoReport,
    }
  }
//dfasdgfghjjk42
  render() {
    users = storeGlobal.getusers();
    //+-console.log("Products user -->",users)
    return (
      <Reports user={users} props = {this.props} value = {this.state.value}>
      </Reports>
    );
  }
}

export default HypoReports
