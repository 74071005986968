
import React, { Component } from 'react'
import axios from 'axios'
import {CSVLink} from "react-csv";
import { Button } from "tabler-react";
import SweetAlert from 'react-bootstrap-sweetalert'

class ButtonExport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      headerShow:[],
      dataToDownload: [],
      fileName: "test.csv",
      isButtonExportLoading:false,
      alertShow: false,
      alertText: '',
    }
  }

  // componentDidMount() {
  //   console.log("parameters =>",this.props.parameters)
  //   console.log("isButtonDisabled =>",this.props.isButtonDisabled)
  // }

  exportCSV = () => {
    console.log("this.props.parameters =>",this.props.parameters)
    let THIS = this
    let values = this.props.parameters.values
    values["headerShow"] = this.props.parameters.headerShow
    THIS.setState({isButtonExportLoading:true})
    axios({
      method: this.props.parameters.method,
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: this.props.parameters.urls,
      data: values,
      headers:{
        "Authorization":this.props.parameters.token
      }
      //withCredentials: true
    })
    .then(function (response) {
      console.log("response =>",response.data)
      if(response.data.value){
        let dataToDownload = response.data.value 
        if(response.data.headerCsv !== undefined ){
          let headerCsv = response.data.headerCsv 
          console.log(headerCsv)
          dataToDownload = headerCsv.concat( response.data.value);
        }

        if(response.data.total){
          dataToDownload.push(response.data.total)
        }
        if(response.data.foottersummary){
          dataToDownload.push(response.data.foottersummary)
        }
        if(response.data.sumRabbit){
          dataToDownload.push(response.data.sumRabbit)
        }
        if(response.data.sumLinepay){
          dataToDownload.push(response.data.sumLinepay)
        }
        if(response.data.sumCash){
          dataToDownload.push(response.data.sumCash)
        }
        if(response.data.sum){
          dataToDownload.push(response.data.sum)  
        }
        let d = new Date().toISOString().replace(/[-T:.]/g, "_")
            d = d.replace(/[Z]/g, "")
        let fileName = ""
        if(THIS.props.parameters.nameOfReport !== undefined){
          fileName = THIS.props.parameters.nameOfReport+d+".csv"  
        }else{
          fileName = "Report"+d+".csv"
        }
        fileName = fileName.split(' ').join('_')

        THIS.setState({ dataToDownload: dataToDownload ,fileName:fileName,isButtonExportLoading:false}, () => {
          // click the CSVLink component to trigger the CSV download
          THIS.csvLink.link.click()
        })
      }else{
        THIS.setState({
          isButtonExportLoading:false
        })
      }
    })
    .catch(function (error) {
      THIS.setState({
        isButtonExportLoading:false,
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }

  render() {
    //console.log("render =>",this.state)
      return( 
      <div>
        <CSVLink
          headers = {this.state.headerShow}
          data={this.state.dataToDownload}
          filename= {this.state.fileName}
          //className="hidden"
          ref={(r) => this.csvLink = r}
          target="_blank" 
          />
          {                
            ( this.state.isButtonExportLoading ? <Button 
                color="green" 
                loading
                >Export CSV
              </Button> : <Button 
                color="green"
                onClick={this.exportCSV}
                disabled={this.props.isButtonDisabled}
                >Export CSV
              </Button>
            )
          }
          <SweetAlert
            warning
            show={this.state.alertShow}
            title="Oops!"
            onConfirm={() => this.setState({ alertShow: false })}
          >
            {this.state.alertText}
          </SweetAlert>
      </div>
      )
  }
}

ButtonExport.defaultProps = {
  parameters:{
    urls:"http://13.251.52.58:3001/api/v1/report/topup",
    method:"post",
    values:{},
    token:"",
    query:"",
    headerShow:[],
    headers:[],
    nameOfReport:"Report"
  },
  isButtonDisabled:true
}

export default ButtonExport;