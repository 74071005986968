// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "./globalStore";
// import SelectBranchs from "./SelectBranchsAndDate";
import { Redirect } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination'

import {
  Page,
  Text,
  Grid,
  Card,
  Table,
  Button,
  Icon,
} from "tabler-react";

// import C3Chart from "react-c3js";
import SiteWrapper from "./SiteWrapper.react";
//let users = {}

class DetailDashboard extends Component {
  constructor(props) {
    super(props);
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      BranchsSelect:[],
      BranchidSelect:[],
      branchNameSelect:"",
      title:"Detial...",
      header:[],
      value:[
        []
      ],
      sum:[],
      dataShow:[],
      sumShow:[],
      paginationItem: [1],
      paginationActive: "1",
      resultAll:[],
      countPerPage:20,
      user:{},
      isButtonLoading:true,
      redirect: false
    }
  }
//dfasdgfghjjk42
paginationActive(e) {
  let dataShow = this.state.resultAll
  this.paginationItemCal(dataShow,e)
}

paginationItemCal(dataIn,paginationActive) {
  //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
  let page = this.state.countPerPage
  let pageCount = Math.ceil(dataIn.length / page)
  let tmppaginationActive = this.state.paginationActive 
  let paginationItem = []

  switch (paginationActive){
    case '>':
        tmppaginationActive++;
    break;
    case '<':
        tmppaginationActive--;
    break;
    case '>|':
        tmppaginationActive = pageCount;
    break;
    case '|<':
        tmppaginationActive  = 1;
    break;
    default:
        tmppaginationActive = paginationActive*1
    break;
  }

  if(tmppaginationActive > pageCount){
    tmppaginationActive = pageCount;
  }

  if(pageCount < 6){
    for(let i = 1; i <= pageCount; i++){
        paginationItem.push(i)
    }
  }else{
    if(tmppaginationActive < 4){
      for(let i = 1; i <= pageCount; i++){
        if(i < 6){
          paginationItem.push(i)
        }else if(i === 6){
          paginationItem.push(">")
        }else if(i === 7){
          paginationItem.push(">|")
        }else{
          break;
        }  
      }
    }else if(tmppaginationActive > (pageCount-3)){
      for(let i = 1; i < 8; i++){
        if(i > 2){
          paginationItem.push((pageCount - (7-i)))
        }else if(i === 2){
          paginationItem.push("<")
        }else if(i === 1){
          paginationItem.push("|<")
        }else{
          break;
        }  
      }  
    }else{
      for(let i = 1; i < 10; i++){
        switch(i){
          case 1:
            paginationItem.push("|<")
          break;
          case 2:
            paginationItem.push("<")
          break;
          case 3:
              paginationItem.push(tmppaginationActive-2)
          break;
          case 4:
              paginationItem.push(tmppaginationActive-1)
          break;
          case 5:
            paginationItem.push(tmppaginationActive)
          break;
          case 6:
              paginationItem.push(tmppaginationActive+1)
          break;
          case 7:
            paginationItem.push(tmppaginationActive+2)
          break;
          case 8:
              paginationItem.push(">")
          break;
          case 9:
              paginationItem.push(">|")
          break;
          default: 
          break
        }
      }  
    }
  }

  let dataShow = []
  if(dataIn.length){
    for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
      dataShow.push(dataIn[i])
    }
  }

  let sumShow = []
  if(paginationActive === paginationItem[paginationItem.length-1]){
    sumShow = this.state.sum
  }

  this.setState({
    dataShow: dataShow,
    sumShow: sumShow,
    paginationItem: paginationItem,
    paginationActive: tmppaginationActive
  })
}
  
  refresh = (dataIn) => {
    let users = this.state.user
    //if(!(Object.entries(this.state.user).length === 0)){
    if(JSON.stringify(users) !== '{}'){
      let state = this.props.location.state
      //if(this.props.location.state !== undefined ){
      if(JSON.stringify(state) !== '{}'){
        let BranchidSelect = state.dataSend.BranchidSelect;
        let BranchsSelect = state.dataSend.BranchsSelect;
        let OrganizationSelect = state.dataSend.OrganizationSelect;
        let dataShow = state.dataSend.defaultValue;
        let sum = state.dataSend.defaultSum;
        let title = state.dataSend.title;
        let header = state.dataSend.header;
        let urls = state.dataSend.urls;
        let countPerPage = state.dataSend.countPerPage;
        this.setState({
          BranchidSelect: BranchidSelect,
          BranchsSelect: BranchsSelect,
          OrganizationSelect: OrganizationSelect,
          header: header,
          dataShow: dataShow,
          sum: sum,
          title: title,
          urls: urls,
          countPerPage: countPerPage
        })
        //console.log("componentDidMount state -->",this.state);
        if(BranchidSelect.length && OrganizationSelect !==""){
          // let branchNameSelect = [] //""
          // for(var i = 0; i < this.state.BranchsSelect.length; i++){ 
          //   // this.state.branchNameSelect += "("+this.state.BranchsSelect[i].branchName +"),"
          //   branchNameSelect.push(this.state.BranchsSelect[i].branchName)
          // }
          // let lengthShow = 3
          // let branchNameSelectLength = branchNameSelect.length
          // if(branchNameSelectLength <= lengthShow) {
          //   this.state.branchNameSelect = branchNameSelect.join(', ')
          // } else {
          //   let txt = branchNameSelect.slice(0, 3)
          //   this.state.branchNameSelect = txt.join(', ') + ' ... +' + (branchNameSelectLength-lengthShow) + ' Branchs'
          // }
          
          let THIS = this
          let token = this.state.user.token
          let values = {
            organization:OrganizationSelect,
            branchID: BranchidSelect,
            startDate:new Date(),
            endDate:new Date(),
            //type:"",
            search:"",
            username: this.state.user.username
          }

          if(state.dataSend.startDate){
            values.startDate = new Date(state.dataSend.startDate)
          }

          if(state.dataSend.endDate){
            values.endDate = new Date(state.dataSend.endDate)
          }

          values.startDate.setHours(0); values.startDate.setMinutes(0); values.startDate.setSeconds(0)
          values.endDate.setHours(23); values.endDate.setMinutes(59); values.endDate.setSeconds(59)
          
          if(state.dataSend._sortNatural){
            values._sortNatural = state.dataSend._sortNatural
          }

          if(state.dataSend.username){
            values.username = state.dataSend.username
          }

          //console.log("values ->",values)
          //console.log("token ->",token)
          //console.log("url -->",urls)
          axios({
            method: 'post',
            // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
            url: urls,
            data: values,
            headers:{
              "Authorization":token
            }
            //withCredentials: true
          })
          .then(function (response) {
            let resultAll = response.data.value
            //console.log('resultAll  =>',resultAll)
            THIS.setState({
              "resultAll": resultAll,
              "sum": response.data.sum,
              "isButtonDisabled":false,
              "isButtonLoading": true
            }, () =>{
              THIS.paginationItemCal(resultAll,THIS.state.paginationActive)
            })
            THIS.setState({"paginationItem":THIS.state.paginationItem})
          })
          .catch(function (error) {
            console.log(error);
            alert("error: " + JSON.stringify(error) );
          });
        }
      }else{
        this.goto401()
      }
    }
  }

  // gotoBack = (item) => {
  //   // console.log('detailBranch item =>', item);
  //   let location = {
  //     pathname: '/dashboard',
  //     state: {}
  //   }
    
  //   this.setState({
  //     location: location,
  //     redirect: true 
  //   })
  // }

  gotoBack = (item) => {
    //console.log('detailBranch item =>', item);
    let location = this.state.location
    //console.log('location =>',location)
    if(location === undefined){
      location = {
        pathname: '/dashboard',
        state: {}
      }
    }

    this.setState({
      location: location,
      redirect: true 
    })
  }

  goto401 = () => {
    console.log('goto401..');
    this.props.history.push('/dashboard');
    this.props.history.push('/401');
  }

  componentWillMount() {
    let users = storeGlobal.getusers();
    //this.state.user = users
    this.setState({"user":users})
    //console.log("componentWillMount users =>",users)
    //console.log("componentWillMount this.props.location =>",this.props.location)
  }

  componentDidMount() {
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      if(this.props.location.pageFrom !== undefined){
        let location = {
          pathname: this.props.location.pageFrom,
          state: {}
        }
        this.setState({
          location
        })
      }
      this.refresh()
    }
  }

  render() {
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )
    return (
      <SiteWrapper user={this.state.user} props = {this.props}>
        <Page.Content title={this.state.title}>
          {/* <SelectBranchs getbranchs = {this.getbranchs}/> */}
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>Vending : {this.state.branchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title}</Card.Title>
                  <Card.Options>
                    <Button.List>
                      <Button 
                        color="secondary"                           
                        onClick={ () => this.gotoBack(this) }
                      > 
                      <Icon prefix="fe" name="arrow-left" className="mr-2" />
                      Back 
                      </Button>  
                      {this.state.isButtonLoading? <Button color="default" icon="rotate-cw" 
                          onClick={ () => { 
                            this.setState({isButtonLoading: false},() => {
                              this.refresh()
                            })
                          }}
                          />:<Button color="default" icon="rotate-cw" loading />
                        }
                      </Button.List>
                    </Card.Options>
                </Card.Header>
                  
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.header.map( (item, index) =>
                      <Table.ColHeader key={index}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { this.state.dataShow.length? <Table.Row></Table.Row> : <Table.Row > 
                        <Table.Col colSpan="12"	alignContent="center">
                          <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                        </Table.Col>
                      </Table.Row>
                    }
                    {this.state.dataShow.map( (item, index) =>
                      <Table.Row key={index}>
                      {item.map( (item1, indexs) =>
                        <Table.Col key={indexs}>{item1}</Table.Col>
                      )}
                      </Table.Row>
                    )}
                    <Table.Row > 
                      {this.state.sumShow.map( (item, index) => 
                        <Table.Col key={index}>{item}</Table.Col>
                      )}
                    </Table.Row>                    
                    <Table.Row > 
                      <Table.Col colSpan="12"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default DetailDashboard
