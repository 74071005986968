// @flow
import React, { Component } from 'react'
import { instanceOf } from 'prop-types';
import { NavLink, withRouter } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import {
  Site,
  Nav,
  Grid,
  List,
  Button,
  Badge,
  RouterContextProvider,
} from "tabler-react";

//import type { NotificationProps } from "tabler-react";

import storeGlobal from "./globalStore";
import ModalFormLarge from "./ModalFormLarge";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert'
import { Redirect } from "react-router-dom";
import ModalResetPassword from "../components/ModalResetPassword";
import { isThisHour } from 'date-fns';
import { invalid } from 'moment';

// type Props = {|
//   +children: React.Node,
// |};

// type State = {|
//   notificationsObjects: Array<NotificationProps>,
// |};

// type subNavItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +LinkComponent?: React.ElementType,
// |};

// type navItem = {|
//   +value: string,
//   +to?: string,
//   +icon?: string,
//   +active?: boolean,
//   +LinkComponent?: React.ElementType,
//   +subItems?: Array<subNavItem>,
//   +useExact?: boolean,
// |};

let navBarItems = [];

class SiteWrapper extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      notificationsObjects: [],
      notificationsObjects1: [
        {
          unread: true,
          avatarURL: "demo/faces/male/41.jpg",
          message: (
            <React.Fragment>
              <strong>Nathan</strong> pushed new commit: Fix page load performance
              issue.
            </React.Fragment>
          ),
          time: "10 minutes ago",
        },
        {
          unread: true,
          avatarURL: "demo/faces/female/1.jpg",
          message: (
            <React.Fragment>
              <strong>Alice</strong> started new task: Tabler UI design.
            </React.Fragment>
          ),
          time: "1 hour ago",
        },
        {
          unread: false,
          avatarURL: "demo/faces/female/18.jpg",
          message: (
            <React.Fragment>
              <strong>Rose</strong> deployed new version of NodeJS REST Api // V3
            </React.Fragment>
          ),
          time: "2 hours ago",
        },
      ],

      modalShow: false,
      modalTitle: 'Profile',
      modalWorning: '',
      modalForm: [],
      alert: null,
      redirect: false,

      modalResetPasswordShow: false,
      resetId: null
    };
  }
  
  componentWillMount() {
    //console.log("componentWillMount.. this.props.user =>", this.props.user)
    if("username" in this.props.user) {
      this.state.modalForm = [
        { key: "imageURL", name: "Avatar", placeholder:"", value: this.props.user.profile.imageURL, disabled: false, type: 'avatar', col: 1 },
        { key: "username", name: "Username", placeholder:"", value: this.props.user.username, disabled: true, type: 'input', col: 1 },
        // { key: "password", name: "Password", placeholder:"", value: "", disabled: false, type: 'input', col: 1 },
        { key: "fullname", name: "Full name", placeholder:"", value: this.props.user.profile.fullname, disabled: false, type: 'input', col: 2 }, 
        { key: "email", name: "Email", placeholder:"", value: this.props.user.profile.email, disabled: false, type: 'input', col: 2 },
        { key: "address", name: "Address", placeholder:"Enter Description..", value: this.props.user.profile.address, disabled: false, type: 'textarea', rows: 2, col: 2 },
        { key: "about", name: "About Me", placeholder:"Enter Description..", value: this.props.user.profile.about, disabled: false, type: 'textarea', rows: 3, col: 2 },
      ]
      this.setState({modalForm: this.state.modalForm})
    } else {
      this.goto401()
    }
  }

  // These methods are called "lifecycle hooks".
  componentWillUnmount() {
    //+-console.log("componentWillUnmount");
    if(this.setTimeOutReadNotificationsObjects) clearTimeout(this.setTimeOutReadNotificationsObjects)
  }

  // componentDidUpdate(){
  //   console.log('componentDidUpdate =>',this.props.props)
  //   if(this.props.dataSend){
  //     if(this.state.OrganizationSelect !== this.props.dataSend.OrganizationSelect){
  //       this.setState({
  //         OrganizationSelect: this.props.dataSend.OrganizationSelect
  //       })
  //     }
  //   }
  // }

  alertBadge = (item, itemType, index) => {
    let badge ='secondary'
    switch(item) {
      case 'error': badge='danger'; break;
      case 'warning': badge='warning'; break;
      case 'info': badge='info'; break;
    }
    //return <Badge color={badge} className="my-4" key={index}>{item}</Badge>
    return <Badge color={badge} key={index}>{itemType}</Badge>
  }

  goTos = (i) =>{
    //console.log("goto =>",i)
    //+-console.log("props =>",this.props.props.match.path)
    if(this.props.props.match.path !== '/notificationreport'){
      let location = {
        pathname: '/notificationreport',
        state: {}
      }
      //this.state.location = location
      // this.setState(state => ({
      //   location:location,
      //   redirect: true
      // }));
      this.setState({
        location:location,
        redirect: true
      })
    }else{
      window.location.reload();
    }
  }

  alertShowImage = (item) => {
    let path ='image/info-svgrepo-com.svg'
    switch(item) {
      case 'error': path='image/error-svgrepo-com.svg'; break;
      case 'warning': path='image/warning-svgrepo-com.svg'; break;
      case 'info': path='image/info-svgrepo-com.svg'; break;
    }  
    return path
  }

  changFormatDate = (dateIn) => {
    let tempArr = dateIn.split("/");
    let temparr2 = tempArr[2].split(" ");
    return (temparr2[0] + '-' + tempArr[1] + '-' + tempArr[0] + ' ' + temparr2[1])
  }

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  time_ago = (time) => {
    //console.log("timein =>",typeof time)
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        if(this.isValidDate(time)){
          time = +new Date(time);
        }else{
          time = +new Date(this.changFormatDate(time))       //chang date format invalid frome 'DD/MM/YYYY HH:SS' to 'YYYY-MM-DD HH:MM'    
        }
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    //console.log("time =>",time)
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;
  
    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }
  
  // var aDay = 24 * 60 * 60 * 1000;
  // console.log(time_ago(new Date(Date.now() - aDay)));
  // console.log(time_ago(new Date(Date.now() - aDay * 2)));
  clearNotificationsObjects = () =>{
    let users = this.props.user
    //if(!(Object.entries(users).length === 0)){
    if(JSON.stringify(users) !== '{}'){
      let branchIDs =[]
      for (let x in users.branchID) {
        branchIDs.push({branchID:users.branchID[x]})
      }
      let THIS = this
      let token = users.token
      let urls = window.url + "api/v1/report/alert-report"
      let values = {
        organization:  users.OrganizationSelect,
        branchID: users.branchID,
        _sortNatural: -1,
        header:["branchID","branch","status","type","action","message","date"], 
        projection : {branchID:1,branch:1, status:1, type:1, action:1, message: 1,date:1},
        collections:  users.OrganizationSelect+"_alert",
        countLimit: 5,
        countSkip: 0,
      }
      let aDay = 24 * 60 * 60 * 1000;
      let startDate = new Date();
      let endDate = new Date();
      startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
      endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59)
      values.startDate = (startDate - (aDay * window.setTimeNotifications.startDate))
      values.endDate = endDate 
      values.type = ""
      values.search = ""
      values.username = users.username
      values.unread = users.username
      //+-console.log("values =>",values)

      axios({
        method: 'post',
        // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
        url: urls,
        data: values,
        headers:{
          "Authorization":token
        }
        //withCredentials: true
      })
      .then(function (response) {
        //+-console.log("response =>", response.data.value)
        if(THIS.setTimeOutReadNotificationsObjects) clearTimeout(THIS.setTimeOutReadNotificationsObjects)
        THIS.setTimeOutReadNotificationsObjects = setTimeout(() => {  
          THIS.getNotificationsObjects()
        },1000)
      })
      .catch(function (error) {
        console.log('axios error =>',error.message);
      });
    }
  }

  getNotificationsObjects = () =>{
    let users = this.props.user
    //console.log('users =>',users)
    //if(!(Object.entries(users).length === 0)){
    if(JSON.stringify(users) !== '{}'){
      let branchIDs =[]
      for (let x in users.branchID) {
        branchIDs.push({branchID:users.branchID[x]})
      }
      let THIS = this
      let token = users.token
      let urls = window.url + "api/v1/report/row-data"
      let values = {
        organization:  users.OrganizationSelect,
        branchID: users.branchID,
        sortNatural: -1,
        header:["branchID","branch","type","message","date","status"], 
        projection : {branchID:1,branch:1, type:1, message: 1,date:1,status:1},
        collections: users.OrganizationSelect+"_alert",
        countLimit: 5,
        countSkip: 0,
      }
      let aDay = 24 * 60 * 60 * 1000;
      let startDate = new Date();
      let endDate = new Date();
      startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
      endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59)
      startDate = (startDate - (aDay * window.setTimeNotifications.startDate))
      values.query = { 
        $and:[
          {date: {$gte: startDate, $lt:endDate }},
          {$or:branchIDs},
          {unread:{$ne:users.username}}
        ]
      }
  
      // if(dataCallback.CollectionsSelect.label !== 'All'){
      //   values.query.$and.push({$or:[{type:dataCallback.CollectionsSelect.value}]})
      // }
  
      // if(tempPayType.length){
      //   values.query.$and.push({$or:tempPayType})  
      // }
  
      // if(dataCallback.Filter !== ''){
      //   values.query.$and.push({$or:[{name:dataCallback.Filter}]})  
      // }
  
      //console.log("values ->",values)
      axios({
        method: 'post',
        url: urls,
        data: values,
        headers:{
          "Authorization":token
        }
        //withCredentials: true
      })
      .then(function (response) {
        //console.log("getNotificationsObjects response =>", response.data.value);
        let tempNotificationsObjects = []
        for(let i = 0; i < response.data.value.length; i++){
          let tempMsg = "   "
          if(response.data.value[i][4].length > 40){
            tempMsg += response.data.value[i][4].substring(0, 40)+"..."
          }else{
            tempMsg += response.data.value[i][4]
          }  
          let tempType = THIS.alertBadge(response.data.value[i][6], response.data.value[i][3], i)
          //console.log("response.data.value[i][4] =>",response.data.value[i][4])
          let timeAgo = THIS.time_ago(response.data.value[i][5])
          //console.log("timeAgo =>",timeAgo)
          let avatarURL = THIS.alertShowImage(response.data.value[i][6])
          let tempObj = {
            unread: true,
            avatarURL: avatarURL,
            message: (
              <div  onClick={() => THIS.goTos(i)}>
                <React.Fragment>
                  <strong>{tempType}</strong>{tempMsg}
                </React.Fragment>
              </div>
            ),
            time: timeAgo,
          }
          tempNotificationsObjects.push(tempObj)
        }
        THIS.setState({notificationsObjects:tempNotificationsObjects})
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  }

  componentDidMount() {
    //console.log("componentDidMount this.props.user =>",this.props.user)
    const { cookies } = this.props;
    let users = this.props.user
    if(users && users.username){
      console.log("componentDidMount this.props.user.username =>",users.username)
      storeGlobal.setusers(users);
      this.setState({homePage:"/"+users.permission.home})
      // let tempUsers = JSON.parse(JSON.stringify(users))
      // delete tempUsers.permission
      // delete tempUsers.token
      let tempUsers = {
        userID : users.userID,
        username :users.username,
        token : users.token,
        role : users.role,
        BranchidSelect : users.BranchidSelect,
        ProjectSelect : users.ProjectSelect,
        OrganizationSelect : users.OrganizationSelect
      }
      let tempPath = this.props.props.match.path
          tempPath = tempPath.split('/').join('')
      // console.log("tempUsers =>",tempPath)
      cookies.set('user',JSON.stringify(tempUsers), { path: '/' ,maxAge: window.setTimeExpire.timeExpire});// Will expire after 1hr*12 (value is in number of sec.)
      cookies.set('homePage',JSON.stringify(tempPath), { path: '/' ,maxAge: window.setTimeExpire.timeExpire});// Will expire after 1hr*12 (value is in number of sec.)
    }
    if(this.setTimeOutReadNotificationsObjects) clearTimeout(this.setTimeOutReadNotificationsObjects)
    this.setTimeOutReadNotificationsObjects = setTimeout(() => {  
      this.getNotificationsObjects()
    },1000)
  }

  //deployUser = async() => {
  signOut = () => {
    let dataSave = {
      "type" : 'information', //information //warning // error
      "date" : new Date(),
      "surce" : 'siteWrapper',
      "category" : 'webApp',
      "even" : 'Logout',
      "user" :  this.props.user.username,
      "ip" : "",
      "discription" : 'Logout complete =>'+ this.props.user.username
    }
    let THIS = this
    this.saveLog(dataSave,function(cb){
      console.log('cb =>',cb)
      const { cookies } = THIS.props;
      cookies.remove('user', { path: '/'});
      cookies.remove('permission', { path: '/'});
      cookies.remove('token', { path: '/'});
      cookies.remove('homePage', { path: '/'});
      storeGlobal.setusers(null);
      //+-console.log("signOut");
      // props.history.push('/login');
      window.location.reload();
    })
  }

  goto401 = () => {
    //+-console.log('SiteWrapper goto401..');
    let location = {
      pathname: '/401',
      state: {}
    }
    
    this.state.location = location
    this.setState(state => ({
			redirect: true
		}));
  }

  getForm = () => {
    // console.log('getForm modalForm =>', this.state.modalForm);   
    let data = {}
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].value === '') { 
        // console.log('this.state.modalForm[i].value == ""', this.state.modalForm[i]);
        return this.setState({modalWorning: "Input is blank!"})
      }

      if(this.state.modalForm[i].type == 'tree') {
        if(!this.state.modalForm[i].tree.checkedKeys.length) {
          return this.setState({modalWorning: "Permission is blank!"})
        }
        this.state.modalForm[i].value = this.state.modalForm[i].tree.checkedKeys
         
        // check tree to menu
        let permission = this.state.modalForm[i].value
        let permissionDefault = JSON.parse(JSON.stringify(this.state.permissionDefault))
        // for(let i=0; i<permissionDefault.length; i++) {  
        for(let i=permissionDefault.length-1; i>=0; i--) {  
          let subItems1 = permissionDefault[i].subItems
          if(subItems1) {
            if(!subItems1.length) {              
              if(permission.indexOf(permissionDefault[i].value) < 0) {
                // console.log('!subItems1.length delete permissionDefault[i] =>', i, permissionDefault[i]);
                // delete permissionDefault[i]
                permissionDefault.splice(i,1)
              }
            } else {
              // for(let j=0; j<subItems1.length; j++) {  
              for(let j=subItems1.length-1; j>=0; j--) { 
                let subItems2 = permissionDefault[i].subItems[j].subItems
                if(subItems2) {
                  if(!subItems2.length) {                    
                    if(permission.indexOf(subItems1[j].value) < 0) {
                      // console.log('delete subItems1[j] =>', subItems1[j]);
                      // delete subItems1[j]
                      subItems1.splice(j,1)
                    }
                  } else {
                    // for(let k=0; k<subItems2.length; k++) { 
                    for(let k=subItems2.length-1; k>=0; k--) { 
                      if(permission.indexOf(subItems2[k].value) < 0) {
                        // console.log('delete subItems2[k] =>', subItems2[k]);
                        // delete subItems2[k]
                        subItems2.splice(k,1)
                      }
                    }
                  }
                } else {
                  // console.log('subItems1[j].value =>', j, subItems1[j].value);
                  if(permission.indexOf(subItems1[j].value) < 0) {
                    //+-console.log('==== delete subItems1[j] 1 =>', subItems1[j]);
                    // delete subItems1[j]
                    subItems1.splice(j,1)
                  }
                }    
                // console.log('--- subItems1 =>', j, subItems1);  
                // if(typeof subItems1[j] === 'undefined') { //.length
                //   subItems1.splice(j,1)
                // } else if(!subItems1[j].length) { //.length
                //   subItems1.splice(j,1)
                // } 
              }
              // console.log('--- permissionDefault[i] =>', i, permissionDefault[i]);
              if(permissionDefault[i]) {
                if(typeof permissionDefault[i].subItems === 'undefined') {
                  permissionDefault.splice(i,1)
                } else if(!permissionDefault[i].subItems.length) {
                  permissionDefault.splice(i,1)
                }
              }   
            }
            
          } else {
            if(permission.indexOf(permissionDefault[i].value) < 0) {
              // console.log('delete permissionDefault[i] =>', i, permissionDefault[i]);
              // delete permissionDefault[i]
              permissionDefault.splice(i,1)
            }
          }  
        }
        data.menu = permissionDefault
        // end check tree to menu
      }

      data[this.state.modalForm[i].key] = this.state.modalForm[i].value

      // change menu 'value' to 'to' homepage 
      if(this.state.modalForm[i].key === 'home') {
        data.homeSelect = this.state.modalForm[i].value
        let permissionDefault = this.state.permissionDefault
        for(let ii=0; ii<permissionDefault.length; ii++) {  
          if(this.state.modalForm[i].value === permissionDefault[ii].value) {
            data[this.state.modalForm[i].key] = permissionDefault[ii].to.split('/').join('')
            break
          }
          for(let jj=0; jj<permissionDefault[ii].subItems.length; jj++) {  
            if(this.state.modalForm[i].value === permissionDefault[ii].subItems[jj].value) {
              data[this.state.modalForm[i].key] = permissionDefault[ii].subItems[jj].to.split('/').join('')
              break
            }
          }
        }
      }

    }

    // data.createBy = 'this.state.user.username'
    data.updateBy = this.props.user.username
    data.flag = 'edit'
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    // return
    // if(this.state.branchEdit) {
    //   data.flag = 'edit'
    //   this.getFormEdit(data)
    //   return
    // }
    
    let THIS = this
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + '_' + '/' + 'users' + '/' + this.props.user._id,
      data: {profile: data},
      headers:{
        "Authorization": this.props.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Add project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'siteWrapper',
        "category" : 'webApp',
        "even" : 'Edit profile',
        "user" :  THIS.props.user.username,
        "ip" : "",
        "discription" : 'Edit profile =>'+ THIS.props.user.username
      }
      //THIS.saveLog(dataSave)
      THIS.saveLog(dataSave,function(cb){
        console.log('cb =>',cb)
      })

      THIS.setState({modalShow: false})
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
    });

  }

  saveLog = (dataIn,cb) => {
    console.log('dataIn ->',dataIn)
    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.props.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.props.user.organization[0]
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)
        cb(null)
      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
        cb(error)
      });
    })
  }

  resetPwd = (e) => {
    console.log('resetPwd(e) =>', e, this.props);   
    let data = {password: e.value}
    data.updateBy = this.props.user.username
    data.flag = 'reset'
    data.status = 'reset'
    // data.OrganizationSelect = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);

    let THIS = this
    axios({
      method: 'put',
      // url: window.url + 'api/v1/collection/' + '_' + '/' + 'users' + '/' + this.props.user._id,
      url: window.url + 'api/v1/users/' +  this.props.user.OrganizationSelect + '/' + this.props.user._id,
      data: data,
      headers:{
        "Authorization": this.props.user.token
      }
    })
    .then(function (response) {
      console.log("Add project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'siteWrapper',
        "category" : 'webApp',
        "even" : 'Reset password',
        "user" :  THIS.props.user.username,
        "ip" : "",
        "discription" : 'Reset password =>'+ THIS.props.user.username
      }
      //THIS.saveLog(dataSave)
      THIS.saveLog(dataSave,function(cb){
        console.log('cb =>',cb)
      })

      THIS.setState({ alert: null })
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({ alert: null })
      THIS.modalClose()
    });

  }

  modalClose = () => {
    this.setState({ modalResetPasswordShow: false })
  };

  // modal funtions ====================================================================
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange  =>", name, value);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].name == name) {
        this.state.modalForm[i].value = value
        break
      }
    }

    this.setState({modalForm: this.state.modalForm})

  }
  // tree
  onExpand = expandedKeys => {
    // console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });

    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].type == 'tree') {
        this.state.modalForm[i].tree.expandedKeys = expandedKeys
        this.state.modalForm[i].tree.autoExpandParent = false
        break
      }
    }
    this.setState({modalForm: this.state.modalForm})
  };

  onCheck = checkedKeys => {
    console.log('onCheck', checkedKeys);
    this.setState({ checkedKeys });
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].type == 'tree') {
        this.state.modalForm[i].tree.checkedKeys = checkedKeys
        this.state.home = checkedKeys
        //break
      }

      if(this.state.modalForm[i].key == 'home') {
        let items = JSON.parse(JSON.stringify(checkedKeys))
        let permissionDefault = this.state.permissionDefault

        for(let i=permissionDefault.length-1; i>=0; i--) { 
          let indexOfitems = items.indexOf(permissionDefault[i].value)
          if( indexOfitems >= 0) {
            if(permissionDefault[i].subItems.length) { 
              items.splice(indexOfitems,1)
            }
          }         
        }

        this.state.home = items
        this.state.modalForm[i].items = this.state.home
        this.state.modalForm[i].value = this.state.modalForm[i].items[0]
      }
    }
    this.setState({modalForm: this.state.modalForm})
  };

  onSelect = (selectedKeys, info) => {
    // console.log('onSelect', info);
    this.setState({ selectedKeys });
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].type == 'tree') {
        this.state.modalForm[i].tree.selectedKeys = selectedKeys
        break
      }
    }
    this.setState({modalForm: this.state.modalForm})
  };
  // modal funtions end ====================================================================

  render(){
  //console.log("this.props =>",this.props)
  //render(): React.Node {
    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    const notificationsObjects = this.state.notificationsObjects || [];
    const unreadCount = this.state.notificationsObjects.reduce(
      (a, v) => a || v.unread,
      false
    );

    let accountDropdownProps = {
      avatarURL: this.props.user.profile.imageURL,
      name: "Jane Pearson",
      description: "Administrator",
      options: [
        { icon: "user", value: "Profile", onClick: () => this.setState({ modalShow: true}) },
        { icon: "repeat", value: "Change Password", onClick: () => this.setState( state => ({
          // alert: (
          //   <SweetAlert
          //     input
          //     showCancel
          //     cancelBtnBsStyle="default"
          //     title="Reset Password"
          //     // placeHolder="New password"
          //     onConfirm={(value)=> this.resetPwd(value) }
          //     onCancel={ ()=> this.setState(state => ({
          //         alert: null
          //       })) }
          //     >
          //     Enter new "{this.props.user.profile.fullname}" password :
          //   </SweetAlert>
          // )
          modalResetPasswordShow: true, 
          resetId: this.props.user._id,
        })) },
        { icon: "log-out", value: "Sign out", onClick: () => this.signOut(this.props.props)},
        //{ icon: "log-out", value: "Sign out", onClick: this.signOut(this.props.props)},
      ],
    };

    //console.log("siteWrapper this.props.user ----->",this.props.user);
    //console.log("siteWrapper this.props ----->",this.props);
    if(!(Object.entries(this.props.user).length === 0)){
      //if(this.props.menu != null){
        let menuShow = this.props.user.permission.menu;
        navBarItems =[];
        for(let i = 0; i < menuShow.length; i++){
          if(menuShow[i].subItems.length){
            let subMenushow = menuShow[i].subItems
            for(let j = 0; j < subMenushow.length; j++){
              if(subMenushow[j].length){
                let itemMenuShow = subMenushow[j].subItems
                for(let k = 0; k < itemMenuShow.length; k++){
                  itemMenuShow[k].LinkComponent = withRouter(NavLink);
                }
              }else{
                subMenushow[j].LinkComponent = withRouter(NavLink);
              }    
            }
          }else{
            menuShow[i].LinkComponent = withRouter(NavLink); 
          }
          navBarItems.push(menuShow[i]);  
        }
      //}
      accountDropdownProps.name = this.props.user.profile.fullname;
      accountDropdownProps.description =  this.props.user.OrganizationSelect;
    }
    
    //console.log("navBarItems ->",navBarItems)

    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Tabler React",
          imageURL: "./image/brand/logo-manu-footer1-3.png",
          notificationsTray: {
            notificationsObjects,
            markAllAsRead: () =>
              this.setState(
                () => ({
                  notificationsObjects: this.state.notificationsObjects.map(
                    v => ({ ...v, unread: false })
                  ),
                }),
                () =>
                  setTimeout(
                    () =>
                      // this.setState({
                      //   notificationsObjects: this.state.notificationsObjects.map(
                      //     v => ({ ...v, unread: true })
                      //   ),
                      // }),
                      this.clearNotificationsObjects(),
                    1000
                  )
              ),
            unread: unreadCount,
          },
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2019
              <a href="http://www.ppetech.com/" target="_blank" > PPETECH</a>.
              <a
                href="http://www.ppetech.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                
              </a>{" "}
              All rights reserved.
            </React.Fragment>
          ),
          note:
          " ",
        }}
      >
        {this.props.children}

        <ModalFormLarge
          show={this.state.modalShow}
          onHide={() => this.setState({ modalWorning: "", modalShow: false })}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalTitle}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />
        {this.state.alert}

        <ModalResetPassword
          show={this.state.modalResetPasswordShow}
          onHide={this.modalClose}
          onSave={this.resetPwd}
          resetId={this.state.resetId}
        />
      </Site.Wrapper>     
    );
  }
}

// export default SiteWrapper;
export default withCookies(SiteWrapper);