// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchsandtype";
import SelectBranchs from "../components/SelectBranchsStateLess";
import Pagination from 'react-bootstrap/Pagination'
import ButtonExport from "../components/buttonExports"
import SweetAlert from 'react-bootstrap-sweetalert'
import {  Page,  Grid,  Card,  Text,  Table } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
//let users = storeGlobal.getusers();

class SettelmentCashReports extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      users: storeGlobal.getusers(),
      organization:"",
      branchs:[],
      brabchidSelect:[],
      brabchNameSelect:"",
      key: 'cash',
      title:"Daily report",
      //urls:"http://localhost:3001/api/v1/report/row-data",
      //urls: window.url + "api/v1/report/row-data",
      //urls: window.url + "api/v1/report/sumreport",
      urls: window.url + "api/v1/report/sumtransactions",
      header:[],
      value:[],
      sum:["total",0,0],
      dataShow:[],
      sumShow:[],
      paginationItem: [],
      paginationActive: '1',
      countPerPage:24,
      dataCallback:{},
      resultCount:"",
      resultAll:[],

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useIputSelect",
      Collections:[
        {label:"Sale",value:"sale"},
        {label:"topUp",value:"topUp"},
      ],

      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled:true,
      alertShow: false,
      alertText: ''
    }
  }
//dfasdgfghjjk42
getbranchs = (dataCallback) => {
  //+-console.log("getbranchs ->",dataCallback);
  //let test11 = '{"type":"topup"}'
  // let Filter =JSON.parse(dataCallback.Filter)
  // console.log("Filter ->",Filter.type)
  this.state.brabchNameSelect = ""
  for(var i = 0; i < dataCallback.BranchsSelect.length; i++){
    this.state.brabchNameSelect += "("+dataCallback.BranchsSelect[i].branchName +"),"
  }
  this.setState({dataCallback:dataCallback})
  this.setState({brabchNameSelect:this.state.brabchNameSelect});
  this.setState({brabchidSelect:dataCallback.BranchidSelect});
  this.setState({organization:dataCallback.OrganizationSelect});
  this.setState({startDate:dataCallback.startDate});
  this.setState({endDate:dataCallback.endDate});
  this.setState({CollectionsSelect:dataCallback.CollectionsSelect})
  this.setState({paginationActive:1});
  let branchID = dataCallback.BranchidSelect
  let branchIDs = []
  let header = []
  for (let x in branchID) {
    branchIDs.push({branchID:branchID[x]})
  }

  // //let start = new Date('2019-01-01T08:08:09.000Z') //test
  // let start = new Date(startDate)
  // start.setHours(0); start.setMinutes(0); start.setSeconds(0)
  // //let end = new Date('2019-02-01T08:08:09.000Z') //test
  //   let end = new Date(endDate)
  // end.setHours(23); end.setMinutes(59); end.setSeconds(59);

  if(!(Object.entries(this.state.users).length === 0)){
    let users = this.state.users
    let THIS = this
    let token = users.token
    let urls = this.state.urls
    let values = {
      "organization":"",
      "branchID": [],
      startDate:new Date(),
      endDate:new Date()
    }
    // switch(dataCallback.CollectionsSelect.label){
    switch(dataCallback.CollectionsSelect){
      case "Sale":
      case "sale":
        //header = ["Txn Type","Volume","Amount(value)","Rate","Fee Amount","Vat","WHT","Net Fee", "Net Amount"] 
        header = ["Item"," ","Date/Time","Type of Payment","Product","Amount(Value)", "Paid(Value)", "Change", "Rate", "Fee Amount", "Vat", "WHT", "Net Fee", "Net Amount","CARD NO/REF NO"]
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,value:1,branch:1,receiptNO:1}
        values.collections=dataCallback.OrganizationSelect+'_Transaction'
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;

        values.branchID = dataCallback.BranchidSelect;
        values.project = dataCallback.ProjectSelect;
        values.organization = dataCallback.OrganizationSelect;
        values.startDate = dataCallback.startDate;
        values.endDate = dataCallback.endDate;
        values.paginationActive = 1
        values.countPerPage = this.state.countPerPage

        values.payType = ["rabbit","RABBIT","linepay","LINEPAY","LINECREDIT","cash","CASH"]
        values.type = ["Sale"]
        values.rateMDR =  window.rateMDRCash
        values.Transaction =" Usage Fee (Charged Fee) “Pay by "
        values.SP = "xxx"
        values.query = { 
          $and:[
            {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
            {$or:branchIDs}
          ]
        }
      break;
      case "topUp":
        //header = ["Txn Type","Volume","Amount(value)","Rate","Fee Amount","Vat","WHT","Net Fee", "Net Amount"] 
        header = ["Item"," ","Date/Time","Type of Top-Up","Product","Amount(Value)", "Paid(Value)", "Change", "Rate", "Fee Amount", "Vat", "WHT", "Net Fee", "Net Amount","CARD NO/REF NO"]
        this.setState({header:header})
        this.setState({value:[["-","-","-","-","-","-","-","-","-","-","-"]]})
        this.setState({sum:["","","","","","","","","","",""]})
        values.projection = {date:1,name:1,type:1,payType:1,payValue:1,value:1,branch:1,receiptNO:1}
        values.collections=dataCallback.OrganizationSelect+'_Transaction'
        values.countLimit = this.state.countPerPage
        values.countSkip = 0
        values.header = header;

        values.branchID = dataCallback.BranchidSelect;
        values.project = dataCallback.ProjectSelect;
        values.organization = dataCallback.OrganizationSelect;
        values.startDate = dataCallback.startDate;
        values.endDate = dataCallback.endDate;
        values.paginationActive = 1
        values.countPerPage = this.state.countPerPage

        values.payType = ["rabbit","RABBIT","linepay","LINEPAY"]
        values.type = ["Top-Up"]
        values.rateMDR =  window.rateMDRCash
        values.Transaction =" Usage Fee (Charged Fee) “Pay by Cash” "
        values.SP = "xxx"
        values.query = { 
          $and:[
            {date: {$gte: dataCallback.startDate, $lt:dataCallback.endDate }},
            {$or:branchIDs}
          ]
        }
      break;
    }

    // values.branchID = []
    values.organization = dataCallback.OrganizationSelect;
    //+-console.log("values ->",values)
    //console.log("token ->",token)
    //+-console.log("url -->",urls)
    //console.log("this.state.user.token -->",THIS.state)
    let valuesNolimit = JSON.parse(JSON.stringify(values))
    valuesNolimit.countLimit = 0
    
    let parameters = {
      urls: this.state.urls,
      token : users.token,
      values:valuesNolimit,
      nameOfReport : this.state.title+'_'+dataCallback.CollectionsSelect.label,
      headerShow : header,
      method: "post"
    } 

    this.setState({"parameters":parameters})

    axios({
      method: 'post',
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: urls,
      data: values,
      headers:{
        "Authorization":token
      }
      //withCredentials: true
    })
    .then(function (response) {
      //+-console.log("response -->",response);
      let resultAll = []
      if(response.data.value) resultAll = response.data.value
      THIS.setState({
        "resultAll": resultAll,
        "sum": response.data.sum,
        "sumLinepay":response.data.sumLinepay,
        "sumRabbit":response.data.sumRabbit,
        "sumCash":response.data.sumCash,
        "total":response.data.total,
        "foottersummary":response.data.foottersummary,
        "isButtonDisabled": false
      }, () =>{
        THIS.paginationItemCal(resultAll,THIS.state.paginationActive)
      })
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }
}

paginationActive(e) {
  let dataShow = this.state.resultAll
  this.paginationItemCal(dataShow,e)
}

paginationItemCal(dataIn,paginationActive) {
  //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
  let page = this.state.countPerPage
  let pageCount = Math.ceil(dataIn.length / page)
  let tmppaginationActive = this.state.paginationActive 
  let paginationItem = []

  switch (paginationActive){
    case '>':
        tmppaginationActive++;
    break;
    case '<':
        tmppaginationActive--;
    break;
    case '>|':
        tmppaginationActive = pageCount;
    break;
    case '|<':
        tmppaginationActive  = 1;
    break;
    default:
        tmppaginationActive = paginationActive>>0
    break;
  }

  if((tmppaginationActive > pageCount) && (pageCount != 0)){
    tmppaginationActive = pageCount;
  }

  if(pageCount < 6){
    for(let i = 1; i <= pageCount; i++){
        paginationItem.push(i)
    }
  }else{
    if(tmppaginationActive < 4){
      for(let i = 1; i <= pageCount; i++){
        if(i < 6){
          paginationItem.push(i)
        }else if(i == 6){
          paginationItem.push(">")
        }else if(i == 7){
          paginationItem.push(">|")
        }else{
          break;
        }  
      }
    }else if(tmppaginationActive > (pageCount-3)){
      for(let i = 1; i < 8; i++){
        if(i > 2){
          paginationItem.push((pageCount - (7-i)))
        }else if(i == 2){
          paginationItem.push("<")
        }else if(i == 1){
          paginationItem.push("|<")
        }else{
          break;
        }  
      }  
    }else{
      for(let i = 1; i < 10; i++){
        switch(i){
          case 1:
            paginationItem.push("|<")
          break;
          case 2:
            paginationItem.push("<")
          break;
          case 3:
            paginationItem.push(tmppaginationActive-2)
          break;
          case 4:
            paginationItem.push(tmppaginationActive-1)
          break;
          case 5:
            paginationItem.push(tmppaginationActive)
          break;
          case 6:
            paginationItem.push(tmppaginationActive+1)
          break;
          case 7:
            paginationItem.push(tmppaginationActive+2)
          break;
          case 8:
            paginationItem.push(">")
          break;
          case 9:
            paginationItem.push(">|")
          break;
        }
      }  
    }
  }

  let dataShow = []
  if(dataIn.length){
    for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
      dataShow.push(dataIn[i])
      //console.log("i=",i)
    }
  }

  let showTotal = []
  let showSummary = []
  let sumShow = []
  let sumLinepayShow = []
  let sumRabbitShow = []
  let sumCashShow = []

  if(tmppaginationActive == paginationItem[paginationItem.length-1]){
    showTotal = this.state.total
    showSummary = this.state.foottersummary
    sumShow = this.state.sum
    sumLinepayShow = this.state.sumLinepay
    sumRabbitShow = this.state.sumRabbit
    sumCashShow = this.state.sumCash
  }

  this.setState({
    dataShow: dataShow,
    showTotal: showTotal,
    showSummary: showSummary,
    sumShow: sumShow,
    sumLinepayShow: sumLinepayShow,
    sumRabbitShow: sumRabbitShow,
    sumCashShow: sumCashShow,
    paginationItem: paginationItem,
    paginationActive: tmppaginationActive
  })
}

  
  componentDidMount() {
    let start = new Date()
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});
    if(!(Object.entries(this.state.users).length === 0)){
      let users = this.state.users
      let organization = []
      for(var i = 0; i < users.organization.length; i++){
        organization.push({label:users.organization[i],value:users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let project = [{label:"All",value:"All"}]
      for(var i = 0; i < users.project.length; i++){
        if(organization[0].value == users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      this.setState({optionProjects:project});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < users.branch.length; j++){
        branchID.push({label:users.branch[j].branchID,value:users.branch[j].branchID});
      }
      this.setState({optionBranchs:branchID});

      let projectSelect = ''
      if(this.state.users.ProjectSelect !== undefined){
        projectSelect = this.state.users.ProjectSelect
      }else{
        if(project.length){
          projectSelect = project[0].value
        }
      }

      this.setState({optionProjects:project});

      let BranchsSelect = []
      let BranchidSelect = []
      for(let i = 0; i < this.state.users.branch.length; i++){
        if(projectSelect === this.state.users.branch[i].projectName){
          for(let j = 0; j < this.state.users.BranchidSelect.length; j++){
            if(this.state.users.BranchidSelect[j] === this.state.users.branch[i].branchID){
              BranchsSelect.push(this.state.users.branch[i])
              BranchidSelect.push(this.state.users.branch[i].branchID)
            }
          }
        }
      }

      let parameters = {
        urls: this.state.urls,
        token : users.token,
        values:{},
        nameOfReport : this.state.title,
        headerShow : this.state.headerShow
      } 
      this.setState({"parameters":parameters})


      let dataCallback = {
        BranchsSelect: BranchsSelect, 
        BranchidSelect: BranchidSelect,
        ProjectSelect : users.ProjectSelect,
        OrganizationSelect: users.OrganizationSelect,
        startDate: start,
        endDate: end,
        CollectionsSelect: this.state.Collections[0].value
        // CollectionsSelect2: window.transactionsPageList.payType[0],
        // CollectionsSelect3: window.transactionsPageList.sorting[0]
      }
      this.getbranchs(dataCallback)
      
      //+-console.log("optionBranchs =>",branchID)
      //+-console.log("optionProjects =>",project)
      //+-console.log("optionOrganization =>",organization)
    }
  }

  render() {
    let props = storeGlobal.getprops();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    //+-console.log("Products user -->",users)
    //+-console.log("Products branchs -->",this.state.branchs)
    //+-console.log("Products branchs -->",this.state.organization)

    return (
      <SiteWrapper user={this.state.users} props = {this.props}>
        <Page.Content title={this.state.title}>
        {(this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              Collections = {this.state.Collections}
              labelSelectName = {"Report Type"}
            />
          }
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>{this.state.title} : {this.state.brabchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title}</Card.Title>
                  <Card.Options>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                      <ButtonExport
                        parameters = {this.state.parameters}
                        isButtonDisabled = {this.state.isButtonDisabled}
                      >
                      </ButtonExport>
                    }
                  </Card.Options>
                </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.header.map( (item,index) =>
                    <Table.ColHeader key={index} className="text-center">{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.dataShow.length ? 
                      this.state.dataShow.map( (items, indexs) =>
                        <Table.Row key={indexs}>
                        {items.map((item, index) =>
                            (index < 5 ? <Table.Col key={index}>{item}</Table.Col>: <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                        </Table.Row>
                      ) : <Table.Row > 
                          <Table.Col colSpan="12"	alignContent="center">
                            <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                          </Table.Col>
                        </Table.Row>
                    }
                    {this.state.showTotal &&
                      <Table.Row > 
                        {this.state.showTotal.map((item,index) => 
                          (index < 5 ? <Table.Col key={index}  >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    {this.state.showSummary &&
                      <Table.Row > 
                        {this.state.showSummary.map((item,index) => 
                          (index < 5 ? <Table.Col key={index}  >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    {this.state.sumRabbitShow &&
                      <Table.Row > 
                        {this.state.sumRabbitShow.map((item,index) => 
                          (index < 5 ? <Table.Col key={index} >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    {this.state.sumLinepayShow &&
                      <Table.Row > 
                        {this.state.sumLinepayShow.map((item,index) => 
                          (index < 5 ? <Table.Col key={index} >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    {this.state.sumCashShow &&
                      <Table.Row > 
                        {this.state.sumCashShow.map((item,index) => 
                          (index < 5 ? <Table.Col key={index} >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    {this.state.sumShow &&
                      <Table.Row > 
                        {this.state.sumShow.map((item,index) => 
                          (index < 5 ? <Table.Col key={index} >{item}</Table.Col> : <Table.Col key={index} className="text-right">{item}</Table.Col>)
                        )}
                      </Table.Row>
                    }
                    <Table.Row > 
                      <Table.Col colSpan="15"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
      </SiteWrapper>
    );
  }
}

export default SettelmentCashReports