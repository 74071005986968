import React,{Component} from 'react'
import {  Page,  Card,  Button, Form } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert'
import storeGlobal from "../components/globalStore";
import { Redirect } from "react-router-dom";
import moment from 'moment'
import DatePicker from "react-datepicker";

//import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class JournalLog extends Component{
  constructor(props) {
    super(props);
    this.state = {
      user: storeGlobal.getusers(),
      branchs: storeGlobal.getusers().branchID || [], //['001', '002'],
      branchID: storeGlobal.getusers().branchID[0],
      usersRes: [],
      branchsPage: [],
      userEdit: null,

      modalAddbranchShow: false,
      modalWorning:'',
      modalForm: [
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input' }, 
        { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' }, 
        { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input' }, 
        { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select', items: [
          'p1', 'p2', 'p3',
        ] }, 
      ],

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      branchsPageLimit: 10,

      redirect: false,
      location: {},
      alertShow: false,
      alertText: '',

      searchText: '',
      searchButton: true,

      journalLog: '...',
      startDate: new Date(new Date().getTime()-(5*60*1000)),
      endDate: new Date(), 
    }
  }

  componentDidMount() {
    this.setState({title: 'company'});
    let user = this.state.user
    // console.log('user -->', user)
    if(JSON.stringify(user) !== '{}'){
      let THIS = this
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + user.OrganizationSelect + '/branch',
        data: 'values',
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response) {
        //console.log('get branch response =>',response)
        if(response.data.value){
          let branch = response.data.value
          //console.log('branch =>',branch)
          let branchID = []
          for(let i = 0; i < branch.length; i++){
            if(branch[i].approved){
              branchID.push(branch[i].branchID)
            }
          }
          //console.log('branchID =>',branchID)
          THIS.setState({
            branchs:  branchID,
            branchID : branchID[0]
          })
        }
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      })
    }


    //console.log('this.state 1 -->', this.state)
    //console.log('window.url -->', window.url)

  }

  handleClick(e) {
    console.log('this ->', e)
  }

  handleChangeStart = (date) => {
    console.log('handleChangeStart ->', date)
    this.setState({
      startDate: date,
      endDate: new Date(date.getTime()+(5*60*1000))
    })
  }

  handleChangeEnd = (date) => {
    console.log('handleChangeEnd ->', date)
    this.setState({endDate: date})
  }

  handleChangeSelect = (e) => {
    const branchID = e.target.value
    console.log('handleChangeSelect ->', branchID)
    this.setState({branchID})
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.branch}"
            </SweetAlert>
          )
        }));
        break
        default:
        break
    }
		
  } 

  getBranchs = () => {
    console.log("getbranchs ...");
    
    let THIS = this
    THIS.setState({searchButton: false, journalLog : '...',})

    const startDate = moment(this.state.startDate).format('YYYY-MM-DD HH:mm')
    const endDate = moment(this.state.endDate).format('YYYY-MM-DD HH:mm')

    console.log("getbranchs ...", startDate, endDate, this.state.branchID);
    axios({
      method: 'post',
      // url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/' + 'branch' + '?_show_not=products',
      url: window.url + 'api/v1/websocket/' + this.state.user.OrganizationSelect,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
      data: {branchID: this.state.branchID, 
        start: startDate, 
        end: endDate 
      },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // console.log('response.data.value =>', response.data.value);  
      // return
      //let branchs = response.data.value
      console.log('=====> response.data', response.data); 
      
      THIS.setState({
        // branchs: branchs,
        // usersRes: branchs,
        searchButton: true,
        journalLog : response.data.value,
      },() => {
   

      });
    }) 
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.response.data,
        searchButton: true,
      })
      console.log('axios error =>',error.message);
      console.log('axios error =>',error.response);
    });
  }
  
  //=========================================================================== render()
  render() {
    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content 
          title="Vending Log"
         
        >
          <Card>
            <Card.Header>
              <Card.Title>Select..</Card.Title>
              <Card.Options>
                <Form className="form-inline">
                  <Form.Group label="Branch ID" >
                    <Form.Select className="ml-2 mr-4" onChange={this.handleChangeSelect}>
                      { 
                        // this.state.branchs.map( (branchID, i) => <option key={i}> {branchID} </option>)
                        this.state.user.branch.map( (branch, i) => (window.notJournalLog.indexOf(branch.branchType) < 0) && <option key={i}> {branch.branchID} </option>)
                      }                                 
                    </Form.Select>
                  </Form.Group>
                </Form>
                <Form className="form-inline">
                  <Form.Group label="Start date" >
                    <DatePicker
                      selected={this.state.startDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={this.handleChangeStart}
                      className="form-control ml-2 mr-4"
                    />
                  </Form.Group>
                </Form>
                <Form className="form-inline">
                  <Form.Group label="End date" >
                    <DatePicker
                      selected={this.state.endDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={this.handleChangeEnd}
                      className="form-control ml-2 mr-4"
                      minDate={this.state.startDate}
                      maxDate={this.state.startDate}
                      minTime={this.state.startDate}
                      maxTime={new Date(this.state.startDate).setMinutes(this.state.startDate.getMinutes()+10)}
                    />
                  </Form.Group>
                </Form>
                <Button.List className="ml-2">                                
                  { this.state.searchButton ? 
                    <Button 
                      color="red"                  
                      onClick={this.getBranchs }
                    > Get log </Button>  
                    : <Button color="default" icon="" loading > Get log </Button>
                  }
                </Button.List>              
              </Card.Options>
            
            </Card.Header>
            <Card.Body>
            {/* <Form.Group label={<Form.Label aside="56/100">Textarea</Form.Label>}> */}
            <Form.Group >
              <Form.Textarea
                // defaultValue="..."
                name="example-textarea"
                placeholder="Content.."
                rows={20}
                value={this.state.journalLog}
              />
            </Form.Group>
            </Card.Body>
          </Card>

        </Page.Content>

        {this.state.alert}

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
       
      </SiteWrapper>   
      
    )
    
  }
}

export default JournalLog;