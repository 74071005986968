// @flow
import React,{Component} from 'react'
import { Page, Grid, Card, Text, Table, Button, Form } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
//import ModalAddUser from "../components/ModalAddUser";
//import ModalFormLarge from "../components/ModalFormLarge";
import ModalFormLarge from "../components/ModalFormImage";
//import ModalFormLarge from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
// import { threadId } from 'worker_threads';


class CampaignMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),

      modalWorning:'',
      modalFormTitle:"",
      header:[
        {"name":"ITEM", "class":"text-center"},
        {"name":"IMAGE", "class":"text-center"},
        {"name":"NAME", "class":"text-center"},
        {"name":"ACTIVE DATE", "class":"text-center"},
        {"name":"EXPIRE DATE", "class":"text-center"},
        {"name":"STATUS", "class":"text-center"},
        {"name":"ACTION", "class":"text-center"}
      ],
      tempModalForm:{},
      modalForm: [
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'image', col: '2' }, 
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select',col: '2', items: [
          'p1', 'p2', 'p3'
        ] }, 
      ],

      alert: null,
      paginationItem: [1],
      paginationActive: 1,
      countPerPage:10,
      organizItems:[],
      projectItems:[],
      dataResponse:{products:[]},
      dataShow:[],

      redirect: false,
      location: {},

      btDeployDisnabled: true,

      defaultFileUpload:{
        organization:'',
        uri:window.url + 'upload',
        type:'campaigns',
        filetype:['image/png', 'image/jpeg']
      },
      isFilePondEnable: true,
      alertShow: false,
      alertText: '',

      usersRes: [],
      searchText: '',
      searchButton: true,
      deployText: 'Synchronize',
      syncText: 'Success',

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization"
    };

    // this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){

      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: this.state.user.OrganizationSelect,
      }

      this.setState({
        OrganizationSelect: users.OrganizationSelect,
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },
        this.getbranchs(dataCallback)
      )
    }
  }

  getbranchs = (dataCallback) => {
  //console.log('props -->', this.props)
  let users = this.state.user
  if(!(Object.entries(users).length === 0)){
      let THIS = this
      let organization = dataCallback.OrganizationSelect
      let collection = 'campaigns'
      //let id = this.props.location.state.id
      this.state.defaultFileUpload['organization'] = organization 
      this.setState({defaultFileUpload:this.state.defaultFileUpload})
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + organization + '/' + collection,
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("response -->",response.data);
        THIS.setState({
          dataResponse: response.data.value,
          usersRes: response.data.value,
        })
        let products = response.data.value
        let paginationActive = THIS.state.paginationActive
        THIS.paginationItemCal(products,paginationActive)

        // check status for enable deploy button
        for(let i=0; i<products.length; i++) {
          if(products[i].status != THIS.state.deployText && products[i].status != THIS.state.syncText) {
            THIS.setState({
              btDeployDisnabled: false
            })
            break
          }
        }

      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
    this.setState(state => ({
      alert: null
    }));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={ ()=> {
                  this.deleteDb(e,(fn) =>{ 
                    if(fn) console.log('delete error ->',fn) 
                  })
                } 
              } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.name}"
            </SweetAlert>
          )
        }));
        break
    }
    
  } 
  
  deleteDb = (dataIn,fn) => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    //+-console.log('deleteDb dataIn ->', dataIn);  
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'campaigns'
    let id = dataIn.id
    let THIS = this
    let values =  { user: this.state.user }
    //+-console.log('deleteDb values ->', values); 
    //fn(null)
    THIS.hideAlert()
    axios({
      method: 'delete',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("deleteDb response =>", response.data); // {oganization:'server',value:{n: 1, ok: 1}}
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'campaignMan',
        "category" : 'webApp',
        "even" : 'Delete campaigns',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete campaign =>' + dataIn.name
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse
      products = products.filter(person => person._id != dataIn.id)
      THIS.setState({ 
        dataResponse: products
      },() => {        
        let paginationActive = THIS.state.paginationActive
        THIS.paginationItemCal(products,paginationActive)
      })
      //THIS.hideAlert()
      fn(null)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "insert error!"})
      //THIS.hideAlert()
      fn(error)
    });
  }

  insertDb = (dataIn,fn) => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    //console.log('insertDb dataIn ->', dataIn);  
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'campaigns'
    let THIS = this
    let values = dataIn
        values.createBy = this.state.user.username
        values.updateBy = this.state.user.username
    //console.log('insertDb values ->', values); 
    //fn(null)
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("insert response =>", response.data); // {oganization:'server',value:[obj]}
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'campaignMan',
        "category" : 'webApp',
        "even" : 'New campaign',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Add new campaign =>' + values.name
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse
      products.push(response.data.value[0])
      //--------------------------cerren last page
      THIS.setState({ 
        dataResponse: products
      },() => {        
        let page = THIS.state.countPerPage
        let pageCount = Math.ceil(products.length / page)
        //console.log('paginationActive ->',pageCount)
        THIS.paginationItemCal(products,pageCount)
      })
      fn(null)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "insert error!"})
      fn(error)
    });
  }

  updateDb = (dataIn,fn) => {
    const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    //console.log('updateDb dataIn ->', dataIn);  
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'campaigns'
    let id = dataIn._id
    let THIS = this
    let values = JSON.parse(JSON.stringify(dataIn));
        values.createBy = this.state.user.username
        values.updateBy = this.state.user.username
    delete values._id
    //console.log('updateDb values ->', values);
    //console.log('updateDb dataIn2 ->', dataIn); 
    //fn(null)
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("updateDb response =>", response.data); // {n: 1, nModified: 1, ok: 1}
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'campaignMan',
        "category" : 'webApp',
        "even" : 'Edit campaign',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Edit campaign =>' + values.name
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse
      let newDataIn =[dataIn]
      var newProducts = products.map(obj => newDataIn.find(o => o._id === obj._id) || obj);
      //console.log('res ->',newProducts)
      THIS.setState({ 
        dataResponse: newProducts
      },() => {        
        let paginationActive = THIS.state.paginationActive
        THIS.paginationItemCal(newProducts,paginationActive)
      })
      fn(null)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "updateDb error!"})
      fn(error)
    });
  }
  
  paginationActive(e) {
    let dataShow = this.state.dataResponse
    this.paginationItemCal(dataShow,e)
  }

  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.countPerPage
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i == 6){
            paginationItem.push(">")
          }else if(i == 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i == 2){
            paginationItem.push("<")
          }else if(i == 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataIn[i].index = i+1
        dataShow.push(dataIn[i])
      }
    }

    this.setState({
      dataShow: dataShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddbranchShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    //+-console.log('getForm modalForm =>', this.state.modalForm);    
    let dataEdit = this.state.tempModalForm
    for(let k=0; k<this.state.modalForm.length; k++) {
      if(this.state.modalForm[k].value === '') {
        return this.setState({modalWorning: "Input is blank!"})
      }
      dataEdit[this.state.modalForm[k].key] = this.state.modalForm[k].value
      if(this.state.modalForm[k].key == 'image'){
        dataEdit['path']= this.state.modalForm[k].path 
      }
    }
    if(dataEdit.status == 'New campaign'){
      this.insertDb(dataEdit,(calback) =>{
        if(calback) console.log('insertDb error ->',calback)
        this.modalClose()
      })
    }else{
      this.updateDb(dataEdit,(calback) =>{
        if(calback) console.log('updateDb error ->',calback)
        this.modalClose()
      })
    }
  }

  edit = (item) => {    
    //+-console.log('addBranch item =>',item);
    item.status = 'editProduct'
    this.state.tempModalForm =  JSON.parse(JSON.stringify(item))
    this.state.modalFormTitle = "Edit Product"
    this.state.modalForm = [
      { key: "image", name: "image", placeholder:"", value: item.image, disabled: window.modalFormImageConfig.modifyProductCampaign.disableImage, type: 'image', col: '1' }, 
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: window.modalFormImageConfig.modifyProductCampaign.disableName, type: 'input', col: '2' }, 
      { key: "price", name: "Price", placeholder:"", value: item.price, disabled: window.modalFormImageConfig.modifyProductCampaign.disablePrice, type: 'input', col: '2' }, 
      // { key: "group", name: "Group", placeholder:"", value: item.group, disabled: false, type: 'input', col: '2' }, 
      // { key: "channel", name: "Channel", placeholder:"", value: item.channel, disabled: true, type: 'input', col: '2' }, 
      // { key: "count", name: "Cerrent Value", placeholder:"", value: item.count, disabled: true, type: 'input', col: '2' }, 
      // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
      // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
    ]
    // console.log('this.state.modalForm =>', this.state.modalForm);
    this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
  }

  add = () => {    
    //console.log('addBranch item =>');
    this.state.tempModalForm = {
      name : "",
      image : "new-campaign-red.jpg",
      path :"",
      activeDate:"",
      expireDate:"",
      status : "New campaign",
      products:[],
      branchID:[]
    }
    this.state.modalFormTitle = "New Campaign"
    this.state.modalForm = [
      { key: "image", name: "image", placeholder:"", value: "new-campaign-red.jpg", disabled: false, type: 'image', path:'/campaigns/', col: '1' }, 
      { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
      { key: "activeDate", name: "Active Date", placeholder:"", value: "", disabled: false, type: 'date', col: '2' }, 
      { key: "expireDate", name: "Expire Date", placeholder:"", value: "", disabled: false, type: 'date', col: '2' }, 
      // { key: "channel", name: "Channel", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
      // { key: "count", name: "Cerrent Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
      // { key: "max", name: "Max Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
    ]
    // console.log('this.state.modalForm =>', this.state.modalForm);
    //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
    this.setState({ 
      modalForm: this.state.modalForm,
      modalFormSave: this.state.modalForm,
      modalFormTitleSave: this.state.modalFormTitle,
      modalAddbranchShow: true 
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("handleChange event  =>", event.target);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    for(let i=0; i<this.state.modalForm.length; i++) {
      if(this.state.modalForm[i].name == name) {
        this.state.modalForm[i].value = value
        break
      }
    }

    this.setState({modalForm: this.state.modalForm})
  }

  handleInputChangeMain = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("handleChange event  =>", event.target);
    this.state.dataResponse[name] = value
    this.setState({dataResponse: this.state.dataResponse})
  }

  detailCampaign = (item) => {
    // console.log('detailBranch item =>', item);
    let organizationDefault = {label:this.state.user.OrganizationSelect, value:this.state.user.OrganizationSelect}
    let ProjectDefault = [{label:"All", value:"All"}]
    let location = {
      pathname: '/detailcampaign',
      state: { 
        id: item._id, 
        organization: organizationDefault,
        project: ProjectDefault
      }
    }
    
    this.state.location = location
    this.setState(state => ({
			redirect: true
		}));
  }

  selectFileClick = (dataIn) => {
    //+-console.log("selectFileClick =>",dataIn)
    let THIS = this
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/' + 'upload'+'?_sortNatural=-1'+'&type=campaigns',
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("response -->",response.data);
      if(response.data.value.length){
        THIS.state.modalFormTitle = "Select campaign"
        THIS.state.modalForm = []
        for(let i = 0; i < response.data.value.length; i++){
          let item = response.data.value[i] 
          let tempObj =  JSON.parse(JSON.stringify(item))
            tempObj.type = 'imageSelect'
            tempObj.path = tempObj.path
            tempObj.value = tempObj.image
            tempObj.col = 1
            tempObj.cardSelect = false
            if(dataIn.value === tempObj.fileName){
              tempObj.cardSelect = true
            }
            THIS.state.modalForm.push(tempObj)
        }
        //+-console.log("modalForm =>",THIS.state.modalForm)
        THIS.setState({modalForm:THIS.state.modalForm},() =>{
          THIS.setState({modalAddbranchShow:true})  
        })
      }
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }

  //imageOnclicks = (dataIn) => {
  //   console.log('dataIn.cardSelect =>',dataIn.cardSelect)
  //   console.log('tempModalForm =>',this.state.modalFormSave)
  //   if((dataIn.cardSelect === true) && (this.state.modalFormTitle === "Select campaign")){
  //     this.modalClose()
  //     let dataEdit = this.state.modalFormSave
  //     //let dataEdit = this.state.modalForm
  //     for(let k=0; k < dataEdit.length; k++) {
  //       if(dataEdit[k].key == 'image'){
  //         dataEdit[k].value= dataIn.fileName
  //         dataEdit[k].path= dataIn.path 
  //       }
  //       if(dataEdit[k].key == 'name'){
  //         let name  = dataIn.fileName.split('.');
  //         dataEdit[k].value= name[0]
  //       }
  //     }

  //     console.log('dataEdit =>',dataEdit)
  //     this.setState({ 
  //       modalFormSave: dataEdit,
  //       modalForm: dataEdit, 
  //       modalFormTitle: this.state.modalFormTitleSave,
  //       modalAddbranchShow: true
  //     })
  //   }else if(this.state.modalFormTitle === "Add Products"){
  //     for(let i=0; i<this.state.modalForm.length; i++) {
  //       if(this.state.modalForm[i]._id == dataIn._id) {
  //         this.state.modalForm[i].cardSelect = dataIn.cardSelect
  //         break
  //       }
  //     }
  //     this.setState({modalForm: this.state.modalForm})
  //   }
  // }

  imageOnclicks = (dataIn) => {
    //+-console.log('dataIn.cardSelect =>',dataIn.cardSelect)
    //+-console.log('tempModalForm =>',this.state.modalFormSave)
    if(dataIn.cardSelect === true){
      this.modalClose()
      let dataEdit = this.state.modalFormSave
      //let dataEdit = this.state.modalForm
      for(let k=0; k < dataEdit.length; k++) {
        if(dataEdit[k].key == 'image'){
          dataEdit[k].value= dataIn.fileName
          dataEdit[k].path= dataIn.path 
        }
        if(dataEdit[k].key == 'name'){
          let name  = dataIn.fileName.split('.');
          dataEdit[k].value= name[0]
        }
      }
      //+-console.log('dataEdit =>',dataEdit)
      this.setState({ 
        modalFormSave: dataEdit,
        modalForm: dataEdit, 
        modalFormTitle: this.state.modalFormTitleSave,
        modalAddbranchShow: true
      })
    }
  }

  // detailCampaign = (item) => {
  //   //calculate your data here
  //   //then redirect:
  //   let value = {
  //     //urls:"http://13.251.52.58:3001/api/v1/report/best-product",
  //     urls: window.url + "api/v1/report/best-product",
  //     title:"Detail best seller",
  //     header:["PRODUCT","TRANSACTION","AMOUNT"],
  //     defaultValue:[['-','-','-']],
  //     defaultSum:['','',''],
  //     countPerPage:20,
  //     BranchsSelect: ['kserv'],
  //     BranchidSelect:['0184'],
  //     OrganizationSelect:"ppetech"
  //   }

  //   let location = {
  //     pathname: '/detaildashboard',
  //     state: {
  //       dataSend: value
  //     }
  //   }

  //   this.state.location = location
  //   this.setState(state => ({
	// 		redirect: true
	// 	}));
  // }
  
  deploy = () => {      
    let dataResponse = this.state.dataResponse
    let deployDatas = [] //
    for(let i=0; i<dataResponse.length; i++) {
      if(dataResponse[i].status != this.state.deployText && dataResponse[i].status != this.state.syncText) {
        dataResponse[i].status = this.state.deployText
        deployDatas.push({
          _id: dataResponse[i]._id,
          status: dataResponse[i].status,
          updateBy: this.state.user.username,
          branchID: dataResponse[i].branchID,
          name: dataResponse[i].name,
        })
      }
    } 
    // console.log('deploy deployDatas =>', deployDatas); return
    let paginationActive = this.state.paginationActive
    this.paginationItemCal(dataResponse, paginationActive)
    //+-console.log('deploy deployDatas =>', deployDatas);     
    this.setState({
      btDeployDisnabled: true
    })

    let branchs = []
    for(let i=0; i<deployDatas.length; i++) {
      for(let j=0; j<deployDatas[i].branchID.length; j++) {
        branchs.push({
          name: deployDatas[i].name, 
          type: 'campaign',
          status: this.state.deployText,
          branchID: deployDatas[i].branchID[j],
          deployID: deployDatas[i]._id,
          updateBy: this.state.user.username,
          deployTime: (new Date()).getTime(),

        })
      }
    }
    //+-console.log('branchs =>', branchs);
    // return 
    // Get all branchID
    let THIS = this
    // Insert to deploy collections
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy' + '/many', 
      data: branchs,
      headers:{
        "Authorization": THIS.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Insert deploy response =>", response); 
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'campaignMan',
        "category" : 'webApp',
        "even" : 'Deploy campaigns',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Deploy campaigns'
      }
      THIS.saveLog(dataSave)

      delete deployDatas.branchID
      axios({
        method: 'patch',
        url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/campaigns' , 
        data: deployDatas,
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("Insert deploy response =>", response); 
        
      })
      .catch(function (error) {
        console.log('error ->', error);
        THIS.setState({modalWorning: "Insert deploy error!"})
        THIS.setState({
          btDeployDisnabled: false
        })
      });
    })
    .catch(function (error) {
      console.log('error ->', error);
      THIS.setState({modalWorning: "Insert deploy error!"})
      THIS.setState({
        btDeployDisnabled: false
      })
    });

  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  onSearch = () => {
    console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
      key=='price'? obj[key]=obj[key]+'' : obj[key]=obj[key]
      return key!='_id' && typeof(obj[key])=='string' && key!='updateBy' && key!='updateDate' &&  obj[key].includes(search) 
    }))
    this.setState({searchButton: false, branchs: res}, () => {
      let products = res
      let paginationActive = this.state.paginationActive
      this.paginationItemCal(products, paginationActive)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      let products = this.state.usersRes
      let paginationActive = this.state.paginationActive
      this.paginationItemCal(products, paginationActive)
    })          
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddUserShow: false });
    //console.log('dataResponse ->',this.state.dataResponse);
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )
     
    return (     
      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content title="Campaign manager"
          options={ this.state.usersRes.length &&
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>            
          }
        >
        { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
          <Grid.Row>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Campaign</Card.Title>
                  { this.state.user.level=='write' && <Card.Options>
                    <Button.List>
                    { this.state.btDeployDisnabled ?                 
                      <Button 
                        color="secondary"  
                        disabled           
                      > Deploy </Button> 
                      :        
                      <Button 
                        color="green"                           
                        onClick={ () => this.deploy(this) }
                      > Deploy </Button>
                    }   
                      <Button 
                        color="green"                  
                        onClick={ () => this.add() }
                      > 
                        New Campaign
                      </Button>    
                    </Button.List>            
                  </Card.Options>
                  }
                </Card.Header>
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap table-hover "
                  >
                    <Table.Header>
                      <Table.Row>
                        {this.state.header.map( (item, index) =>
                          <Table.ColHeader key={index} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                        )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      { this.state.dataShow.length ? this.state.dataShow.map( (item, i) =>
                          <Table.Row key={ i }>
                            <Table.Col className="text-center align-middle">
                              {/* { i+1} */}
                              { item.index }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <img 
                                alt=""
                                src={item.path}
                                //src={"/campaigns/"+item.image}
                                // "/products/คาราบาวแดง.png"
                                className="h-8"
                              />
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              {item.name}
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <Moment format="DD/MM/YYYY HH:mm">
                                { item.activeDate }
                              </Moment>
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <Moment format="DD/MM/YYYY HH:mm">
                                { item.expireDate }
                              </Moment>
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.status }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <Button.List>
                                <Button size="sm" color="info"
                                  onClick={ () => this.detailCampaign(item) }
                                  >
                                  Detail
                                </Button>
                                { this.state.user.level=='write' && <Button size="sm" color="danger" 
                                  onClick={ ()=> { this.alert({action:'delete', id: item._id, name: item.name, i: i }) } } >
                                  Delete
                                </Button> 
                                }
                              </Button.List> 
                            </Table.Col>
                          </Table.Row>
                        ): <Table.Row > 
                            <Table.Col colSpan="12"	alignContent="center">
                              <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                            </Table.Col>
                          </Table.Row>
                        }
                      <Table.Row > 
                        <Table.Col colSpan="12"	alignContent="center">
                          <div style={paginationStyle}>
                            <Pagination>
                              { this.state.paginationItem.map( number =>
                                <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                  {number} 
                                </Pagination.Item>
                              )}
                            </Pagination>
                          </div>      
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
        
        <ModalFormLarge
          show={this.state.modalAddbranchShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalFormTitle}
          worning={this.state.modalWorning}
          form={this.state.modalForm}

          imageOnclicks = {this.imageOnclicks}
          selectFileClick = {this.selectFileClick}
          defaultFileUpload = {this.state.defaultFileUpload}
          isFilePondEnable={this.state.isFilePondEnable}
        />

        {this.state.alert}

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>

      </SiteWrapper>   
    )
  }
}

export default CampaignMan;