// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import {Redirect } from "react-router-dom"
import { Redirect} from "react-router-dom";

import {
  Page,
  Grid,
  Card,
  Table,
  colors,
  Button,
  StampCard,
  Badge,
} from "tabler-react";
  
import C3Chart from "react-c3js";
// import 'c3/c3.css';
import SiteWrapper from "../components/SiteWrapper.react";
//import DetailDashboard from "../components/DetailDashboard";
import SweetAlert from 'react-bootstrap-sweetalert'
//import { Icon } from 'antd';
import CountUp from 'react-countup';
import RTChart from 'react-rt-chart';
import { forEach } from 'async';

//let users = storeGlobal.getusers();
const apiNamesAll = ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction']

class Dashboard extends Component {
  constructor(props) {
    super(props);
    // let users = storeGlobal.getusers();
    const rtAmountSale = window.dashboardPage.rtAmountSale
    const rtTransactionSale = window.dashboardPage.rtTransactionSale
    const rtAmountTopup = window.dashboardPage.rtAmountTopup
    const rtAlertMessages = window.dashboardPage.rtAlertMessages
    this.state = {
      online: {
        "total": 0,
        "online": 0
      },
      drink:[[1,2,3,4],[4,3,2,1]],
      topup:[[1,2,3],[3,2,1]],
      billing:{
        "count":5,
        "totalAmount":5
      },
      top10branch:[{
          count: 0,
          totalAmount: 0,
          _id: {
            branchID: "-",
            "branchName":"-"
          }
        }
      ],
      top10product:[{
          count: 0,
          totalAmount: 0,
          _id: {name: "-"}
        }
      ],
      top10topup:[{
          count: 0,
          totalAmount: 0,
          _id: {
            branchID: "-",
            "branchName":"-"
          }
        }
      ],
      top10billing:[{
          count: 0,
          totalAmount: 0,
          _id: {
            branchID: "-",
            "branchName":"-"
          }
        }
      ],
      top10Alert:[['-','-','-']],
      //user:users,
      users :storeGlobal.getusers(),

      branchs:[],
      branchID:[],
      organization:[],

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"nodate",

      redirect: false,
      location: {},
      btLoading: false,
      alertShow: false,
      alertText: '',

      rtAmountSale: { 
        start: rtAmountSale.start, 
        end: rtAmountSale.end, 
        duration: rtAmountSale.duration
      },
      rtTransactionSale: { 
        start: rtTransactionSale.start, 
        end: rtTransactionSale.end, 
        duration: rtTransactionSale.duration
      },
      rtAmountTopup: { 
        start: rtAmountTopup.start, 
        end: rtAmountTopup.end, 
        duration: rtAmountTopup.duration
      },
      rtAlertMessages: { 
        start: rtAlertMessages.start, 
        end: rtAlertMessages.end, 
        duration: rtAlertMessages.duration
      },
      // rtTimeout: null,
      dataCallback: {},

      latestTransactionData: {
        'date': new Date(),
        'Sale': Math.floor(Math.random() * 11),
        'Top-Up': Math.floor(Math.random() * 11)
      },
      latestTransactionList: [['-','-','-','-','-','-',]], //test,
      // latestTransactionTimeout: null,
      latestTransactionEnd: new Date(),
      apiNamesAll : ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction'],
      tmpApiNamesAll : [],
      apiNames : []
      
    }

    this.rtTimeout=null
    this.latestTransactionTimeout=null

    //this.routeChange = this.routeChange.bind(this);
  }
  
  componentWillUnmount(){    
    if(this.rtTimeout != null){
      clearTimeout(this.rtTimeout)
    }
    if(this.latestTransactionTimeout != null){
      clearTimeout(this.latestTransactionTimeout)
    }
    
    //+-console.log("componentWillUnmount......", this.rtTimeout, this.latestTransactionTimeout);
  }

  componentDidMount() {
    //+-console.log("componentDidMount......");
    if(!(Object.entries(this.state.users).length === 0)){
      //this.setState({branchs:dataCallback.BranchsSelect});
      this.setState({branchID:this.state.users.branchID});
      //this.setState({ user:this.state.users});

      let organization = []
      for(var i = 0; i < this.state.users.organization.length; i++){
        organization.push({label:this.state.users.organization[i],value:this.state.users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.users.branch.length; j++){
        branchID.push({label:this.state.users.branch[j].branchID,value:this.state.users.branch[j].branchID});
      }
      this.setState({optionBranchs:branchID});
      this.setState({branchID:this.state.users.branchID});
      this.setState({branchs:this.state.users.branch})
      this.setState({organization:this.state.users.OrganizationSelect})

      // let project = [{label:"All",value:"All"}]
      let projectSelect = ""
      let project = []
      for(let i = 0; i < this.state.users.project.length; i++){
        if(this.state.users.OrganizationSelect === this.state.users.project[i].organization){
          project.push({label:this.state.users.project[i].projectName,value:this.state.users.project[i].projectName})    
        }
      }
      // console.log('project =>',project)
      if(this.state.users.ProjectSelect !== undefined){
        projectSelect = this.state.users.ProjectSelect
      }else{
        if(project.length){
          projectSelect = project[0].value
        }
      }
      this.setState({optionProjects:project});

      // let THIS = this
      // let token = users.token
      //console.log('projectSelect =>',projectSelect)

      let BranchsSelect = []
      let BranchidSelect = []
      for(let i = 0; i < this.state.users.branch.length; i++){
        if(projectSelect === this.state.users.branch[i].projectName){
          for(let j = 0; j < this.state.users.BranchidSelect.length; j++){
            if(this.state.users.BranchidSelect[j] === this.state.users.branch[i].branchID){
              BranchsSelect.push(this.state.users.branch[i])
              BranchidSelect.push(this.state.users.branch[i].branchID)
            }
          }
        }
      }

      let values = {
        organization: this.state.users.OrganizationSelect,
        branchID: BranchidSelect,
        startDate: (new Date()).setHours(0,0,0,0),
        endDate: new Date()
      }

      let dataCallback = {
        BranchsSelect: BranchsSelect, 
        BranchidSelect: BranchidSelect,
        OrganizationSelect: values.organization,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }
      //console.log('dataCallback =>',dataCallback)
      this.getbranchs(dataCallback)
      this.getLatestTransaction(dataCallback) //close
      
    }
  }

  componentWillUpdate(){
    // console.log("componentWillUpdate......");
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log("componentDidCatch =>",info.componentStack)
    //logComponentStackToMyService(info.componentStack);
  }

  getLatestTransaction = async (dataCallback) => {
    // console.log("getRealtime ...");
    // let THIS = this
    let organization =this.state.users.OrganizationSelect
    const collections = ['transaction']
    const types = ['Sale', 'Top-Up']
    let token = this.state.users.token
    let ds = this.state.latestTransactionEnd
    let de = new Date()
    this.state.latestTransactionEnd = de
    // ds.setHours(0, 0, 0, 0)
    // let branchID = []    
    let getTransaction = false
    let countValue = { sale: 0, topup: 0 }

    // let p = await Promise.all(types.map(async (type) => {
    //   let axiosReq = {}
    //   let values = { start: ds.getTime(), end: de.getTime(), branchID: dataCallback.BranchidSelect, query: { type: type} }     
    //   try {
    //     // console.log("values ->", values, types, type)
    //     axiosReq = await axios({ method: 'post', url: window.url + "api/v1/dashboard/count/"+organization+'/'+collections[0], data: values, headers:{ "Authorization": token } })
         
    //     countValue[type] = axiosReq.data.value

    //   } catch (error) {
    //     console.log(error.message);
    //     this.state.alertText = error.message
    //     return new Promise((resolve) => {
    //       resolve('error')
    //     })
    //   }
    // }))
    // // console.log("getRealtime p ->", p)
    // if(p.indexOf('error') >= 0) {
    //   this.setState({ 
    //     alertShow: true,
    //     alertText: this.state.alertText, 
    //   }) 
    // }  
    
    //+-console.log("====== getLatestTransaction 'Sale' ...")
    {
      const type = 'Sale'
      let axiosReq = {}
      let values = { start: ds.getTime(), end: de.getTime(), branchID: dataCallback.BranchidSelect, query: { type: type} }     
      try {
        // console.log("values ->", values, types, type)
        axiosReq = await axios({ method: 'post', url: window.url + "api/v1/dashboard/count/"+organization+'/'+collections[0], data: values, headers:{ "Authorization": token } })
         
        countValue[type] = axiosReq.data.value
        if(axiosReq.data.value){
          getTransaction = true
        }

      } catch (error) {
        console.log("getLatestTransaction 'Sale' error =>", error.message);
        this.state.alertText = error.message
        return
      }
    }

    //+-console.log("====== getLatestTransaction 'Top-Up' ...")
    {
      const type = 'Top-Up'
      let axiosReq = {}
      let values = { start: ds.getTime(), end: de.getTime(), branchID: dataCallback.BranchidSelect, query: { type: type} }     
      try {
        // console.log("values ->", values, types, type)
        axiosReq = await axios({ method: 'post', url: window.url + "api/v1/dashboard/count/"+organization+'/'+collections[0], data: values, headers:{ "Authorization": token } })
         
        countValue[type] = axiosReq.data.value
        if(axiosReq.data.value){
          getTransaction = true
        }

      } catch (error) {
        console.log("getLatestTransaction 'Top-Up' error =>", error.message);
        this.state.alertText = error.message
        return
      }
    }

    let latestTransactionData = {
      'date': new Date(),
      'Sale': countValue['Sale'],
      'Top-Up': countValue['Top-Up'],
    }
    this.setState({ latestTransactionData })
    //+-console.log("latestTransactionData ->", latestTransactionData)
    // console.log("latestTransactionTimeout old->", this.latestTransactionTimeout)
    if(this.latestTransactionTimeout != null){
      clearTimeout(this.latestTransactionTimeout)
    }
    this.latestTransactionTimeout = setTimeout(() => {      
      this.getLatestTransaction(this.state.dataCallback)
    }, window.dashboardPage.latestTransaction.interval * 1000); 

    if(getTransaction){
      this.setState({
        tmpApiNamesAll : ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction']
      },this.getOneApi())
    }else{
      this.setState({
        tmpApiNamesAll : ['alert', 'online']
      },this.getOneApi())
      
    }
    // console.log("latestTransactionTimeout new->", this.latestTransactionTimeout)
  }

  getOneApi = () => {
    let rtTimeout = null
    let tOut =   window.dashboardPage.latestTransaction.timeout * 1000
    let tmpApiNamesAll = this.state.tmpApiNamesAll
    //console.log('getOneApi timeOut =>',tOut)
    if(tmpApiNamesAll.length){
      //console.log('tmpApiNamesAll =>',tmpApiNamesAll)
      let apiNames = [tmpApiNamesAll[0]]
      this.setState({
        apiNames 
      })

      if(rtTimeout != null){
        console.log('clear time out')
        clearTimeout(this.rtTimeout)
      }

      rtTimeout = setTimeout(() =>{
        //console.log('begin')
        //console.log('apiNames =>',apiNames)
        this.getbranchs(this.state.dataCallback) 
        tmpApiNamesAll.splice(0, 1)
        this.setState({
          tmpApiNamesAll 
        },
          () => {if(tmpApiNamesAll.length){
            this.getOneApi()
          }else{
            this.setState({
              apiNames : []
            })  
          }
        })
      }, tOut)

    }else{
      this.setState({
        apiNames : []
      })
    }
  }
  
  getbranchs = async (dataCallback) => {
    // console.log("getbranchs ->",dataCallback);
    
    this.setState({
      branchs: dataCallback.BranchsSelect, 
      branchID: dataCallback.BranchidSelect,
      organization: dataCallback.OrganizationSelect,
      btLoading: true,
    });

    if(!(Object.entries(this.state.users).length === 0)){
      //this.setState({ user:this.state.users});
      //let THIS = this
      let token = this.state.users.token
      let values = {
        organization: dataCallback.OrganizationSelect,
        branchID: dataCallback.BranchidSelect,
        startDate: (new Date()).setHours(0,0,0,0),
        endDate: new Date(),
        sortNatural: -1,
      }
      let apiNames =[]
      if(this.state.apiNames.length){
        apiNames = this.state.apiNames
      }else{
        //apiNames = this.state.apiNamesAll
        apiNames = ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction']
        //apiNames =  ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction']
      }
      //let apiNames = ['online', 'drink', 'topup', 'billing', 'top10-drink-branch', 'top10-drink-sale', 'top10-topup-branch', 'alert', 'transaction']
      //let apiNames = ['online', 'drink'] //test
      // for(let k=0; k<apiNames.length; k++) {
      let p = await Promise.all(apiNames.map(async (apiName) => {
        let axiosReq = {}
        // let apiName = apiNames[k]
        let response = {}
        try {
          axiosReq = await axios({ method: 'post', url: window.url + "api/v1/dashboard/"+apiName, data: values, headers:{ "Authorization": token } })
          //console.log("axiosReq "+apiName+" ->", axiosReq.data.value)
          response = axiosReq
          switch(apiName) {
            case 'top10-drink-branch':
              apiName = 'top10branch'
              // response = axiosReq
              if(response.data.value.length){
                for(var i = 0; i < response.data.value.length; i++){
                  for(var j = 0;j < response.data.branchs.length; j++){
                    if(response.data.value[i]._id.branchID === response.data.branchs[j].branchID){
                      response.data.value[i]._id.branchName = response.data.branchs[j].branch;
                      break;  
                    }
                  }
                }
                this.setState({ [apiName] : response.data.value });
              }else{
                this.setState({ [apiName] :[] });  
              }
              break
            case 'top10-drink-sale':
              apiName = 'top10product'
              // response = axiosReq
              if(response.data.value.length){
                this.setState({ [apiName] : axiosReq.data.value });
              }else{
                this.setState({ [apiName] :[] });  
              }
              break
            case 'top10-topup-branch':
              apiName = 'top10topup'
              // response = axiosReq
              if(response.data.value.length){
                for(let i = 0; i < response.data.value.length; i++){
                  for(let j = 0;j < response.data.branchs.length; j++){
                    if(response.data.value[i]._id.branchID === response.data.branchs[j].branchID){
                      response.data.value[i]._id.branchName = response.data.branchs[j].branch;
                      break;  
                    }
                  }
                }
                this.setState({ [apiName] : response.data.value });
              }else{
                this.setState({ [apiName] :[] });  
              }
              break
            case 'alert':
              apiName = 'top10Alert'
              // response = axiosReq
              if(response.data.value.length){
                this.setState({ [apiName] : axiosReq.data.value });
              }else{
                this.setState({ [apiName] :[] });  
              }
              break
            case 'drink':
              let rtAmountSale = { 
                start: this.state.rtAmountSale.end, 
                end: axiosReq.data.value[1][0],
                duration: this.state.rtAmountSale.duration
              }
              let rtTransactionSale = { 
                start:this.state.rtTransactionSale.end, 
                end: axiosReq.data.value[0][0],
                duration: this.state.rtTransactionSale.duration
              }
              this.setState({ rtAmountSale, rtTransactionSale, [apiName] : axiosReq.data.value });
              break
              case 'topup':
                // console.log("========>", axiosReq.data.value[1], axiosReq.data.value[1].reduce((accumulator, currentValue) => accumulator + currentValue))
                let rtAmountTopup = { 
                  start: this.state.rtAmountTopup.end, 
                  end: axiosReq.data.value[1][0], 
                  duration: this.state.rtAmountTopup.duration
                }
                this.setState({ rtAmountTopup, [apiName] : axiosReq.data.value });
                break
              case 'transaction':
                apiName = 'latestTransactionList'
                if(axiosReq.data.value.length){
                  this.setState({ [apiName] : axiosReq.data.value });
                }else{
                  this.setState({ [apiName] :[] });  
                }
                break
            default :
              if(axiosReq.data.value){
                this.setState({ [apiName] : axiosReq.data.value });
              }
              break
          }
          
        } catch (error) {
          console.log(error.message);
          this.state.alertText = error.message
          return new Promise((resolve) => {
            resolve('error')
          })
        }
      }))
      //+-console.log("this.state ->", this.state, p)
      if(p.indexOf('error') >= 0) {
        this.setState({ 
          btLoading: false,
          alertShow: true,
          alertText: this.state.alertText, 
        }) 
      }
      this.setState({ btLoading: false })      
      
      // near time get data
      this.state.dataCallback = dataCallback   
      // console.log('rtTimeout old =>',this.state.rtTimeout) 
      if(this.rtTimeout != null){
        clearTimeout(this.rtTimeout)
      }
      //let tOut = window.dashboardPage.rtGetRealtime 
      // console.log("tOut ->",tOut, dataCallback);
      //this.rtTimeout = setTimeout(() => this.getbranchs(this.state.dataCallback), tOut*1000) //close
      // console.log('rtTimeout new =>',this.state.rtTimeout)
      this.getRealtime(this.state.users.branchID) //close

    }
  }

  detailBestSeller = () => {
    //calculate your data here
    //then redirect:
    let value = {
      //urls:"http://13.251.52.58:3001/api/v1/report/best-product",
      urls: window.url + "api/v1/report/best-product",
      title:"Detail best seller",
      header:["ITEM","SKU","PRODUCT","TRANSACTION","AMOUNT(THB)"],
      defaultValue:[],
      defaultSum:[],
      countPerPage:20,
      BranchsSelect: this.state.branchs,
      BranchidSelect:this.state.users.BranchidSelect,
      OrganizationSelect:this.state.users.OrganizationSelect
    }

    let location = {
      pathname: '/detaildashboard',
      state: {
        dataSend: value
      }
    }

    // let location = {
    //   pathname: '/detailcampaign',
    //   state: { id: '0184', organization: 'ppetech'}
    // }
    
    // this.state.location = location
    // this.setState(state => ({
		// 	redirect: true
    // }));
    
    this.setState({
      redirect: true,
      location: location
		});

    // this.props.history.push({
    //   pathname: 'detaildashboard',
    //   state: {
    //     dataSend: value
    //   }
    // });


    // return (
    //   <Redirect
    //     to={{
    //       pathname: "/detaildashboard",
    //       state: {
    //         dataSend: value
    //       }
    //     }}
    //   />
    // )
  }

  // detailBranch = (item) => {
  //   // console.log('detailBranch item =>', item);
  //   let location = {
  //     pathname: '/branchsetting',
  //     state: { id: item._id, organization: item.organization}
  //   }
    
  //   this.state.location = location
  //   this.setState(state => ({
	// 		redirect: true
	// 	}));
  // }

  detailBestSellingBranch = () => {
    //calculate your data here
    //then redirect:
    let value = {
      //urls:"http://13.251.52.58:3001/api/v1/report/best-branchs",
      urls: window.url + "api/v1/report/best-branchs",
      title:"Detail best selling branch",
      header:["ITEM","VENDING ID","VENDING NAME","LOCATION","TRANSACTION","AMOUNT(THB)"],
      defaultValue:[],
      defaultSum:[],
      countPerPage:20,
      BranchsSelect: this.state.branchs,
      BranchidSelect:this.state.users.BranchidSelect,
      OrganizationSelect:this.state.users.OrganizationSelect
    }
    this.props.history.push({
      pathname: '/detaildashboard',
      state: {
        dataSend: value
      }
    });
  }

  detailMosttopUpBranches = () => {
    //+-console.log('detailMosttopUpBranches this.state.branchs =>', this.state.branchs)
    //calculate your data here
    //then redirect:
    let value = {
      //urls:"http://13.251.52.58:3001/api/v1/report/best-topup-branchs",
      urls: window.url + "api/v1/report/best-topup-branchs",
      title:"Detail most top-up branches",
      header:["ITEM","VENDING ID","TRANSACTION","AMOUNT(THB)"],
      defaultValue:[],
      defaultSum:[],
      countPerPage:20,
      BranchsSelect: this.state.branchs,
      BranchidSelect:this.state.users.BranchidSelect,
      OrganizationSelect:this.state.users.OrganizationSelect
    }
    this.props.history.push({
      pathname: '/detaildashboard',
      state: {
        dataSend: value
      }
    });
  }

  detailAlert = () => {
    //calculate your data here
    //then redirect:
    let value = {
      //urls:"http://13.251.52.58:3001/api/v1/report/alert-report",
      urls: window.url + "api/v1/report/alert-report",
      title:"Detail Alert",
      header:["ITEM","VENDING ID","VENDING NAME","STATUS","TYPE","ACTION","MESSAGE","DATE"],
      defaultValue:[],
      defaultSum:[],
      countPerPage:20,
      BranchsSelect: this.state.branchs,
      BranchidSelect:this.state.users.BranchidSelect,
      OrganizationSelect:this.state.users.OrganizationSelect,
      _sortNatural: -1
    }
    this.props.history.push({
      pathname: '/detaildashboard',
      state: {
        dataSend: value
      }
    });
  }


  getRealtime = async (branchID) => {
    // console.log("getRealtime ...");
    // let THIS = this
    let organization = this.state.users.OrganizationSelect
    let collections = ['alert']
    let token = this.state.users.token
    let ds = new Date()
    let de = new Date()
    ds.setHours(0, 0, 0, 0)
    // let branchID = []
    let values = { start: ds.getTime(), end: de.getTime(), branchID: branchID }

    let p = await Promise.all(collections.map(async (collection) => {
      let axiosReq = {}
      // let response = {}
      try {
        axiosReq = await axios({ method: 'post', url: window.url + "api/v1/dashboard/count/"+organization+'/'+collection, data: values, headers:{ "Authorization": token } })
        // console.log("axiosReq "+organization+', '+collection+" ->", axiosReq.data.value)
        switch(collection) {           
          case 'alert':
            let rtAlertMessages= { 
              start: this.state.rtAlertMessages.end, 
              end: axiosReq.data.value, 
              duration: this.state.rtAlertMessages.duration
            }
            this.setState({ rtAlertMessages });
            break
          // default :
          //   this.setState({ [apiName] : axiosReq.data.value });
          //   break
        }
        
      } catch (error) {
        console.log(error.message);
        this.state.alertText = error.message
        return new Promise((resolve) => {
          resolve('error')
        })
      }
    }))
    // console.log("getRealtime p ->", p)
    if(p.indexOf('error') >= 0) {
      this.setState({ 
        alertShow: true,
        alertText: this.state.alertText, 
      }) 
    }     
  }

  alertBadge = (item, index) => {
    if(item.length > 40){
      item = item.substring(0, 37)+"..."
    }
    if(index!=1) return <Table.Col key={index}>{item}</Table.Col>
    item = item.split(' ').join('')
    let badge ='secondary'
    switch(item) {
      case 'error': badge='danger'; break;
      // case 'worning': 
      case 'warning': badge='warning'; break;
      case 'info': badge='info'; break;
    }
    return <Table.Col key={index}><Badge color={badge} className="my-2" key={index}>{item}</Badge></Table.Col>
  }

  render() {

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )  //redirec

    let top10Branch = this.state.top10branch
    let top10Product = this.state.top10product
    let top10topup = this.state.top10topup
    let top10Alert = this.state.top10Alert
    //users = storeGlobal.getthis.state.users();
 
    const vendingStatusCard = window.dashboardPage.vendingStatus
    const saleCard = window.dashboardPage.sale
    const topupCard = window.dashboardPage.topup
    const top10TopupVendingCard = window.dashboardPage.top10TopupVending
    const top10SellingVendingCard = window.dashboardPage.top10SellingVending
    const top10SalesProductCard = window.dashboardPage.top10SalesProduct
    const tenLatestAlertCard = window.dashboardPage.tenLatestAlert

    const rtAmountSale = window.dashboardPage.rtAmountSale
    const rtTransactionSale = window.dashboardPage.rtTransactionSale
    const rtAmountTopup = window.dashboardPage.rtAmountTopup
    const rtAlertMessages = window.dashboardPage.rtAlertMessages 

    const latestTransaction = window.dashboardPage.latestTransaction
    
    // console.log("Dashboard user -->",users)
    return (
      <SiteWrapper user={this.state.users} props = {this.props}>
        <Page.Content title="Dashboard">
        { (this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
        
          <SelectBranchs 
            typeSelectFilter = {this.state.typeSelectFilter}
            getbranchs = {this.getbranchs} 
            // organization = {this.state.organization}
            // project = {this.state.project}
            // branchs = {this.state.branchID}
            optionOrganizations = {this.state.optionOrganizations}
            optionProjects = {this.state.optionProjects}
            optionBranchs = {this.state.optionBranchs}
            btLoading={this.state.btLoading}
          />
        }

          <Grid.Row cards={true}>

            <Grid.Col sm={6} lg={3}>
              <StampCard
                color={rtAmountSale.color}
                icon={rtAmountSale.icon}
                className="" 
                header={
                  <a href="#">
                    <CountUp start={this.state.rtAmountSale.start} end={this.state.rtAmountSale.end} duration={this.state.rtAmountSale.duration} /> 
                    <small> {rtAmountSale.header}</small>
                  </a>
                }                 
                footer={rtAmountSale.footer}           
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <StampCard
                color={rtTransactionSale.color}
                icon={rtTransactionSale.icon}
                className="" 
                header={
                  <a href="#">
                    <CountUp start={this.state.rtTransactionSale.start} end={this.state.rtTransactionSale.end} duration={this.state.rtTransactionSale.duration} /> 
                    <small> {rtTransactionSale.header}</small>
                  </a>
                }                 
                footer={rtTransactionSale.footer}           
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <StampCard
                color={rtAmountTopup.color}
                icon={rtAmountTopup.icon}
                className="" 
                header={
                  <a href="#">
                    <CountUp start={this.state.rtAmountTopup.start} end={this.state.rtAmountTopup.end} duration={this.state.rtAmountTopup.duration} /> 
                    <small> {rtAmountTopup.header}</small>
                  </a>
                }                 
                footer={rtAmountTopup.footer}           
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <StampCard
                color={rtAlertMessages.color}
                icon={rtAlertMessages.icon}
                className="" 
                header={
                  <a href="#">
                    <CountUp start={this.state.rtAlertMessages.start} end={this.state.rtAlertMessages.end} duration={this.state.rtAlertMessages.duration} /> 
                    <small> {rtAlertMessages.header}</small>
                  </a>
                }                 
                footer={rtAlertMessages.footer}           
              />
            </Grid.Col>
        
            <Grid.Col lg={3}>
              <Card>
                <Card.Header>
                  <Card.Title>{vendingStatusCard.title}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <C3Chart
                    style={{ height: "12rem" }}     
                    data= {{
                      // iris data from R
                      columns: [
                        ["data1", this.state.online.online],
                        ["data2", (this.state.online.total-this.state.online.online)],
                      ],
                      type : 'pie',
                      // onclick: function (d, i) { console.log("onclick", d, i); },
                      // onmouseover: function (d, i) { console.log("onmouseover", d, i); },
                      // onmouseout: function (d, i) { console.log("onmouseout", d, i); },
                      colors: {
                        data1: colors[vendingStatusCard.colors[0]],
                        data2: colors[vendingStatusCard.colors[1]],
                      }, 
                      names: {
                        // name of each serie
                        data1: vendingStatusCard.itemEvent[0],
                        data2: vendingStatusCard.itemEvent[1], 
                      },   
                    }}
                    pie= {{
                      label: {
                        format: function(value, ratio, id) {
                          return value;
                        }
                      }
                    }}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>

            <Grid.Col lg={5}>
              <Card>
                <Card.Header>
                  <Card.Title>{saleCard.title}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <C3Chart
                    style={{ height: "12rem" }}
                    data = {{
                      columns: [
                        // each columns data
                        ["data1", this.state.drink[0][0],this.state.drink[0][1],this.state.drink[0][2],this.state.drink[0][3]],
                        ["data2", this.state.drink[1][0],this.state.drink[1][1],this.state.drink[1][2],this.state.drink[1][3]],
                        // ['data1', 30, 200, 100, 400, 150, 250],
                        // ['data2', 130, 100, 140, 200, 150, 50]
                      ],
                      type: "bar", // default type of chart
                      //groups: [["data1", "data2"]],
                      colors: {
                        data1: colors[saleCard.colors[0]],
                        data2: colors[saleCard.colors[1]],
                      }, 
                      names: {
                        // name of each serie
                        data1: saleCard.itemEvent[0],
                        data2: saleCard.itemEvent[1], 
                      }, 
                    }}
                    axis={{
                      x: {
                        type: "category",
                        categories: saleCard.categories,
                      },
                    }}
                    // legend={{
                    //   show: false, //hide legend
                    // }}
                    padding={{
                      bottom: 0,
                      top: 0,
                    }}
                    
                  />
                </Card.Body>
              </Card>
            </Grid.Col>

            <Grid.Col lg={4}>
              <Card>
                <Card.Header>
                  <Card.Title>{topupCard.title}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <C3Chart
                    style={{ height: "12rem" }}
                    data = {{
                      columns: [
                        // // each columns data
                        ["data1", this.state.topup[0][0], this.state.topup[0][1], this.state.topup[0][2]],
                        ["data2", this.state.topup[1][0], this.state.topup[1][1], this.state.topup[1][2]],
                        // ['data1', 30, 200, 100, 400, 150, 250],
                        // ['data2', 130, 100, 140, 200, 150, 50]
                      ],
                      type: "bar", // default type of chart
                      //groups: [["data1", "data2"]],
                      colors: {
                        data1: colors[topupCard.colors[0]],
                        data2: colors[topupCard.colors[1]],
                      }, 
                      names: {
                        // name of each serie
                        data1: topupCard.itemEvent[0],
                        data2: topupCard.itemEvent[1], 
                      }, 
                    }}
                    axis={{
                      x: {
                        type: "category",
                        // name of each category
                        categories: topupCard.categories,
                      },
                    }}
                    // legend={{
                    //   show: false, //hide legend
                    // }}
                    padding={{
                      bottom: 0,
                      top: 0,
                    }}
                  />

                  </Card.Body>
              </Card>
            </Grid.Col>

          </Grid.Row>
          
          <Grid.Row cards={true}>
            <Grid.Col>
              <Card>
                <Card.Header>
                  <Card.Title>{latestTransaction.title}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <RTChart
                    style={{ height: "12rem" }}
                    chart={{
                      axis: {
                        // y: { min: 50, max: 100 }
                        // x: { max: 20 },
                      },
                      point: {
                        show: false
                      },
                      data: {
                        colors: {
                          ['Sale']: colors['red'],
                          ['Top-Up']: colors['green'],
                        }
                      }
                    }}
                    flow={{duration: 1500}}
                    fields={['Sale','Top-Up']}
                    data={this.state.latestTransactionData} 
                    maxValues={40}
                  />
                 
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                    <Table.Header>
                      <Table.Row>
                        { latestTransaction.tableHead.map( (tableHead,index) =>
                          <Table.ColHeader key={index}>{tableHead}</Table.ColHeader>
                        )}                       
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      { this.state.latestTransactionList.map( (items,indexs) =>
                        <Table.Row key={indexs}>
                          { items.map( (item, index) =>
                            <Table.Col key={index}>{item}</Table.Col>
                          )}
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            
          <Grid.Col lg={6}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>Most top-up branch</Card.Title> */}
                  <Card.Title>{top10TopupVendingCard.title}</Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{top10TopupVendingCard.tableHead[0]}</Table.ColHeader>
                      <Table.ColHeader>{top10TopupVendingCard.tableHead[1]}</Table.ColHeader>
                      <Table.ColHeader>{top10TopupVendingCard.tableHead[2]}</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {top10topup.map( (item, index) =>
                      <Table.Row key={index}>
                      <Table.Col>{item._id.branchName}</Table.Col>
                      <Table.Col>{item.count}</Table.Col>
                      <Table.Col>{item.totalAmount}</Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {/* <a class="text-right" onClick={this.detailMosttopUpBranches}> more... &nbsp;&nbsp;</a> */}
                <Button icon="plus" color="primary" outline onClick={this.detailMosttopUpBranches}>More</Button>
              </Card>
              {/* <a class="text-right" href="" onClick={this.detailMosttopUpBranches}> more... &nbsp;&nbsp;</a> */}
            </Grid.Col>
            
            <Grid.Col lg={6}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>Best selling branch</Card.Title> */}
                  <Card.Title>{top10SellingVendingCard.title}</Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{top10SellingVendingCard.tableHead[0]}</Table.ColHeader>
                      <Table.ColHeader>{top10SellingVendingCard.tableHead[1]}</Table.ColHeader>
                      <Table.ColHeader>{top10SellingVendingCard.tableHead[2]}</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {top10Branch.map( (item, index) =>
                      <Table.Row key={index}>
                      <Table.Col>{item._id.branchName}</Table.Col>
                      <Table.Col>{item.count}</Table.Col>
                      <Table.Col>{item.totalAmount}</Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {/* <a class="text-right" onClick={this.detailBestSellingBranch}> more... &nbsp;&nbsp;</a> */}
                <Button icon="plus" color="primary" outline onClick={this.detailBestSellingBranch}>More</Button>
              </Card>
              {/* <a class="text-right" href="" onClick={this.detailBestSellingBranch}> more... &nbsp;&nbsp;</a> */}
            </Grid.Col>
            </Grid.Row>

            <Grid.Row>
            <Grid.Col lg={6}>
              <Card>
                <Card.Header>
                  <Card.Title>{top10SalesProductCard.title}</Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{top10SalesProductCard.tableHead[0]}</Table.ColHeader>
                      <Table.ColHeader>{top10SalesProductCard.tableHead[1]}</Table.ColHeader>
                      <Table.ColHeader>{top10SalesProductCard.tableHead[2]}</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {top10Product.map( (item, index) =>
                      <Table.Row key={index}>
                      <Table.Col>{item.name}</Table.Col>
                      <Table.Col>{item.count}</Table.Col>
                      <Table.Col>{item.totalAmount}</Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
{/* 
                onClick={ () => this.detailCampaign(item) }
                                > */}
                {/* <a class="text-right" onClick={ () => this.detailBestSeller()}> more...&nbsp;&nbsp;</a> */}
                <Button icon="plus" color="primary" outline onClick={ () => this.detailBestSeller()}>More</Button>
              </Card>
              {/* <a class="text-right" href="" onClick={this.detailBestSeller}> more... </a> */}
            </Grid.Col>

            <Grid.Col lg={6}>
              <Card>
                <Card.Header>
                  <Card.Title>{tenLatestAlertCard.title}</Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{tenLatestAlertCard.tableHead[0]}</Table.ColHeader>
                      <Table.ColHeader>{tenLatestAlertCard.tableHead[1]}</Table.ColHeader>
                      <Table.ColHeader>{tenLatestAlertCard.tableHead[2]}</Table.ColHeader>
                      <Table.ColHeader>{tenLatestAlertCard.tableHead[3]}</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {top10Alert.map( (item, indexs) =>
                      <Table.Row key={indexs}>
                      {item.map( (item1, index) =>
                        // <Table.Col>{item1}</Table.Col>
                        this.alertBadge(item1, index)
                      )}
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                {/* <a class="text-right"  onClick={this.detailAlert}> more... &nbsp;&nbsp;</a> */}
                <Button icon="plus" color="primary" outline onClick={this.detailAlert}>More</Button>

              </Card>
              {/* <a class="text-right" href="" onClick={this.detailAlert}> more... &nbsp;&nbsp;</a> */}
            </Grid.Col>

          </Grid.Row>

          <SweetAlert
            warning
            show={this.state.alertShow}
            title="Oops!"
            onConfirm={() => this.setState({ alertShow: false })}
          >
            {this.state.alertText}
          </SweetAlert>

        </Page.Content>
      </SiteWrapper>
    );
  }
}

export default Dashboard;

