import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Form,
  // Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalFormLarge from "../components/ModalFormLarge";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
// import SelectBranchsLess from "../components/SelectBranchsLess";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';

class RoleMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      branchs: [],
      usersRes: [],
      branchsPage: [],
      branchEdit: null,

      modalAddProjectShow: false,
      modalShow: false,
      modalEditProjectShow: false,
      modalTitle: 'Add role',
      modalWorning:'',
      modalForm: [
        { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col:1 },
        { key: "description", name: "Description", placeholder:"", value: "", disabled: false, type: 'textarea', row: 3, col:1 }, 
        // { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi' , items: [
        //     'p1', 'p2', 'p3',
        // ] }, 
        // { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select', items: [
        //   'p1', 'p2', 'p3',
        // ] }, 
      ],  

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      branchsPageLimit: window.roleManPage.tableList, 

      redirect: false,
      location: {},
      home:[],

      treeData: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      currentRole: [],
      permissionDefault: [],

      searchText: '',
      searchButton: true,

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization"

      // SelectBranchsLess
      // SelectBranchsLess : {
      //   title: 'Select filter...',
      //   worning: "",
      //   organizations: [], //[ { value: 'organizations', label: 'Organizations' }, ],
      //   projects: [],//[ { value: 'projects', label: 'Projects' }, ],
      //   branchs: [],//[ { value: 'branchs', label: 'Branchs' }, ],
      // }
      
    };


    // this.handleClick = this.handleClick.bind(this);

  }
    
  // componentDidMount() {
  //   this.getbranchs(null)
  //   this.getPermissionDefault(null)
  // }

  componentDidMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){
      //console.log('this.state.user --->', this.state.user)
      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      // let values = {
      //   organization: this.state.user.OrganizationSelect,
      //   branchID: this.state.user.branchID,
      //   startDate: (new Date()).setHours(0,0,0,0),
      //   endDate: new Date()
      // }

      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: this.state.user.OrganizationSelect,
        // startDate: values.startDate,
        // endDate: values.endDate,
      }

      this.setState({
        OrganizationSelect: users.OrganizationSelect,
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },() =>{
        this.getbranchs(dataCallback)
        //this.getPermissionDefault(null)
      })
    }
  }

  componentWillMount() {       
    //+-console.log('window.url --->', window.url)

    // SelectBranchsLess
    // let SelectBranchsLess = {
    //   title: 'Select filter',
    //   worning: "",
    //   organizations: [],
    //   projects: [ { value: 'all', label: 'ALL' }, ],
    //   branchs: [ { value: 'all', label: 'ALL' }, ],
    // } 
    // for(let i=0; i<this.state.user.organization.length; i++) {
    //   SelectBranchsLess.organizations.push({ value: this.state.user.organization[i], label: this.state.user.organization[i] })
    // }
    // for(let i=0; i<this.state.user.project.length; i++) { 
    //   SelectBranchsLess.projects.push({ value: this.state.user.project[i].projectName, label: this.state.user.project[i].projectName })
    // }
    // for(let i=0; i<this.state.user.branchID.length; i++) {
    //   SelectBranchsLess.branchs.push({ value: this.state.user.branchID[i], label: this.state.user.branchID[i] })
    // }
    // this.setState({SelectBranchsLess: SelectBranchsLess}); 

  }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.branch}"
            </SweetAlert>
          )
        }));
        break
        default:
        break
    }
		
  } 

  deleteBranch(branch) {
    //+-console.log('deleteBranch =>', branch)
    let THIS = this
    axios({
      // method: 'delete',
      // url: window.url + 'api/v1/collection/' + this.state.user.organization[0] + '/' + 'role' + '/' + branch.id,
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/role/' + branch.id,
      data: { updateBy: this.state.user.username, flag: 'delete' },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      // console.log('deleteUser response.data.value =>', response.data.value);  
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'roloMan',
        "category" : 'webApp',
        "even" : 'Delete role',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete role =>'+ branch.branch
      }
      THIS.saveLog(dataSave)

      let branchs = THIS.state.branchs 
      branchs = branchs.filter(person => person._id !== branch.id)
      THIS.setState({ 
        branchs: branchs
      },() => {        
        THIS.paginationItemCal()
        // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
        if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
      })
      THIS.hideAlert()

      let branchsPage = THIS.state.branchsPage 
      branchsPage = branchsPage.filter(person => person._id !== branch.id)
      // console.log('branchsPage =>', branchsPage); 
      THIS.state.branchsPage = branchsPage
      THIS.setState({branchsPage: branchsPage})
      if(!branchsPage.length) THIS.paginationActive(1)
      
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
    
  }
  
  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.branchsPage(page)
  }

  branchsPage(page) {
		let branchsPage = []
    let start = (page-1) * this.state.branchsPageLimit
    let end = start + this.state.branchsPageLimit
    if(end >= this.state.branchs.length ) {
      end = this.state.branchs.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      this.state.branchs[i].index = i+1
      branchsPage.push(this.state.branchs[i])
    }
    this.setState({branchsPage: branchsPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.branchs.length; i+=this.state.branchsPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }
    //this.state.paginationItem = paginationItem
    this.setState({paginationItem: paginationItem})
  }
  
  getbranchs = (dataCallback) => {
    //+-console.log("getbranchs ->",dataCallback);
    // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    this.setState({OrganizationSelect: dataCallback.OrganizationSelect})
    let THIS = this
    let user = this.state.user
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/role?', 
      // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
      data: 'values',
      headers:{
        "Authorization": user.token
      }
    })
    .then(function (response) {
      // console.log('response.data.value =>', response.data.value);  
      // return
      let branchs = response.data.value
      // console.log('=====>', dataCallback.BranchidSelect); 
      // filter
      branchs = branchs.filter(person => {
        // if(person.branchID) {
        //   if(dataCallback.BranchidSelect.indexOf(person.branchID) >= 0) {
        //     // console.log('=====> 2', person.branchID); 
        //     return true
        //   }          
        // }  
        // if(person.name === 'sadmin') return false   
        // return person.flag !== 'delete' 

          //if((person.name === 'sadmin') && user.role !== 'sadmin') return false   
          if(person.name === 'sadmin') return false   
          if((person.name === 'owner') && !((user.role === 'sadmin') || (user.role === 'owner')) ) return false   
          return person.flag !== 'delete'  
      });
      //+-console.log('branchs 1 -->', branchs)
      // sort by value
      // items.sort(function (a, b) {
      //   return a.value - b.value;
      // });
      // sort by name
      branchs.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      // THIS.setState({users: response.data.value})
      // console.log('branchs 2 -->', branchs)
      THIS.setState({
        branchs: branchs,
        usersRes: branchs,
      },() => {
        
        THIS.paginationItemCal()
        THIS.branchsPage(1)
        THIS.getPermissionDefault(null)


      });
    }) 
    .catch(function (error) {
      console.error(error);
      // alert("error: " + JSON.stringify(error) );
    });
  }

  // getPermissionDefault = (dataCallback) => {
  //   //+-console.log("getPermissionDefault..",dataCallback);
  //   // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
  //   if(JSON.stringify(this.state.user) !== '{}'){
  //     let users = this.state.user
  //     let urls =  window.url + 'api/v1/collection/_/permissionDefault'
  //     if(users.role !== "sadmin"){
  //       urls =  window.url + 'api/v1/collection/'+ users.OrganizationSelect +'/permissionDefault'
  //     }

  //     let THIS = this
  //     axios({
  //       method: 'get',
  //       url:urls, 
  //       data: 'values',
  //       headers:{
  //         "Authorization": users.token
  //       }
  //     })
  //     .then(function (response) {
  //       //+-console.log('getPermissionDefault response -->', response)
  //       let permissionDefault = response.data.value

  //       THIS.setState({
  //         permissionDefault: permissionDefault
  //       })
  //     }) 
  //     .catch(function (error) {
  //       console.error(error);
  //       // alert("error: " + JSON.stringify(error) );
  //     });
  //   }
  // }

  getPermissionDefault = (dataCallback) => {
    //+-console.log("getPermissionDefault..",dataCallback);
    // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    if(JSON.stringify(this.state.user) !== '{}'){
      let users = this.state.user
      let urls =  window.url + 'api/v1/collection/_/permissionDefaults'
      if(users.role !== "sadmin"){
        urls =  window.url + 'api/v1/collection/'+ users.OrganizationSelect +'/permissionDefault'
      }

      let THIS = this
      axios({
        method: 'get',
        url:urls, 
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log('getPermissionDefault response -->', response)
        let permissionDefault =[]
        response.data.value.forEach(currentValue => {
          permissionDefault = permissionDefault.concat(currentValue.menu)
        })

        //console.log('permissionDefault =>',permissionDefault)

        THIS.setState({
          permissionDefault: permissionDefault
        })
      }) 
      .catch(function (error) {
        console.error(error);
        // alert("error: " + JSON.stringify(error) );
      });
    }
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddProjectShow: false })
    this.setState({ modalWorning: "", modalShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    // console.log('getForm modalForm =>', this.state.modalForm);   
    let data = {}
    let modalForm = this.state.modalForm
    for(let i=0; i<this.state.modalForm.length; i++) {

      if(modalForm[i].key === 'name') {
        if(modalForm[i].value === 'sadmin'){
          return this.setState({modalWorning: "Cannot use reserved words!"})
        }  
      }

      if(modalForm[i].value === '') { 
        // console.log('modalForm[i].value == ""', modalForm[i]);
        return this.setState({modalWorning: "Input is blank!"})
      }

      if(modalForm[i].type === 'tree') {
        if(!modalForm[i].tree.checkedKeys.length) {
          return this.setState({modalWorning: "Permission is blank!"})
        }
        modalForm[i].value = modalForm[i].tree.checkedKeys
         
        // check tree to menu
        let permission = modalForm[i].value
        let permissionDefault = JSON.parse(JSON.stringify(this.state.permissionDefault))
        // for(let i=0; i<permissionDefault.length; i++) {  
        for(let i=permissionDefault.length-1; i>=0; i--) {  
          let subItems1 = permissionDefault[i].subItems
          if(subItems1) {
            if(!subItems1.length) {              
              if(permission.indexOf(permissionDefault[i].value) < 0) {
                // console.log('!subItems1.length delete permissionDefault[i] =>', i, permissionDefault[i]);
                // delete permissionDefault[i]
                permissionDefault.splice(i,1)
              }
            } else {
              // for(let j=0; j<subItems1.length; j++) {  
              for(let j=subItems1.length-1; j>=0; j--) { 
                let subItems2 = permissionDefault[i].subItems[j].subItems
                if(subItems2) {
                  if(!subItems2.length) {                    
                    if(permission.indexOf(subItems1[j].value) < 0) {
                      // console.log('delete subItems1[j] =>', subItems1[j]);
                      // delete subItems1[j]
                      subItems1.splice(j,1)
                    }
                  } else {
                    // for(let k=0; k<subItems2.length; k++) { 
                    for(let k=subItems2.length-1; k>=0; k--) { 
                      if(permission.indexOf(subItems2[k].value) < 0) {
                        // console.log('delete subItems2[k] =>', subItems2[k]);
                        // delete subItems2[k]
                        subItems2.splice(k,1)
                      }
                    }
                  }
                } else {
                  // console.log('subItems1[j].value =>', j, subItems1[j].value);
                  if(permission.indexOf(subItems1[j].value) < 0) {
                    //+-console.log('==== delete subItems1[j] 1 =>', subItems1[j]);
                    // delete subItems1[j]
                    subItems1.splice(j,1)
                  }
                }    
                // console.log('--- subItems1 =>', j, subItems1);  
                // if(typeof subItems1[j] === 'undefined') { //.length
                //   subItems1.splice(j,1)
                // } else if(!subItems1[j].length) { //.length
                //   subItems1.splice(j,1)
                // } 
              }
              // console.log('--- permissionDefault[i] =>', i, permissionDefault[i]);
              if(permissionDefault[i]) {
                if(typeof permissionDefault[i].subItems === 'undefined') {
                  permissionDefault.splice(i,1)
                } else if(!permissionDefault[i].subItems.length) {
                  permissionDefault.splice(i,1)
                }
              }   
            }
            
          } else {
            if(permission.indexOf(permissionDefault[i].value) < 0) {
              // console.log('delete permissionDefault[i] =>', i, permissionDefault[i]);
              // delete permissionDefault[i]
              permissionDefault.splice(i,1)
            }
          }  
        }
        data.menu = permissionDefault
        // end check tree to menu
      }

      data[modalForm[i].key] = modalForm[i].value

      // change menu 'value' to 'to' homepage 
      if(modalForm[i].key === 'home') {
        data.homeSelect = modalForm[i].value
        let permissionDefault = this.state.permissionDefault
        for(let ii=0; ii<permissionDefault.length; ii++) {  
          if(modalForm[i].value === permissionDefault[ii].value) {
            data[modalForm[i].key] = permissionDefault[ii].to.split('/').join('')
            break
          }
          for(let jj=0; jj<permissionDefault[ii].subItems.length; jj++) {  
            if(modalForm[i].value === permissionDefault[ii].subItems[jj].value) {
              data[modalForm[i].key] = permissionDefault[ii].subItems[jj].to.split('/').join('')
              break
            }
          }
        }
      }

    }

    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    data.flag = 'add'
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);

    if(this.state.branchEdit) {
      data.flag = 'edit'
      this.getFormEdit(data)
      return
    }
    
    let THIS = this
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect +'/role',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Add project response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let branchAdd = response.data.value[0]
      THIS.state.branchsPage.unshift(branchAdd)
      THIS.setState({
        branchsPage: THIS.state.branchsPage,
        modalForm: modalForm
      })

      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'roleMan',
        "category" : 'webApp',
        "even" : 'New role',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Add new role =>'+ data.name
      }
      THIS.saveLog(dataSave)

      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit user error!"})
    });

  }

  getFormEdit = (data) => {
    //+-console.log('getForm data =>', data);
    // return
    let THIS = this
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/role/' + this.state.branchEdit._id,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Edit api response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}

      for (var k in data) {
        THIS.state.branchEdit[k] = data[k]
      }
      THIS.state.branchEdit.updateDate = new Date()
      for(let i=0; i<THIS.state.branchsPage.length; i++) {
        if(THIS.state.branchsPage[i]._id === THIS.state.branchEdit._id) {
          THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      for(let i=0; i<THIS.state.branchs.length; i++) {
        if(THIS.state.branchs[i]._id === THIS.state.branchEdit._id) {
          THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
        }
      }
      THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
      // THIS.state.branchPage.unshift(userAdd)
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'roleMan',
        "category" : 'webApp',
        "even" : 'Edit role',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Edit role =>'+ data.name
      }
      THIS.saveLog(dataSave)

      THIS.modalClose()
      THIS.state.branchEdit = null
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit api error!"})
      THIS.state.branchEdit = null
    });

  }

  addBranch = () => {
    //+-console.log('addBranch item =>');
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }

    let treeData =[]
    let permissionDefault = this.state.permissionDefault
    for(let i=0; i<permissionDefault.length; i++) {  
      let obj={
        title: permissionDefault[i].value,
        key: permissionDefault[i].value,
        children: [],
      }
      let subItems1 = permissionDefault[i].subItems
      if(subItems1) {
        for(let j=0; j<subItems1.length; j++) {  
          let obj1={
            title: subItems1[j].value,
            key: subItems1[j].value,
            children: [],
          }
          let subItems2 = permissionDefault[i].subItems[j].subItems
          if(subItems2) {
            for(let k=0; k<subItems2.length; k++) {  
              let obj2={
                title: subItems2[j].value,
                key: subItems2[j].value,
                children: [],
              }
              obj1.children.push(obj2)
            }
          }  
          obj.children.push(obj1)
        }
      }    
      treeData.push(obj)
    }
    let home = []
    let checkedKeys = []
    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: 1 }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: "", disabled: false, type: 'textarea', rows: 3, col: 1 },
      { key: "home", name: "Home page", placeholder:"", value: home[0], disabled: false, type: 'select', items: home, col: 1 },
      // { key: "routeman", name: "Route man", placeholder:"", value: true, disabled: false, type: 'checkbox', col: 1 }, 
      { key: "permission", name: "Permission", placeholder:"", value: true, disabled: false, type: 'tree', col: 2, 
        tree: {
          treeData: treeData,
          expandedKeys: this.state.expandedKeys,
          autoExpandParent: this.state.autoExpandParent,
          checkedKeys: checkedKeys,
          selectedKeys: this.state.selectedKeys,
          onExpand: this.onExpand,
          onCheck: this.onCheck,
          onSelect: this.onSelect,
        } 
      }, 
      { key: "level", name: "Level", placeholder:"", value: 'read', disabled: false, type: 'radios', items: [{isInline: true, label: 'read', name: 'Level', value: 'read'}, {isInline: true, label: 'write', name: 'Level', value: 'write'}], col: 2 },
      // { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]
    // console.log('modalForm =>', modalForm);
    let modalTitle = 'New role'
    this.setState({ 
      modalForm: modalForm, 
      modalAddProjectShow: true,
      modalShow: true, 
      modalTitle: modalTitle,
    })
  }

  editBranch = (item) => {
    //+-console.log('editBranch item =>', item);
    //this.state.branchEdit = item
    // return
    let treeData =[]
    let permissionDefault = this.state.permissionDefault
    for(let i=0; i<permissionDefault.length; i++) {  
      let obj={
        title: permissionDefault[i].value,
        key: permissionDefault[i].value,
        children: [],
      }
      let subItems1 = permissionDefault[i].subItems
      if(subItems1) {
        for(let j=0; j<subItems1.length; j++) {  
          let obj1={
            title: subItems1[j].value,
            key: subItems1[j].value,
            children: [],
          }
          let subItems2 = permissionDefault[i].subItems[j].subItems
          if(subItems2) {
            for(let k=0; k<subItems2.length; k++) {  
              let obj2={
                title: subItems2[j].value,
                key: subItems2[j].value,
                children: [],
              }
              obj1.children.push(obj2)
            }
          }  
          obj.children.push(obj1)
        }
      }    
      treeData.push(obj)
    }

    // home list
    let items = JSON.parse(JSON.stringify(item.permission))
    // let permissionDefault = this.state.permissionDefault
    for(let i=permissionDefault.length-1; i>=0; i--) { 
      let indexOfitems = items.indexOf(permissionDefault[i].value)
      if( indexOfitems >= 0) {
        if(permissionDefault[i].subItems.length) { 
          items.splice(indexOfitems,1)
        }        
      }         
    }
    let home = items

    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: true, type: 'input', col: 1 }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: item.description, disabled: false, type: 'textarea', rows: 3, col: 1 },
      { key: "home", name: "Home page", placeholder:"", value: item.homeSelect, disabled: false, type: 'select', items: home, col: 1 },
      // { key: "routeman", name: "Route man", placeholder:"", value: item.routeman, disabled: false, type: 'checkbox', col: 1 }, 
      { key: "permission", name: "Permission", placeholder:"", value: true, disabled: false, type: 'tree', col: 2, 
        tree: {
          treeData: treeData,
          expandedKeys: this.state.expandedKeys,
          autoExpandParent: this.state.autoExpandParent,
          checkedKeys: item.permission,
          selectedKeys: this.state.selectedKeys,
          onExpand: this.onExpand,
          onCheck: this.onCheck,
          onSelect: this.onSelect,
        } 
      }, 
      { key: "level", name: "Level", placeholder:"", value: item.level, disabled: false, type: 'radios', items: [{isInline: true, label: 'read', name: 'Level', value: 'read'}, {isInline: true, label: 'write', name: 'Level', value: 'write'}], col: 2 },
      // { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi', items: this.state.user.branchID }, 
    ]

    let modalTitle = 'Modify role'
    this.setState({ 
      modalForm: modalForm, 
      modalEditProjectShow: true,      
      branchEdit: item, 
      modalTitle: modalTitle,
      modalShow: true,
    }, () => {      
      
    })
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  statusOnline = (item) => {
    let connect = new Date(item.connect)
    let disconnect = new Date(item.disconnect)
    if(connect > disconnect) return <Text color={'green'}>Online</Text>
    return <Text color={'red'}>Offline</Text>
  }

  // modal funtions ====================================================================
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange RoleMan =>", name, value);
    let modalForm = this.state.modalForm
    // console.log("modalForm  =>", modalForm);
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value
        break
      }
    }
    //+-console.log("handleChange modalForm =>", modalForm);
    this.setState({modalForm: modalForm})

  }
  // tree
  onExpand = expandedKeys => {
    // console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
    let modalForm = this.state.modalForm
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.expandedKeys = expandedKeys
        modalForm[i].tree.autoExpandParent = false
        break
      }
    }
    this.setState({modalForm: modalForm})
  };

  onCheck = checkedKeys => {
    //+-console.log('onCheck', checkedKeys);
    this.setState({ checkedKeys });
    let modalForm = this.state.modalForm 
    let home = this.state.home
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.checkedKeys = checkedKeys
        home = checkedKeys
        //break
      }

      if(modalForm[i].key === 'home') {
        let items = JSON.parse(JSON.stringify(checkedKeys))
        let permissionDefault = this.state.permissionDefault

        for(let i=permissionDefault.length-1; i>=0; i--) { 
          let indexOfitems = items.indexOf(permissionDefault[i].value)
          if( indexOfitems >= 0) {
            if(permissionDefault[i].subItems.length) { 
              items.splice(indexOfitems,1)
            }
          }         
        }

        home = items
        modalForm[i].items = home
        modalForm[i].value = modalForm[i].items[0]
      }
    }
    this.setState({
      modalForm: modalForm,
      home: home
    })
  };

  onSelect = (selectedKeys, info) => {
    // console.log('onSelect', info);
    this.setState({ selectedKeys });
    let modalForm = this.state.modalForm 
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.selectedKeys = selectedKeys
        break
      }
    }
    this.setState({modalForm: modalForm})
  };
  // modal funtions end ====================================================================

  // SelectBranchsLess
  // onChangeSelectBranchsLess = () => {
  //   console.log("onChangeSelectBranchsLess");
  // }

  // onGoPressSelectBranchsLess = () => {
  //   console.log("onGoPressSelectBranchsLess");
  // }
 
  onSearch = () => {
    // console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
      let out = key!=='_id' && typeof(obj[key])=='string' && obj[key].includes(search) 
      // console.log('onSearch out =>', out);
      return out
    }))
    this.setState({searchButton: false, branchs: res}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
        
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddProjectShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content 
          title="Roles manager"
          options={ this.state.usersRes.length &&
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText || ''} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>            
          }
        >
        { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }
          <Card>
            <Card.Header>
              <Card.Title>Roles</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button 
                  color="green"                  
                  onClick={ () => this.addBranch() }
                > New Role </Button>                
              </Card.Options>
              }
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>ITEM</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader> 
                  <Table.ColHeader>Description</Table.ColHeader>
                  <Table.ColHeader>Level</Table.ColHeader>    
                  <Table.ColHeader>create By</Table.ColHeader>
                  <Table.ColHeader>update By</Table.ColHeader>
                  <Table.ColHeader>update date</Table.ColHeader>
                  {/* <Table.ColHeader>branchs</Table.ColHeader> */}
                  { this.state.user.level==='write' && <Table.ColHeader className="text-center">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { this.state.branchsPage.length ? this.state.branchsPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    <Table.Col> { ((this.state.paginationActive-1) * this.state.branchsPageLimit) + (i+1) }</Table.Col>
                    <Table.Col>{ item.name }</Table.Col>
                    <Table.Col>{ item.description }</Table.Col>
                    <Table.Col>{ item.level }</Table.Col>
                    <Table.Col>{ item.createBy }</Table.Col> 
                    <Table.Col>{ item.updateBy }</Table.Col> 
                    {/* <Table.Col>{ item.createDate }</Table.Col> */}
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.updateDate }
                      </Moment>
                    </Table.Col>
                    {/* <Table.Col>{ item.branchID.toString() }</Table.Col>   */}                    
                    { 
                      (((item.name==='owner') && (this.state.user.role !== 'sadmin'))) ? (<Table.Col className="text-center">
                        <Button.List>                        
                          <Button size="sm" color="default"
                            // onClick={ () => this.editBranch(item) }
                            >
                            Edit
                          </Button>
                          <Button size="sm" color="default" 
                            // onClick={ ()=> { this.alert({action:'delete', id: item._id, branch: item.name, i: i }) } } 
                            >
                            Delete
                          </Button>                        
                        </Button.List>
                      </Table.Col> ) :
                      (this.state.user.level==='write' && <Table.Col className="text-center">
                        <Button.List>                        
                          <Button size="sm" color="info"
                            onClick={ () => this.editBranch(item) }
                            >
                            Edit
                          </Button>
                          <Button size="sm" color="danger" 
                            onClick={ ()=> { this.alert({action:'delete', id: item._id, branch: item.name, i: i }) } } >
                            Delete
                          </Button>                        
                        </Button.List>
                      </Table.Col> )
                    }          
                  </Table.Row>
                ) : <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>
        
        <ModalFormLarge
          show={this.state.modalShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalTitle}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default RoleMan;